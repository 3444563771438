import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal/Modal'
import axios from 'axios'
import { useForm } from 'react-hook-form'

const UpdatePasswordModal = ({ display, setDisplay }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' })

  const handleUpdatePassword = async data => {
    axios.defaults.headers.Authorization = `Bearer ${window.localStorage.getItem(
      'jwt'
    )}`
    const { id } = JSON.parse(window.localStorage.getItem('me'))
    const [password, confirmPassword] = [data.password, data.confirmPassword]
    if (password === confirmPassword) {
      const url = `${process.env.REACT_APP_BASE_URL}api/v1/users/${id}/password?password=${password}`
      const res = await axios.post(url).catch(e => console.error(e))
      if (res) {
        setDisplay(false)
      }
    }
  }

  return (
    <Modal
      targetId='update-password-modal'
      display={display}
      setDisplay={setDisplay}
    >
      <div className='card-header'>
        <h5 className='card-title fw-500'>{`${t('UPDATE')} ${t('PASSWORD')}`}</h5>
        <i className='fa fa-close' onClick={() => setDisplay(false)}></i>
      </div>
      <div className='card-body'>
        <form
          onSubmit={handleSubmit(handleUpdatePassword)}
          className='form-type-material'
        >
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              name='password'
              id='password'
              ref={register({ required: true })}
            />
            <label htmlFor='password'>{t('NEW_PASSWORD')}</label>
          </div>
          <div className='form-group'>
            <input
              type='password'
              className='form-control'
              name='confirmPassword'
              id='confirmPassword'
              ref={register({ required: true })}
            />
            <label htmlFor='confirmPassword'>{t('CONFIRM_PASSWORD')}</label>
          </div>

          <br />
          <button
            className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label ${
              formState.isValid ? '' : 'disabled'
            }`}
            type='submit'
          >
            <span>
              <i className='ti-check'></i>
            </span>
            {t('UPDATE')}
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default UpdatePasswordModal
