import { gql } from '@apollo/client';

// Queries
export const student_mail_by_period = gql`
  query student_mail_by_period(
    $studentId: uuid
    $enrollmentPeriodSegmentId: uuid
    $offset: Int
    $limit: Int
  ) {
    students_email_logs(
      where: {
        student_id: { _eq: $studentId }
        enrollment_period_segment_id: { _eq: $enrollmentPeriodSegmentId }
      }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
    ) {
      created_at
      email_type
      id
      subject
      to_email_address
      student_id
      viewed
    }
    students_email_logs_aggregate(
      where: {
        student_id: { _eq: $studentId }
        enrollment_period_segment_id: { _eq: $enrollmentPeriodSegmentId }
      }
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

export const enrollment_periods_segments = gql`
  query enrollment_periods_segments(
    $schoolId: uuid
    $startDate: date
    $studentId: uuid
  ) {
    enrollment_periods_segments(
      order_by: { coverage_start_date: desc }
      where: {
        _and: {
          school_id: { _eq: $schoolId }
          coverage_start_date: { _lt: $startDate }
          students_eligibilities: { student_id: { _eq: $studentId } }
        }
      }
    ) {
      id
      segment_label
      enrollment_period {
        academic_year
      }
    }
  }
`;

export const student_emails_periods = gql`
  query student_emails_periods($studentId: uuid) {
    enrollment_periods_segments(
      where: { students_email_logs: { student_id: { _eq: $studentId } } }
      distinct_on: coverage_start_date
      order_by: { coverage_start_date: desc }
    ) {
      id
      enrollment_period {
        academic_year
      }
      segment_label
    }
  }
`;

export const students_eligibility_by_enrollment_period = gql`
  query students_eligibility_by_enrollment_period($studentId: uuid) {
    students_eligibility_by_enrollment_period(
      where: { student_id: { _eq: $studentId } }
    ) {
      enrollment_period_id
      id
      school_id
      segment_1
      segment_1_locked
      segment_2
      segment_2_locked
      segment_3
      segment_3_locked
      segment_4
      segment_4_locked
      student_id
      segment_1_type
      segment_2_type
      segment_1_level
      segment_2_level
    }
  }
`;
