import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import UsersContent from '../components/UsersContent'
import { useTranslation } from 'react-i18next'

const Users = () => {
  const { t } = useTranslation()
  return (
    <PageLayout withHeader={true} title={t('USERS')} crumbs={[]}>
      <UsersContent />
    </PageLayout>
  )
}

export default Users
