import React from 'react';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import { ApolloProvider } from '@apollo/client';
import './App.css';
import Router from './router/Router';
import { AlertService } from './services/AlertService';
import { DashboardStateProvider } from './context/DashboardContext'
import { client } from './client'

function refAlert(alertRef) {
  AlertService.setDropdownAlertInstance(alertRef);
}

function App() {

  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLE && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      siteSpeedSampleRate: 100,
    });
  } else {
    console.log('Google Analytics disabled')
  }

  ReactGA.event({
    category: 'User',
    action: 'Open App'
  });
  return (
    <div className='App'>
      <ApolloProvider client={client}>
        <DashboardStateProvider>
          <Router />
          <ToastContainer
            ref={refAlert}
          />
        </DashboardStateProvider>
      </ApolloProvider>
    </div>
  )
}

export default App