import React, { useCallback, useContext, useState } from 'react';

import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';
import { SchoolStateContext } from '../../../context/SchoolContext';
import AddShipFileModal from './AddShipFileModal';
import { StyledMain } from '../../../styles/common/main';
import ShipFileCard from './ShipFileCard';
import Spinner from '../../Spinner/index';

const EnrollmentPeriodShipAttachments = ({ period }) => {
  const [schoolId] = useContext(SchoolStateContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState();

  const [{ data: files, loading: loadingFiles }, getFiles] = useAuthorizedAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/periods/${period.id}/files`,
      method: 'GET'
    }
  );

  const handleOpenModal = useCallback((segment) => {
    setSelectedSegment(segment);
    setShowModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedSegment(undefined);
    setShowModal(false);
  }, []);

  return (
    <StyledMain className="container py-4">
      {selectedSegment && (
        <AddShipFileModal
          display={showModal}
          setDisplay={handleCloseModal}
          segment={selectedSegment}
          refetch={getFiles}
        />
      )}
      {loadingFiles ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-wrap">
          {files.segments.map((segment, index) => (
            <ShipFileCard
              key={index}
              title={segment.segment_label}
              segment={segment}
              files={segment.files}
              handleOpenModal={handleOpenModal}
              refetch={getFiles}
            />
          ))}
        </div>
      )}
    </StyledMain>
  );
};
export default EnrollmentPeriodShipAttachments;
