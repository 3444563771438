import React from 'react'
import Modal from '../Modal/Modal'
import useAxios from 'axios-hooks'

const DeleteModal = ({ display, setDisplay, toDelete, endpoint, onCloseModal, customMessage }) => {
  const [{ loading, error }, executeDelete] = useAxios(
    {
      url: endpoint,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  )

  const closeDeleteModal = () => {
    setDisplay(false)
  }

  const handleRemove = async () => {
    await executeDelete()
    if (!error && !loading) {
      onCloseModal()
      closeDeleteModal()
    }
  }

  return (
    <Modal targetId='delete-modal' display={display} setDisplay={setDisplay}>
      <header className='card-header'>
        <h5 className='card-title fw-500'>
          Delete{' '}
          {`${toDelete && toDelete[0].toUpperCase()}${toDelete &&
            toDelete.slice(1)}`}
        </h5>
        <button className='btn border-0' onClick={closeDeleteModal}>
          <i className='fa fa-close'></i>
        </button>
      </header>
      <div className='card-body'>
        <p className='text-center'>
          {customMessage
            ? customMessage
            : `Are you sure you want to delete this ${toDelete && toDelete}?`}
        </p>
        <div className='d-flex'>
          <button
            onClick={handleRemove}
            className='btn btn-bold d-block ml-auto btn-danger btn-label'
          >
            <span>
              <i className='ti-close'></i>
            </span>
            Remove
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
