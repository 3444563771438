import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import NewNote from "../NewNote";
import ChangeStatus from "../ChangeStatus";
import CoverageDates from "../CoverageDates";
import StatusCard from '../../StatusCard/index';
import ManualBilling from './ManualBilling';
import CardHeader from "../../CardHeader";
import { SchoolService } from '../../../services/SchoolService';



const EnrollmentTab = ({ student, submission, updatedAt, coveragePeriod, refetch, setUpdatedData }) => {
  const { t } = useTranslation();
  const [notesPermission, setNotesPermission] = useState(false);
  const [mBillingPermission, setMBillingPermissionPermission] = useState(false);
  const [adePermission, setADEPermission] = useState(false);
  const [wManualAppPermission, setWManualAppPermission] = useState(false);
  //Multiroles permissions
  const [CDPermission, setCDPermission] = useState(false);

  //Use of Multirole permissions
  SchoolService.rolePermission("STUDENTS_SUBMISSIONS:ADD_NOTES", student.school_id == undefined ? submission.segment.school_id : student.school_id, setNotesPermission);
  SchoolService.rolePermission("STUDENTS_SUBMISSIONS:MANUAL_BILLING", student.school_id == undefined ? submission.segment.school_id : student.school_id, setMBillingPermissionPermission);
  SchoolService.rolePermission("STUDENTS_SUBMISSIONS:MANUAL_APPROVAL", student.school_id == undefined ? submission.segment.school_id : student.school_id, setWManualAppPermission);
  SchoolService.rolePermission("STUDENTS_SUBMISSIONS:ADD_SHIP_ENROLLMENT", student.school_id == undefined ? submission.segment.school_id : student.school_id, setADEPermission);
  SchoolService.rolePermission("STUDENTS_SUBMISSIONS:COVERAGE_DATES", student.school_id == undefined ? submission.segment.school_id : student.school_id, setCDPermission);

  return (
    <>
      <div className="row">
        <div className="col-md-8 pb-4">
          <div className="card border border-light mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <small>{t("FIRST_NAME")}</small>
                  <p>
                    <strong>{student.first_name}</strong>
                  </p>
                </div>
                <div className="col-md-3">
                  <small>{t("LAST_NAME")}</small>
                  <p>
                    <strong>{student.last_name}</strong>
                  </p>
                </div>
                <div className="col-md-4">
                  <small>{t("PHONE")}</small>
                  <p>
                    <strong>{student.phone}</strong>
                  </p>
                </div>
                <div className="col-md-6">
                  <small>{t("EMAIL_ADDRESS")}</small>
                  <p>
                    <strong>{student.email_address}</strong>
                  </p>
                </div>
                <div className="col-md-3">
                  <small>{t("DATE_OF_BIRTH")}</small>
                  <p>
                    <strong>{moment(student.date_of_birth).format('MM/DD/YYYY')}</strong>
                  </p>
                </div>
                <div className="col-md-3">
                  <small>{t("GENDER")}</small>
                  <p><strong>{student.gender}</strong></p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card border border-light mb-0">
                <CardHeader title={t("CURRENT_STATUS")} />
                <div className="card-body">
                  <div className="d-flex">
                    <StatusCard status={submission.status} title={submission.status} titleStyle="fs-36 fw-900" />
                    <div className="ml-3">
                      <p className="m-0 fw-700">{t(`ENROLLMENTCODES_STATUS_CODES_${submission.status}_NAME`)}</p>
                      {/* <p className="m-0">{moment(updatedAt).format("MM/DD/YYYY")}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <ManualBilling submissionId={submission.id} manualBilling={submission.manual_billing} refetch={refetch} setUpdatedData={setUpdatedData} RolePermissionValue={mBillingPermission} />
            </div>
          </div>

        </div>
        <div className="col pb-4">
          <div className="card border border-light h-100 mb-0">
            <div className="card-body d-flex">
              <ChangeStatus type="ship" submissionId={submission.id} currentStatus={submission.status} refetch={refetch} setUpdatedData={setUpdatedData} RolePermissionValue={{ 'adePermission': adePermission }} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-8 pb-4 pr-2">
          <CoverageDates
            submissionId={submission.id}
            startDate={coveragePeriod.start}
            endDate={coveragePeriod.end}
            refetch={refetch}
            setUpdatedData={setUpdatedData}
            RolePermissionValue={CDPermission}
          />
        </div>
        <div className="col pb-4 pl-2">
          <div className="card border border-light h-100 mb-0">
            <div className="card-body d-flex">
              <NewNote submissionId={submission.id} header={false} setUpdatedData={setUpdatedData} RolePermissionValue={notesPermission} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollmentTab;
