import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import useAxios from 'axios-hooks';
import { useHistory } from "react-router-dom";
import { css } from "@emotion/core";
import { useTranslation } from 'react-i18next'
import CurrencyFormat from 'react-currency-format';

import { SchoolStateContext } from '../../context/SchoolContext'
import CardHeader from '../CardHeader/index';
import Spinner from '../Spinner';
import { StyledMain } from '../../styles/common/main';
import { AlertService } from '../../services/AlertService';
import Preloader from '../Preloader/index';
import EnrollmentSegmentCard from '../EnrollmentContent/EnrollmentPeriodSettings/EnrollmentSegmentCard';
import { formatDateToAPI, formatDateWithHourToAPI } from '../../utils/common';

const NewEnrollmentContent = ({ period }) => {
  const { t } = useTranslation()
  const [schoolId] = useContext(SchoolStateContext)
  const history = useHistory();
  const [formData, setFormData] = useState({
    validationPeriod: '',
    planStartDate: '',
    planEndDate: '',
    closedMessage: ''
  });
  const [periodRow1, setPeriodRow1] = useState({
    price: '',
    enrollmentDeadline: '',
    tuitionDeadline: '',
    openDate: '',
    closeDate: '',
    coverageStartDate: '',
    coverageEndDate: '',
  });
  const [periodRow2, setPeriodRow2] = useState({
    price: '',
    enrollmentDeadline: '',
    tuitionDeadline: '',
    openDate: '',
    closeDate: '',
    coverageStartDate: '',
    coverageEndDate: '',
  });
  const [periodRow3, setPeriodRow3] = useState({
    price: '',
    enrollmentDeadline: '',
    tuitionDeadline: '',
    openDate: '',
    closeDate: '',
    coverageStartDate: '',
    coverageEndDate: '',
  });
  const [periodRow4, setPeriodRow4] = useState({
    price: '',
    enrollmentDeadline: '',
    tuitionDeadline: '',
    openDate: '',
    closeDate: '',
    coverageStartDate: '',
    coverageEndDate: '',
  });

  const [{ data, loading: loadingValidation }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/periods/validate`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  },
    {
      useCache: false,
    },
  )

  const [{ error, loading, data: createPeriod }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/periods`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  )

  useEffect(() => {
    if (createPeriod && !error) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
      history.push(`/schools/${schoolId}/periods/${createPeriod.id}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPeriod, error])

  useEffect(() => {
    if (data) {
      setFormData(f => ({ ...f, validationPeriod: data.academicPeriodFormat }))
    }
  }, [data])

  const handleInputChange = useCallback((key, value) => {
    setFormData(d => (
      {
        ...d,
        [key]: value
      }
    ))
  }, [setFormData]);

  const handleAddEnrollmentPeriod = useCallback(() => {
    const segments = [
      {
        price: periodRow1.price,
        enrollmentDeadline: formatDateToAPI(periodRow1.enrollmentDeadline),
        tuitionDeadline: formatDateToAPI(periodRow1.tuitionDeadline),
        openDate: formatDateWithHourToAPI(periodRow1.openDate),
        closeDate: formatDateWithHourToAPI(periodRow1.closeDate),
        coverageStartDate: formatDateToAPI(periodRow1.coverageStartDate),
        coverageEndDate: formatDateToAPI(periodRow1.coverageEndDate),
      }]
    if (formData.validationPeriod === 'Semesters' || formData.validationPeriod === 'Trimesters' || formData.validationPeriod === 'Quarters') {
      segments.push({
        price: periodRow2.price,
        enrollmentDeadline: formatDateToAPI(periodRow2.enrollmentDeadline),
        tuitionDeadline: formatDateToAPI(periodRow2.tuitionDeadline),
        openDate: formatDateWithHourToAPI(periodRow2.openDate),
        closeDate: formatDateWithHourToAPI(periodRow2.closeDate),
        coverageStartDate: formatDateToAPI(periodRow2.coverageStartDate),
        coverageEndDate: formatDateToAPI(periodRow2.coverageEndDate),
      })
    }
    if (formData.validationPeriod === 'Trimesters' || formData.validationPeriod === 'Quarters') {
      segments.push({
        price: periodRow3.price,
        enrollmentDeadline: formatDateToAPI(periodRow3.enrollmentDeadline),
        tuitionDeadline: formatDateToAPI(periodRow3.tuitionDeadline),
        openDate: formatDateWithHourToAPI(periodRow3.openDate),
        closeDate: formatDateWithHourToAPI(periodRow3.closeDate),
        coverageStartDate: formatDateToAPI(periodRow3.coverageStartDate),
        coverageEndDate: formatDateToAPI(periodRow3.coverageEndDate),
      })
    }
    if (formData.validationPeriod === 'Quarters') {
      segments.push({
        price: periodRow4.price,
        enrollmentDeadline: formatDateToAPI(periodRow4.enrollmentDeadline),
        tuitionDeadline: formatDateToAPI(periodRow4.tuitionDeadline),
        openDate: formatDateWithHourToAPI(periodRow4.openDate),
        closeDate: formatDateWithHourToAPI(periodRow4.closeDate),
        coverageStartDate: formatDateToAPI(periodRow4.coverageStartDate),
        coverageEndDate: formatDateToAPI(periodRow4.coverageEndDate),
      })
    }
    executePost({
      params: {
        validationPeriod: formData.validationPeriod,
        planStartDate: `${formData.planStartDate.slice(4, 8)}-${formData.planStartDate.slice(0, 2)}-${formData.planStartDate.slice(2, 4)}`,
        planEndDate: `${formData.planEndDate.slice(4, 8)}-${formData.planEndDate.slice(0, 2)}-${formData.planEndDate.slice(2, 4)}`,
        segments: JSON.stringify(segments)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, periodRow1, periodRow2, periodRow3, periodRow4]);

  const isFormDisabled = useMemo(() => {
    if (formData && periodRow1 && periodRow2 && periodRow3 && periodRow4) {
      return (formData.validationPeriod === '' || formData.planStartDate.length < 8 || formData.planEndDate.length < 8 ||
        periodRow1.price === '' || periodRow1.enrollmentDeadline.length < 8 || periodRow1.openDate.length < 12 || periodRow1.closeDate.length < 12 ||
        periodRow1.coverageStartDate.length < 8 || periodRow1.coverageEndDate.length < 8 ||
        (formData.validationPeriod === 'Semesters' && (periodRow2.price === '' || periodRow2.enrollmentDeadline.length < 8 ||
          periodRow2.openDate.length < 12 || periodRow2.closeDate.length < 12 || periodRow2.coverageStartDate.length < 8 || periodRow2.coverageEndDate.length < 8)) ||
        (formData.validationPeriod === 'Trimesters' && (periodRow3.price === '' || periodRow3.enrollmentDeadline.length < 8 ||
          periodRow3.openDate.length < 12 || periodRow3.closeDate.length < 12 || periodRow3.coverageStartDate.length < 8 || periodRow3.coverageEndDate.length < 8)) ||
        (formData.validationPeriod === 'Quarters' && (periodRow4.price === '' || periodRow4.enrollmentDeadline.length < 8 ||
          periodRow4.openDate.length < 12 || periodRow4.closeDate.length < 12 || periodRow4.coverageStartDate.length < 8 || periodRow4.coverageEndDate.length < 8))
      )
    } else return true;
  }, [formData, periodRow1, periodRow2, periodRow3, periodRow4])

  const cardsHeader = useMemo(() => {
    let titles = [''];
    if (formData) {
      if (formData.validationPeriod === 'Semesters') {
        titles = [t('FALL'), t('SPRING_SUMMER')]
      } else if (formData.validationPeriod === 'Trimesters') {
        titles = [`${t('TRIMESTER')} 1`, `${t('TRIMESTER')} 2`, `${t('TRIMESTER')} 3`]
      } else if (formData.validationPeriod === 'Quarters') {
        titles = [`${t('QUARTER')} 1`, `${t('QUARTER')} 2`, `${t('QUARTER')} 3`, `${t('QUARTER')} 4`]
      }
    }
    return titles
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  if (loadingValidation) {
    return <Spinner />
  }

  return (
    <StyledMain className='container py-4'>
      {data && data.isAvailable ? (
        <>
          <div className="card">
            <CardHeader title={t('CREATE_ENROLLMENT_PERIOD')} />
            <div className="card-body pt-3">
              {loading && <Preloader customStyle={{ backgroundColor: 'transparent' }} />}
              <div className="row">
                <div className="col-md-2 pt-4">
                  <div className="form-type-material">
                    <div className="form-group do-float mb-0">
                      <input
                        css={css`
                        width: unset;
                        background-image: none !important;
                    `}
                        type='text'
                        name='academicYear'
                        id='academicYear'
                        className='form-control'
                        value={data.academicYear}
                        readOnly
                      />
                      <label htmlFor='academicYear'>
                        {t('ACADEMIC_YEAR')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 pt-4 form-type-material">
                  <div className='form-group do-float mb-0'>
                    {/* <select
                      className='form-control'
                      // data-provide="selectpicker"
                      onChange={e => handleInputChange('validationPeriod', e.target.value)}
                      value={formData.validationPeriod}
                      disabled
                    >*/}
                    {/* <option disabled value="">{t('SELECT')}</option> */}
                    {/* <option value="Annual">{t('ANNUAL')}</option> */}
                    {/*  <option value={data.academicPeriodFormat}>{t(data.academicPeriodFormat.toUpperCase())}</option>
                    </select>
                    <label className='label-floated'>
                      {t('VERIFICATION_FORMAT')}
                    </label>*/}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="d-flex">{t('PLAN_DATES')}</label>
                  <div
                    css={css`
                        .input-group{
                          width: unset;
                        }
                        .second-date {
                          margin-left: -1px;
                        }
                        .date-input{ 
                          width: 115px;
                        }
                      `}
                    className="d-flex">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t('FROM')}</span>
                      </div>
                      <div>
                        <CurrencyFormat
                          id="planStartDate"
                          name="planStartDate"
                          value={formData.planStartDate}
                          onValueChange={val => handleInputChange('planStartDate', val.value)}
                          className="form-control date-input"
                          format="##/##/####"
                          placeholder="MM/DD/YYYY"
                          mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                        />
                      </div>
                    </div>
                    <div className="input-group second-date">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t('TO')}</span>
                      </div>
                      <div>
                        <CurrencyFormat
                          id="planEndDate"
                          name="planEndDate"
                          value={formData.planEndDate}
                          onValueChange={val => handleInputChange('planEndDate', val.value)}
                          className="form-control date-input"
                          format="##/##/####"
                          placeholder="MM/DD/YYYY"
                          mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <EnrollmentSegmentCard title={cardsHeader[0]} data={periodRow1} setData={setPeriodRow1} />
              {(formData.validationPeriod === 'Trimesters' || formData.validationPeriod === 'Quarters')
                && <EnrollmentSegmentCard title={cardsHeader[2]} data={periodRow3} setData={setPeriodRow3} />}
            </div>
            <div className="col-md-6">
              {(formData.validationPeriod === 'Semesters' || formData.validationPeriod === 'Trimesters' ||
                formData.validationPeriod === 'Quarters') && <EnrollmentSegmentCard title={cardsHeader[1]} data={periodRow2} setData={setPeriodRow2} />}
              {formData.validationPeriod === 'Quarters' && <EnrollmentSegmentCard title={cardsHeader[3]} data={periodRow4} setData={setPeriodRow4} />}
            </div>
          </div>

          <button
            onClick={handleAddEnrollmentPeriod}
            disabled={isFormDisabled}
            className={`btn btn-bold d-block ml-auto btn-primary btn-label ${isFormDisabled ? 'disabled text-white' : ''}`}
          >
            <span>
              <i className='ti-check'></i>
            </span>
            {t('ADD')}
          </button>
        </>
      ) : (<p className="text-center">{t('ENROLLMENT_PERIOD_EXISTS')}</p>)}
    </StyledMain>
  )
}

export default NewEnrollmentContent

