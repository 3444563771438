import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import { UserService } from '../../services/UserService';
import Spinner from '../Spinner';
import Preloader from '../Preloader/index';

const shipItems = [
  {
    value: 'DF',
    label: 'DEFAULT_ENROLLED'
  },
  {
    value: 'OI',
    label: 'OPT_IN'
  },
  {
    value: 'CE',
    label: 'CANCELLED'
  }
];

const waiverItems = [
  {
    value: 'PE',
    label: 'PENDING'
  },
  {
    value: 'PA',
    label: 'PENDING_AUDIT'
  },
  {
    value: 'PR',
    label: 'PENDING_REVIEW'
  },
  {
    value: 'AP',
    label: 'APPROVED'
  },
  {
    value: 'DE',
    label: 'DENIED'
  },
  {
    value: 'CW',
    label: 'CANCELLED'
  }
];

export const pendingDenialReason = [
  {
    value: 'POLICY_NOT_ACTIVE',
    label: 'POLICY_NOT_ACTIVE'
  },
  {
    value: 'REQUIREMENTS_NOT_MET',
    label: 'REQUIREMENTS_NOT_MET'
  },
  {
    value: 'NO_POLICY_FOUND',
    label: 'NO_POLICY_FOUND'
  },
  {
    value: 'CAN_NOT_COMPLETE_AUDIT',
    label: 'CAN_NOT_COMPLETE_AUDIT'
  },
  {
    value: 'OUT_OF_STATE_MEDICAID',
    label: 'OUT_OF_STATE_MEDICAID'
  }
];

const ChangeStatus = ({
  type,
  submissionId,
  currentStatus,
  refetch,
  updateLocal,
  setUpdatedData,
  RolePermissionValue
}) => {
  const { t } = useTranslation();
  const [approvalException, setApprovalException] = useState(false);
  const [approvalExceptionEnable, setApprovalExceptionEnable] = useState(true);
  const [sendEmail, setSendEmail] = useState(true);
  const [selectedDenialReason, setSelectedDenialReason] = useState('');

  const [annual, setAnnual] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [note, setNote] = useState('');
  const [count, setCount] = useState(0);
  const [reasonCount, setReasonCount] = useState(0);

  const [{ loading, data }, postStatus] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/${type}/status`,
    method: 'POST',
    manual: true
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      status: selectedStatus,
      note: note
    };

    if (type === 'waiver') {
      payload.approvalException = approvalException ? 1 : 0;
      payload.approvalExceptionAnnual = annual ? 1 : 0;
    }

    payload.sendEmail = sendEmail ? 1 : 0;

    if (selectedStatus === 'PE') {
      payload.denialReason = selectedDenialReason;
    }

    postStatus({ params: payload });
  };

  useEffect(() => {
    if (data) {
      if (updateLocal) {
        updateLocal(data.submission);
      } else if (refetch) {
        refetch();
      }
      setUpdatedData(data);
      setNote('');
      setApprovalExceptionEnable(false);
      setApprovalException(false);
      setAnnual(false);
      setSelectedStatus('');
      setSelectedDenialReason('');
      setCount((c) => c + 1);
      setReasonCount((rc) => rc + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setCount((c) => c + 1);
  }, [currentStatus]);

  const getOptions = useCallback(() => {
    if (type === 'ship') {
      return shipItems
        .filter((i) => i.value !== currentStatus)
        .map((item) => {
          return (
            <option value={item.value} key={item.value}>
              {t(item.label)}
            </option>
          );
        });
    } else {
      if (
        type !== 'ship'
          ? !RolePermissionValue.adePermission
          : !RolePermissionValue.manualApprove
      ) {
        return waiverItems
          .filter((i) => i.value !== currentStatus)
          .map((item) => {
            return (
              <option value={item.value} key={item.value}>
                {t(item.label)}
              </option>
            );
          });
      } else if (RolePermissionValue.manualApprove) {
        const reducedOptions = [];
        if (currentStatus !== 'AP') {
          reducedOptions.push({
            value: 'AP',
            label: 'APPROVED'
          });
        }
        return reducedOptions.map((item) => {
          return (
            <option value={item.value} key={item.value}>
              {t(item.label)}
            </option>
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus, type]);

  const renderReasonOptions = useCallback(() => {
    switch (selectedStatus) {
      case 'AP':
        if (approvalException) {
          return [
            <option value="Appeal" key="Appeal">
              {t('APPEAL')}
            </option>,
            <option value="School Request" key="School Request">
              {t('SCHOOL_REQUEST')}
            </option>
          ];
        } else {
          return [
            <option value="SIS Audit" key="SIS Audit">
              {t('SIS_AUDIT')}
            </option>,
            <option value="School Audit" key="School Audit">
              {t('SCHOOL_AUDIT')}
            </option>,
            <option value="Approval Agreement" key="Approval Agreement">
              {t('APPROVAL_AGREEMENT')}
            </option>
          ];
        }
      case 'PR':
      case 'PA':
        return [
          <option value="Review" key="Review">
            {t('REVIEW')}
          </option>
        ];
      case 'PE':
        return [
          <option value="SIS Audit" key="SIS Audit">
            {t('SIS_AUDIT')}
          </option>,
          <option value="School Audit" key="School Audit">
            {t('SCHOOL_AUDIT')}
          </option>
        ];
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, approvalException]);

  const handleSelect = (e) => {
    setSelectedStatus(e.target.value);
    setReasonCount((rc) => rc + 1);
    setNote('');
    if (
      type == 'ship'
        ? !RolePermissionValue.adePermission
        : !RolePermissionValue.manualApprove
    ) {
      if (e.target.value === 'AP') {
        setApprovalExceptionEnable(true);
      } else {
        setApprovalExceptionEnable(false);
        setApprovalException(false);
      }
    }

    // else if (e.target.value === 'AP' && shipItems) {
    //   setApprovalException(true);
    // }
  };

  const handleApprovalException = useCallback(() => {
    if (approvalExceptionEnable) {
      setApprovalException((v) => !v);
      setReasonCount((rc) => rc + 1);
    }
  }, [approvalExceptionEnable]);

  const isFormDisabled = useMemo(() => {
    return (
      selectedStatus === '' ||
      note.length === 0 ||
      loading ||
      !RolePermissionValue.manualApprove ||
      (selectedStatus === 'PE' && selectedDenialReason === '')
    );
  }, [selectedStatus, note, loading, selectedDenialReason]);
  return (
    <form
      onSubmit={handleSubmit}
      className="d-flex flex-column w-100 h-100 form-type-material"
    >
      {false ? (
        <Spinner minHeight={false} />
      ) : (
        <>
          {loading && (
            <Preloader customStyle={{ backgroundColor: 'transparent' }} />
          )}
          <div className="form-group do-float" key={count}>
            <select
              name="status"
              id="status"
              data-provide="selectpicker"
              className="form-control w-100"
              disabled={
                type === 'ship'
                  ? !RolePermissionValue.adePermission
                  : !RolePermissionValue.manualApprove
              }
              onChange={handleSelect}
              value={selectedStatus}
            >
              <option disabled value="">
                {t('SELECT')}
              </option>
              {getOptions()}
            </select>
            <label htmlFor="status" className="label-floated">
              {`${t('STATUS')}`}
            </label>
          </div>
          {type !== 'ship' && selectedStatus === 'AP' && (
            <div className="d-flex mb-3">
              <div className="col-m6">
                <label
                  className={`switch ${
                    approvalExceptionEnable ? '' : 'disabled'
                  }`}
                >
                  <input
                    name="approvalException"
                    type="checkbox"
                    checked={approvalException}
                    onChange={handleApprovalException}
                    readOnly={!RolePermissionValue.waiverSupplements}
                  />
                  <span className="switch-indicator"></span>
                  <span className="switch-description">{t('EXCEPTION')}</span>
                </label>
              </div>
              {selectedStatus === 'AP' && (
                <div className="col-m6 ml-3">
                  <label className="switch">
                    <input
                      name="annual"
                      type="checkbox"
                      checked={annual}
                      onChange={() => setAnnual((v) => !v)}
                      readOnly={
                        type == 'ship'
                          ? !RolePermissionValue.adePermission
                          : !RolePermissionValue.manualApprove
                      }
                      disabled={!approvalException}
                    />
                    <span className="switch-indicator"></span>
                    <span className="switch-description">{t('ANNUAL')}</span>
                  </label>
                </div>
              )}
            </div>
          )}
          {type !== 'ship' && selectedStatus === 'PE' && (
            <div className="d-flex mb-3">
              <div className="form-group do-float w-75">
                <select
                  name="pendding-reason"
                  id="pendding-reason"
                  data-provide="selectpicker"
                  className="form-control"
                  readOnly={!RolePermissionValue.adePermission}
                  onChange={(e) => setSelectedDenialReason(e.target.value)}
                  value={selectedDenialReason}
                >
                  <option disabled value="">
                    {t('SELECT')}
                  </option>
                  {pendingDenialReason.map((reason) => (
                    <option key={reason.value} value={reason.value}>
                      {t(reason.label)}
                    </option>
                  ))}
                </select>
                <label
                  htmlFor="pendding-reason"
                  className="label-floated require"
                >
                  {`${t('REASON_DENIAL')}`}
                </label>
              </div>
            </div>
          )}
          <div
            className="form-group do-float flex-grow-1"
            key={`reaseon_${reasonCount}`}
          >
            {type === 'waiver' &&
            selectedStatus !== 'CW' &&
            selectedStatus !== 'DE' ? (
              <select
                name="reason-select"
                id="reason-select"
                data-provide="selectpicker"
                className="form-control"
                readOnly={!RolePermissionValue.adePermission} //waiver
                onChange={(e) => setNote(e.target.value)}
                value={note}
              >
                <option disabled value="">
                  {t('SELECT')}
                </option>
                {renderReasonOptions()}
              </select>
            ) : (
              <textarea
                name="reason"
                id="reason"
                className="form-control h-100"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                readOnly={
                  type == 'ship'
                    ? !RolePermissionValue.adePermission
                    : !RolePermissionValue.manualApprove
                }
              ></textarea>
            )}
            <label htmlFor="reason" className="label-floated require">
              {`${t('REASON_FOR_CHANGE')}`}
            </label>
          </div>
          <div className="d-flex">
            <label className="switch">
              <input
                name="email"
                type="checkbox"
                disabled={
                  type == 'ship'
                    ? !RolePermissionValue.adePermission
                    : !RolePermissionValue.manualApprove
                }
                checked={sendEmail}
                onChange={() => setSendEmail((v) => !v)}
              />
              <span className="switch-indicator"></span>
              <span className="switch-description">{t('EMAIL')}</span>
            </label>
            <button
              disabled={
                isFormDisabled || type == 'ship'
                  ? !RolePermissionValue.adePermission
                  : !RolePermissionValue.manualApprove
              }
              className={`btn btn-bold d-block ml-auto btn-primary btn-label ${
                isFormDisabled || type == 'ship'
                  ? !RolePermissionValue.adePermission
                  : !RolePermissionValue.manualApprove
                  ? 'disabled text-white'
                  : ''
              }`}
            >
              <span>
                <i className="ti-check"></i>
              </span>
              {t('UPDATE')}
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default ChangeStatus;
