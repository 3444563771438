import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'


const Rules = ({ formData, changeRule }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(0);

  return (
    <form className='form-type-material row mx-auto'>
      <div className='col-12'>
        <div className='form-group w-50'>
          <select
            className='form-control'
            value={formData.patternChangeRule.rule}
            onChange={e => { changeRule('rule', e.target.value); setCount(c => (c + 1)) }}
            data-provide="selectpicker"
            data-live-search="true"
          >
            <option disabled value="">{t('SELECT')}</option>
            <option value="none">{t('NONE')}</option>
            <option value="Add">{t('ADD')}</option>
            <option value="Strip">{t('STRIP')}</option>
          </select>
          <label className='label-floated'>
            {t('RULE')}
          </label>
        </div>
        <div className="w-100">
          <div className='form-group w-50' key={count}>
            <select
              className='form-control'
              value={formData.patternChangeRule.type}
              onChange={e => changeRule('type', e.target.value)}
              data-provide="selectpicker"
              data-live-search="true"
              disabled={!formData.patternChangeRule.rule}
            >
              <option disabled value="">{t('SELECT')}</option>
              <option value="Prepend">{t('PREPEND')}</option>
              <option value="Append">{t('APPEND')}</option>
            </select>
            <label className={`label-floated ${formData.patternChangeRule.rule ? 'require' : ''}`}>
              {t('TYPE')}
            </label>
          </div>
        </div>
        <div className='form-group w-50'>
          <input
            type='text'
            className='form-control'
            value={formData.patternChangeRule.pattern}
            onChange={e => changeRule('pattern', e.target.value)}
            disabled={!formData.patternChangeRule.rule}
          />
          <label className={`label-floated ${formData.patternChangeRule.rule ? 'require' : ''}`} >{t('PATTERN')}</label>
        </div>
      </div>
    </form>
  )
}

export default Rules
