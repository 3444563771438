import React, { useEffect, useRef } from 'react';

export const Spacer = ({ id, updateElement }) => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current.outerHTML;
    updateElement(id, element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref}>
      <br />
      <br />
    </div>
  );
};
