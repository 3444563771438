import React, { useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'

import { StyledMain } from '../../styles/common/main'
import Spinner from '../Spinner'
import ReportCard from './ReportCard';

// icons
import chartCircleImg from '../../img/chart-circle.svg'

// Context
import { SearchStateContext } from '../../context/SearchContext'

const ReportsContent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const loading = false;
  const { setWithSearch } = useContext(SearchStateContext)

  useEffect(() => {
    setWithSearch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenReport = useCallback((report) => {
    history.push(`/reports/${report}`)
  })

  return (
    <StyledMain className='container row'>

      {loading ? (
        <Spinner />
      ) : (
          <div className="d-flex flex-wrap ">
            <ReportCard
              title={t('MANUAL_BILLING')}
              icon={chartCircleImg}
              onClick={() => handleOpenReport('billing')}
            />
            <ReportCard
              title={t('ELIGIBILITY_LOCKED')}
              icon={chartCircleImg}
              onClick={() => handleOpenReport('eligibility')}
            />
            <ReportCard
              title={t('USERS')}
              icon={chartCircleImg}
              onClick={() => handleOpenReport('users')}
            />
            <ReportCard
              title={t('DENTAL_REPORT')}
              icon={chartCircleImg}
              onClick={() => handleOpenReport('dental')}
            />
            <ReportCard
              title={t('LOCKED_FIELDS')}
              icon={chartCircleImg}
              onClick={() => handleOpenReport('locked-fields')}
            />
            <ReportCard
              title={t('DATA_DUMP')}
              icon={chartCircleImg}
              onClick={() => handleOpenReport('data-dump')}
            />
            {/* <ReportCard
              title={t('STUDENT_PROCESSING')}
              icon={chartBarImg}
            />
            <ReportCard
              title={t('STUDENT_ECI')}
              icon={chartIconPageImg}
            />
            <ReportCard
              title={t('CANCELLED_SUBSCRIPTIONS')}
              icon={chartBarImg}
            />
            <ReportCard
              title={t('WAIVER_PROVIDERS')}
              icon={chartBarHorImg}
            />
            <ReportCard
              title={t('RESPONSE_TIME')}
              icon={chartBarMapImg}
            />
            <ReportCard
              title={t('PARTICIPATION_DATES')}
              icon={chartBarImg}
            /> */}

          </div>

        )}
    </StyledMain>
  )
}

export default ReportsContent
