import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import Preloader from '../components/Preloader/index';
import { SchoolStateProvider } from '../context/SchoolContext'
import EnrollmentContent from '../components/EnrollmentContent';


const EnrollmentPeriod = props => {
  const { t } = useTranslation()
  const { match } = props
  const [{ data: schoolData, loading: schoolLoading, error: schoolError }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${match.params.id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  })

  const [{ data: periodData, loading: periodLoading, error: periodError }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${match.params.id}/periods/${match.params.periodId}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  })

  if (schoolError) {
    return <h1>No school found.</h1>
  }

  if (periodError) {
    return <h1>No school found.</h1>
  }

  if (schoolLoading || periodLoading) {
    return <Preloader />
  }

  return (
    <SchoolStateProvider value={[match.params.id]}>
      <PageLayout
        match={match}
        withHeader={true}
        title={schoolData && schoolData.school_name}
        crumbs={[
          { title: t('SCHOOLS'), to: '/schools' },
          { title: schoolData.school_abbr, to: `/schools/${match.params.id}` },
          { title: `${t('ENROLLMENT_PERIOD')} ${periodData.academic_year}`, to: '' },
        ]}
      >
        <EnrollmentContent period={periodData}/>
      </PageLayout>
    </SchoolStateProvider>
  )
}

export default EnrollmentPeriod
