/* eslint-disable no-shadow */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import Autosuggest from 'react-autosuggest';
import { useLazyQuery } from '@apollo/client';
import { SearchStateContext } from '../../context/SearchContext';
import '../../styles/common/autosuggest.css';

// Components
import LabeledInput from '../LabeledInput';

// Queries
import { suggested_data } from '../../data/summary/query';

const SuggestInput = ({ placeholder, field, value, filters, updateFilter }) => {
  // Autosuggest is a controlled component.
  // This means that you need to provide an input value
  // and an onChange handler that updates this value (see below).
  // Suggestions also need to be provided to the Autosuggest,
  // and they are initially empty because the Autosuggest is closed.

  const { setWithSearch } = useContext(SearchStateContext);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestedDataQuery, { data: suggestedData }] = useLazyQuery(
    suggested_data
  );

  useEffect(() => {
    setWithSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (suggestedData) {
      setSuggestions(suggestedData.students_submissions);
    } else {
      setSuggestions([]);
    }
  }, [suggestedData]);

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const setFilter = useCallback(
    ({ value }) => {
      if (value !== '') {
        updateFilter(field, value);
      }
    },
    [filters]
  );

  // Autosuggest will call this function every time you need to update suggestions.
  // eslint-disable-next-line no-unused-vars
  const getSuggestions = useEffect(() => {
    if (
      filters.first_name !== '' ||
      filters.last_name !== '' ||
      filters.student_id !== '' ||
      (filters.provider_name && filters.provider_name !== '')
    ) {
      const and = [];

      if (filters) {
        const {
          last_name,
          first_name,
          student_id,
          active,
          rollover,
          status,
          provider_name,
          enrollment_period_segment_id
        } = filters;

        and.push({
          student: {
            first_name: { _ilike: `%${first_name}%` },
            last_name: { _ilike: `%${last_name}%` },
            student_id: { _ilike: `%${student_id}%` }
          }
        });
        if (filters.status.includes('SP')) {
          and.push({
            students_submissions_addons: {
              type: { _eq: 'SUPPLEMENT_PLAN' },
              is_active: { _eq: true }
            },
            status: { _in: ['AP'] }
          });
        } else {
          and.push({ status: { _in: status } });
        }

        and.push({
          enrollment_period_segment_id: { _eq: enrollment_period_segment_id },
          student_is_active: { _eq: !!active }
        });

        if (provider_name !== null) {
          and.push({ provider_name: { _ilike: `%${provider_name}%` } });
        }

        if (rollover !== null) {
          and.push({ is_rollover: { _eq: rollover } });
        }
      }

      const where = {
        _and: and
      };

      const variables = {
        where
      };

      suggestedDataQuery({ variables });
    }
  }, [filters[field]]);

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => {
    if (field !== 'provider_name') return suggestion.student[field];
    return suggestion[field];
  };

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => (
    <div>
      {field !== 'provider_name'
        ? suggestion.student[field]
        : suggestion[field]}
    </div>
  );

  const onChange = useCallback((event, { newValue }) => {
    updateFilter(field, newValue);
  }, []);

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: '',
    value,
    onChange
  };

  // Finally, render it!
  return (
    <div className="input-group">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={setFilter}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={(inputProps) => (
          <LabeledInput
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...inputProps}
            className="form-control bg-transparent"
            label={placeholder}
          />
        )}
      />
    </div>
  );
};

export default SuggestInput;
