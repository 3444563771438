/**
 * It returns either the href of the <a/> element or undefined
 */
export const getHrefFromAnchor = (anchor = undefined) => {
  const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
  return anchor ? anchor.match(linkRx)?.[2] : undefined;
};

/**
 * This work with only one Tag
 * i.e: <a>Text</a>
 * @param {*} tag an html tag
 * It returns either the Text or undefined
 */
export const getContentBetweenTags = (content, tag) => {
  return content?.split('>')?.[1]?.split(`</${tag}`)[0];
};

/**
 * Helper for getStyleObjectFromString
 * @param {*} str
 */
const formatStringToCamelCase = (str) => {
  const splitted = str.split('-');
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  );
};

/**
 * Transforms the inline html style to one usable for React
 *
 * @param {*} str the css contained in an element:
 * i.e "display: flex; flex-direction: column; align-items: center;"
 * returns: { display: "flex", flexDirection: "column", alignItems: "center" }
 */
export const getStyleObjectFromString = (str) => {
  if (!str) return undefined;

  const style = {};
  str.split(';').forEach((el) => {
    const [property, value] = el.split(':');
    if (!property) return;

    const formattedProperty = formatStringToCamelCase(property.trim());
    style[formattedProperty] = value?.trim();
  });

  return style;
};

export const getStyleFromHtmlString = (tag) => {
  return tag?.match(/style="[^"]*"/)?.[0];
};

export const parseStylesToJsx = (tag) => {
  if (!tag) return {};
  const style = getStyleFromHtmlString(tag).replace('style="', '');
  return getStyleObjectFromString(style);
};
