import React from 'react'
import { useTranslation } from 'react-i18next';

// Components
import PageLayout from '../components/PageLayout/PageLayout';
import LockedFieldsReportContent from '../components/ReportsContent/LockedFieldsReportContent';


const LockedFieldsReport = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      withHeader={true}
      title={t('LOCKED_FIELDS')}
      crumbs={[
        { title: t('REPORTS'), to: '/reports' },
        { title: t('LOCKED_FIELDS'), to: '/' },
      ]}
    >
      <LockedFieldsReportContent />
    </PageLayout>
  )
}

export default LockedFieldsReport; 
