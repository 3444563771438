import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import PageLayout from '../components/PageLayout/PageLayout'
import Preloader from '../components/Preloader'
import StudentContent from '../components/StudentContent'
import { StudentStateProvider } from '../context/StudentContext'

const Student = ({ match }) => {
  const { t } = useTranslation()
  const [{ data, loading, error }, getUser] = useAxios(
    {
    url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${match.params.id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('jwt')}`,
    },
  },
  {
    manual: true,
  });

  useEffect(() => {
    getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading || !data) {
    return <Preloader />
  }

  if (error) {
    return <p>No student with id {match.params.id}</p>
  }

  return (
    <StudentStateProvider value={[data]}>
      <PageLayout
        withHeader={true}
        title={t('STUDENTS')}
        crumbs={[
          { title: t('STUDENTS'), to: '/students' },
          {
            title: `${data && data.first_name} ${data && data.last_name}`,
            to: '',
          },
        ]}
      > 
        <StudentContent student={data} reloadUser={getUser}/>
      </PageLayout>
    </StudentStateProvider>
  )
}

export default Student
