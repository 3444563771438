import React, { useEffect, useCallback, useState } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { css } from "@emotion/core";

import Spinner from '../Spinner/index';
import RowCard from '../RowCard';
import AduditorCard from '../AuditorCard/index';

const AuditingDashboard = ({onClick, setStatus, status}) => {
  const [defaultLoading, setDefaultLoading] = useState(true);
  const { t } = useTranslation()
  const [{ data, loading }, getAuditors] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/dashboard/auditors`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getAuditors({
      params: {
        status
      }
    })
    const interval = setInterval(() => {
      getAuditors({
        params: {
          status
        }
      })
    }, 60000);
    return () => {
      clearInterval(interval)
    };
  }, [status]);

  useEffect(() => {
    getAuditors({
      params: {
        status
      }
    })
  }, [status]);

  useEffect(() => {
    if (defaultLoading && data) {
      setDefaultLoading(false)
    }
  }, [data, defaultLoading]);

  const handleToggleChange = useCallback(() => {
    setStatus(s => s === 'PA' ? 'PR' : 'PA')
  }, [])


  if (defaultLoading || loading) {
    return <Spinner />
  }

  return (
    <>
      <RowCard>
        <div className='col-md-6'>
          <h5 className="mb-0"><span className="fw-700">{data.academic_year}</span> {data.label}</h5>
        </div>
        <div className='col-md-6 text-right'>

          <label className='switch'>
            <span className='switch-description'>{t('STATUS')}: PA</span>
            <input
              type='checkbox'
              checked={status === 'PR'}
              name='enrollmentsEnabled'
              id='enrollmentsEnabled'
              onChange={handleToggleChange}
            />
            <span className='switch-indicator mx-3'></span>
            <span className='switch-description'>{t('STATUS')}: PR</span>
          </label>
        </div>
      </RowCard>
      <div 
        className="row"
        css={css`
          @media (min-width: 1920px) {
            .col{
              max-width: 25%
            }
          }
          @media (min-width: 1024px) {
            .col{
              max-width: 25%
            }
          }
          @media (min-width: 512px) {
            .col{
              max-width: 50%
            }
          }
        `}
      >
        {Object.keys(data.data).map(key => <div key={key} className="col"> <AduditorCard title={key} amount={data.data[key]} onClick={onClick} status={status}/></div>)}
      </div>
    </>

  );
}

export default AuditingDashboard
