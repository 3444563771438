import React from "react";

const LabeledInput = ({ label, ...rest }) => {
  return (
    <div className={`input-group-input ${{ ...rest }.value !== '' ? 'do-float' : ''}`}>
      <input {...rest} />
      <label className='label-floated'>{label}</label>
    </div>
  );
};

export default LabeledInput;