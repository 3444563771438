import React from 'react';
import { css } from "@emotion/core";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CurrencyFormat from "react-currency-format";
import useAxios from 'axios-hooks';

import ValidatorStatusTag from '../ValidatorStatusTag/index';
import Preloader from '../Preloader/index';

const SubmissionRow = ({
  onClick,
  submission,
  open,
  dataProviders,
  selectedSubmission,
  loadingUpdate,
  loadingValidate,
  submissionForm,
  handleSelectChanged,
  handleFormChange,
  handleValidate,
  submitForm,
  handleSendToPR,
  handleView
}) => {
  const { t } = useTranslation();

  const [{ loading }, executeGet] = useAxios(
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
    {
      manual: true,
    }
  );

  return (
    <>
      <tr onClick={() => onClick(submission)} key={submission.id} className="cursor-pointer">
        <td>{submission.campus_id}</td>
        <td>{submission.first_name}</td>
        <td>{submission.last_name}</td>
        <td>{submission.school_name}</td>
        <td>{submission.waiver_form_data.insuranceCompanyName}</td>
        <td><ValidatorStatusTag validatorCurrentStatus={submission.validator_current_status} /></td>
        <td>{submission.files && submission.files.length > 0 ? <i className="fa fa-file-o"></i> : null}</td>
        <td>{moment(submission.created_at).format('MM/DD/YYYY')}</td>
        <td>{open ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}</td>
      </tr>
      {loading && <Preloader customStyle={{ backgroundColor: 'transparent' }} />}
      {open && (
        <tr>
          {loadingValidate && (
            <Preloader
              customStyle={{ backgroundColor: 'rgba(245, 246, 247,0.9 )' }}
              message={t('INSURANCE_IS_BEING_VERIFIED')}
            />)}
          <td colSpan="9" className="p-0 bg-white boder-0 border-0 p-3">
            <div className="row form-type-material">
              <div className="col-7">
                <div className="row">
                  <div className="col-6">
                    <div
                      className="form-group mb-2 do-float"
                      css={css`
                      .dropdown-menu {
                        min-width: 100px !important;
                      }`}>
                      <select
                        name="insuranceId"
                        id="insuranceId"
                        value={`${submissionForm.insuranceId ? `insurance${submissionForm.insuranceId}` : 'none'}`}
                        onChange={handleSelectChanged}
                        data-provide="selectpicker"
                        data-live-search="true"
                        className="form-control w-100"
                      >
                        <option value="">{t("PLEASE_SELECT_INSURANCE")}</option>
                        {dataProviders &&
                          dataProviders.map(provider => {
                            return (
                              <option
                                key={provider.id}
                                value={`insurance${provider.id}`}
                                id={`insurance${provider.id}`}
                                data-id={provider.id}
                                data-name={provider.name}
                                data-payerid={provider.payer_id}
                              >
                                {provider.name}
                              </option>
                            );
                          })}
                      </select>
                      <label className="label-floated" htmlFor="insuranceId">
                        {t("INSURANCE_PROVIDER")}
                      </label>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-group mb-2">
                          <input
                            value={submissionForm.payerId}
                            id="payerId"
                            className="form-control"
                            type="text"
                            name="payerId"
                            readOnly
                          />
                          <label className="label-floated" htmlFor="payerId">
                            {t("PAYER_ID")}
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-group mb-2">
                          <input
                            value={submissionForm.groupId}
                            onChange={e => handleFormChange('groupId', e.target.value)}
                            id="groupId"
                            className="form-control"
                            type="text"
                            name="groupId"
                          />
                          <label className="label-floated" htmlFor="groupId">{t("GROUP_ID")}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group mb-2">
                      <CurrencyFormat
                        id="phone"
                        name="phone"
                        value={submissionForm.memberSupport}
                        onValueChange={val => handleFormChange('memberSupport', val.value)}
                        className="form-control"
                        format="+1 ###-###-####"
                      />
                      <label className="label-floated" htmlFor="memberSupport">{t("CUSTOMER_SERVICE")} #</label>
                    </div>
                    <div className="form-group mb-2">
                      <input
                        value={submissionForm.memberId}
                        onChange={e => handleFormChange('memberId', e.target.value)}
                        id="memberId"
                        className="form-control"
                        type="text"
                        name="memberId"
                      />
                      <label className="label-floated" htmlFor="memberId">{t("MEMBER")} #</label>
                    </div>
                  </div>
                  <div className="col-3 d-flex flex-column">
                    <button
                      onClick={handleValidate}
                      disabled={submissionForm.dirty}
                      className={`btn btn-bold d-block ml-auto btn-info btn-label ${submissionForm.dirty ? 'disabled text-white' : ''} mt-auto mb-3`}
                    >
                      <span>
                        <i className="ti-reload"></i>
                      </span>
                      {t("VALIDATE")}
                    </button>
                    <button
                      onClick={submitForm}
                      disabled={loadingUpdate || !submissionForm.dirty}
                      className={`btn btn-bold d-block ml-auto btn-primary btn-label ${loadingUpdate || !submissionForm.dirty ? 'disabled text-white' : ''}`}
                    >
                      <span>
                        <i className="ti-check"></i>
                      </span>
                      {t("UPDATE")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="row h-100">
                  <div className="col-6">
                    {selectedSubmission.files.map(file => (
                      <div key={file.id} className="d-flex cursor-pointer" onClick={() => executeGet({ url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${selectedSubmission.id}/waivers/files/${file.id}` }).then(data => window.open(data.data))}>
                        {file.file_extension === 'pdf'
                          ? <i className="fa fa-file-pdf-o fs-20 text-fade"></i>
                          : <i className="fa fa-file-image-o fs-20 text-fade"></i>
                        }
                        <p className="ml-2 mb-0 text-fade">{t(file.document_type)}</p>
                      </div>
                    ))}

                  </div>
                  <div className="col-6 d-flex flex-column">
                    <div className="mt-auto">
                      <a
                        css={css`float: right`}
                        href={`/students/${submission.student_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-bold d-block btn-primary btn-label mb-3"
                      >
                        <span>
                          <i className="ti-check"></i>
                        </span>
                        {t("PROFILE")}
                      </a>
                      {selectedSubmission.status !== 'PR' && (
                        <button
                          onClick={handleSendToPR}
                          className="btn btn-bold d-block ml-auto btn-warning btn-label mb-3"
                        >
                          <span>
                            <i className="ti-share"></i>
                          </span>
                          {t("SEND_TO_PR")}
                        </button>
                      )}
                      <button
                        onClick={handleView}
                        className="btn btn-bold d-block ml-auto btn-info btn-label"
                      >
                        <span>
                          <i className="ti-eye"></i>
                        </span>
                        {t("VIEW")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default SubmissionRow
