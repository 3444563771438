import React, { useState, useEffect, useContext, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { SearchStateContext } from '../../context/SearchContext';

// Components
import Modal from '../Modal/Modal';

// Services
import { UserService } from '../../services/UserService';
import EnrollmentPeriodFilter from './EnrollmentPeriodFilter';
import SelectFilter from './Filters/SelectFilter';
import SchoolFilter from './SchoolFilter';

const DataDumpReportContent = () => {
  const { setWithSearch, debouncedSearchKeyword } = useContext(SearchStateContext);
  const [schoolName, setSchoolName] = useState();
  const [multiOptions, setMultiOptions] = useState('');
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  const [selectEnrollmentId, setSelectEnrollmentId] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedSchoolId, setSelectedSchoolId] = useState(UserService.getUser().school_id ? UserService.getUser().school_id : '');

  const { t } = useTranslation();

  const [{ data: exportData, loading: exportLoading }, executeExportPost] = useAxios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },

  },
    {
      manual: true,
    })

  const handleExport = async data => {
    let test = {
      "filters": [
        {
          "schoolId": selectedSchoolId,
          "segments": [
            selectEnrollmentId
          ],
          "types": [
            "NO_ACTION",
            "SHIP",
            "WAIVER"
          ]
        }
      ]
    }
    let response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v1/reports/students-data-dump/exports`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('jwt')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'filters': [
          {
            'schoolId': selectedSchoolId,
            'segments': [
              selectEnrollmentId
            ],
            'types': [selectedType]
          }
        ]
      })
    }).then(response => {
      return response.text();
    });
    if (response) {
      response = JSON.parse(response);
      setOpenNotificationModal(true);
    }
  }
  const [{ data: schoolData }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  });


  useEffect(() => {
    if (schoolData) {
      schoolData.forEach(school => {
        if (school.id === selectedSchoolId)
          setSchoolName(school.school_name)
      });
    }
  }, [selectedSchoolId, schoolData]);
  if (schoolData) {
    let academic_year = schoolData.map(function (x) {
      return x.current_period_year;
    });
  }

  return (
    <div className="container">
      <>
        <Modal targetId='delete-modal' display={openNotificationModal} setDisplay={setOpenNotificationModal}>
          <header className='card-header'>
            <h5 className='card-title fw-500'>
            Request Processing
            </h5>
            <button className='btn border-0'
              onClick={() => setOpenNotificationModal(!openNotificationModal)}
            >
              <i className='fa fa-close'></i>
            </button>
          </header>
          <div className='card-body'>
            <p className='text-center'>
              The report may take several minutes to be generated, we will send it to the registered email when it’s ready.
            </p>
            <div className='d-flex'>
              <button
                onClick={() => setOpenNotificationModal(!openNotificationModal)}
                className='btn btn-bold d-block ml-auto btn-danger btn-label'
              >
                <span>
                  <i className='ti-close'></i>
                </span>
                {t('CLOSE')}
              </button>
            </div>
          </div>
        </Modal>
      </>

      <div className="row form-type-material">

        {!UserService.getUser().school_id && (

          <div className="col-3">
            <SchoolFilter
              selectedSchoolId={selectedSchoolId}
              setSelectedSchoolId={setSelectedSchoolId}
            />
          </div>
        )}
        <div className="col-2">
          <SelectFilter arrayData={["2017", "2018", "2019", "2020", "2021", "2022", "2023"]} Label={"Academic year"} setValue={setSelectedYear} value={selectedYear} />
        </div>
        <div className="col-2">
          <SelectFilter arrayData={["WAIVER", "SHIP", "No Action"]} Label={"Types"} setValue={setSelectedType} value={selectedType} />
        </div>
        {

        }
        <div className="col-3">
          {
            selectedYear !== "" && selectedType !== "" && (
              <EnrollmentPeriodFilter
                selectEnrollmentId={selectEnrollmentId}
                selectedSchoolId={selectedSchoolId}
                setSelectEnrollmentId={setSelectEnrollmentId}
                filters={{ year: selectedYear }}
              />)
          }
        </div>

        <div className="col-2">
          <button
            disabled={selectedType == "" || selectedType == undefined}
            onClick={handleExport}
            className="mt-10 btn btn-bold d-block ml-auto btn-primary btn-label"
            type='submit'>
            <span>
              <i className='ti-download'></i>
            </span>
            {t('SEND_REPORT')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DataDumpReportContent

