import React from 'react';
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

const Filter = ({ options, status, onSelect }) => {
  const { t } = useTranslation();

  return (
    <ul className="m-0 p-0">
      <li className='dropdown d-block'>
        <span
          css={css`
                color: #212529;
              `}
          tabIndex='0' className='btn border-0 p-0 fw-500 bg-transparent' data-toggle='dropdown'>
          <i className="fa ion-ios-settings-strong"></i><span className="mx-2">{t('FILTERS')}</span> <i className="fa fa-chevron-down"></i>
        </span>
        <div
          className='dropdown-menu open-top-right dropdown-menu-right p-2'
          x-placement='bottom-end'
          style={{
            position: 'absolute',
            top: '65px',
            left: '-120px',
            willChange: 'top, left',
          }}
        >
          {options.map(option => (
            <span
              key={option.key}
              tabIndex='0'
              className='dropdown-item btn border-0'
              onClick={() => onSelect(option.key)}
            >
              {status[option.key] ? <i className="fa fa-check-square-o"></i> : <i className="fa fa-square-o"></i>}
              {option.label}
            </span>
          ))}
        </div>
      </li>
    </ul>
  )
}

export default Filter
