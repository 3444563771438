import React, { useContext, useEffect, useState } from 'react'
import useAxios from 'axios-hooks'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal/Modal'
import DeleteModal from '../DeleteModal/DeleteModal'
import { SchoolStateContext } from '../../context/SchoolContext'

const SAMLModal = ({ display, setDisplay, onCloseModal, data }) => {
  const { t } = useTranslation()
  const [schoolId] = useContext(SchoolStateContext)
  const [metadata, setMetadata] = useState('')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteModalEndpoint, setDeleteModalEndpoint] = useState('')

  const [{ error, loading }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/metadata`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  )

  useEffect(() => {
    if (data && data.settings_saml_peer_metadata) {
      setMetadata(atob(data.settings_saml_peer_metadata))
    }
  }, [data])

  const handleFormSubmit = async e => {
    e.preventDefault()

    const encoded = btoa(e.target.metadata.value)

    await executePost({
      params: {
        metaData: encoded,
      },
    })

    if (!loading && !error) {
      setDisplay(false)
      if (onCloseModal) {
        onCloseModal();
      }
    }
  }

  const openDeleteModal = () => {
    setDisplay(false)
    setDeleteModalEndpoint(
      `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/metadata`
    )
    setDeleteModalOpen(true)
  }

  return (
    <>
      <DeleteModal
        toDelete='Metadata'
        display={deleteModalOpen}
        setDisplay={setDeleteModalOpen}
        endpoint={deleteModalEndpoint}
        onCloseModal={onCloseModal}
        customMessage={t('SAML_DELETE_CONFIRMATION')}
      />
      <Modal
        targetId='saml-modal'
        width='w-75'
        height='h-75'
        display={display}
        setDisplay={setDisplay}
      >
        <div className='card h-100'>
          <div className='card-header'>
            <h5 className="card-title fw-500">{t('SAML_METADATA')}</h5>
            <i
              className='btn border-0 fa fa-close'
              onClick={() => setDisplay(false)}
            ></i>
          </div>
          <div className='card-body'>
            <form onSubmit={handleFormSubmit} className='form-type-fill'>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  rows='18'
                  id='metadata'
                  name='metadata'
                  value={metadata}
                  placeholder={t('ENTER_YOUR_CODE')}
                  onChange={e => setMetadata(e.target.value)}
                ></textarea>
              </div>

              <div className='d-flex'>
                {data && data.settings_saml_peer_metadata && (
                  <button
                    type='button'
                    onClick={openDeleteModal}
                    className='btn border-0'
                  >
                    <i className='fa fa-trash'></i>
                  </button>
                )}
                <button
                  disabled={metadata === ''}
                  className="my-20 btn btn-bold d-block ml-auto btn-primary btn-label"
                  type='submit'
                >
                  <span>
                    <i className='ti-check'></i>
                  </span>
                  {t('SUBMIT')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SAMLModal
