import React from 'react';
import { Footer } from '../StaticEmailLayout/Footer';
import { Header } from '../StaticEmailLayout/Header';
import { Body } from '../StaticEmailLayout/Body';
import ReactHtmlParser from 'react-html-parser';
import styled from '@emotion/styled';
import Spinner from '../Spinner/index';

const PreviewEmail = ({ size, items, loading }) => {
  return (
    <div className={`w-100 wrapper overflow-auto`}>
      <div
        className={`${
          size === 'desktop' ? 'desktop' : 'phone'
        } wrapper-${size}`}
      >
        <div className="h-100 overflow-auto">
          <Header />
          <Body>
            {loading && <Spinner />}
            {!loading && items && items.length > 0 && (
              <div className="row">
                {items.map((item, i) => {
                  return (
                    <Wrapper key={item.id} half={item.half}>
                      <div>{ReactHtmlParser(item.content)}</div>
                    </Wrapper>
                  );
                })}
              </div>
            )}
          </Body>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default PreviewEmail;

const Wrapper = styled.div`
  height: auto;
  //min-height: 97px;
  margin: 0;
  //align-items: center;
  justify-content: center;
  display: ${(props) => (props.half ? 'inline-flex' : 'flex')};
  vertical-align: ${(props) => (props.half ? 'inherit' : '')};
  width: ${(props) => (props.half ? '50%' : '100%')};
  padding-left: ${(props) => (props.half ? '10px' : '')};
  padding-right: ${(props) => (props.half ? '10px' : '')};
  
  div:first-of-type {
    width: 100%;
    //text-align: center;
  }
`;
