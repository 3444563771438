import React from 'react';
import { css } from '@emotion/core';
import ada from '../../img/ada_badge_white.svg';
import logo from '../../img/AHP-wh.png';

const Footer = () => {
  return (
    <footer
      className="row m-0 bg-primary py-5 py-md-0"
      style={{ maxWidth: '100%' }}
    >
      <div className="col-12 col-md-4 align-items-center justify-content-start pl-5 d-none d-md-flex">
        <img width="124" height="77" src={logo} alt="logo" />
      </div>
      <div className="col-12 col-md-4 py-35">
        <svg
          css={css`
            width: 140px;
            margin: 15px auto;
            display: block;
          `}
          xmlns="http://www.w3.org/2000/svg"
          x="0"
          y="0"
          viewBox="293 308 462 127"
        >
          <g opacity=".7">
            <path
              fill="#fff"
              d="M333 334h49v13h-35v17h21v11h-21v21h35v13h-49v-75z"
            ></path>
            <path
              fill="#fff"
              d="M381 364h19v11h-19v-11zM402 334h14l10 43 10-43h17l10 43 10-43h14l-18 75h-11l-13-57-14 57h-11l-18-75zM510 334h15l24 75h-15l-5-17h-24l-5 17h-14l24-75zm7 20l-8 27h17l-9-27zM562 334h15v75h-15v-75zM594 334h14l15 52 16-52h13l-22 75h-14l-22-75zM666 334h50v13h-35v17h21v11h-21v21h35v13h-50v-75z"
            ></path>
          </g>
          <path
            d="M742 435H306c-7 0-13-6-13-13V321c0-7 6-13 13-13h436c7 0 13 5 13 13v101c0 7-6 13-13 13z"
            opacity=".7"
            fill="none"
            stroke="#fff"
            strokeWidth="2"
            strokeMiterlimit="10"
          ></path>
        </svg>

        <p className="fs-11 text-center bold text-white m-0">
          <strong>16201 West 95th Street, Ste. 210 Lenexa, KS 66219</strong>
        </p>
        <p className="fs-11 text-center bold text-white m-0">
          <strong>
            student-insurance@ahpcare.com Phone / Fax: 877-974-7462 Ext. 315
          </strong>
        </p>
      </div>
      <div className="col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end pr-md-5">
        <img width="100px" height="auto" src={ada} alt="ADA Badge." />
      </div>
    </footer>
  );
};

export default Footer;
