import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useAxios from 'axios-hooks';

// Components
import PreAuth from '../components/PreAuth';
import { AlertService } from '../services/AlertService';

const ResetPassword = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const sendEmailForm = useForm({ mode: 'onChange' });
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const queryParams = new URLSearchParams(history.location.search);
  const [{ data, loading, error, response }, resetPassword] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/auth/reset`,
      method: 'POST'
    },
    {
      manual: true
    }
  );

  const handleEmailSend = async (data) => {
    const emailAddress = data.email;
    const url = `${process.env.REACT_APP_BASE_URL}api/v1/auth/forgot?emailAddress=${emailAddress}`;
    await axios
      .post(url)
      .then(() => {
        AlertService.showSuccess(t('EMAIL_SENT'));
        setErrorMessage('');
        setDisplayError(false);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          setErrorMessage(t('ERROR_USER_NOT_FOUND'));
          setDisplayError(true);
        } else {
          setErrorMessage(t('ERROR_SOMETHING_WENT_WRONG'));
          setDisplayError(true);
        }
      });
  };

  const handleResetSubmit = (data) => {
    const emailAddress = queryParams.get('emailAddress');
    const code = queryParams.get('code');
    const password = data.password;
    const confirmPassword = data.confirmPassword;
    if (password === confirmPassword) {
      resetPassword({
        params: {
          emailAddress,
          password,
          code
        }
      });
    } else {
      AlertService.showError(t('PASSWORD_NOT_MATCH'));
    }
  };

  useEffect(() => {
    if (error) {
      if (
        error.response.data.message === 'Invalid Account Password Reset Code'
      ) {
        setErrorMessage(t('LINK_INVALID_OR_EXPIRED'));
      } else {
        setErrorMessage(t('ERROR_SOMETHING_WENT_WRONG'));
      }
      setDisplayError(true);
    }

    if (!loading && !error && response && response.status === 201) {
      history.push('/login');
    }
  }, [loading, error, response, history, data, t]);

  const renderResetContent = () => {
    if (
      queryParams &&
      queryParams.get('code') &&
      queryParams.get('emailAddress')
    ) {
      return (
        <div
          className="card card-shadowed w-350px mx-auto"
          style={{ maxWidth: '100%' }}
        >
          <div className="card-header">
            <h5 className="text-left card-title fw-500">
              {t('RESET_PASSWORD')}
            </h5>
          </div>
          <div className="card-body">
            <form
              onSubmit={handleSubmit(handleResetSubmit)}
              className="form-type-material"
            >
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  autoComplete="off"
                  id="password"
                  ref={register({ required: true, minLength: 8 })}
                />
                <label htmlFor="password">{t('NEW_PASSWORD')}</label>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  autoComplete="off"
                  name="confirmPassword"
                  id="confirmPassword"
                  ref={register({ required: true, minLength: 8 })}
                />
                <label htmlFor="confirmPassword">{t('CONFIRM_PASSWORD')}</label>
              </div>

              <br />
              <button
                disabled={!formState.isValid}
                className="btn btn-bold w-auto ml-auto btn-block btn-primary btn-label"
                type="submit"
              >
                <span>
                  <i className="ti-check"></i>
                </span>
                {t('UPDATE')}
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="card card-shadowed w-350px mx-auto"
          style={{ maxWidth: '100%' }}
        >
          <div className="card-header">
            <h5 className="card-title fw-500">{t('RECOVER_PASSWORD')}</h5>
          </div>
          <div className="card-body">
            <form
              onSubmit={sendEmailForm.handleSubmit(handleEmailSend)}
              className="form-type-material"
            >
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="email"
                  ref={sendEmailForm.register({ required: true })}
                />
                <label htmlFor="email">{t('EMAIL_ADDRESS')}</label>
              </div>

              <br />
              <button
                style={{ backgroundColor: '#a8a9ae', color: '#fff' }}
                className="btn btn-bold btn-label"
                onClick={() => history.goBack()}
              >
                <label className="center-v">
                  <i className="ti-close" />
                </label>
                {t('CANCEL')}
              </button>
              <button
                style={{ float: 'right' }}
                className={`btn btn-bold btn-label btn-primary ${
                  sendEmailForm.formState.isValid ? '' : 'disabled'
                }`}
                type="submit"
              >
                <span>
                  <i className="ti-check"></i>
                </span>
                {t('SUBMIT')}
              </button>
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <PreAuth>
      {displayError && (
        <div
          className="callout callout-danger mb-4 w-350px mx-auto"
          role="alert"
        >
          <p>{errorMessage}</p>
        </div>
      )}
      {renderResetContent()}
    </PreAuth>
  );
};

export default ResetPassword;
