import React from 'react'
import { useTranslation } from 'react-i18next';

// Components
import PageLayout from '../components/PageLayout/PageLayout';
import DataDumpReportContent from '../components/ReportsContent/DataDumpReportContent';

const DataDumpReport = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      withHeader={true}
      title={t('DATA_DUMP')}
      crumbs={[
        { title: t('REPORTS'), to: '/reports' },
        { title: t('DATA_DUMP'), to: '/' },
      ]}
    >
      <DataDumpReportContent />
    </PageLayout>
  )
}

export default DataDumpReport;
