import React from 'react';
import PageLayout from '../components/PageLayout/PageLayout';
import EmailTemplateEditorContent from '../components/EmailTemplateEditorContent';
import { useTranslation } from 'react-i18next';

const EmailTemplateEditor = () => {
  const { t } = useTranslation();
  return (
    <PageLayout
      withHeader={true}
      title={t('EMAILS')}
      crumbs={[{ title: 'Templates', to: '/email-templates' }, { title: 'Edit', to: null}]}
    >
      <EmailTemplateEditorContent />
    </PageLayout>
  );
};

export default EmailTemplateEditor;
