import React, { createContext, useState } from 'react'

const SchoolStateContext = createContext(['', undefined])
const LocalStateProvider = SchoolStateContext.Provider

function SchoolStateProvider(props) {
  const [schoolId, setSchoolId] = useState(props.value[0])

  return (
    <LocalStateProvider value={[schoolId, setSchoolId]}>
      {props.children}
    </LocalStateProvider>
  )
}

export { SchoolStateProvider, SchoolStateContext }
