import React, { useContext, useState, useEffect, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { SchoolStateContext } from '../../context/SchoolContext';
import Spinner from '../Spinner/index';
import { AlertService } from '../../services/AlertService';
import EmailsPreviewModal from '../EmailsPreviewModal/index';
import templateIcon from '../../img/template.svg';
import { EmailEditorContainer } from '../EmailEditorContainer';

const EmailsTab = () => {
  const { t } = useTranslation();
  const [schoolId] = useContext(SchoolStateContext);
  const [form, setForm] = useState();

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewtemplateName, setPreviewTemplateName] = useState('');
  const [previewtemplateData, setPreviewTemplateData] = useState('');
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [editTemplate, setEditTemplate] = useState(false);
  const [willEdit, setWillEdit] = useState(false);
  const [selectedCreateType, setSelectedCreateType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({
    WELCOME_EMAIL: null,
    SHIP_CONFIRMATION: null,
    WAIVER_CONFIRMATION: null,
    WAIVER_APPROVAL_CONFIRMATION: null,
    WAIVER_PENDING_DENIAL: null,
    WAIVER_FINAL_DENIAL: null,
    SUPPLEMENT_CONFIRMATION: null
  });
  const [sendForm, setSendForm] = useState(false);

  const EmailCard = useCallback(
    ({
      title,
      enabled,
      emailKey,
      subject,
      handleCheck,
      handleInput,
      frequency,
      frequencyValue,
      handleFrequency,
      handleLimit,
      doubleSubject,
      preDeadline,
      postDeadline,
      limit,
      limitValue,
      emailTemplates,
      selectedTemplate,
      handleSelectedTemplate,
      handleEdit,
      handlePreview,
      handleCreate
    }) => {
      let frequenLabel = t('FREQUENCY');
      switch (frequencyValue) {
        case 1:
          frequenLabel = t('DAILY');
          break;
        case 7:
          frequenLabel = t('EVERY_7_DAYS');
          break;
        case 14:
          frequenLabel = t('EVERY_14_DAYS');
          break;

        default:
          break;
      }

      let limitLabel = t('LIMIT');
      switch (limitValue) {
        case 0:
          limitLabel = t('NO_LIMIT');
          break;
        case 100:
          limitLabel = 100;
          break;
        case 1000:
          limitLabel = `1,000`;
          break;
        case 5000:
          limitLabel = `5,000`;
          break;
        default:
          break;
      }

      let selectedTemplateLabel = 'No selected template';
      if (selectedTemplate[emailKey] && selectedTemplate[emailKey].name) {
        selectedTemplateLabel = selectedTemplate[emailKey].name;
      }

      return (
        <div className="card" key={emailKey}>
          <div className="card-header">
            <div className="row w-100 justify-content-between">
              <h5 className="card-title fw-500">
                <span>
                  <strong>{title}</strong>
                </span>
              </h5>
              <div className="d-flex">
                {limit && (
                  <ul className="m-0">
                    <li className="dropdown d-block">
                      <span
                        tabIndex="0"
                        className="btn border-0 p-0 fw-500 text-black bg-transparent"
                        data-toggle="dropdown"
                      >
                        <span>{limitLabel}</span>{' '}
                        <i className="fa fa-chevron-down"></i>
                      </span>
                      <div
                        className="dropdown-menu open-top-right dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          position: 'absolute',
                          top: '65px',
                          left: '-120px',
                          willChange: 'top, left'
                        }}
                      >
                        <span
                          tabIndex="0"
                          className={`dropdown-item btn border-0 ${
                            limitValue === 100 ? 'bg-lighter text-body' : ''
                          }`}
                          onClick={() => handleLimit(emailKey, 100)}
                        >
                          100
                        </span>
                        <span
                          tabIndex="0"
                          className={`dropdown-item btn border-0 ${
                            limitValue === 1000 ? 'bg-lighter text-body' : ''
                          }`}
                          onClick={() => handleLimit(emailKey, 1000)}
                        >
                          1,000
                        </span>
                        <span
                          tabIndex="0"
                          className={`dropdown-item btn border-0 ${
                            limitValue === 5000 ? 'bg-lighter text-body' : ''
                          }`}
                          onClick={() => handleLimit(emailKey, 5000)}
                        >
                          5,000
                        </span>
                        <span
                          tabIndex="0"
                          className={`dropdown-item btn border-0 ${
                            limitValue === 0 ? 'bg-lighter text-body' : ''
                          }`}
                          onClick={() => handleLimit(emailKey, 0)}
                        >
                          {t('NO_LIMIT')}
                        </span>
                      </div>
                    </li>
                  </ul>
                )}
                {frequency && (
                  <ul className="m-0">
                    <li className="dropdown d-block">
                      <span
                        tabIndex="0"
                        className="btn border-0 p-0 fw-500 text-black bg-transparent"
                        data-toggle="dropdown"
                      >
                        <i className="ti-calendar"></i>{' '}
                        <span>{frequenLabel}</span>{' '}
                        <i className="fa fa-chevron-down"></i>
                      </span>
                      <div
                        className="dropdown-menu open-top-right dropdown-menu-right"
                        x-placement="bottom-end"
                        style={{
                          position: 'absolute',
                          top: '65px',
                          left: '-120px',
                          willChange: 'top, left'
                        }}
                      >
                        <span
                          tabIndex="0"
                          className={`dropdown-item btn border-0 ${
                            frequencyValue === 1 ? 'bg-lighter text-body' : ''
                          }`}
                          onClick={() => handleFrequency(emailKey, 1)}
                        >
                          {t('DAILY')}
                        </span>
                        <span
                          tabIndex="0"
                          className={`dropdown-item btn border-0 ${
                            frequencyValue === 7 ? 'bg-lighter text-body' : ''
                          }`}
                          onClick={() => handleFrequency(emailKey, 7)}
                        >
                          {t('EVERY_7_DAYS')}
                        </span>
                        <span
                          tabIndex="0"
                          className={`dropdown-item btn border-0 ${
                            frequencyValue === 14 ? 'bg-lighter text-body' : ''
                          }`}
                          onClick={() => handleFrequency(emailKey, 14)}
                        >
                          {t('EVERY_14_DAYS')}
                        </span>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-2">
                <label className="switch">
                  <input
                    key={`switch_${emailKey}`}
                    type="checkbox"
                    checked={enabled}
                    onChange={() => handleCheck(emailKey)}
                  />
                  <span className="switch-indicator"></span>
                  <span className="switch-description">{t('ENABLE')}</span>
                </label>
              </div>
              <div className="col-md-2 pd-0">
                {doubleSubject && (
                  <>
                    <p className="mt-15">{t('PRE_DEADLINE_SUBJECT')}</p>
                    <p className="mt-15">{t('POST_DEADLINE_SUBJECT')}</p>
                  </>
                )}
              </div>
              <div className="col-md-8">
                {doubleSubject ? (
                  <>
                    <div
                      className={`form-type-material form-group ${
                        preDeadline ? 'do-float' : ''
                      }`}
                    >
                      <input
                        key={`input_${emailKey}`}
                        type="text"
                        className="form-control"
                        value={preDeadline || ''}
                        onChange={(e) =>
                          handleInput(emailKey, e.target.value, 'preDeadline')
                        }
                      />
                      <label>{t('SUBJECT')}</label>
                    </div>
                    <div
                      className={`form-type-material form-group ${
                        postDeadline ? 'do-float' : ''
                      }`}
                    >
                      <input
                        key={`input_${emailKey}`}
                        type="text"
                        className="form-control"
                        value={postDeadline || ''}
                        onChange={(e) =>
                          handleInput(emailKey, e.target.value, 'postDeadline')
                        }
                      />
                      <label>{t('SUBJECT')}</label>
                    </div>
                  </>
                ) : (
                  <div
                    className={`form-type-material form-group ${
                      subject ? 'do-float' : ''
                    }`}
                  >
                    <input
                      key={`input_${emailKey}`}
                      type="text"
                      className="form-control"
                      value={subject || ''}
                      onChange={(e) => handleInput(emailKey, e.target.value)}
                    />
                    <label>{t('SUBJECT')}</label>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-8 d-flex">
                <div className="row flex-start">
                  <ul
                    className="m-0"
                    css={css`
                      min-width: 250px;
                    `}
                  >
                    <li className="dropdown d-block">
                      <span
                        tabIndex="0"
                        className="btn border-0 p-0 fw-500 text-black bg-transparent"
                        data-toggle="dropdown"
                      >
                        <img
                          src={templateIcon}
                          alt="templateIcon"
                          className="mr-2"
                        />
                        <span>{selectedTemplateLabel}</span>{' '}
                        <i className="fa fa-chevron-down"></i>
                      </span>
                      <div
                        className="dropdown-menu open-top-left dropdown-menu-left"
                        x-placement="bottom-end"
                        style={{
                          position: 'absolute',
                          top: '65px',
                          willChange: 'top, right'
                        }}
                      >
                        {emailTemplates &&
                          emailTemplates.map((emailTemplate) => (
                            <span
                              key={`span_${emailTemplate.name}`}
                              tabIndex="0"
                              className={`dropdown-item btn border-0 ${
                                selectedTemplateLabel === emailTemplate.name
                                  ? 'bg-lighter text-body'
                                  : ''
                              }`}
                              onClick={() =>
                                handleSelectedTemplate(
                                  emailTemplate.emailKey,
                                  emailTemplate.name,
                                  emailTemplate.id
                                )
                              }
                            >
                              {emailTemplate.name}
                            </span>
                          ))}
                      </div>
                    </li>
                  </ul>
                  <button
                    disabled={
                      selectedTemplate[emailKey] &&
                      !selectedTemplate[emailKey].name
                    }
                    className="ml-10 btn btn-bold d-block ml-auto btn-info btn-label"
                    type="submit"
                    onClick={() =>
                      handlePreview(
                        emailKey,
                        selectedTemplate[emailKey].name,
                        title
                      )
                    }
                  >
                    <label>
                      <i
                        css={css`
                          font-size: 18px;
                        `}
                        className="ti-new-window"
                      ></i>
                    </label>
                    {t('PREVIEW')}
                  </button>
                  <button
                    className={`ml-10 btn btn-bold ${
                      !selectedTemplate[emailKey] ||
                      !selectedTemplate[emailKey].name ||
                      selectedTemplate[emailKey].name === 'Default Template'
                        ? 'd-none'
                        : 'd-block'
                    } ml-auto btn-warning btn-label`}
                    type="submit"
                    onClick={() =>
                      handleEdit(
                        emailKey,
                        selectedTemplate[emailKey].name,
                        title
                      )
                    }
                  >
                    <label>
                      <i
                        css={css`
                          font-size: 18px;
                        `}
                        className="ti-pencil-alt"
                      ></i>
                    </label>
                    {t('EDIT')}
                  </button>
                </div>
              </div>
              <div className="col-4 flex-end">
                <button
                  className="btn btn-bold d-block ml-auto btn-primary btn-label"
                  type="submit"
                  onClick={() => handleCreate(emailKey, title)}
                >
                  <label>
                    <i
                      css={css`
                        font-size: 18px;
                      `}
                      className="ti-plus"
                    ></i>
                  </label>
                  {t('CREATE_TEMPLATE')}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const [{ loading, data }, getEmails] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/emails`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [{ data: templatesList }, getTemplatesList] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/mails-templates/basic`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [
    { loading: templatesContentLoading, data: templatesContentData },
    getTemplateContent
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/${selectedTemplateId}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [
    { response: createResponse, error: errorCreate },
    executeCreate
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/mails-templates`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [{ response: editResponse, error: errorEdit }, executeEdit] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/${selectedTemplateId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (createResponse && !errorCreate) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
      getTemplatesList();
    } else if (editResponse && !errorEdit) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));

      //Changes current template name if saved
      setSelectedTemplate((f) => ({
        ...f,
        [selectedCreateType]: {
          name: previewtemplateName,
          id: f[selectedCreateType].id
        }
      }));
    } else if (errorEdit || errorCreate) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createResponse, errorCreate, editResponse, errorEdit]);

  useEffect(() => {
    getEmails();
    getTemplatesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setForm({
        ...data.emails_config,
        WELCOME_EMAIL: {
          ...data.emails_config.WELCOME_EMAIL,
          frequency: data.emails_config.WELCOME_EMAIL.frequency
            ? data.emails_config.WELCOME_EMAIL.frequency
            : 7,
          limit:
            data.emails_config.WELCOME_EMAIL.limit ||
            data.emails_config.WELCOME_EMAIL.limit === 0
              ? data.emails_config.WELCOME_EMAIL.limit
              : null
        },
        WAIVER_PENDING_DENIAL: {
          ...data.emails_config.WAIVER_PENDING_DENIAL,
          frequency: data.emails_config.WAIVER_PENDING_DENIAL.frequency
            ? data.emails_config.WAIVER_PENDING_DENIAL.frequency
            : 7
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (selectedTemplateId) {
      getTemplateContent();
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (templatesContentData) {
      const parsedItems = JSON.parse(templatesContentData.items);
      setPreviewTemplateData(parsedItems);
    }
  }, [templatesContentData]);

  const [{ error, response }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/emails`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (response && !error) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, response]);

  const getTemplatesByType = (type) => {
    let templateObj = [];

    if (!templatesList) {
      return;
    }

    Object.entries(templatesList).map((templateType) => {
      if (templateType[0] == type) {
        templateType[1].map((template) => {
          templateObj.push({
            id: template.id,
            name:
              template.template_name == 'Fall Template' ||
              template.template_name == 'Spring Template'
                ? 'Default Template'
                : template.template_name,
            enabled: template.enabled,
            emailKey: template.type
          });
        });
      }
    });

    if (templateObj.length < 1) {
      return;
    }

    //Sorts enabled first
    templateObj.sort(function (x, y) {
      return y.enabled - x.enabled;
    });

    //By default selected template is null, this triggers first time only...
    if (!selectedTemplate[type]) {
      handleSelectedTemplate(type, templateObj[0].name, templateObj[0].id);
    }

    return templateObj;
  };

  const handleUpdate = useCallback(() => {
    const entries = Object.entries(selectedTemplate);

    entries.map((entry) => {
      setForm((f) => ({
        ...f,
        [entry[0]]: {
          ...f[entry[0]],
          mailTemplateId: entry[1] && entry[1].id ? entry[1].id : null
        }
      }));
    });

    setSendForm(true);
  }, [executePost, form, selectedTemplate]);

  useEffect(() => {
    if (sendForm) {
      executePost({
        params: {
          configuration: JSON.stringify(form)
        }
      });
    }
    setSendForm(false);
  }, [sendForm]);

  const handleCheck = useCallback(
    (key) => {
      setForm((f) => ({
        ...f,
        [key]: {
          ...f[key],
          enabled: !f[key].enabled
        }
      }));
    },
    [setForm]
  );

  const handleInput = useCallback(
    (key, val, doubleSubject = false) => {
      if (!doubleSubject) {
        setForm((f) => ({
          ...f,
          [key]: {
            ...f[key],
            subject: val
          }
        }));
      } else {
        setForm((f) => ({
          ...f,
          [key]: {
            ...f[key],
            [doubleSubject]: {
              subject: val
            }
          }
        }));
      }
    },
    [setForm]
  );

  const handleFrequency = useCallback(
    (key, value) => {
      setForm((f) => ({
        ...f,
        [key]: {
          ...f[key],
          frequency: value
        }
      }));
    },
    [setForm]
  );

  const handleLimit = useCallback(
    (key, value) => {
      setForm((f) => ({
        ...f,
        [key]: {
          ...f[key],
          limit: value
        }
      }));
    },
    [setForm]
  );

  const handleSelectedTemplate = useCallback(
    (key, name, value) => {
      //setSelectedTemplate(f => ({...f,[key]: value}))
      setSelectedTemplate((f) => ({
        ...f,
        [key]: { name: name, id: value }
      }));
    },
    [setSelectedTemplate]
  );

  const handlePreview = useCallback((key, value, title) => {
    setPreviewTitle(title);
    setPreviewTemplateName(value);
    //setPreviewTemplateData() //la data del template seleccionado
    getSelectedTemplateData(key);
    setOpenPreviewModal(true);
  });

  const handleEdit = useCallback((key, value, title) => {
    setSelectedCreateType(key);
    setPreviewTitle(title);
    setPreviewTemplateName(value);
    //setPreviewTemplateData() //la data del template seleccionado
    getSelectedTemplateData(key);
    setEditTemplate(true);
    setWillEdit(true);
  });

  const handleCreate = useCallback((key, title) => {
    //setPreviewTemplateData() //la data del default
    setSelectedCreateType(key);
    setPreviewTitle(title);
    setPreviewTemplateName('Copy from default template');
    //Should get key of default tempalte...
    getSelectedTemplateData(key);
    setEditTemplate(true);
    setWillEdit(false);
  });

  const handleCancel = useCallback(() => {
    setEditTemplate(false);
    //Obtengo el contenido enviado
    getTemplateContent();
  });

  //This method should only be triggered on handleFunctions...
  const getSelectedTemplateData = (key) => {
    setSelectedTemplateId(selectedTemplate[key].id);
  };

  if (loading) {
    return <Spinner />;
  }

  if (form) {
    if (editTemplate) {
      return (
        <div className="card card-shadowed" style={{ marginTop: '-45px' }}>
          <div className="card-header">
            <h5 className="card-title fw-500">
              <span>
                <strong>{previewTitle}</strong> {t('EDIT')}
              </span>
            </h5>
          </div>
          <EmailEditorContainer
            title={previewTitle}
            templateName={previewtemplateName}
            setTemplateName={setPreviewTemplateName}
            content={previewtemplateData}
            selectedCreateType={selectedCreateType}
            handleCancel={handleCancel}
            willEdit={willEdit}
            loading={templatesContentLoading}
            executeEdit={executeEdit}
            executeCreate={executeCreate}
          />
        </div>
      );
    } else {
      return (
        <>
          <EmailsPreviewModal
            title={previewTitle}
            templateName={previewtemplateName}
            items={previewtemplateData}
            display={openPreviewModal}
            setDisplay={setOpenPreviewModal}
            fromEdit={false}
            onCloseModal={getEmails}
            loading={templatesContentLoading}
          />
          <EmailCard
            key="WELCOME_EMAIL"
            title={t('WELCOME_EMAIL')}
            emailKey="WELCOME_EMAIL"
            enabled={form.WELCOME_EMAIL.enabled}
            subject={form.WELCOME_EMAIL.subject}
            handleCheck={handleCheck}
            handleFrequency={handleFrequency}
            handleInput={handleInput}
            handleLimit={handleLimit}
            frequency
            frequencyValue={form.WELCOME_EMAIL.frequency}
            limit
            limitValue={form.WELCOME_EMAIL.limit}
            emailTemplates={getTemplatesByType('WELCOME_EMAIL')}
            selectedTemplate={selectedTemplate}
            handleSelectedTemplate={handleSelectedTemplate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            handleCreate={handleCreate}
          />
          <EmailCard
            title={t('SHIP_ENROLLMENT_CONFIRMATION_EMAIL')}
            emailKey="SHIP_CONFIRMATION"
            enabled={form.SHIP_CONFIRMATION.enabled}
            subject={form.SHIP_CONFIRMATION.subject}
            handleCheck={handleCheck}
            handleFrequency={handleFrequency}
            handleInput={handleInput}
            frequency={form.SHIP_CONFIRMATION.frequency}
            emailTemplates={getTemplatesByType('SHIP_CONFIRMATION')}
            selectedTemplate={selectedTemplate}
            handleSelectedTemplate={handleSelectedTemplate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            handleCreate={handleCreate}
          />
          <EmailCard
            title={t('WAIVER_APPLICATION_CONFIRMATION_EMAIL')}
            emailKey="WAIVER_CONFIRMATION"
            enabled={form.WAIVER_CONFIRMATION.enabled}
            subject={form.WAIVER_CONFIRMATION.subject}
            handleCheck={handleCheck}
            handleFrequency={handleFrequency}
            handleInput={handleInput}
            frequency={form.WAIVER_CONFIRMATION.frequency}
            emailTemplates={getTemplatesByType('WAIVER_CONFIRMATION')}
            selectedTemplate={selectedTemplate}
            handleSelectedTemplate={handleSelectedTemplate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            handleCreate={handleCreate}
          />
          <EmailCard
            title={t('WAIVER_APPROVAL_CONFIRMATION_EMAIL')}
            emailKey="WAIVER_APPROVAL_CONFIRMATION"
            enabled={form.WAIVER_APPROVAL_CONFIRMATION.enabled}
            subject={form.WAIVER_APPROVAL_CONFIRMATION.subject}
            handleCheck={handleCheck}
            handleFrequency={handleFrequency}
            handleInput={handleInput}
            frequency={form.WAIVER_APPROVAL_CONFIRMATION.frequency}
            emailTemplates={getTemplatesByType('WAIVER_APPROVAL_CONFIRMATION')}
            selectedTemplate={selectedTemplate}
            handleSelectedTemplate={handleSelectedTemplate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            handleCreate={handleCreate}
          />
          <EmailCard
            title={t('WAIVER_PENDING_REQUIREMENTS_EMAIL')}
            emailKey="WAIVER_PENDING_DENIAL"
            enabled={form.WAIVER_PENDING_DENIAL.enabled}
            subject={form.WAIVER_PENDING_DENIAL.subject}
            handleCheck={handleCheck}
            handleFrequency={handleFrequency}
            handleInput={handleInput}
            frequency
            frequencyValue={form.WAIVER_PENDING_DENIAL.frequency}
            doubleSubject
            preDeadline={form.WAIVER_PENDING_DENIAL.preDeadline.subject}
            postDeadline={form.WAIVER_PENDING_DENIAL.postDeadline.subject}
            emailTemplates={getTemplatesByType('WAIVER_PENDING_DENIAL')}
            selectedTemplate={selectedTemplate}
            handleSelectedTemplate={handleSelectedTemplate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            handleCreate={handleCreate}
          />
          <EmailCard
            title={t('WAIVER_DENIAL_EMAIL')}
            emailKey="WAIVER_FINAL_DENIAL"
            enabled={form.WAIVER_FINAL_DENIAL.enabled}
            subject={form.WAIVER_FINAL_DENIAL.subject}
            handleCheck={handleCheck}
            handleFrequency={handleFrequency}
            handleInput={handleInput}
            frequency={form.WAIVER_FINAL_DENIAL.frequency}
            emailTemplates={getTemplatesByType('WAIVER_FINAL_DENIAL')}
            selectedTemplate={selectedTemplate}
            handleSelectedTemplate={handleSelectedTemplate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            handleCreate={handleCreate}
          />
          <EmailCard
            title={t('SUPPLEMENT_EMAIL')}
            emailKey="SUPPLEMENT_CONFIRMATION"
            enabled={form.SUPPLEMENT_CONFIRMATION.enabled}
            subject={form.SUPPLEMENT_CONFIRMATION.subject}
            handleCheck={handleCheck}
            handleFrequency={handleFrequency}
            handleInput={handleInput}
            frequency={form.SUPPLEMENT_CONFIRMATION.frequency}
            emailTemplates={getTemplatesByType('SUPPLEMENT_CONFIRMATION')}
            selectedTemplate={selectedTemplate}
            handleSelectedTemplate={handleSelectedTemplate}
            handleEdit={handleEdit}
            handlePreview={handlePreview}
            handleCreate={handleCreate}
          />
          <button
            // disabled={!imagesFormDirty}
            className="my-20 btn btn-bold d-block ml-auto btn-primary btn-label"
            type="submit"
            onClick={handleUpdate}
          >
            <label>
              <i className="ti-check"></i>
            </label>
            {t('UPDATE')}
          </button>
        </>
      );
    }
  }
  return null;
};

export default EmailsTab;
