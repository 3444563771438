import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export class AlertService {
  static alertInstance = null;

  static defaultConfig = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 8000,
    draggable: true
  };

  static setDropdownAlertInstance(ref) {
    this.alertInstance = ref;
  }

  static showError(body = 'Error', config = {}) {
    toast.error(body, { ...this.defaultConfig, ...config });
  }

  static showErrorV2(message, config = {}) {
    let parsedError;

    if (Array.isArray(message.response.data.message)) {
      parsedError = message.response.data.message[0];
    } else if (
      !Array.isArray(message.response.data.message) &&
      message.response.data.message
    ) {
      parsedError = message.response.data.message;
    } else {
      parsedError = 'ERROR_SOMETHING_WENT_WRONG';
    }

    toast.error(parsedError, { ...this.defaultConfig, ...config });
  }

  static showInfo(body = 'Info', config = {}) {
    toast.info(body, { ...this.defaultConfig, ...config });
  }

  static showSuccess(body = 'Success', config = {}) {
    toast.success(body, { ...this.defaultConfig, ...config });
  }

  static showWarning(body = 'Warning', config = {}) {
    toast.warn(body, { ...this.defaultConfig, ...config });
  }
}
