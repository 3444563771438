/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';

const SchoolFilter = ({
  selectedSchoolId,
  setSelectedSchoolId,
  defaultSelected
}) => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [count, setCount] = useState(0);

  const { t } = useTranslation();
  const [{ data: schoolData }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    }
  });

  useEffect(() => {
    if (schoolData) {
      const schoolOptions = [];
      if (!defaultSelected) {
        schoolOptions.push({ value: null, label: null });
      }
      schoolData.forEach((school) =>
        schoolOptions.push({ value: school.id, label: school.school_name })
      );
      setSelectOptions(schoolOptions);
      if (defaultSelected) {
        setSelectedSchoolId(schoolOptions[0].value);
      }
      setCount((c) => c + 1);
    }
  }, [schoolData]);

  return (

      <div
        className="form-group do-float"
        key={count}
        css={css`
          .form-type-material .bootstrap-select .dropdown-toggle:focus {
            background-color: transparent !important;
          }
        `}
    >
      <select
        type="text"
        name="schoolId"
        id="schoolId"
        className="form-control bg-transparent"
        data-provide="selectpicker"
        onChange={(e) => setSelectedSchoolId(e.target.value)}
        value={selectedSchoolId}
      >
        {selectOptions.map((o) => (
          <option
            key={o.value}
            value={o.value}
            className={o.value ? '' : 'd-none'}
          >
            {t(o.label)}
          </option>
        ))}
      </select>
      <label htmlFor="schoolId" className="label-floated">
        {t('SCHOOL_NAME')}
      </label>
    </div>

  );
};

export default SchoolFilter;
