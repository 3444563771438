import React, { memo, useState, useEffect } from 'react';
import { StatefulTargetBox as TargetBox } from './TargetBox';
import styled from '@emotion/styled';
import { OptionsBox } from './OptionsBox';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EmailsPreviewModal from '../EmailsPreviewModal';

export const EmailEditorContainer = memo(function Container({
  title,
  templateName,
  content,
  handleCancel,
  setTemplateName,
  selectedCreateType,
  willEdit,
  selectedTemplateId,
  loading,
  executeEdit,
  executeCreate
}) {
  const [viewport, setViewport] = useState('tablet');
  const [list, setList] = useState(content ? content : []);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const handlePreview = () => {
    setOpenPreviewModal(true);
  };

  useEffect(() => {
    if (content) {
      setList(content);
    }
  }, [content]);

  return (
    <DndProvider backend={HTML5Backend}>
      <MainWrapper>
        {/*PREVIEW FROM EDIT... */}
        <EmailsPreviewModal
          title={title}
          templateName={templateName}
          items={list}
          display={openPreviewModal}
          setDisplay={setOpenPreviewModal}
          fromEdit={true}
          selectedCreateType={selectedCreateType}
          willEdit={willEdit}
          selectedTemplateId={selectedTemplateId}
          loading={loading}
          executeEdit={executeEdit}
          executeCreate={executeCreate}
        />
        <TargetBox
          viewport={viewport}
          setList={setList}
          list={list}
          templateName={templateName}
          setTemplateName={setTemplateName}
          loading={loading}
        />
        <OptionsBox
          viewport={viewport}
          setViewport={setViewport}
          handlePreview={handlePreview}
          handleCancel={handleCancel}
        />
      </MainWrapper>
    </DndProvider>
  );
});

const MainWrapper = styled.div`
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;
