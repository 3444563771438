import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const Modal = ({
  inputCSS,
  targetId,
  width,
  height,
  children,
  display,
  setDisplay,
  overflow
}) => {
  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      setDisplay(false);
    }
  };

  return (
    <CSSTransition
      unmountOnExit
      in={display}
      timeout={300}
      classNames="animate-modal"
    >
      <ModalWrapper
        css={css`
          background-color: #00000033;
          ${inputCSS ? inputCSS : ''}
        `}
        //className={`modal modal-center fade d-block show`}
        id={targetId}
        tabIndex="-1"
        aria-modal="true"
        onClick={handleClickOutside}
      >
        <div className="mx-auto" style={!width ? { width: 'fit-content' } : {}}>
          <div
            className={`modal-content card card-shadowed mx-auto ${
              overflow ? overflow : 'overflow-auto'
            } ${height ? 'fit-content' : ''} ${width ? width : 'w-350px'}`}
          >
            {children}
          </div>
        </div>
      </ModalWrapper>
    </CSSTransition>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  display: block;
  overflow: scroll;
  width: 100%;
  height: 100%;
  padding-top: 3%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export default Modal;
