import React, { useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DeleteModal from '../../DeleteModal/DeleteModal';
import { AlertService } from '../../../services/AlertService';

const AddUserCard = ({ schoolId, onAdd,executeGet, user: selectedUser }) => {
  const { t } = useTranslation()

  const { register, handleSubmit, formState, watch, setValue } = useForm({
    mode: 'onChange',
  })

  const values = watch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteModalEndpoint, setDeleteModalEndpoint] = useState('')

  useEffect(() => {
    setValue('firstName', selectedUser ? selectedUser.first_name : '')
    setValue('lastName', selectedUser ? selectedUser.last_name : '')
    setValue('emailAddress', selectedUser ? selectedUser.email_address : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser])

  const [postRes, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/users${selectedUser ? `/${selectedUser.id}` : ''
        }`,
      method: selectedUser ? 'PUT' : 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  );
  const [{ resError, resLoading }, exeRestore] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/users/restore`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },

    {
      manual: true,
    }
  );

  const handleAddUser = async () => {

    try {
      let response = await executePost({
        params: {
          ...values,
        },
      });
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    }
    catch (e) {
      // Handle cancellation
      switch (e.response.data.message) {
        case 'Email Address is Already Assigned to Another User':

          let restoreData = await exeRestore({
            params: {
              emailAddress: selectedUser ? selectedUser.email_address : values.emailAddress,
            },
          });
          if (restoreData.status == 200) {
            executeGet();
            AlertService.showSuccess(t('SAVE_SUCCESS'));
          } else {
            postRes.error = true;
            AlertService.showError(t('STUDENT_EMAIL_EXISTS'));
          }

          break;


        default:
          postRes.error = true;
          AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
          break;
      }

    }
    if (!postRes.error && !postRes.loading) {
      setValue('firstName', '');
      setValue('lastName', '');
      setValue('emailAddress', '');
      onAdd()
    }

  }

  const handleUserDelete = () => {
    setOpenDeleteModal(true)
    setDeleteModalEndpoint(
      `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/users/${selectedUser.id}`
    )
  }

  const handleInputChange = e => {
    setValue(e.target.name, e.target.value)
  }

  return (
    <>
      <DeleteModal
        display={openDeleteModal}
        setDisplay={setOpenDeleteModal}
        onCloseModal={onAdd}
        endpoint={deleteModalEndpoint}
        toDelete='User'
      />
      <div className='card card-shadowed'>
        <header className='card-header'>
          <h4 className='card-title'>
            <strong>
              {t(`${selectedUser ? 'EDIT' : 'ADD'}`)} {t('USER')}
            </strong>
          </h4>
        </header>
        <div className='card-body'>
          <form
            onSubmit={handleSubmit(handleAddUser)}
            className='form-type-material'
          >
            <div className={`form-group ${selectedUser ? 'do-float' : ''}`}>
              <input
                type='text'
                className='form-control'
                name='firstName'
                id='firstName'
                value={values.firstName || ''}
                onChange={handleInputChange}
                ref={register({ required: true })}
              />
              <label
                className={`${selectedUser ? 'label-floated' : ''}`}
                htmlFor='firstName'
              >
                {t('FIRST_NAME')}
              </label>
            </div>
            <div className={`form-group ${selectedUser ? 'do-float' : ''}`}>
              <input
                type='text'
                className='form-control'
                name='lastName'
                id='lastName'
                value={values.lastName || ''}
                onChange={handleInputChange}
                ref={register({ required: true })}
              />
              <label
                className={`${selectedUser ? 'label-floated' : ''}`}
                htmlFor='lastName'
              >
                {t('LAST_NAME')}
              </label>
            </div>
            <div className={`form-group ${selectedUser ? 'do-float' : ''}`}>
              <input
                type='text'
                className='form-control'
                name='emailAddress'
                id='emailAddress'
                value={values.emailAddress || ''}
                onChange={handleInputChange}
                ref={register({ required: true })}
              />
              <label
                className={`${selectedUser ? 'label-floated' : ''}`}
                htmlFor='emailAddress'
              >
                {t('EMAIL_ADDRESS')}
              </label>
            </div>

            <div className='d-flex'>
              {selectedUser && (
                <button
                  type='button'
                  className='btn border-0 mr-auto'
                  onClick={handleUserDelete}
                >
                  <i className='fa fa-trash'></i>
                </button>
              )}
              <button
                className={`btn btn-bold d-block ml-auto btn-primary btn-label ${formState.isValid || selectedUser ? '' : 'disabled'
                  }`}
                type='submit'
              >
                <span>
                  <i className='ti-check'></i>
                </span>
                {t(`${selectedUser ? 'UPDATE' : 'ADD'}`)}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddUserCard
