import React, { useState, useMemo, useEffect } from 'react'
import Modal from '../Modal/Modal'
import useAxios from 'axios-hooks'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import CardHeader from '../CardHeader/index';
import { AlertService } from '../../services/AlertService';

const AddSchoolModal = ({ display, setDisplay, onCloseModal }) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' })
  const { t } = useTranslation();
  const [selectOption, setSelectOption] = useState('');

  const [{ loading, error,response }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    { manual: true }
  )

  const handleCreateSchool = async data => {
    await executePost({
      params: {
        schoolName: data.schoolName,
        schoolAbbr: data.schoolAbbr,
        timeZone: selectOption,
      },
    })
    if (!error && !loading) {
      onCloseModal()
      setDisplay(false)
    }
  }

  useEffect(() => {
    if (response) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (error) {
      Object.keys( error.response.data.message).map(key => AlertService.showError(error.response.data.message[key]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  const isFormValid = useMemo(() => formState.isValid && selectOption !== ''
    , [formState,selectOption])
  return (
    <Modal
      targetId='add-school-modal'
      width='w-350px'
      display={display}
      setDisplay={setDisplay}
    >
      <CardHeader
        title={t('CREATE_SCHOOL')}
        rightComponent={(
          <button onClick={() => setDisplay(false)} className='btn border-0 p-0'>
            <i className='fa fa-close'></i>
          </button>
        )}
      />
      <div className='card-body'>
        <form
          onSubmit={handleSubmit(handleCreateSchool)}
          className='form-type-material row mx-auto'
        >
          <div className='col-12'>
            <div className='form-group'>
              <input
                type='text'
                name='schoolName'
                id='schoolName'
                className='form-control'
                ref={register({ required: true })}
              />
              <label htmlFor='schoolName' className='require'>
                {t('SCHOOL_NAME')}
              </label>
            </div>

            <div className='form-group'>
              <input
                type='text'
                name='schoolAbbr'
                id='schoolAbbr'
                className='form-control'
                ref={register({ required: true, maxLength: 10 })}
                maxLength={10}
              />
              <label htmlFor='schoolAbbr' className='require'>
                {t('SCHOOL_ABBREVIATION')}
              </label>
            </div>

            <div className='form-group do-float'>
              <select
                name='timeZone'
                id='timeZone'
                className='form-control'
                data-provide='selectpicker'
                defaultValue={selectOption}
                onChange={e => setSelectOption(e.target.value)}
              >
                <option disabled value="">{t('SELECT')}</option>
                <option value='Eastern'>Eastern</option>
                <option value='Central'>Central</option>
                <option value='Mountain'>Mountain</option>
                <option value='Pacific'>Pacific</option>
              </select>
              <label htmlFor='timeZone' className='label-floated'>
                {t('TIME_ZONE')}
              </label>
            </div>

          </div>


          <br />
          <button
            disabled={!isFormValid}
            className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label ${
              isFormValid ? '' : 'disabled'
              }`}
            type='submit'
          >
            <span>
              <i className='ti-check'></i>
            </span>
            {t('ADD')}
          </button>
        </form>
        {error &&
          error.response &&
          error.response.data &&
          Object.keys(error.response.data.message).map(key => {
            return (
              <pre key={key} className='mt-10'>
                {error.response.data.message[key]}
              </pre>
            )
          })}
      </div>
    </Modal>
  )
}

export default AddSchoolModal
