import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Modal/Modal';
import React, { useState, useEffect } from 'react';
import { AlertService } from '../../../../services/AlertService';
import styled from '@emotion/styled';

// Mutations
import { useAuthorizedAxios } from '../../../../hooks/use-authorizedaxios';

const EnrollmentModal = (props) => {
  const {
    display,
    setDisplay,
    options,
    student,
    getStudentEligibilityByPeriods,
    studentElegibilityByPeriod
  } = props;
  const { t } = useTranslation();

  const levelOptions = [
    { value: 'Grad', label: 'Grad' },
    { value: 'Undergrad', label: 'Undergrad' },
    { value: 'Law', label: 'Law' }
  ];

  const typeOptions = [
    { value: 'Domestic', label: 'Domestic' },
    { value: 'International', label: 'International' }
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(levelOptions[0]);
  const [selectedType, setSelectedType] = useState(typeOptions[0]);

  const [{ data, error, loading }, createStudentEligibility] =
    useAuthorizedAxios({
      url: `${process.env.REACT_APP_BASE_URL}api/v2/students/${student.id}/eligibility`,
      method: 'POST',
      manual: true
    });

  useEffect(() => {
    setSelectedOption(options[0]);
  }, [options]);

  const handleAddEnrollment = () => {
    const studentEleg =
      studentElegibilityByPeriod?.students_eligibility_by_enrollment_period;

    for (let z = 0; z < studentEleg.length; z++) {
      if (studentEleg[z].enrollment_period_id == selectedOption.value) {
        AlertService.showError(t('ERROR_HAS_ENROLLMENT'));
        return;
      }
    }

    function showSuccess() {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
      getStudentEligibilityByPeriods();
    }

    createStudentEligibility({
      data: {
        enrollment_period_id: selectedOption.value,
        level: selectedLevel.value,
        type: selectedType.value
      }
    }).then(
      (res) => {
        showSuccess();
        setDisplay(false);
      },
      (err) => {
        AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
      }
    );
  };

  return (
    <Modal
      targetId="add-waiver-modal"
      display={display}
      setDisplay={setDisplay}
      overflow={'overflow-visible'}
    >
      <CloseButton
        className={`btn border-0 p-0`}
        onClick={() => setDisplay(false)}
      >
        <i className="fa fa-close pb-1 "></i>
      </CloseButton>
      <div style={{ minHeight: '175px', paddingTop: '20px' }}>
        <p
          className="text-center"
          style={{
            fontSize: '1.25rem',
            borderBottom: '1px solid #D7DADD',
            height: '32px'
          }}
        >
          {t(`ADD_ENROLLMENT`)}
        </p>
        <div style={{ margin: '0px 46.5px' }}>
          <Select
            className="basic-single"
            isSearchable={false}
            classNamePrefix="select"
            menuPlacement="bottom"
            onChange={(e) => {
              setSelectedOption(e);
            }}
            value={selectedOption}
            options={options}
          />
        </div>

        <div style={{ margin: '20px 46.5px' }}>
          <Select
            className="basic-single"
            isSearchable={false}
            classNamePrefix="select"
            menuPlacement="bottom"
            onChange={(e) => {
              setSelectedType(e);
            }}
            value={selectedType}
            options={typeOptions}
          />
        </div>

        <div style={{ margin: '20px 46.5px' }}>
          <Select
            className="basic-single"
            isSearchable={false}
            classNamePrefix="select"
            menuPlacement="bottom"
            onChange={(e) => {
              setSelectedLevel(e);
            }}
            value={selectedLevel}
            options={levelOptions}
          />
        </div>
        <button
          className={`btn btn-bold w-auto ml-auto mr-auto btn-block ${
            loading ? 'btn-secondary' : 'btn-primary'
          } btn-label my-20`}
          type="submit"
          onClick={() => handleAddEnrollment()}
          disabled={loading ? true : false}
        >
          <label>
            <i className="ti-check"></i>
          </label>
          {t('ADD')}
        </button>
      </div>
    </Modal>
  );
};

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  i {
    font-size: 25px;
  }
`;

export default EnrollmentModal;
