import React, { useContext, useCallback, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import CurrencyFormat from "react-currency-format";

import { StudentStateContext } from '../../context/StudentContext'
import CardHeader from '../CardHeader/index';
import Spinner from '../Spinner/index';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import { AlertService } from '../../services/AlertService';
import Preloader from '../Preloader/index';
import { pendingDenialReason } from '../StudentContent/ChangeStatus';

const AddWaiverModal = ({ display, setDisplay, segment, onCreated }) => {
  const { t } = useTranslation();
  const [student,] = useContext(StudentStateContext);
  const [editInsuranceName, setEditInsuranceName] = useState(false);
  const [reasonCount, setReasonCount] = useState(0);
  const [selectedDenialReason, setSelectedDenialReason] = useState('');
  const [formData, setFormData] = useState({
    insuranceName: '',
    memberId: '',
    groupId: '',
    memberSupport: '',
    insuranceId: '',
    email: false,
    approvalException: false,
    annual: false,
    status: '',
    phone: '',
    note: '',
    formData: '',
  });
  const [{ loading: loadingProviders, data: dataProviders }] = useAuthorizedAxios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}api/v1/providers`
  });

  const [{ loading, response, error }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/waivers`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  )

  useEffect(() => {
    if (response && !error) {
      onCreated()
    }
    if (error) {
      AlertService.showErrorV2(error);
    }
  }, [response, error, onCreated])

  const waiverItems = [
    {
      value: 'PE',
      label: 'PENDING'
    },
    {
      value: 'PA',
      label: 'PENDING_AUDIT'
    },
    {
      value: 'PR',
      label: 'PENDING_REVIEW'
    },
    {
      value: 'AP',
      label: 'APPROVED'
    },
    {
      value: 'DE',
      label: 'DENIED'
    },
    {
      value: 'CW',
      label: 'CANCELLED'
    }
  ];

  const handleSelectChanged = e => {
    const option = document.querySelector(`#${e.currentTarget.value}`);
    const { id, name } = option.dataset;
    setReasonCount(rc => (rc + 1))
    setEditInsuranceName(id === '' && name === '' ? true : false)
    setFormData({
      ...formData,
      dirty: true,
      insuranceId: id,
      insuranceName: name,
    });
  };

  const handleFormChange = useCallback((key, value) => {
    setReasonCount(rc => (rc + 1))
    setFormData(f => {
      const newStatus = {
        ...f,
        [key]: value
      }
      if (key === 'status') {
        if (value === 'AP' || value === 'PE') {
          newStatus.email = true
        }
      }
      return (newStatus)
    }
    );
  }, []);

  const renderReasonOptions = useCallback(() => {
    switch (formData.status) {
      case 'AP':
        if (formData.approvalException) {
          return [
            <option value="Appeal" key="Appeal">{t('APPEAL')}</option>,
            <option value="School Request" key="School Request">{t('SCHOOL_REQUEST')}</option>,
          ]
        } else {
          return [
            <option value="SIS Audit" key="SIS Audit">{t('SIS_AUDIT')}</option>,
            <option value="School Audit" key="School Audit">{t('SCHOOL_AUDIT')}</option>,
            <option value='Approval Agreement' key='Approval Agreement'>{t('APPROVAL_AGREEMENT')}</option>
          ]
        }
      case 'PR':
      case 'PA':
        return [<option value="Review" key="Review">{t('REVIEW')}</option>]
      case 'PE':
        return [
          <option value="SIS Audit" key="SIS Audit">{t('SIS_AUDIT')}</option>,
          <option value="School Audit" key="School Audit">{t('SCHOOL_AUDIT')}</option>,
        ]
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  const handleApprovalChange = useCallback(() => {
    setFormData(v => ({ ...v, approvalException: !v.approvalException, email: false }));
    setReasonCount(rc => (rc + 1));
  }, [])

  const handleAnnualChange = useCallback(() => {
    setFormData(v => ({ ...v, annual: !v.annual }));
  }, [])

  const handleEmailChange = useCallback(() => {
    setFormData(v => ({ ...v, email: !v.email }));
  }, [])

  const handleCreateWaiver = useCallback(() => {
    const params = {
      studentId: student.id,
      segmentId: segment.id,
      insuranceId: formData.insuranceId,
      payerName: formData.insuranceName,
      memberId: formData.memberId,
      groupNumber: formData.groupId,
      memberSupportPhone: formData.phone,
      approvalException: formData.approvalException ? 1 : 0,
      approvalExceptionAnnual: formData.annual ? 1 : 0,
      sendEmail: formData.email ? 1 : 0,
      status: formData.status,
      pendingReason: formData.pendingReason,
      note: formData.note
    }
    if (formData.status === 'PE') {
      params.denialReason = selectedDenialReason
    }

    executePost({
      params
    })

  }, [student, segment, formData, selectedDenialReason])

  const isFormDisabled = useMemo(() => formData.status === '' || formData.note === '' || formData.memberId === '' || formData.insuranceName === '' || (formData.status === 'PE' && selectedDenialReason === ''), [formData, selectedDenialReason])

  return (
    <Modal
      width="w-650px"
      targetId='add-waiver-modal'
      display={display}
      setDisplay={setDisplay} >
      <CardHeader
        title={t('ADD_WAIVER')}
        rightComponent={(
          <button
            className="btn border-0 p-0"
            onClick={() => setDisplay(false)}
          >
            <i className="fa fa-close"></i>
          </button>)}
      />
      {loadingProviders
        ? <Spinner />
        : (
          <div className="card-body form-type-material">
            {loading && <Preloader customStyle={{ backgroundColor: 'transparent' }} />}
            <div
              className="form-group mb-2 do-float"
              css={css`
            .dropdown-menu {
              min-width: 100px !important;
            }`}
            >
              <select
                name="insuranceId"
                id="insuranceId"
                value={`${formData.insuranceId || formData.insuranceId === '' ? `insurance${formData.insuranceId}` : 'none'}`}
                onChange={handleSelectChanged}
                data-provide="selectpicker"
                data-live-search="true"
                className="form-control w-100"
              >
                <option value="insurance" disabled>{t("SELECT")}</option>
                {dataProviders &&
                  dataProviders.map(provider => {
                    return (
                      <option
                        key={provider.id}
                        value={`insurance${provider.id}`}
                        id={`insurance${provider.id}`}
                        data-id={provider.id}
                        data-name={provider.name}
                      >
                        {provider.name}
                      </option>
                    );
                  })}
                <option value="none" id="none" data-id="" data-name="">
                  {t("OTHER")}
                </option>
              </select>

              <label className="label-floated require" htmlFor="insuranceId">
                {t("INSURANCE_PROVIDER")}
              </label>
            </div>
            <div className="row">
              <div className='col-6'>
                <div
                  css={css`height: 45px;`}
                  className="form-group mb-2">
                  {editInsuranceName && (
                    <>
                      <input
                        value={formData.insuranceName}
                        onChange={(e) => handleFormChange('insuranceName', e.target.value)}
                        placeholder={t("IF_OTHER_SPECIFY")}
                        id="insuranceName"
                        className="form-control"
                        type="text"
                        name="insuranceName"
                      />
                      <label className="label-floated require" htmlFor="insuranceName">
                        {t("INSURANCE_COMPANY_NAME")}
                      </label>
                    </>
                  )}
                </div>
                <div className="form-group mb-2">
                  <input
                    value={formData.memberId}
                    onChange={(e) => handleFormChange('memberId', e.target.value)}
                    id="memberId"
                    className="form-control"
                    type="text"
                    name="memberId"
                  />
                  <label className="label-floated require" htmlFor="memberId">{t("MEMBER")} #</label>
                </div>

                <div className="form-group mb-2">
                  <input
                    value={formData.groupId}
                    onChange={(e) => handleFormChange('groupId', e.target.value)}
                    id="groupId"
                    className="form-control"
                    type="text"
                    name="groupId"
                  />
                  <label className="label-floated" htmlFor="groupId">{t("GROUP")} #</label>
                </div>
                <div className="form-group mb-2">
                  <CurrencyFormat
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onValueChange={val => handleFormChange('phone', val.value)}
                    className="form-control"
                    format="+1 ###-###-####"
                  />
                  <label className="label-floated" htmlFor="phone">{t("CUSTOMER_SERVICE")} #</label>
                </div>
              </div>
              {/* STATUS DATA */}
              <div className='col-6'>
                <div className="form-group do-float">
                  <select
                    name='status'
                    id='status'
                    data-provide='selectpicker'
                    className='form-control w-75'
                    onChange={(e) => handleFormChange('status', e.target.value)}
                    value={formData.status}
                  >
                    <option disabled value="">{t('SELECT')}</option>
                    {waiverItems.map(item => <option value={item.value} key={item.value}>{t(item.label)}</option>)}
                  </select>
                  <label htmlFor='status' className='label-floated require'>
                    {`${t('STATUS')}`}
                  </label>
                </div>
                <div className="mb-2">
                  <label className="switch">
                    <input
                      name="approvalException"
                      type="checkbox"
                      checked={formData.approvalException}
                      onChange={handleApprovalChange}
                    />
                    <span className="switch-indicator"></span>
                    <span className="switch-description">{t('APPROVAL_EXCEPTION')}</span>
                  </label>
                </div>
                <div className="d-flex mb-2">
                  <label className="switch">
                    <input
                      name="annual"
                      type="checkbox"
                      checked={formData.annual}
                      onChange={handleAnnualChange}
                    />
                    <span className="switch-indicator"></span>
                    <span className="switch-description">{t('ANNUAL')}</span>
                  </label>
                  <label className="switch ml-3">
                    <input
                      name="email"
                      type="checkbox"
                      checked={formData.email}
                      onChange={handleEmailChange}
                    />
                    <span className="switch-indicator"></span>
                    <span className="switch-description">{t('EMAIL')}</span>
                  </label>
                </div>
                {formData.status === 'PE' && (
                  <div className="form-group do-float w-75">
                    <select
                      name='pendding-reason'
                      id='pendding-reason'
                      data-provide='selectpicker'
                      className='form-control'
                      onChange={e => setSelectedDenialReason(e.target.value)}
                      value={selectedDenialReason}
                    >
                      <option disabled value="">{t('SELECT')}</option>
                      {pendingDenialReason.map(reason => <option key={reason.value} value={reason.value}>{t(reason.label)}</option>)}
                    </select>
                    <label htmlFor='pendding-reason' className='label-floated require'>
                      {`${t('REASON_DENIAL')}`}
                    </label>
                  </div>
                )}
                <div className="form-group do-float flex-grow-1" key={`reaseon_${reasonCount}`}>
                  <label htmlFor='timeZone' className='label-floated require'>
                    {t('NOTES')}
                  </label>
                  {formData.status !== 'CW' && formData.status !== 'DE'
                    ? (
                      <select
                        name='reason-select'
                        id='reason-select'
                        data-provide='selectpicker'
                        className='form-control'
                        onChange={e => handleFormChange('note', e.target.value)}
                        value={formData.note}
                      >
                        <option disabled value="">{t('SELECT')}</option>
                        {renderReasonOptions()}
                      </select>
                    )
                    : (
                      <textarea
                        name='note'
                        id='note'
                        className='form-control h-100'
                        value={formData.note}
                        onChange={(e) => handleFormChange('note', e.target.value)}
                      ></textarea>
                    )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                disabled={isFormDisabled}
                className="btn btn-bold d-block btn-primary btn-label"
                onClick={handleCreateWaiver}
              >
                <span>
                  <i className='ti-check'></i>
                </span>
                {t('CREATE')}
              </button>
            </div>
          </div>
        )}
    </Modal>
  )
}

export default AddWaiverModal;
