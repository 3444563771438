import React, { useState } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'

import PreAuth from '../components/PreAuth'

const LockScreen = () => {
  const { t } = useTranslation()
  const [password, setPassword] = useState()
  const me = JSON.parse(window.localStorage.getItem('me'))
  const queryParams = new URLSearchParams(useLocation().search)
  const history = useHistory()

  const [{ error }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/auth/unlock?password=${password}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
      params: {
        password,
      },
    },
    {
      manual: true,
    }
  )

  const handleUnlock = async e => {
    e.preventDefault()

    const password = e.target.password.value
    if (password) {
      const res = await executePost()
      if (res && res.status === 200 && queryParams.get('from')) {
        window.localStorage.setItem('jwt', res.headers['x-sis-token'])
        window.localStorage.setItem('loggedIn', '1')
        history.push(queryParams.get('from'))
      }
    }
  }

  return (
    <PreAuth>
      <div
        className='card card-shadowed w-350px mx-auto'
        style={{ maxWidth: '100%' }}
      >
        <div className='card-header'>
          <h5 className='card-title fw-500'>
            {me.first_name} {me.last_name}
          </h5>
        </div>
        <div className='card-body'>
          <p>{t('UNLOCK_MSG')}</p>

          <form onSubmit={handleUnlock} className='form-type-material'>
            <div className='form-group'>
              <input
                type='password'
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id='password'
                onChange={e => setPassword(e.target.value)}
                value={password || ''}
              />
              <label htmlFor='password'>{t('PASSWORD')}</label>
              {error && (
                <div className='invalid-feedback'>
                  Submitted password is invalid
                </div>
              )}
            </div>

            <br />
            <button
              className={`w-auto ml-auto btn btn-bold btn-label btn-block btn-primary ${
                password ? '' : 'disabled'
                }`}
              type='submit'
            >
              <span>
                <i className='ti-check'></i>
              </span>
              {t('UNLOCK')}
            </button>
          </form>
        </div>
        <p className='text-center fs-13' style={{ marginBottom: '-32px' }}>
          {t('YOU_ARE_NOT')} {me.first_name} {me.last_name}?{' '}
          <Link className='text-primary fw-500' to='/login'>
            {t('SIGN_IN')}
          </Link>
        </p>
      </div>
    </PreAuth>
  )
}

export default LockScreen
