import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios'
import Spinner from '../../Spinner'
import Pagination from '../../Pagination'

const ChangeLogTab = ({ submissionId, active }) => {
  const { t } = useTranslation()
  const perPage = 10;
  const [{data, loading}, getLogs] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/logs`,
    method: 'GET',
    params: {
      perPage
    }
  })
  
  const handlePageChange = async selectedPage => {
    await getLogs({ params: { perPage, page: selectedPage } })
  }

  React.useEffect(() => {
    getLogs({ params: { perPage, page: 1 } })
  }, [active])

  return (
    <div className="card border border-light">
      <div className="card-body">
        {
          loading ? (
            <Spinner />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>{t('USER')}</th>
                  <th>{t('ACTION_TYPE')}</th>
                  <th>{t('DETAILS')}</th>
                  <th>{t('DATE')} / {t('TIME')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.data.map(({ id, user, created_at, log_entry_type, log_entry }) => {
                    return (
                      <tr key={id}>
                        <td>{user.first_name} {user.last_name}</td>
                        <td>{log_entry_type}</td>
                        <td>{log_entry}</td>
                        <td>{moment(created_at).format('MM/DD/YYYY hh:mm A')}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          )}
          {data && (
            <Pagination
              pages={data.last_page}
              onPageChange={handlePageChange}
              {...data}
            />
          )}
      </div>
    </div>
  )
}

export default ChangeLogTab
