import React, { useState, useEffect, useCallback } from 'react'
import Modal from '../../Modal/Modal'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import EnrollmentTab from './EnrollmentTab'
import NotesTab from './NotesTab'
import ChangeLogTab from '../SHIPModal/ChangeLogTab'
import AttachmentsTab from './AttachmentsTab'
import moment from 'moment'
import InsuranceChangesTab from './InsuranceChangesTab';
import Tag from '../../Tag/index';
import NotificationAlert from '../../NotificationAlert/index';

const WaiverModal = ({ display, setDisplay, submission, school, refetch, updateLocal }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('#waiver')
  const [updatedData, setUpdatedData] = useState();
  const student = submission ? submission.student : {};
  const segment = submission ? submission.segment : {};
  const period = submission ? submission.period : {};
  const hasSupplement = submission ? submission.has_supplement : false;
  useEffect(() => {
    if (!display) {
      setActiveTab('#waiver');
    }
  }, [display])

  const renderTags = useCallback(() => {
    const tags = []
    if (submission) {
      if (submission.approval_exception) {
        tags.push(<Tag key="APPROVAL_EXCEPTION" title={t('APPROVAL_EXCEPTION')} color="bg-purple" customStyle="mb-0 ml-1" />)
      }
      if (submission.has_supplement) {
        tags.push(<Tag key="SUPPLEMENT" title={t('SUPPLEMENT')} color="bg-dark-blue" customStyle="mb-0 ml-1" />)
      }
      if (submission.approval_exception_annual) {
        tags.push(<Tag key="ANNUAL" title={t('ANNUAL')} color="bg-info" customStyle="mb-0 ml-1" />)
      }
      if (submission.pending_denial_reason && submission.pending_denial_reason === 'POLICY_NOT_ACTIVE') {
        tags.push(<Tag key="NOT_ACTIVE" title={t('NOT_ACTIVE')} color="bg-warning" customStyle="mb-0 ml-1" />)
      }
      if (submission.pending_denial_reason && submission.pending_denial_reason === 'REQUIREMENTS_NOT_MET') {
        tags.push(<Tag key="NOT_MET" title={t('NOT_MET')} color="bg-warning" customStyle="mb-0 ml-1" />)
      }
      if (submission.pending_denial_reason && submission.pending_denial_reason === 'NO_POLICY_FOUND') {
        tags.push(<Tag key="NOT_FOUND" title={t('NOT_FOUND')} color="bg-warning" customStyle="mb-0 ml-1" />)
      }
      if (submission.pending_denial_reason && submission.pending_denial_reason === 'CAN_NOT_COMPLETE_AUDIT') {
        tags.push(<Tag key="NOT_AUDITABLE" title={t('NOT_AUDITABLE')} color="bg-warning" customStyle="mb-0 ml-1" />)
      }
    }
    return tags

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission])

  return (
    <Modal inputCSS={css`
        .w-960px {
          width: 960px;
        }
      `} width="w-960px" height="h-90" display={display} setDisplay={setDisplay}>
      {display && (
        <>
          <div className='card-header'>
            <div className="row w-100">
              <div className="col-6">
                <h5 className='card-title fw-500'>
                  {student.first_name} {student.last_name} - ID: {student.student_id} - {t('WAIVER_APPLICATION')}
                </h5>
              </div>
              <div className="col-6">
                <div className="d-flex justify-content-end">
                  {renderTags()}
                  <button onClick={() => setDisplay(false)} className='btn border-0 p-0 ml-3'>
                    <i className='fa fa-close'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body flex-grow-0">
            <div>
              <ul className="nav nav-tabs mb-0 border-0">
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="nav-link cursor-pointer active" data-toggle="tab" tole="button" href="#waiver">{t('WAIVER')}</span>
                </li>
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="nav-link cursor-pointer" data-toggle="tab" href="#attachments" role="button">{t('ATTACHMENTS')}</span>
                </li>
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="nav-link cursor-pointer" data-toggle="tab" href="#notes" role="button">{t('NOTES')}</span>
                </li>
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="nav-link cursor-pointer" data-toggle="tab" href="#change-log" role="button">{t('CHANGE_LOG')}</span>
                </li>
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="nav-link cursor-pointer" data-toggle="tab" href="#insurance-changes" role="button">{t('INSURANCE_CHANGES')}</span>
                </li>
                <li className="ml-auto">
                  <h5 className="m-0 text-right">
                    {period.academic_year} {segment.segment_label}
                  </h5>
                  <p className="m-0 text-right">
                    {t('SUBMITTED')}: {submission && moment(submission.created_at).format('MM/DD/YYYY')}
                  </p>
                </li>
              </ul>
              <div className='mt-2'>
                <NotificationAlert data={updatedData} message={t('NOTIFICATION_STUDENT_UPDATE_SUCCESS')} />
              </div>
            </div>
          </div>
          <div css={css`overflow-y: auto; `} >
            <div className="card-body pt-0">
              <div className="tab-content" css={css`
            &.tab-content {
              ${'' /* min-height: 600px; */}
              max-height: 100vh;
            }
          `}>
                <div className="tab-pane fade active show" id="waiver">
                  {activeTab === '#waiver' && (
                    <EnrollmentTab
                      submission={submission}
                      updatedAt={submission && moment(submission.updated_at).format('MM/DD/YYYY')}
                      student={student}
                      refetch={refetch}
                      updateLocal={updateLocal}
                      supplementPlanEnabled={school.supplement_plan_enabled}
                      supplementPlan={hasSupplement}
                      setUpdatedData={setUpdatedData}
                    />
                  )}
                </div>
                <div className="tab-pane fade" id="attachments">
                  {activeTab === '#attachments' && (
                    <AttachmentsTab submission={submission} refetch={refetch} setUpdatedData={setUpdatedData}/>
                  )}
                </div>
                <div className="tab-pane fade" id="notes">
                  {activeTab === '#notes' && (
                    <NotesTab submissionId={submission && submission.id} student={student} active={activeTab === '#notes'}/>
                  )}
                </div>
                <div className="tab-pane fade" id="change-log">
                  {activeTab === '#change-log' && (
                    <ChangeLogTab submissionId={submission && submission.id} active={activeTab === '#change-log'} />
                  )}
                </div>
                <div className="tab-pane fade" id="insurance-changes">
                  {activeTab === '#insurance-changes' && (
                    <InsuranceChangesTab submissionId={submission && submission.id} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )
      }
    </Modal>
  )
}

export default WaiverModal
