import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import CurrencyFormat from 'react-currency-format';
import CardHeader from '../../CardHeader';

const EnrollmentSegmentCard = ({ title, data, setData }) => {
  const { t } = useTranslation();

  const handleInputChange = useCallback((key, value) => {
    setData(d => (
      {
        ...d,
        [key]: value
      }
    ))
  }, [setData])

  return (
    <div className="card">
      <CardHeader title={title} />
      <div className="card-body">
        <div className="row">
          <div className="col-12 form-type-material">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text pl-0">$</span>
              </div>
              <div
                className={`form-group input-group-input mt-10 ${data.price ? 'do-float' : ''}`}
              >
                <input
                  type='number'
                  className='form-control w-25'
                  name='price'
                  onChange={e => handleInputChange('price', e.target.value)}
                  value={data.price}
                />
                <label htmlFor='price' className='require'>{t('PRICE')}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label className='require'>{t('ENROLLMENT_DEADLINE')}</label>
            <div 
              className="input-group flex-grow-1">
              <CurrencyFormat
                value={data.enrollmentDeadline}
                onValueChange={val => handleInputChange('enrollmentDeadline', val.value)}
                className="form-control"
                format="##/##/####"
                placeholder="MM/DD/YYYY"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
              />
              <div className="input-group-append">
                <span className="input-group-text"><i className='fa fa-calendar'></i></span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <label >{t('TUITION_DEADLINE')}</label>
            <div 
              className="input-group form-group flex-grow-1">
              <CurrencyFormat
                value={data.tuitionDeadline}
                onValueChange={val => handleInputChange('tuitionDeadline', val.value)}
                className="form-control"
                format="##/##/####"
                placeholder="MM/DD/YYYY"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
              />
              <div className="input-group-append">
                <span className="input-group-text"><i className='fa fa-calendar'></i></span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 p-0">
          <label className="require">{t('OPEN_CLOSE_DATES')}</label>
          <div css={css`
            .input-group{
              width: unset;
            }
            .second-date {
              margin-left: -1px;
            }
          `} className="form-group">
            <div className="input-group mb-1">
              <div className="input-group-prepend">
                <span className="input-group-text w-60px justify-content-center">{t('FROM')}</span>
              </div>

              <CurrencyFormat
                value={data.openDate}
                onValueChange={val => handleInputChange('openDate', val.value)}
                className="form-control"
                format="##/##/#### ##:##"
                placeholder="MM/DD/YYYY HH:MM"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y', 'H', 'H', 'M', 'M']}
              />
            </div>

            <div className="input-group second-date">
              <div className="input-group-prepend">
                <span className="input-group-text w-60px justify-content-center">{t('TO')}</span>
              </div>

              <CurrencyFormat
                value={data.closeDate}
                onValueChange={val => handleInputChange('closeDate', val.value)}
                className="form-control"
                format="##/##/#### ##:##"
                placeholder="MM/DD/YYYY HH:MM"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y', 'H', 'H', 'M', 'M']}
              />
            </div>
          </div>
        </div>
        <div className="col-12 p-0">
          <label className="require">{t('COVERAGE_START_END')}</label>
          <div css={css`
            .input-group{
              width: unset;
            }
            .second-date {
              margin-left: -1px;
            }
          `} className="form-group">
            <div css={css`width:unset;`} className="input-group mb-1">
              <div className="input-group-prepend">
                <span className="input-group-text w-60px justify-content-center">{t('FROM')}</span>
              </div>
              <CurrencyFormat
                value={data.coverageStartDate}
                onValueChange={val => handleInputChange('coverageStartDate', val.value)}
                className="form-control"
                format="##/##/####"
                placeholder="MM/DD/YYYY"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
              />
            </div>
            <div className="input-group second-date">
              <div className="input-group-prepend">
                <span className="input-group-text w-60px justify-content-center">{t('TO')}</span>
              </div>
              <CurrencyFormat
                value={data.coverageEndDate}
                onValueChange={val => handleInputChange('coverageEndDate', val.value)}
                className="form-control"
                format="##/##/####"
                placeholder="MM/DD/YYYY"
                mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnrollmentSegmentCard;
