import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import useAxios from 'axios-hooks'
import DeleteModal from '../../DeleteModal/DeleteModal'

const AddContactTab = ({ schoolId, selectedContact, onAdd }) => {
  const { t } = useTranslation()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [deleteEndpoint, setDeleteEndpoint] = useState('')
  const { register, handleSubmit, formState, reset, watch, setValue } = useForm(
    {
      mode: 'onChange',
    }
  )
  const values = watch()

  useEffect(() => {
    setValue('firstName', selectedContact ? selectedContact.first_name : '')
    setValue('lastName', selectedContact ? selectedContact.last_name : '')
    setValue(
      'emailAddress',
      selectedContact ? selectedContact.email_address : ''
    )
    setValue('phone', selectedContact ? selectedContact.phone : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact])

  const [{ error, loading }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL
        }api/v1/schools/${schoolId}/contacts${selectedContact ? `/${selectedContact.id}` : ''
        }`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  )

  const handleInputChange = e => {
    setValue(e.target.name, e.target.value)
  }

  const handleContactDelete = () => {
    setOpenDeleteModal(true)
    setDeleteEndpoint(
      `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/contacts/${selectedContact.id}`
    )
  }

  const handleAddContact = async () => {
    await executePost({
      params: {
        ...values,
      },
    })

    if (!error && !loading) {
      reset()
      onAdd()
    }
  }

  return (
    <>
      <DeleteModal
        display={openDeleteModal}
        setDisplay={setOpenDeleteModal}
        toDelete='Contact'
        endpoint={deleteEndpoint}
        onCloseModal={onAdd}
      />
      <div className='card'>
        <div className='card-header'>
          <h5 className="card-title fw-500">
            <strong>
              {t(`${selectedContact ? 'EDIT' : 'ADD'}`)} {t('CONTACT')}
            </strong>
          </h5>
        </div>
        <div className='card-body'>
          <form
            onSubmit={handleSubmit(handleAddContact)}
            className='form-type-material'
          >
            <div className={`form-group ${values.firstName ? 'do-float' : ''}`}>
              <input
                type='text'
                className='form-control'
                name='firstName'
                id='firstName'
                onChange={handleInputChange}
                ref={register({ required: true })}
                value={values.firstName || ''}
              />
              <label htmlFor='firstName'>{t('FIRST_NAME')}</label>
            </div>
            <div className={`form-group ${values.lastName ? 'do-float' : ''}`}>
              <input
                type='text'
                className='form-control'
                name='lastName'
                id='lastName'
                onChange={handleInputChange}
                ref={register({ required: true })}
                value={values.lastName || ''}
              />
              <label htmlFor='lastName'>{t('LAST_NAME')}</label>
            </div>
            <div
              className={`form-group ${values.emailAddress ? 'do-float' : ''}`}
            >
              <input
                type='email'
                className='form-control'
                name='emailAddress'
                id='emailAddress'
                onChange={handleInputChange}
                ref={register({ required: true })}
                value={values.emailAddress || ''}
              />
              <label htmlFor='emailAddress'>{t('EMAIL_ADDRESS')}</label>
            </div>
            <div className={`form-group ${values.phone ? 'do-float' : ''}`}>
              <input
                type='phone'
                className='form-control'
                name='phone'
                id='phone'
                onChange={handleInputChange}
                ref={register({ required: true })}
                value={values.phone || ''}
              />
              <label htmlFor='phone'>{t('PHONE')}</label>
            </div>

            <div className='d-flex'>
              {selectedContact && (
                <i
                  role='button'
                  className='btn border-0 fa fa-trash'
                  onClick={handleContactDelete}
                ></i>
              )}

              <button
                className={`btn btn-bold d-block ml-auto btn-primary btn-label ${(!selectedContact && formState.isValid) ||
                  (selectedContact && formState.dirty)
                  ? ''
                  : 'disabled'
                  }`}
                type='submit'
              >
                <span>
                  <i className='ti-check'></i>
                </span>
                {selectedContact ? t('EDIT') : t('ADD')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddContactTab
