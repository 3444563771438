import React from 'react'
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core'
import asterisk from "../../img/asterisk.svg"

const NoActionSubmissionCard = ({ segment, academicYear }) => {
  const { t } = useTranslation();
  return (
    <div
      css={css`
            height: 338px;
            border: solid 1px rgba(151, 151, 151, 0.25);
          `}
      className="card card-shadowed w-250px mr-4">
      <div
        css={css`
            border-left: 2px solid #f5f6f7
          `}
        className="card-header" role="alert">
        <p className="fs-16 m-0">{t('NO_ACTION')}</p>
      </div>
      <div className="card-body">

        <div className="row mb-3">
          <div className="col-7">
            <p className="m-0 fw-700">{segment}</p>
            {/* <p className="m-0 fw-700">{academicYear}</p> */}
          </div>
        </div>
        <div className="d-flex justify-content-center pt-4">
          <div
            css={css`
            height: 126px;
            width: 126px;
            border-radius: 63px;
            padding-top: 31px;
          `}
            className="text-center bg-purple">
            <img
              src={asterisk}
              alt="asterisk"
            />
          </div>
        </div>
        <p className="text-center pt-2">{t('NO_RESPONSE')}</p>
      </div>
    </div>
  )
}

export default NoActionSubmissionCard
