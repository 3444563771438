import React from "react";

const CardHeader = ({ title, rightComponent = null, bold = '', light = '' }) => {
  let strongWord = '';
  let rest = ''
  if (bold || light) {
    strongWord = bold;
    if (light) {
      rest = light
    }
  } else {
    strongWord = title.split(' ')[0];
    rest = title.split(' ').length > 1 ? title.substr(title.indexOf(" ") + 1) : '';
  }

  return (
    <div className="card-header">
      <div className="row w-100 justify-content-between">
        <h5 className="card-title fw-500">
          <span>
            <strong>{strongWord}</strong> {rest}
          </span>
        </h5>
        {rightComponent}
      </div>
    </div>
  );
};

export default CardHeader;
