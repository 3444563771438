import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { SchoolStateContext } from '../../context/SchoolContext';
import Preloader from '../Preloader/index';

import { useLazyQuery } from '@apollo/client';
import { schools_tpa } from '../../data/schoolSettings/query';

const TAPTab = () => {
  const { t } = useTranslation();
  const [schoolId] = useContext(SchoolStateContext);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedExport, setSelectedExport] = useState();
  const [highlightedDates, setHighlightedDates] = useState([]);

  // Dropdrown Period
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [period, setPeriod] = useState(null);

  const [dataTpaQuery, { loading: loading, data }] = useLazyQuery(schools_tpa);

  useEffect(() => {
    if (schoolId) {
      dataTpaQuery({
        variables: {
          schoolId: schoolId
        }
      });
    }
  }, []);

  const [{ loading: downloadLoading }, download] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/tpa/exports`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );

  useEffect(() => {
    if (data?.enrollment_periods) {
      const { enrollment_periods: realContent } = data;
      const periods = realContent.map((i) => i.academic_year);

      // Set the lastest period
      setPeriod(periods);

      if (realContent[selectedPeriod].schools_tpa_exports_files.length) {
        const result = [];
        realContent[selectedPeriod].schools_tpa_exports_files.forEach(
          (element) => {
            result.push(new Date(element.created_at));
          }
        );

        setSelectedDate(
          new Date(
            realContent[selectedPeriod].schools_tpa_exports_files[0].created_at
          )
        );
        setSelectedExport(
          realContent[selectedPeriod].schools_tpa_exports_files[0]
        );
        setHighlightedDates(result);
      } else {
        setSelectedDate();
        setSelectedExport(null);
        setHighlightedDates([]);
      }
    }
  }, [data, selectedPeriod]);

  const handleSelectDate = useCallback(
    (date) => {
      const { enrollment_periods: realContent } = data;
      const searchDate = moment(date).format('YYYY-MM-DD');
      const selectDate = realContent[
        selectedPeriod
      ].schools_tpa_exports_files.find((element) =>
        element.created_at.includes(searchDate)
      );
      setSelectedDate(date);
      setSelectedExport(selectDate);
    },
    [data, selectedPeriod]
  );

  const ColorCard = useCallback(
    ({ className, amout, name }) => (
      <div
        css={css`
          height: 127px;
          width: 140px;
        `}
        className={`text-center pt-4 ${className}`}
      >
        <p className="text-white fw-700 fs-35">{amout}</p>
        <p className="text-white fw-700 fs-13">{name}</p>
      </div>
    ),
    []
  );

  return (
    <div className="card card-shadowed">
      <div className="col-md-3 card-title">
        {period && (
          <select
            name="period"
            id="period"
            className="form-control"
            data-provide="selectpicker"
            onChange={(e) => setSelectedPeriod(e.target.options.selectedIndex)}
            value={period[selectedPeriod] ? period[selectedPeriod] : period[0]}
          >
            {period.map((e, key) => {
              return (
                <option key={key} value={e}>
                  {e}
                </option>
              );
            })}
          </select>
        )}
      </div>
      <div className="card-body">
        <div
          css={css`
            border: solid 1px rgba(151, 151, 151, 0.25);
          `}
          className="card-body"
        >
          {(downloadLoading || loading) && (
            <Preloader customStyle={{ backgroundColor: 'transparent' }} />
          )}
          <div className="d-flex justify-content-between form-type-material">
            <div className="col p-0">
              <label
                css={css`
                  color: #626a79;
                  font-family: Roboto;
                  font-size: 1rem;
                `}
              >
                {selectedExport
                  ? moment(selectedExport.created_at).format(
                      'MM/DD/YYYY hh:mm A'
                    )
                  : ''}
              </label>
            </div>
            <div
              className="col-3 text-right"
              css={css`
                .react-datepicker-popper {
                  z-index: 995;
                }
              `}
            >
              <DatePicker
                selected={selectedDate}
                onChange={handleSelectDate}
                includeDates={highlightedDates}
                className="form-control"
                placeholderText="Pick a Date"
              />
            </div>
          </div>

          {selectedExport && (
            <div
              css={css`
                .bg-dark-purple {
                  background-color: #926bde;
                }
              `}
              className="d-flex my-3"
            >
              <ColorCard
                className="mr-1 bg-success"
                name={t('ENROLLMENTS')}
                amout={selectedExport.process_report.SHIP}
              />
              <ColorCard
                className="mr-1 bg-dark-purple"
                name={t('CANCELLED')}
                amout={selectedExport.process_report.CE}
              />
              {selectedExport.process_report.DENTAL && (
                <ColorCard
                  className="mr-1 bg-info"
                  name={t('DENTAL')}
                  amout={selectedExport.process_report.DENTAL}
                />
              )}
              <ColorCard
                className="mr-1 bg-warning"
                name={t('SPECIAL_ENROLLMENT')}
                amout={selectedExport.process_report.SP}
              />
            </div>
          )}

          <div className="d-flex justify-content-end">
            <button
              onClick={() =>
                download({
                  url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/tpa/exports/${selectedExport.id}`
                }).then((data) => window.open(`${data.data}`))
              }
              className="btn border-0 px-2 bg-transparent mr-3"
            >
              <i className="fa fa-eye"></i>
            </button>
            <button
              onClick={() =>
                download({
                  url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/tpa/exports/${selectedExport.id}`
                }).then((data) => window.open(`${data.data}&download=1`))
              }
              className="btn border-0 px-2 bg-transparent"
            >
              <i className="fa fa-download"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TAPTab;
