/* eslint-disable no-console */
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { SearchStateContext } from '../../context/SearchContext';
// Queries
import {
  studentsEligibilityLockedSySegment,
  enrollment_periods_segments
} from '../../data/reports/query';

// Services
// eslint-disable-next-line import/named
import { UserService } from '../../services/UserService';

const DEFAULT_PAGINATION = {
  limit: 20,
  offset: 0
};

const EnrollmentPeriodFilter = ({
  selectedSchoolId,
  selectEnrollmentId,
  setSelectEnrollmentId,
  filters,
}) => {

  const { setWithSearch } = useContext(SearchStateContext);
  const [count, setCount] = useState(0);
  const [pagination, setPagination] = useState({
    limit: 20,
    offset: 0
  });

  const [enrollmentOptions, setEnrollmentOptions] = useState([]);
  const [currentDataStudents, setCurrentDataStudents] = useState();
  const refCsvLink = React.useRef(null);

  const { t } = useTranslation();


  // Queries
  const [
    studentsQuery,
    { loading: loadingStudents, data: dataStudents, refetch: refetchStudents }
  ] = useLazyQuery(studentsEligibilityLockedSySegment, {
    fetchPolicy: 'network-only'
  });

  const [
    periodsQuery,
    { loading: loadingPeriods, data: dataPeriods }
  ] = useLazyQuery(enrollment_periods_segments);

  useEffect(() => {
    setWithSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectEnrollmentId &&
      !loadingStudents &&
      dataStudents &&
      dataStudents.students_eligibility_by_enrollment_period &&
      dataStudents.students_eligibility_by_enrollment_period.length > 0
    ) {
      const rows = [];
      dataStudents.students_eligibility_by_enrollment_period.forEach(
        (studentByPeriod) => {
          const is_eligible = `segment_${Number(selectEnrollmentId.split('&')[1]) + 1
            }`;
          rows.push({
            id: studentByPeriod.student.id,
            first_name: studentByPeriod.student.first_name,
            last_name: studentByPeriod.student.last_name,
            student_id: studentByPeriod.student.student_id,
            is_eligible: studentByPeriod[is_eligible]
          });
        }
      );
      setCurrentDataStudents(rows);
    } else {
      setCurrentDataStudents();
    }
  }, [dataStudents, loadingStudents, selectEnrollmentId]);



  useEffect(() => {
    if (dataPeriods) {
      const enrollmentsOptions = [];
      enrollmentsOptions.push({ value: null, label: null });
      dataPeriods.enrollment_periods_segments.forEach((period) =>
      filters && filters.year ?
          period.enrollment_period.academic_year.includes(filters.year) ?
            enrollmentsOptions.push({
              value: `${period.id}`,
              label: `${period.enrollment_period.academic_year} - ${period.segment_label}`,
              year: `${period.enrollment_period.academic_year}`
            }) : ""
          :
          enrollmentsOptions.push({
            value: `${period.id}`,
            label: `${period.enrollment_period.academic_year} - ${period.segment_label}`,
            year: `${period.enrollment_period.academic_year}`
          })
      );
      setEnrollmentOptions(enrollmentsOptions);
      setCount((c) => c + 1);
    }
  }, [dataPeriods, filters]);

  useEffect(() => {
    if (UserService.isAdmin()) {
      if (selectedSchoolId) {
        periodsQuery({
          variables: {
            schoolId: selectedSchoolId,
            startDate: moment().format('YYYY-MM-DD')
          }
        });
        setCurrentDataStudents(undefined);
      }
    } else if (UserService.getUser().school_id) {
      periodsQuery({
        variables: {
          schoolId: UserService.getUser().school_id,
          startDate: moment().format('YYYY-MM-DD')
        }
      });
      setCurrentDataStudents(undefined);
    }
  }, [selectedSchoolId]);

  const handleChangePeriod = useCallback((periodId) => {
    setSelectEnrollmentId(periodId);
    const splitedPeriod = periodId.split('&');
    const and = [];

    and.push({
      enrollment_period_id: { _eq: splitedPeriod[0] }
    });
    const segment = `segment_${Number(splitedPeriod[1]) + 1}_locked`;
    and.push({
      [segment]: { _eq: true }
    });

    const where = {
      _and: and
    };

    const variables = {
      where,
      offset: DEFAULT_PAGINATION.offset,
      limit: DEFAULT_PAGINATION.limit
    };
    studentsQuery({ variables });
    setPagination({
      limit: DEFAULT_PAGINATION.limit,
      offset: DEFAULT_PAGINATION.offset
    });
  }, []);
  return (
    <div
      className="form-group do-float"
      key={count}
      css={css`
              .form-type-material .bootstrap-select .dropdown-toggle:focus {
                background-color: transparent !important;
              }
            `}
    >
      {selectedSchoolId && (
        <>
          <select
            type="text"
            name="schoolId"
            id="schoolId"
            className="form-control bg-transparent"
            data-provide="selectpicker"
            onChange={(e) => setSelectEnrollmentId(e.target.value)}
            value={selectEnrollmentId}
          >

            <>
              {enrollmentOptions.map((o) => (

                <option
                  key={o.value}
                  value={o.value}
                  className={o.value ? '' : 'd-none'}
                >
                  {o.label}
                </option>
              ))}
            </>

          </select>
          <label label={t('ENROLLMENT_PERIOD')} htmlFor="schoolId" className="label-floated">
            {t('ENROLLMENT_PERIOD')}
          </label>
        </>
      )}
    </div>
  );
};

export default EnrollmentPeriodFilter;
