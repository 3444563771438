import React from 'react';
import logo from '../../img/logo-dark.png'

export const Header = () => {
  return (
    <div
      style={{
        backgroundColor: '#e5e5e5',
        padding: '32px 50px 70px 50px',
        textAlign: 'center',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.49)',
        position: 'relative',
      }}
    >
      <img
        src={logo}
        alt="E-Waive Logo"
        width="230"
        height="65"
        className="mb-10"
      />
      <p
        style={{
          fontWeight: 'bold',
          lineHeight: 1.71,
          letterSpacing: '0.18px',
          color: '#5b5b5b',
        }}
      >
        Electronic Waiver Application Insurance Verification &amp; Eligibility
        System
      </p>
    </div>
  );
};
