import React, { createContext, useState } from 'react'

const DashboardStateContext = createContext(['', undefined])
const LocalStateProvider = DashboardStateContext.Provider

function DashboardStateProvider(props) {
  const [dashboard, setDashboard] = useState('SUMMARY')

  return (
    <LocalStateProvider value={[dashboard, setDashboard]}>
      {props.children}
    </LocalStateProvider>
  )
}

export { DashboardStateProvider, DashboardStateContext }
