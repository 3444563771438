import React, { createContext, useState } from 'react'

const StudentStateContext = createContext(['', undefined])
const LocalStateProvider = StudentStateContext.Provider

function StudentStateProvider(props) {
  const [student, setStudent] = useState(props.value[0])

  return (
    <LocalStateProvider value={[student, setStudent]}>
      {props.children}
    </LocalStateProvider>
  )
}

export { StudentStateProvider, StudentStateContext }
