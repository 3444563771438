import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios'
import moment from 'moment'
import Pagination from '../../Pagination'
import Spinner from '../../Spinner'
const NotesTab = ({ submissionId, active }) => {
  const perPage = 4;
  const { t } = useTranslation()
  const [{ data, loading }, getNotes] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/notes`,
    method: 'GET',
    params: {
      perPage
    }
  })

  const handlePageChange = async selectedPage => {
    await getNotes({ params: { perPage, page: selectedPage } })
  }

  React.useEffect(() => {
    getNotes({ params: { perPage, page: 1 } })
  }, [active])

  return (
    <div className="card border border-light">
      <div className="card-body">
        {loading ? (
          <Spinner />
        ) : (
          <table className="table" css={css`
              tr {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                td {
                  padding-top: 0;
                }
                .full-row {
                  padding-top: 0;
                  grid-column: 1/ -1;
                }
              }
            `}>
            <thead>
              <tr>
                <th>
                  {t('USER')}
                </th>
                <th>
                  {t('DATE')} / {t('TIME')}
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.data.map(({ note, user, id, created_at }) => {
                  return (
                    <tr key={id}>
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>
                        {moment(created_at).format('MM/DD/YYYY hh:mm A')}
                      </td>
                      <td className="full-row pl-5 border-top-0">
                        <p>
                          {note}
                        </p>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        )}
        {data && (
          <Pagination
            pages={data.last_page}
            onPageChange={handlePageChange}
            {...data}
          />
        )}
      </div>
    </div>
  )
}

export default NotesTab
