import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import EmailTemplateContent from '../components/EmailTemplateContent'
import { useTranslation } from 'react-i18next'

const EmailTemplate = () => {
  const { t } = useTranslation()
  return (
    <PageLayout withHeader={true} title={t('EMAILS')} crumbs={[{title: 'Templates', to: '/email-templates'}]}>
      <EmailTemplateContent />
    </PageLayout>
  )
}

export default EmailTemplate
