import React, { useState, useEffect, useContext } from 'react'
import useAxios from 'axios-hooks'
import { useTranslation } from 'react-i18next'
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { css } from "@emotion/core";

import { StudentStateContext } from '../../../context/StudentContext';
import Modal from '../../Modal/Modal';
import CardHeader from '../../CardHeader/index';
import TrackingTable from './TrackingTable';
import Spinner from '../../Spinner/index';

const EmailDetailModal = ({ display, setDisplay, openEmail, studentId }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('#message');
  const [student] = useContext(StudentStateContext);

  const [{ loading, data }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${studentId}/emails/${openEmail.id}`,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("jwt")}`
    }
  });

  useEffect(() => {
    if (!display) {
      setActiveTab('#message');
    }
  }, [display])
  
  return (
    <Modal targetId='email-modal' display={display} setDisplay={setDisplay} width='w-1000px' height="h-90">
      <CardHeader
        bold={openEmail.email_type}
        rightComponent={(
          <button
            className="btn border-0 p-0"
            onClick={() => setDisplay(false)}
          >
            <i className="fa fa-close"></i>
          </button>)}
      />
      <div
        css={css`
            min-height: 500px;
            overflow-y: auto;
          `}
        className="card-body">
        {loading ? (
          <Spinner />
        ) : (
            <>
              <div className="row">
                <div className="px-3 w-100px">
                  <p>
                    {t("SUBJECT")}:
                  </p>
                  <p>
                    {t("TO")}:
                  </p>
                </div>
                <div className="col-6 pl-0">
                  <p>
                    {openEmail.subject}
                  </p>
                  <p>
                    {`${student.first_name} ${student.last_name}  <${openEmail.to_email_address}>`}
                  </p>
                </div>
                <div className="col-4">
                  <p className="text-right">
                    {t("DATE")}:{" "}
                    {moment.utc(openEmail.created_at).local().format("MM/DD/YYYY")}
                  </p>
                  <p className="text-right">
                    {t("TIME")}: {moment.utc(openEmail.created_at).local().format("hh:mm A")}
                  </p>
                </div>
              </div>

              <ul className="nav nav-tabs mb-0 border-0">
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="nav-link cursor-pointer active" data-toggle="tab" tole="button" href="#message">{t('EMAIL_MESSAGE')}</span>
                </li>
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="nav-link cursor-pointer" data-toggle="tab" href="#tracking" role="button">{t('TRACKING')}</span>
                </li>
              </ul>

              <div className="tab-content">
                <div className="tab-pane fade active show" id="message">
                  {activeTab === '#message' && (
                    <div css={css`
                        background-color: #f5f6fa;
                        border: solid 1px #d7dadd;
                      `}>
                      <div className="row mx-auto bg-light border border-secondary p-30 justify-content-center">
                        {ReactHtmlParser(atob(data.email_body))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="tab-pane fade active show" id="tracking">
                  {activeTab === '#tracking' && (
                    <TrackingTable studentId={studentId} emailId={openEmail.id} />
                  )}
                </div>
              </div>
            </>
          )}
      </div>
    </Modal>
  )
}

export default EmailDetailModal
