import React from 'react';
import desktopIcon from '../../img/desktop.svg';
import tabletIcon from '../../img/tablet.svg';
import mobileIcon from '../../img/mobile.svg';
import desktopActiveIcon from '../../img/desktop-active.svg';
import tabletActiveIcon from '../../img/tablet-active.svg';
import mobileActiveIcon from '../../img/mobile-active.svg';

export const ViewportChanger = ({ viewport, setViewport }) => {
  return (
    <>
      <button
        onClick={() => setViewport('desktop')}
        className="btn border-0 mr-10 p-0"
      >
        <img
          src={viewport === 'desktop' ? desktopActiveIcon : desktopIcon}
          alt="desktopIcon"
        />
      </button>
      <button
        onClick={() => setViewport('tablet')}
        className="btn border-0 mr-10 p-0"
      >
        <img
          src={viewport === 'tablet' ? tabletActiveIcon : tabletIcon}
          alt="tabletIcon"
        />
      </button>
      <button
        onClick={() => setViewport('mobile')}
        className="btn border-0 mr-10 p-0"
      >
        <img
          src={viewport === 'mobile' ? mobileActiveIcon : mobileIcon}
          alt="mobileIcon"
        />
      </button>
    </>
  );
};
