/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef
} from 'react';
import moment from 'moment';
import { css } from '@emotion/core';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import { SearchStateContext } from '../../context/SearchContext';

// Components
import CardHeader from '../CardHeader/index';
import Spinner from '../Spinner/index';
import Pagination from '../Pagination/index';
// eslint-disable-next-line import/named
import { UserService } from '../../services/UserService';
import Preloader from '../Preloader/index';
import SortArrows from '../SortArrows/index';
import Tag from '../Tag/index';
import SuggestInput from './SuggestInput';

const DEFAULT_PAGINATION = {
  limit: 10,
  offset: 0
};

const SummaryDetailModal = ({
  display,
  setDisplay,
  id,
  code,
  active,
  rollover,
  option
}) => {
  const { setWithSearch } = useContext(SearchStateContext);
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState({
    column: 'first_name',
    asc: true
  });
  const [sortByGql, setSortByGql] = useState({
    student: { first_name: 'asc' }
  });

  const displayInsurance = React.useMemo(() => {
    if (
      code === 'PE' ||
      code === 'PA' ||
      code === 'PR' ||
      code === 'AP' ||
      code === 'CW' ||
      code === 'SP'
    ) {
      return true;
    }
    return false;
  }, [code]);

  const [filters, setFilters] = useState({
    status:
      code === 'TE'
        ? ['DF', 'OI']
        : code === 'TW'
        ? ['PA', 'PE', 'PR', 'AP']
        : [code],
    active,
    rollover,
    enrollment_period_segment_id: id,
    first_name: '',
    last_name: '',
    student_id: '',
    provider_name: ''
    //provider_name: displayInsurance ? '' : null
  });

  const [dirty, setDirty] = useState(false);
  const [searchWithFilter, setSearchWithFilter] = useState(false);
  const [gqlData, setGqlData] = useState();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [isDownloading, setIsDownloading] = useState(false);

  const variablesByCode = (csv) => {
    let endpointByCode = null;
    let csvByCode = null;

    switch (code) {
      case 'AP':
        endpointByCode = 'approvals';
        csvByCode = 'APPROVED';
        break;
      case 'SP':
        endpointByCode = 'supplements';
        csvByCode = 'SUPPLEMENT';
        break;
      case 'DE':
        endpointByCode = 'denied-waivers';
        csvByCode = 'DENIED';
        break;
      case 'CW':
        endpointByCode = 'cancelled-waivers';
        csvByCode = 'CANCELLED-WAIVER';
        break;
      case 'DF':
        endpointByCode = 'default-ship-enrollments';
        csvByCode = 'DEFAULT-SHIP';
        break;
      case 'OI':
        endpointByCode = 'opt-in-ship';
        csvByCode = 'OPT-IN';
        break;
      case 'TW':
        endpointByCode = 'total-waiver';
        csvByCode = 'TOTAL-WAIVER';
        break;
      case 'PR':
        endpointByCode = 'pending-review';
        csvByCode = 'PENDING-REVIEW';
        break;
      case 'PA':
        endpointByCode = 'pending-audit';
        csvByCode = 'PENDING-AUDIT';
        break;
      case 'PE':
        endpointByCode = 'pending-denied';
        csvByCode = 'PENDING-DENIED';
        break;
      case 'NR':
        endpointByCode = 'no-action';
        csvByCode = 'NO-ACTION';
        break;
      case 'TE':
        endpointByCode = 'total-ship';
        csvByCode = 'TOTAL-SHIP';
        break;
      case 'CE':
        endpointByCode = 'cancelled-ship';
        csvByCode = 'CANCELLED-SHIP';
        break;
      default:
        break;
    }

    return csv ? csvByCode : endpointByCode;
  };

  const getRestEndpoint = () => {
    const urlString = (endpoint) => {
      return `${process.env.REACT_APP_BASE_URL}api/v1/dashboard/students/${
        filters.enrollment_period_segment_id
      }/${endpoint}?page=${
        pagination.offset == 0 ? 1 : pagination.offset
      }&page_size=${pagination.limit}&first_name=${
        filters.first_name
      }&last_name=${filters.last_name}&student_id=${
        filters.student_id
      }&provider_name=${filters.provider_name}&sort_by=${
        sortBy.column
      }&sort_dir=${sortBy.asc ? 'asc' : 'desc'}&student_status=${
        option == 'ROLLOVER' ? 'ROLLOVER_ACTIVE' : option
      }`;
    };

    const endpointByCode = variablesByCode(false);

    return {
      url: urlString(endpointByCode),
      method: 'get',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    };
  };

  // Queries *************************
  const [
    { data: summaryModalData, loading: summaryModalLoading },
    summaryGetData
  ] = useAxios(getRestEndpoint(), {
    manual: true
  });

  const [{ data: cvsData, loading: cvsLoading }, getCVS] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/dashboard/students/${
        filters.enrollment_period_segment_id
      }/exports?code=${variablesByCode(true)}&student_status=${
        option == 'ROLLOVER' ? 'ROLLOVER_ACTIVE' : option
      }`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const getGqlDefault = useCallback(() => {
    const and = [];
    const andAggregate = [];
    const andStudentsSubmissions = [];

    if (filters) {
      const {
        active,
        rollover,
        status,
        enrollment_period_segment_id
      } = filters;

      if (code === 'SP') {
        and.push({
          students_submissions_addons: {
            type: { _eq: 'SUPPLEMENT_PLAN' },
            is_active: { _eq: true }
          },
          status: { _in: ['AP'] }
        });
        andAggregate.push({
          students_submissions_addons: {
            enrollment_period_segment_id: {
              _eq: enrollment_period_segment_id
            },
            type: { _eq: 'SUPPLEMENT_PLAN' },
            is_active: { _eq: true }
          }
        });
        andStudentsSubmissions.push({
          status: { _in: ['AP'] }
        });
      } else {
        and.push({ status: { _in: status } });
        andStudentsSubmissions.push({
          status: { _in: status }
        });
      }

      and.push({
        enrollment_period_segment_id: { _eq: enrollment_period_segment_id },
        student_is_active: { _eq: !!active }
      });
      andStudentsSubmissions.push({
        enrollment_period_segment_id: { _eq: enrollment_period_segment_id },
        student_is_active: { _eq: !!active }
      });

      if (rollover !== null) {
        and.push({ is_rollover: { _eq: rollover } });
        andStudentsSubmissions.push({ is_rollover: { _eq: rollover } });
      }
    }

    andAggregate.push({
      students_submissions: {
        _and: andStudentsSubmissions
      }
    });

    setPagination({
      limit: 10,
      offset: 0
    });
  }, [code, filters, sortByGql]);

  const handleDownload = useCallback(() => {
    setIsDownloading(true);
    getCVS(variablesByCode()).then((response) => {
      setIsDownloading(false);

      if (response) {
        const url = window.URL.createObjectURL(
          new Blob([response.data.contents])
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.data.file_name);
        document.body.appendChild(link);
        link.click();
      }
    });
  }, [active, code, pagination, filters]);

  const canSee = () => {
    const isAdmin = UserService.isAdmin();
    if (isAdmin) {
      return true;
    }
    return UserService.hasPermission('students');
  };

  const handleSort = useCallback(
    (column) => {
      if (code === 'NR' || 'AP') {
        const sort = {
          column,
          asc: sortBy.column === column ? !sortBy.asc : true
        };
        setSortBy(sort);
      } else {
        if (
          column !== 'created_at' &&
          column !== 'updated_at' &&
          column !== 'provider_name'
        ) {
          const auxsortGql2 = {
            student: {
              [column]:
                sortByGql.student &&
                sortByGql.student[column] &&
                sortByGql.student[column] === 'asc'
                  ? 'desc'
                  : 'asc'
            }
          };
          setSortByGql(auxsortGql2);
        } else {
          const auxsortGql = {
            [column]:
              sortByGql[column] && sortByGql[column] === 'asc' ? 'desc' : 'asc'
          };
          setSortByGql(auxsortGql);
        }
        setPagination((p) => ({
          ...p,
          offset: 0
        }));
      }
    },
    [sortBy, sortByGql]
  );

  const onPageChangeGqlData = useCallback(
    (newPage) => {
      const and = [];
      const andAggregate = [];
      const andStudentsSubmissions = [];

      if (filters) {
        const {
          last_name,
          first_name,
          student_id,
          active,
          rollover,
          status,
          provider_name,
          enrollment_period_segment_id
        } = filters;

        if (searchWithFilter) {
          and.push({
            student: {
              first_name: { _ilike: `%${first_name}%` },
              last_name: { _ilike: `%${last_name}%` },
              student_id: { _ilike: `%${student_id}%` }
            }
          });

          andAggregate.push({
            first_name: { _ilike: `%${first_name}%` },
            last_name: { _ilike: `%${last_name}%` },
            student_id: { _ilike: `%${student_id}%` }
          });
        }

        if (code === 'SP') {
          and.push({
            students_submissions_addons: {
              type: { _eq: 'SUPPLEMENT_PLAN' },
              is_active: { _eq: true }
            },
            status: { _in: ['AP'] }
          });
          andAggregate.push({
            students_submissions_addons: {
              enrollment_period_segment_id: {
                _eq: enrollment_period_segment_id
              },
              type: { _eq: 'SUPPLEMENT_PLAN' },
              is_active: { _eq: true }
            }
          });
          andStudentsSubmissions.push({
            status: { _in: ['AP'] }
          });
        } else {
          and.push({ status: { _in: status } });
          andStudentsSubmissions.push({
            status: { _in: status }
          });
        }

        and.push({
          enrollment_period_segment_id: { _eq: enrollment_period_segment_id },
          student_is_active: { _eq: !!active }
        });
        andStudentsSubmissions.push({
          enrollment_period_segment_id: { _eq: enrollment_period_segment_id },
          student_is_active: { _eq: !!active }
        });

        if (provider_name !== null && searchWithFilter) {
          and.push({ provider_name: { _ilike: `%${provider_name}%` } });
          andStudentsSubmissions.push({
            provider_name: { _ilike: `%${provider_name}%` }
          });
        }

        if (rollover !== null) {
          and.push({ is_rollover: { _eq: rollover } });
          andStudentsSubmissions.push({ is_rollover: { _eq: rollover } });
        }
      }

      andAggregate.push({
        students_submissions: {
          _and: andStudentsSubmissions
        }
      });

      const where = {
        _and: and
      };
      const whereAggregate = {
        _and: andAggregate
      };

      const variables = {
        where,
        whereAggregate,
        offset: pagination.limit * (newPage - 1),
        limit: pagination.limit,
        order_by: sortByGql
      };

      setPagination((p) => ({
        ...p,
        offset: newPage
      }));
    },
    [pagination, searchWithFilter, filters, sortByGql]
  );

  const handleSearch = useCallback(() => {
    setSearchWithFilter(true);
    const and = [];
    const andAggregate = [];
    const andStudentsSubmissions = [];

    if (filters) {
      const {
        last_name,
        first_name,
        student_id,
        active,
        rollover,
        status,
        provider_name,
        enrollment_period_segment_id
      } = filters;

      and.push({
        student: {
          first_name: { _ilike: `%${first_name}%` },
          last_name: { _ilike: `%${last_name}%` },
          student_id: { _ilike: `%${student_id}%` }
        }
      });

      andAggregate.push({
        first_name: { _ilike: `%${first_name}%` },
        last_name: { _ilike: `%${last_name}%` },
        student_id: { _ilike: `%${student_id}%` }
      });

      if (code === 'SP') {
        and.push({
          students_submissions_addons: {
            type: { _eq: 'SUPPLEMENT_PLAN' },
            is_active: { _eq: true }
          },
          status: { _in: ['AP'] }
        });
        andAggregate.push({
          students_submissions_addons: {
            enrollment_period_segment_id: {
              _eq: enrollment_period_segment_id
            },
            type: { _eq: 'SUPPLEMENT_PLAN' },
            is_active: { _eq: true }
          }
        });
        andStudentsSubmissions.push({
          status: { _in: ['AP'] }
        });
      } else {
        and.push({ status: { _in: status } });
        andStudentsSubmissions.push({
          status: { _in: status }
        });
      }

      and.push({
        enrollment_period_segment_id: { _eq: enrollment_period_segment_id },
        student_is_active: { _eq: !!active }
      });
      andStudentsSubmissions.push({
        enrollment_period_segment_id: { _eq: enrollment_period_segment_id },
        student_is_active: { _eq: !!active }
      });

      if (provider_name !== null) {
        and.push({ provider_name: { _ilike: `%${provider_name}%` } });
        andStudentsSubmissions.push({
          provider_name: { _ilike: `%${provider_name}%` }
        });
      }

      if (rollover !== null) {
        and.push({ is_rollover: { _eq: rollover } });
        andStudentsSubmissions.push({ is_rollover: { _eq: rollover } });
      }
    }

    andAggregate.push({
      students_submissions: {
        _and: andStudentsSubmissions
      }
    });

    if (code) {
      summaryGetData();
    }

    setPagination((p) => ({
      ...p,
      offset: 0
    }));
  }, [searchWithFilter, filters, dirty, sortByGql]);

  //Sort Change
  useEffect(() => {
    //Avoids double call
    if (pagination != DEFAULT_PAGINATION) {
      summaryGetData();
    }
  }, [sortBy, pagination]);

  const handleReset = useCallback(() => {
    setDirty(false);
    setSearchWithFilter(false);
    setFilters((f) => ({
      ...f,
      first_name: '',
      last_name: '',
      student_id: '',
      provider_name: displayInsurance ? '' : null
    }));

    setPagination((p) => ({
      ...p,
      offset: 0
    }));

    setSortByGql({ student: { first_name: 'asc' } });
  }, [searchWithFilter, filters, dirty, sortByGql]);

  const updateFilter = useCallback(
    (key, value) => {
      setFilters((f) => ({
        ...f,
        [key]: value || ''
      }));
      if (!dirty) {
        setDirty(true);
      }
    },
    [filters]
  );

  useEffect(() => {
    setWithSearch(false);
    getGqlDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (summaryModalData) {
      setGqlData(summaryModalData);
    }
  }, [summaryModalData]);

  useEffect(() => {
    const callSetData = (dataType) => {
      if (dataType?.data) {
        setGqlData({
          students_submissions: dataType.data,
          students_aggregate: {
            aggregate: {
              totalCount: dataType.total
            }
          }
        });
      }
    };

    callSetData(summaryModalData);
  }, [summaryModalData]);

  useEffect(() => {
    if (code !== 'NR') {
      if (searchWithFilter) {
        handleSearch();
      } else {
        getGqlDefault();
      }
    }
  }, [sortByGql]);

  //Refactor when all graph is migrated
  const getPagValues = (direction) => {
    if (direction == 'from') {
      if (gqlData.students_aggregate.aggregate.totalCount > 0) {
        if (pagination.offset == 1 || pagination.offset == 0) {
          return 0;
        } else {
          return (pagination.offset - 1) * pagination.limit;
        }
      } else {
        return null;
      }
    } else if (direction == 'to') {
      if (gqlData.students_aggregate.aggregate.totalCount === 0) {
        return null;
      } else {
        if (
          gqlData.students_aggregate.aggregate.totalCount <
          pagination.offset * pagination.limit
        ) {
          return gqlData.students_aggregate.aggregate.totalCount;
        } else {
          return pagination.offset == 0
            ? 1 * pagination.limit
            : pagination.offset * pagination.limit;
        }
      }
    }
  };

  return (
    <Modal
      targetId="add-school-modal"
      width="w-1200px"
      height="h-75"
      display={display}
      setDisplay={setDisplay}
    >
      <CardHeader
        title={t(`SUMMARY_CODE_${code}`)}
        rightComponent={
          <button
            type="button"
            onClick={() => setDisplay()}
            className="btn border-0 p-0 fs-18"
          >
            <i className="fa fa-close" />
          </button>
        }
      />
      <div
        css={css`
          overflow-y: auto;
        `}
        className="card-body bg-white"
      >
        {summaryModalLoading ? (
          <Spinner />
        ) : (
          <>
            {cvsLoading && (
              <Preloader customStyle={{ backgroundColor: 'transparent' }} />
            )}
            <table className="table table-striped table-lg">
              <thead>
                {code !== 'NR' && (
                  <tr className="form-type-material">
                    <th className="pt-0 pb-1 border-0">
                      <SuggestInput
                        placeholder={t('FIRST_NAME')}
                        field="first_name"
                        value={filters.first_name}
                        filters={filters}
                        updateFilter={updateFilter}
                      />
                    </th>
                    <th className="pt-0 pb-1 border-0">
                      <SuggestInput
                        placeholder={t('LAST_NAME')}
                        field="last_name"
                        value={filters.last_name}
                        filters={filters}
                        updateFilter={updateFilter}
                      />
                    </th>
                    <th className="pt-0 pb-1 border-0">
                      <SuggestInput
                        placeholder={t('STUDENT_ID')}
                        field="student_id"
                        value={filters.student_id}
                        filters={filters}
                        updateFilter={updateFilter}
                      />
                    </th>

                    {displayInsurance && (
                      <th className="pt-0 pb-1 border-0">
                        <SuggestInput
                          placeholder={t('INSURANCE_COMPANY')}
                          field="provider_name"
                          value={filters.provider_name}
                          filters={filters}
                          updateFilter={updateFilter}
                        />
                      </th>
                    )}
                    <th className="pt-0 pb-1 border-0" />
                    <th className="pt-0 pb-1 border-0" />
                    <th className="pt-0 pb-1 border-0" />
                    <th
                      className={`pt-0 pb-1 border-0 ${
                        displayInsurance ? 'pl-0' : ''
                      }`}
                    >
                      <div
                        className="input-group justify-content-end"
                        css={css`
                          min-width: 155px;
                        `}
                      >
                        <span
                          css={css`
                            .bg-disabled {
                              background-color: #d7dadd;
                            }
                          `}
                        >
                          <button
                            className={`btn btn-light mr-1 p-1 ${
                              dirty ? 'bg-warning text-white' : 'bg-disabled'
                            }`}
                            css={css`
                              text-transform: uppercase;
                            `}
                            type="button"
                            onClick={handleReset}
                            disabled={!dirty}
                          >
                            {t('RESET')}
                          </button>
                        </span>
                        <span>
                          <button
                            className="btn btn-primary p-1"
                            css={css`
                              text-transform: uppercase;
                            `}
                            type="button"
                            onClick={handleSearch}
                          >
                            Go!
                          </button>
                        </span>
                      </div>
                    </th>
                  </tr>
                )}
                <tr>
                  <th
                    onClick={() => handleSort('first_name')}
                    className="cursor-pointer"
                  >
                    <div className="d-flex">
                      {t('FIRST_NAME')}
                      <SortArrows
                        customStyle="ml-2"
                        column="first_name"
                        sortedBy={sortBy.column}
                        asc={sortBy.asc}
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort('last_name')}
                    className="cursor-pointer"
                  >
                    <div className="d-flex">
                      {t('LAST_NAME')}
                      <SortArrows
                        customStyle="ml-2"
                        column="last_name"
                        sortedBy={sortBy.column}
                        asc={sortBy.asc}
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort('student_id')}
                    className="cursor-pointer"
                  >
                    <div className="d-flex">
                      {t('STUDENT_ID')}
                      <SortArrows
                        customStyle="ml-2"
                        column="student_id"
                        sortedBy={sortBy.column}
                        asc={sortBy.asc}
                      />
                    </div>
                  </th>
                  {displayInsurance && (
                    <th
                      onClick={() => handleSort('provider_name')}
                      className="cursor-pointer"
                    >
                      <div className="d-flex">
                        {t('INSURANCE_COMPANY')}
                        <SortArrows
                          customStyle="ml-2"
                          column="provider_name"
                          sortedBy={sortBy.column}
                          asc={sortBy.asc}
                        />
                      </div>
                    </th>
                  )}
                  <th
                    onClick={() => handleSort('created_at')}
                    className="cursor-pointer"
                  >
                    <div className="d-flex">
                      {t('CREATED')}
                      <SortArrows
                        customStyle="ml-2"
                        column="created_at"
                        sortedBy={sortBy.column}
                        asc={sortBy.asc}
                      />
                    </div>
                  </th>
                  <th
                    onClick={() => handleSort('updated_at')}
                    className="cursor-pointer"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <div className="d-flex">
                      {t('LAST_UPDATED')}
                      <SortArrows
                        customStyle="ml-2"
                        column="updated_at"
                        sortedBy={sortBy.column}
                        asc={sortBy.asc}
                      />
                    </div>
                  </th>
                  <th />
                  <th>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        disabled={isDownloading || cvsLoading}
                        onClick={handleDownload}
                        className="btn border-0 p-0 fs-18"
                      >
                        <i className="fa fa-download" />
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {code === 'NR' &&
                  gqlData &&
                  gqlData.students_submissions &&
                  gqlData.students_submissions.map((item) => (
                    <tr key={item.student_id}>
                      <td className="py-2">{item.first_name}</td>
                      <td className="py-2">{item.last_name}</td>
                      <td className="py-2">{item.student_id}</td>
                      {displayInsurance && (
                        <td className="py-2">{item.insurance_company}</td>
                      )}
                      <td className="py-2">
                        {moment(item.created_at).format('MM/DD/YYYY')}
                      </td>
                      <td className="py-2">
                        {moment(item.updated_at).format('MM/DD/YYYY')}
                      </td>
                      <td className="py-2" style={{ verticalAlign: 'middle' }}>
                        {item.has_supplement && (
                          <Tag
                            title="SUP"
                            color="bg-dark-blue"
                            customStyle="mb-0 mr-2"
                          />
                        )}
                        {item.files_summary &&
                          item.files_summary.length > 0 && (
                            <i className="fa fa-file-o fs-20" />
                          )}
                      </td>
                      <td className="py-2 text-right">
                        <a
                          href={canSee() ? `/students/${item.id}` : null}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`btn btn-bold btn-primary btn-label ${
                            canSee() ? '' : 'disabled text-white'
                          }`}
                        >
                          <span>
                            <i className="ti-check" />
                          </span>
                          {t('VIEW')}
                        </a>
                      </td>
                    </tr>
                  ))}
                {code !== 'NR' &&
                  gqlData &&
                  gqlData.students_submissions &&
                  gqlData.students_submissions.map((item) => (
                    <tr key={item.student.student_id}>
                      <td className="py-2">{item.student.first_name}</td>
                      <td className="py-2">{item.student.last_name}</td>
                      <td className="py-2">{item.student.student_id}</td>
                      {displayInsurance && (
                        <td className="py-2">{item.provider_name}</td>
                      )}
                      <td className="py-2">
                        {moment(item.created_at).format('MM/DD/YYYY')}
                      </td>
                      <td className="py-2">
                        {moment(item.updated_at).format('MM/DD/YYYY')}
                      </td>
                      <td className="py-2" style={{ verticalAlign: 'middle' }}>
                        {item.students_submissions_addons &&
                          item.students_submissions_addons.length > 0 &&
                          item.students_submissions_addons.find(
                            (addon) =>
                              addon.is_active === true &&
                              addon.type === 'SUPPLEMENT_PLAN'
                          ) && (
                            <Tag
                              title="SUP"
                              color="bg-dark-blue"
                              customStyle="mb-0 mr-2"
                            />
                          )}
                        {item.files_summary &&
                          item.files_summary.length > 0 && (
                            <i className="fa fa-file-o fs-20" />
                          )}
                      </td>
                      <td className="py-2 text-right">
                        <a
                          href={
                            canSee()
                              ? `/students/${
                                  item.student.student_uuid || item.student_id
                                }`
                              : null
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`btn btn-bold btn-primary btn-label ${
                            canSee() ? '' : 'disabled text-white'
                          }`}
                        >
                          <span>
                            <i className="ti-check" />
                          </span>
                          {t('VIEW')}
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
        {gqlData && gqlData.students_aggregate && (
          <div className="mt-5">
            <Pagination
              pages={Math.ceil(
                gqlData.students_aggregate.aggregate.totalCount /
                  pagination.limit
              )}
              onPageChange={onPageChangeGqlData}
              from={getPagValues('from')}
              to={getPagValues('to')}
              total={gqlData.students_aggregate.aggregate.totalCount}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SummaryDetailModal;
