import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Modal from '../../Modal/Modal';
import CardHeader from '../../CardHeader/index';
import { SchoolStateContext } from '../../../context/SchoolContext';
import useAxios from 'axios-hooks';
import Preloader from '../../Preloader/index';
import { AlertService } from '../../../services/AlertService';

const AddShipFileModal = ({ display, setDisplay, segment, refetch }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [schoolId] = useContext(SchoolStateContext);

  const [{ data, loading, error }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/segments/${segment.id}/files`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    },
    { manual: true }
  );

  const handleAddFile = () => {
    if (!file) return;

    let formData = new FormData();

    if (file) {
      formData.append('file', file);
    }
    if (fileName) {
      formData.append('name', fileName);
    }

    executePost({
      data: formData
    });
  };

  useEffect(() => {
    if (data && !error) {
      refetch();
      AlertService.showSuccess(t('SAVE_SUCCESS'));
      setDisplay();
    }

    if (error) {
      if (Array.isArray(error.response.data.message)) {
        AlertService.showError(error.response.data.message[0]);
      } else if (
        !Array.isArray(error.response.data.message) &&
        error.response.data.message
      ) {
        AlertService.showError(error.response.data.message);
      } else {
        AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return (
    <Modal
      inputCSS={css`
        .w-500px {
          width: 500px;
        }
      `}
      width="w-350px"
      targetId="add-file-modal"
      display={display}
      setDisplay={setDisplay}
    >
      {loading && (
        <Preloader customStyle={{ backgroundColor: 'transparent' }} />
      )}
      <CardHeader
        title={t('ADD_FILE')}
        rightComponent={
          <button onClick={() => setDisplay()} className="btn border-0 p-0">
            <i className="fa fa-close"></i>
          </button>
        }
      />
      <div className="card-body">
        <form
          css={css`
            .dropify-wrapper {
              height: 100px;
            }
          `}
          className="form-type-material"
        >
          <input
            type="file"
            name="file"
            className="dropify"
            data-provide="dropify"
            accept="image/png,image/gif,image/jpeg,application/pdf"
            onInput={(e) => setFile(e.target.files[0])}
          />
          <div className="form-group mt-3">
            <input
              type="text"
              name="name"
              id="name"
              className="form-control"
              onChange={(e) => setFileName(e.target.value)}
              value={fileName}
            />
            <label htmlFor="name" className="require">
              {t('LINK_DISPLAY_NAME')}
            </label>
          </div>
        </form>
        <div className="d-flex mt-2">
          <button
            disabled={!file || fileName === ''}
            onClick={handleAddFile}
            className="btn btn-bold d-block ml-auto btn-primary btn-label"
          >
            <span>
              <i className="ti-check"></i>
            </span>
            {t('ADD')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddShipFileModal;
