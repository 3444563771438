import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import CardHeader from '../../CardHeader/index';
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';

// Services
import { UserService } from '../../../services/UserService';
const SupplementalCard = React.memo(({ submissionId, supplementPlan, refetch, updateLocal, setUpdatedData,RolePermissionValue }) => {
  const { t } = useTranslation()
  const [enable, setEnable] = useState(false);
  const [{ data }, postSupplement] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/supplement`,
    method: 'POST',
    manual: true
  })

  const [{ data: deleteData }, deleteSupplement] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/supplement`,
    method: 'DELETE',
    manual: true
  })

  const handleSubmit = async e => {
    e.preventDefault();
    if (enable) {
      postSupplement({
        params: {
          isEnabled: 1,
        }
      })
    } else {
      deleteSupplement()
    }
  }

  useEffect(() => {
    setEnable(supplementPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data || deleteData) {
      if (data) {
        setUpdatedData(data)
      }
      if (deleteData) {
        setUpdatedData(deleteData)
      }
      if (updateLocal) {
        updateLocal({ has_supplement: data.is_active })
      } else if (refetch) {
        refetch()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, deleteData]);

  const handleEnrolled = useCallback(() => {
    setEnable(v => (!v))
  }, [])
  return (
    <div className="card border border-light mb-0 h-100">
      <CardHeader title={t('SUPPLEMENT_PLAN')} />
      <div className="card-body">
        <div className="d-flex mt-4">
          <label className="switch">
            <input
              disabled={!RolePermissionValue}
              name="email"
              type="checkbox"
              checked={enable}
              onChange={handleEnrolled}
            />
            <span className="switch-indicator"></span>
            <span className="switch-description">{t('ENROLLED')}</span>
          </label>
          <button
            onClick={handleSubmit}
            // disabled={!RolePermissionValue}
            className={`btn btn-bold d-block ml-auto btn-primary btn-label ${!RolePermissionValue ? 'disabled text-white' : ''}`}
          >
            <span>
              <i className='ti-check'></i>
            </span>
            {t('UPDATE')}
          </button>
        </div>
      </div>
    </div>

  )
});

export default SupplementalCard;


