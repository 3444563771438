import React, { useContext, useState, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { useTranslation } from 'react-i18next'
import { SchoolStateContext } from '../../context/SchoolContext'
import { AlertService } from '../../services/AlertService';
import Spinner from '../Spinner/index';

const StatusCodesTab = () => {
  const { t } = useTranslation()
  const [schoolId] = useContext(SchoolStateContext)
  const [formDirty, setFormDirty] = useState(false)
  const [{data, loading },getRes] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/codes`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  },
  {
    manual: true
  })
  const [{ error, response }, executePost] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/codes`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  )

  useEffect(() => {
    getRes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response && !error) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, response]);
  

  const handleFormSubmit = e => {
    e.preventDefault()
    if (!formDirty) return

    let configuration = {
      id: schoolId,
      settings_status_codes: {},
    }
    if (data) {
      configuration.sync_export_enabled =
        data && data.sync_export_enabled
      for (let key of Object.keys(data.settings_status_codes)) {
        configuration.settings_status_codes[key] = {}
        for (let codeKey of Object.keys(
          data.settings_status_codes[key]
        )) {
          const codeObj = {
            enabled: e.target[`enabled${codeKey}${key}`].checked,
            overrideCode: e.target[`override${codeKey}${key}`].value,
          }
          configuration.settings_status_codes[key][codeKey] = codeObj
        }
      }
    }

    executePost({
      params: {
        configuration: configuration.settings_status_codes,
      },
    })
  }

  if(loading) {
    return <Spinner />
  }
  
  if (data && data.settings_status_codes) {
    return (
      <form onSubmit={handleFormSubmit} className='mt-20'>
        {
          Object.keys(data.settings_status_codes).map(key => {
            return (
              <div key={key} className='mt-30'>
                <h4 className='card-title fw-500'>
                  <strong>{t(key.toUpperCase())}</strong>
                </h4>
                <div className='card card-shadowed'>
                  <table className='table table-hover'>
                    <tbody>
                      {Object.keys(data.settings_status_codes[key]).map(
                        codeKey => {
                          return (
                            <tr key={codeKey}>
                              <td className='pb-0'>
                                <label className='switch mt-10'>
                                  <input
                                    onChange={() => setFormDirty(true)}
                                    type='checkbox'
                                    defaultChecked={
                                    data.settings_status_codes[key][
                                        codeKey
                                      ].enabled
                                    }
                                    name={`enabled${codeKey}${key}`}
                                    id={`enabled${codeKey}${key}`}
                                  />
                                  <span className='switch-indicator'></span>
                                  <span className='switch-description'></span>
                                </label>
                              </td>
                              <td className='pb-0' width='75%'>
                                <div className='media'>
                                  <div className='media-body'>
                                    <h6>
                                      {t(
                                        `${key.toUpperCase()}_STATUS_CODES_${codeKey}_NAME`
                                      )}
                                    </h6>
                                    <small>
                                      {t(
                                        `${key.toUpperCase()}_STATUS_CODES_${codeKey}_DESCRIPTION`
                                      )}
                                    </small>
                                  </div>
                                </div>
                              </td>
                              <td className='pb-0 px-0 text-uppercase'>
                                <h1>{codeKey}</h1>
                              </td>
                              <td width='100px' className='px-0 pb-0'>
                                <div className='media'>
                                  <div className='media-body'>
                                    <small className='mt-15 text-right'>
                                      {t('OVERRIDE_VALUE')}
                                    </small>
                                    <div className='form-type-material'>
                                      <div className='form-group mb-10'>
                                        <input
                                          onChange={() => setFormDirty(true)}
                                          className='form-control bg-transparent'
                                          type='text'
                                          name={`override${codeKey}${key}`}
                                          id={`override${codeKey}${key}`}
                                          placeholder={t('INPUT_VALUE')}
                                          defaultValue={
                                            data.settings_status_codes[
                                              key
                                            ][codeKey].overrideCode
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )
          })}
        {error && error.response && error.response.data && (
          <pre>{error.response.data.message}</pre>
        )}
        <button
          className={`my-30 ml-auto btn btn-bold d-block ml-auto btn-primary btn-label ${
            formDirty ? '' : 'disabled'
            }`}
          type='submit'
        >
          <span>
            <i className='ti-check'></i>
          </span>
            {t('UPDATE_STATUS_CODES')}
          </button>
      </form>
    )
  }

  return null;

}

export default StatusCodesTab
