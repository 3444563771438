import React from 'react';
import { css } from '@emotion/core';

const Tag = ({
  title,
  color,
  small,
  customStyle = '',
  onClick = null,
  className = ''
}) => {
  return (
    <div
      css={css`
        .bg-dark-blue {
          background-color: #2650b2;
        }
      `}
      className={`${className}`}
      onClick={onClick}
    >
      <label
        css={css`
          border-radius: 1px;
          white-space: nowrap;
        `}
        className={`${color} text-white fs-12 fw-700 py-1 px-1 text-center ${
          small ? 'w-70px' : ''
        } ${onClick ? 'cursor-pointer' : ''} ${customStyle}`}
      >
        {title}
      </label>
    </div>
  );
};

export default Tag;
