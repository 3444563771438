/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { css } from '@emotion/core';

import StatusCard from '../StatusCard/index';
import Tag from '../Tag';
import ValidatorStatusTag from '../ValidatorStatusTag';

const SubmissionCard = ({ submission, onClick, elegibilityStatus }) => {
  const { t } = useTranslation();
  const titleColor = elegibilityStatus ? 'success' : 'danger';

  return (
    <div
      css={css`
        border: solid 1px rgba(151, 151, 151, 0.25);
        min-height: 338px;
      `}
      className="card card-shadowed w-250px cursor-pointer"
      onClick={() => onClick(submission)}
    >
      <div className={`callout callout-${titleColor} card-header`} role="alert">
        <div className="d-flex justify-content-between w-100">
          <span className="fs-16">
            {submission.submission_type === 'Waiver Application'
              ? 'Waiver'
              : 'SHIP'}
          </span>
          {(submission.status === 'PA' || submission.status === 'PR') && (
            <ValidatorStatusTag
              validatorCurrentStatus={submission.validator_current_status}
            />
          )}
          {submission.status === 'PE' &&
            submission.pending_denial_reason &&
            submission.pending_denial_reason === 'POLICY_NOT_ACTIVE' && (
              <Tag
                title={t('NOT_ACTIVE')}
                color="bg-warning"
                customStyle="mb-0"
              />
            )}
          {submission.status === 'PE' &&
            submission.pending_denial_reason &&
            submission.pending_denial_reason === 'REQUIREMENTS_NOT_MET' && (
              <Tag title={t('NOT_MET')} color="bg-warning" customStyle="mb-0" />
            )}
          {submission.status === 'PE' &&
            submission.pending_denial_reason &&
            submission.pending_denial_reason === 'OUT_OF_STATE_MEDICAID' && (
              <Tag title={t('OOSM')} color="bg-warning" customStyle="mb-0" />
            )}
          {submission.status === 'PE' &&
            submission.pending_denial_reason &&
            submission.pending_denial_reason === 'NO_POLICY_FOUND' && (
              <Tag
                title={t('NOT_FOUND')}
                color="bg-warning"
                customStyle="mb-0"
              />
            )}
          {submission.status === 'PE' &&
            submission.pending_denial_reason &&
            submission.pending_denial_reason === 'CAN_NOT_COMPLETE_AUDIT' && (
              <Tag
                title={t('NOT_AUDITABLE')}
                color="bg-warning"
                customStyle="mb-0"
              />
            )}
        </div>
      </div>
      <div
        className="card-body"
        style={{ backgroundColor: `${submission.is_active ? '' : '#EDEBEB'}` }}
      >
        <div className="row mb-3">
          <div className="col-7">
            <p className="m-0 fw-700">{submission.segment.segment_label}</p>
            <p className="m-0 fw-700">{submission.period.academic_year}</p>
          </div>
          <div className="col-5">
            <StatusCard
              status={submission.status}
              title={submission.status}
              titleStyle="fs-36 fw-900"
            />
          </div>
        </div>
        {submission.submission_type === 'Waiver Application' ? (
          <>
            <p className="m-0 fw-700">
              {submission.waiver_form_data.insuranceCompanyName}
            </p>
            <div className="mb-2">
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('CONTACT')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {submission.waiver_form_data.insurancePhoneNumber}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('GROUP_ID')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {submission.waiver_form_data.insuranceGroupNumber}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('MEMBER_ID')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {submission.waiver_form_data.insuranceMemberNumber}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('ACTIVE')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {submission.is_active ? t('YES') : t('NO')}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '3px',
                width: '100%',
                alignItems: 'center'
              }}
            >
              {submission.approval_exception && (
                <Tag title="AP EXC" color="bg-purple" small customStyle="m-0" />
              )}

              {submission.approval_exception_annual && (
                <Tag title="ANNUAL" color="bg-info" small customStyle="m-0" />
              )}
              {
                /* [
                { is_active: true, type: 'SUPPLEMENT_PLAN' },
                { is_active: true, type: 'DENTAL' }
              ]  */
                submission.addons.map(({ is_active, type }) => {
                  if (!is_active) return null;

                  switch (type) {
                    case 'SUPPLEMENT_PLAN':
                      return (
                        <Tag
                          title="SUP"
                          color="bg-dark-blue"
                          small
                          customStyle="m-0"
                        />
                      );
                    case 'DENTAL':
                      return (
                        <Tag
                          title="DENTAL"
                          color="bg-success"
                          small
                          customStyle="m-0"
                        />
                      );

                    default:
                      return null;
                  }
                })
              }
            </div>
          </>
        ) : (
          <>
            <div className="mb-2">
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('START_DATE')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {moment(submission.coverage_start_date).format(
                      'MM/DD/YYYY'
                    )}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('END_DATE')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {moment(submission.coverage_end_date).format('MM/DD/YYYY')}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('SUBMITTED')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {moment(submission.created_at).format('MM/DD/YYYY')}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-5 pr-1">
                  <p className="m-0">{t('ACTIVE')}:</p>
                </div>
                <div className="col-7 p-0">
                  <p className="m-0">
                    {submission.is_active ? t('YES') : t('NO')}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex">
              {submission.special_enrollment && (
                <Tag
                  title="SPECIAL"
                  color="bg-primary"
                  small
                  customStyle="mb-0"
                />
              )}
              {submission.manual_billing && (
                <Tag title="MA BILL" color="bg-pink" small customStyle="mb-0" />
              )}
            </div>
          </>
        )}
        <div />
      </div>
    </div>
  );
};

export default SubmissionCard;
