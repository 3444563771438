import { gql } from '@apollo/client';

// Mutations
export const update_schools_data_import = gql`
mutation MyMutation($id: uuid!, $value: Boolean!) {
  update_schools_data_exchange_by_pk(pk_columns: {id: $id}, _set: {is_import_active: $value}) {
    is_import_active
  }
}
`;

export const update_schools_data_export = gql`
mutation MyMutation($id: uuid!, $value: Boolean!) {
  update_schools_data_exchange_by_pk(pk_columns: {id: $id}, _set: {is_export_active: $value}) {
    is_export_active
  }
}
`;