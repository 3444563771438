import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import moment from 'moment'

// Services
import { AlertService } from '../../services/AlertService';

const CoverageDates = React.memo(({ submissionId, startDate, endDate, refetch, setUpdatedData,RolePermissionValue }) => {
  const [note, setNote] = useState('')
  const { t } = useTranslation()
  const { register, getValues, setValue } = useForm({
    mode: 'onChange',
  })
  const [{ loading, data }, postCoverageDates] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/ship/dates`,
    method: 'POST',
    manual: true
  })
  

  const handleSubmit = async e => {
    e.preventDefault();
    const values = getValues();
    if (values.startDate === moment(startDate).format('MM/DD/YYYY') && values.endDate === moment(endDate).format('MM/DD/YYYY')) {
      return AlertService.showError('The dates have not changed');
    }
    if (moment(new Date(values.startDate)).isBefore(moment(new Date(values.endDate)))) {
      const payload = {
        coverageStartDate: moment(values.startDate).format('YYYY-MM-DD'),
        coverageEndDate: moment(values.endDate).format('YYYY-MM-DD'),
        note,
      }
      await postCoverageDates({ params: payload })
      // Refetach submissions
      refetch();
    } else {
      return AlertService.showError('End Date must be bigger than Start Date');
    }
  }

  useEffect(() => {
    setValue('startDate', moment(startDate).format('MM/DD/YYYY'));
    setValue('endDate', moment(endDate).format('MM/DD/YYYY'));
  }, [setValue, startDate, endDate]);

  useEffect(() => {
    if (data) {
      setUpdatedData(data);
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="card border border-light mb-0">
      <div className="card-header">
        <h5 className="card-title fw-500">
          <span>
            <strong>{t("COVERAGE")}</strong> {t("DATES")}
          </span>
        </h5>
      </div>
      <div className="card-body">
        <form
          onSubmit={handleSubmit}
        >
          <div className='row'>
            <div className='col-md-6'>
              <strong>{t('START_DATE')}</strong>
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  data-provide={RolePermissionValue ? 'datepicker' : null}
                  name='startDate'
                  id='startDate'
                  placeholder='MM/DD/YYYY'
                  ref={register({ required: true })}
                  defaultValue={moment(startDate).format('MM/DD/YYYY')}
                  readOnly={!RolePermissionValue}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2"><i className="ti-calendar"></i></span>
                </div>
              </div>
              <strong>{t('END_DATE')}</strong>
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control"
                  data-provide={RolePermissionValue ? 'datepicker' : null}
                  name='endDate'
                  id='endDate'
                  placeholder='MM/DD/YYYY'
                  ref={register({ required: true })}
                  defaultValue={moment(endDate).format('MM/DD/YYYY')}
                  readOnly={!RolePermissionValue}
                />
                <div className="input-group-append">
                  <span className="input-group-text" id="basic-addon2"><i className="ti-calendar"></i></span>
                </div>
              </div>
            </div>
            <div className='col-md-6 form-group form-type-material'>
              <textarea
                name='note'
                id='note'
                className='form-control h-100'
                value={note}
                onChange={e => setNote(e.target.value)}
                readOnly={!RolePermissionValue}
              ></textarea>
              <label htmlFor='note' className='label-floated'>
                {`${t('NOTES')}`}
              </label>
            </div>
          </div>
          <div className="d-flex mt-2">
            <button
              disabled={loading || !RolePermissionValue}
              className={`btn btn-bold d-block ml-auto btn-primary btn-label ${loading || !RolePermissionValue ? 'disabled text-white' : ''}`}
            >
              <span>
                <i className='ti-check'></i>
              </span>
              {t('UPDATE')}
            </button>
          </div>
        </form>
      </div>
    </div>

  )
});

export default CoverageDates


