export const conditionalOptions = [
  {
    value: null,
    label: 'No condition'
  },
  {
    value: 'CONDITION_IS_FIRST_PART_OF_YEAR',
    label: 'Is first part of the year'
  },
  {
    value: 'CONDITION_HAS_SUPPLEMENT_PLAN_ENABLED',
    label: 'Has supplement plan'
  },
  {
    value: 'CONDITION_DEADLINE_EXPIRE',
    label: 'Deadline has expired'
  },
  {
    value: 'CONDITION_DEADLINE_NOT_EXPIRE',
    label: 'Deadline has not expired'
  },
  {
    value: 'CONDITION_HAS_SUPPLEMENT_PLAN_ENABLED_WITH_ENROLLMENTS_ENABLED',
    label: 'Supplement plan with enrollments'
  },
  {
    value: 'CONDITION_HAS_SUPPLEMENT_PLAN_ENABLED_WITH_ENROLLMENTS_DISABLED',
    label: 'Supplement plan without enrollments'
  }
];
