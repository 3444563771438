import React, { createContext, useState } from 'react'
import { useDebounce } from 'use-debounce'

const defaultSearch = {
  withSearch: true,
  setWithSearch: undefined,
  debouncedSearchKeyword: '',
  searchKeyword: '',
  setSearchKeyword: undefined,
}

const SearchStateContext = createContext(defaultSearch)
const LocalStateProvider = SearchStateContext.Provider

function SearchStateProvider(props) {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [withSearch, setWithSearch] = useState(true)
  const [debouncedSearchKeyword] = useDebounce(
    searchKeyword,
    process.env.REACT_APP_DEBOUNCE_WAIT
  )

  return (
    <LocalStateProvider
      value={{
        withSearch,
        setWithSearch,
        debouncedSearchKeyword,
        searchKeyword,
        setSearchKeyword,
      }}
    >
      {props.children}
    </LocalStateProvider>
  )
}

export { SearchStateProvider, SearchStateContext }
