import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
import { StyledMain } from '../../styles/common/main';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmailEditorContainer } from '../EmailEditorContainer';
import useAxios from 'axios-hooks';
import { AlertService } from '../../services/AlertService';
import { useHistory } from 'react-router-dom';

export const EmailTemplateContent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const loading = false;
  const { email, template, id } = useParams();
  const [parsedKey, setParsedKey] = useState('');
  const [previewtemplateData, setPreviewTemplateData] = useState('');

  useEffect(() => {
    switch (email) {
      case 'Ship Enrollment Confirmation':
        setParsedKey('SHIP_CONFIRMATION');
        break;
      case 'Waiver Application Confirmation':
        setParsedKey('WAIVER_CONFIRMATION');
        break;
      case 'Waiver Approval Confirmation':
        setParsedKey('WAIVER_APPROVAL_CONFIRMATION');
        break;
      case 'Waiver Pending Requirements Not Met':
        setParsedKey('WAIVER_PENDING_DENIAL');
        break;
      case 'Waiver Final Denial':
        setParsedKey('WAIVER_FINAL_DENIAL');
        break;
      case 'Supplement Enrollment':
        setParsedKey('SUPPLEMENT_CONFIRMATION');
        break;
      default:
        setParsedKey('WELCOME_EMAIL');
        break;
    }
  }, [email]);

  //(Code rep)This is needed because this component is on a separate url
  const [{ response: editResponse, error: errorEdit }, executeEdit] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/${id}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  //(Code rep)This is needed because this component is on a separate url
  const [
    { loading: templatesContentLoading, data: templatesContentData },
    getTemplateContent
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/${id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (editResponse && !errorEdit) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (errorEdit) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editResponse, errorEdit]);

  useEffect(() => {
    if (id) {
      getTemplateContent();
    }
  }, [id]);

  useEffect(() => {
    if (templatesContentData) {
      const parsedItems = JSON.parse(templatesContentData.items);
      setPreviewTemplateData(parsedItems);
    }
  }, [templatesContentData]);

  return (
    <StyledMain className="container">
      <div className="card card-shadowed" style={{marginTop:"-92.5px"}}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="card-header">
              <h5 className="card-title fw-500">
                <span>
                  <strong>{email}</strong> {template} - {t('EDIT')}
                </span>
              </h5>
            </div>
            <EmailEditorContainer
              title={email}
              templateName={template}
              willEdit={true}
              executeEdit={executeEdit}
              selectedCreateType={parsedKey}
              loading={templatesContentLoading}
              content={previewtemplateData}
              handleCancel={() => history.goBack()}
            />
          </>
        )}
      </div>
    </StyledMain>
  );
};

export default EmailTemplateContent;
