import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';

// Components
import PreAuth from '../components/PreAuth/index';
import Footer from '../components/Footer/index';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div>
      <PreAuth>
        <div
          className="card card-shadowed w-350px mx-auto"
          style={{ maxWidth: '100%' }}
        >
          <div className="card-header">
            <div
              css={css`
                font-size: 16px;
              `}
            >
              <strong>URL</strong> <span>{t('INCORRECT')}</span>
            </div>
          </div>
          <div className="card-body">
            <p style={{ fontSize: '12pt' }}>
              The page you are trying to open could not be found. This is a
              client-side incident which means the page no longer exists or that
              you have misspelled the URL.
            </p>
          </div>
        </div>
      </PreAuth>
      <Footer />
    </div>
  );
};

export default NotFound;
