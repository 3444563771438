import React, { useState, useEffect } from 'react'
import Modal from '../../Modal/Modal'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';
import Spinner from '../../Spinner/index';
import useAxios from 'axios-hooks'
import { AlertService } from '../../../services/AlertService';

const PermissionModal = ({ display, setDisplay, userId, user, reFetch }) => {
  const { t } = useTranslation();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const perPage = 10;
  const [{ data, loading }, getGroups] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles`,
    method: 'GET',
    params: {
      perPage
    },
    manual: true,
  })

  const [{ data: currentUserRoles }, getCurrentUserRoles] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles/user/${userId}`,
    method: 'GET',
    params: {
      perPage
    },
    manual: true,
  })


  const [{ data: updatedData, error: updateError }, updateSelectedGroups] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles/assign`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    getGroups()
    getCurrentUserRoles()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUserRoles) {
      setSelectedGroups(currentUserRoles.map(role => role.id))
    } 
  }, [currentUserRoles]);

  useEffect(() => {
    if (updatedData && !updateError) {
      reFetch();
      setDisplay(false);
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (updateError && updateError.response && updateError.response.data && updateError.response.data.message) {
      Object.keys( updateError.response.data.message).map(key => AlertService.showError(updateError.response.data.message[key]))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedData, updateError])

  const markAsSelected = group => {
    if(selectedGroups.includes(group)){
      setSelectedGroups(g => (g.filter(gr => gr.id !== group.id)))
    } else {
      setSelectedGroups(g => ([...g, group]));
    }
  };

  const handleUpdate = e => {
    e.preventDefault();
    updateSelectedGroups({
      params: {
        "user_id": userId,
        "admin_roles": selectedGroups
      }
    })
  }

  return (
    <Modal inputCSS={css`
        .w-650px {
          width: 650px;
        }
        .h-80 {
          height: 80%;
        }
      `} width="w-650px" display={display} setDisplay={setDisplay} height="h-80">
      <div className='card-header'>
        <h5 className='card-title fw-500'>
          <strong>{user.first_name} </strong>{user.last_name} {t('PERMISSION')}
        </h5>
        <button onClick={() => setDisplay(false)} className='btn border-0'>
          <i className='fa fa-close'></i>
        </button>
      </div>
      <div className="card-body">
        {loading ? (
          <Spinner />
        ) : (
            <>
              <table
                css={css`
              border-right: 1px solid #dee2e6;
              border-left: 1px solid #dee2e6;
              border-bottom: 1px solid #dee2e6;
          `}
                className='table table-striped table-lg table-hover card-shadowed'>
                <tbody>
                  {data && data.data && data.data.length > 0
                    ? (
                      data.data.map(group => {
                        return (
                          <tr key={group.id}>
                            <td className='bg-white'>
                              <div className="input-group-prepend">
                                <span>
                                  <div className="custom-control custom-checkbox" onClick={() => markAsSelected(group.id)}>
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      name={group.id}
                                      id={group.id}
                                      checked={selectedGroups && selectedGroups.includes(group.id) ? true : false}
                                      readOnly
                                    />
                                    <label css={css`font-weight: 500;`} className="custom-control-label fs-19" forlabel={group.id}>{group.name}</label>
                                  </div>
                                </span>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    )
                    : (
                      <tr>
                        <td className='bg-white'>
                          <h5 className="mt-5 text-center">{t('NO_GROUPS')}</h5>
                        </td>
                      </tr>
                    )}
                </tbody>
              </table>
            </>
          )}
      </div>
      <div className='pb-4 pr-4 d-flex mt-4'>
        <button
          css={css`
                    grid-column: 2/3;
                  `}
          className="btn btn-bold d-block ml-auto btn-primary btn-label"
          onClick={handleUpdate}
        >
          <span>
            <i className='ti-check'></i>
          </span>
          {t('UPDATE')}
        </button>
      </div>
    </Modal>
  )
}

export default PermissionModal
