import React, { useState, useEffect } from 'react'


const NotificationAlert = ({ data, message }) => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (data) {
      setDisplay(true);
      setTimeout(() => {
        setDisplay(false)
      }, 3000);
    }
  }, [data])

  if(display) {
    return (
      <div className="alert alert-primary" role="alert">
        {message}
      </div>
    )
  }

  return null
 
}

export default NotificationAlert
