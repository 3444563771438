import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import SchoolsContent from '../components/SchoolsContent/SchoolsContent'
import { useTranslation } from 'react-i18next'

const Schools = () => {
  const { t } = useTranslation()

  return (
    <PageLayout withHeader={true} title={t('SCHOOLS')}>
      <SchoolsContent />
    </PageLayout>
  )
}

export default Schools
