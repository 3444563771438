import React, { useState, useContext, useEffect, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { SchoolStateContext } from '../../context/SchoolContext';
import { useForm } from 'react-hook-form';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';

// Components
import CardHeader from '../CardHeader/index';
import SAMLComponent from '../SAMLModal/SAMLModal';
import Spinner from '../Spinner/index';
import { AlertService } from '../../services/AlertService';

const GeneralTab = () => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm({ mode: 'onChange' });
  const [logoImageFormDirty, setLogoImageFormDirty] = useState(false);
  const [defaultImg, setDefaultImg] = useState({
    logo: null,
    profileLogo: null
  });
  const [profileImageFormDirty, setProfileImageFormDirty] = useState(false);
  const [schoolId] = useContext(SchoolStateContext);
  const [samlModalOpen, setSamlModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    urlSlug: '',
    npiNumber: '',
    userSessionTimeout: '',
    enforceUserSessionTimeout: false,
    hasMetadata: false,
    taxIdentificationNumber: '',
    generalDirty: false,
    inactiveDirty: false,
    emailInboxName: '',
    studentIdLength: ''
  });

  const [formMetadata, setFormMetadata] = useState({
    samlEnabled: false,
    singleDirty: false
  });

  const [{ data, loading }, executeGetSettings] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );
  const [
    { data: metadata, loading: loadingMetada },
    executeMetadata
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/metadata`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );
  const [{ data: imgData, loading: loadingImg }, executeGetImgs] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/images`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    executeGetImgs();
    executeGetSettings();
    executeMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    { response: imageResponse, error: imageError },
    executeImagesPost
  ] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/images`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    manual: true
  });

  const [
    { response: settingsResponse, error: errorSettings },
    executePost
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [
    { response: timeoutResponse, error: timeoutError },
    executeInactiveUser
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/timeout`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [{ response: ssoResponse, error: ssoError }, executeSso] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/settings/sso`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (
      (imageResponse && !imageError) ||
      (settingsResponse && !errorSettings) ||
      (timeoutResponse && !timeoutError) ||
      (ssoResponse && !ssoError)
    ) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else {
      if (imageError) {
        Object.keys(imageError.response.data.message).map((key) =>
          AlertService.showError(imageError.response.data.message[key])
        );
      } else if (errorSettings) {
        Object.keys(errorSettings.response.data.message).map((key) =>
          AlertService.showError(errorSettings.response.data.message[key])
        );
      } else if (timeoutError) {
        Object.keys(timeoutError.response.data.message).map((key) =>
          AlertService.showError(timeoutError.response.data.message[key])
        );
      } else if (ssoError) {
        Object.keys(ssoError.response.data.message).map((key) =>
          AlertService.showError(ssoError.response.data.message[key])
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    imageResponse,
    imageError,
    settingsResponse,
    errorSettings,
    timeoutResponse,
    timeoutError,
    ssoResponse,
    ssoError
  ]);

  useEffect(() => {
    if (data) {
      setFormData((f) => ({
        ...f,
        urlSlug: data.url_slug || '',
        npiNumber: data.npi_number || '',
        hasMetadata: data.has_metadata,
        userSessionTimeout: data.user_session_timeout || '',
        enforceUserSessionTimeout: data.enforce_user_session_timeout,
        taxIdentificationNumber: data.tax_identification_number || '',
        emailInboxName: data.email_inbox_name || '',
        studentIdLength: data.student_id_format_mask
          ? data.student_id_format_mask.length
          : ''
      }));
    }
  }, [data]);

  useEffect(() => {
    if (metadata) {
      setFormMetadata({
        samlEnabled: metadata.saml_enabled
      });
    }
  }, [metadata]);

  useEffect(() => {
    if (imgData) {
      setDefaultImg({
        logo: imgData.LOGO,
        profileLogo: imgData.PROFILE_LOGO
      });
    }
  }, [imgData]);

  const handleSlugChange = (e) => {
    // NOTE: Matches anything that is not letter or hyphen
    const regex = new RegExp('[^a-z-]', 'ig');
    setFormData({
      ...formData,
      urlSlug: e.target.value.replace(regex, ''),
      generalDirty: true
    });
  };

  const handleDataChange = (e, type) => {
    setFormData({
      ...formData,
      [e.currentTarget.name]:
        e.currentTarget[
          e.currentTarget.type === 'checkbox' ? 'checked' : 'value'
        ],
      [`${type}Dirty`]: true
    });
  };

  const submitGeneralSettings = (e) => {
    e.preventDefault();
    executePost({
      params: {
        npiNumber: formData.npiNumber,
        urlSlug: formData.urlSlug,
        taxIdentificationNumber: formData.taxIdentificationNumber,
        emailInboxName: formData.emailInboxName.split('@')[0],
        studentIdLength: formData.studentIdLength
      }
    });
  };

  const submitInactiveUser = (e) => {
    e.preventDefault();
    executeInactiveUser({
      params: {
        timeout: formData.userSessionTimeout,
        enabled: formData.enforceUserSessionTimeout ? '1' : '0'
      }
    });
  };

  const handleProfileLogoImageSubmit = (data) => {
    const profileLogo = new FormData();
    profileLogo.append('file', data.schoolBanner[0]);
    profileLogo.append('type', 'PROFILE_LOGO');
    executeImagesPost({ data: profileLogo });
  };

  const handleLogoImageSubmit = (data) => {
    const logoData = new FormData();
    logoData.append('file', data.schoolLogo[0]);
    logoData.append('type', 'LOGO');
    executeImagesPost({ data: logoData });
  };

  const handleSingleSignon = (e) => {
    e.preventDefault();
    executeSso({
      params: {
        isEnabled: formMetadata.samlEnabled ? '1' : '0'
      }
    });
  };

  const onCloseMetadataModal = () => {
    executeGetSettings();
    executeMetadata();
  };

  const handleSamlChange = useCallback(() => {
    setFormMetadata((m) => ({
      samlEnabled: !m.samlEnabled,
      singleDirty: true
    }));
  }, []);

  const renderGenral = () => (
    <div className="card border border-light">
      <CardHeader title={t('GENERAL_SETTINGS')} />
      <div className="card-body">
        <form onSubmit={submitGeneralSettings} className="form-type-material">
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-group ${formData.npiNumber ? 'do-float' : ''}`}
              >
                <input
                  type="text"
                  name="npiNumber"
                  id="npiNumber"
                  className="form-control"
                  onChange={(e) => handleDataChange(e, 'general')}
                  value={formData.npiNumber}
                />
                <label htmlFor="npiNumber">{t('NPI')} - NPI</label>
              </div>
              <div
                className={`form-group ${
                  formData.taxIdentificationNumber ? 'do-float' : ''
                }`}
              >
                <input
                  type="text"
                  name="taxIdentificationNumber"
                  id="taxIdentificationNumber"
                  className="form-control"
                  onChange={(e) => handleDataChange(e, 'general')}
                  value={formData.taxIdentificationNumber}
                />
                <label htmlFor="taxIdentificationNumber">
                  {t('TIN')} - TIN
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className={`form-group ${formData.urlSlug ? 'do-float' : ''}`}
              >
                <input
                  type="text"
                  name="urlSlug"
                  id="urlSlug"
                  className="form-control"
                  onChange={handleSlugChange}
                  value={formData.urlSlug}
                />
                <label htmlFor="urlSlug">{t('STUDENT_PORTAL_SLUG')}</label>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={`form-group ${
                      formData.emailInboxName ? 'do-float' : ''
                    }`}
                  >
                    <input
                      type="text"
                      name="emailInboxName"
                      id="emailInboxName"
                      className="form-control"
                      onChange={(e) => handleDataChange(e, 'general')}
                      value={formData.emailInboxName}
                    />
                    <label htmlFor="emailInboxName">{t('INBOX_NAME')}</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={`form-group ${
                      formData.studentIdLength ? 'do-float' : ''
                    }`}
                  >
                    <input
                      type="number"
                      name="studentIdLength"
                      id="studentIdLength"
                      className="form-control"
                      onChange={(e) => handleDataChange(e, 'general')}
                      value={formData.studentIdLength}
                    />
                    <label htmlFor="studentIdLength">
                      {t('STUDENT_ID_LENGTH')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <button
              disabled={!formData.generalDirty}
              className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
              type="submit"
            >
              <span>
                <i className="ti-check"></i>
              </span>
              {t('UPDATE')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  const renderSingleSignon = () => (
    <div className="card border border-light">
      <CardHeader
        title={t('SINGLE_SIGNON')}
        rightComponent={
          <label
            className={`m-0 fs-13 ${
              formData.hasMetadata ? 'text-success' : 'text-danger'
            }`}
          >
            {formData.hasMetadata ? t('ACTIVE') : t('INACTIVE')}
          </label>
        }
      />
      <div className="card-body">
        <form onSubmit={handleSingleSignon} className="form-type-material">
          <div className="row">
            <div className="col-md-6">
              <span>
                <strong>{t('SAML_METADATA')}</strong>
              </span>
              <button
                className="fs-10 btn border-0 text-primary"
                onClick={(e) => {
                  e.preventDefault();
                  setSamlModalOpen(true);
                }}
              >
                {t('EDIT')}
              </button>
            </div>
            <div className="col-md-6">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formMetadata.samlEnabled}
                  name="hasMetadata"
                  id="hasMetadata"
                  onChange={handleSamlChange}
                />
                <span className="switch-indicator"></span>
              </label>
              <span className="ml-10">SSO</span>
            </div>
          </div>
          <div className="col-12">
            <button
              disabled={!formMetadata.singleDirty}
              className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
              type="submit"
            >
              <span>
                <i className="ti-check"></i>
              </span>
              {t('UPDATE')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  const renderInactiveUser = () => (
    <div className="card border border-light">
      <CardHeader title={t('INACTIVE_USER')} />
      <div className="card-body">
        <form onSubmit={submitInactiveUser} className="form-type-material">
          <div className="row">
            <div className="col-md-6">
              <div
                className={`form-group w-50 ${
                  formData.userSessionTimeout ? 'do-float' : ''
                }`}
              >
                <input
                  type="text"
                  name="userSessionTimeout"
                  id="userSessionTimeout"
                  className="form-control"
                  onChange={(e) => handleDataChange(e, 'inactive')}
                  value={formData.userSessionTimeout}
                />
                <label htmlFor="userSessionTimeout">
                  {t('TIME_OUT_IN_MIN')}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={formData.enforceUserSessionTimeout}
                    name="enforceUserSessionTimeout"
                    id="enforceUserSessionTimeout"
                    onChange={(e) => handleDataChange(e, 'inactive')}
                  />
                  <span className="switch-indicator"></span>
                </label>
                <span className="ml-10">
                  {formData.enforceUserSessionTimeout
                    ? t('ENABLED')
                    : t('DISABLED')}
                </span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <button
              disabled={!formData.inactiveDirty}
              className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
              type="submit"
            >
              <span>
                <i className="ti-check"></i>
              </span>
              {t('UPDATE')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  if (loading || loadingMetada || loadingImg) {
    return <Spinner />;
  }

  return (
    <>
      <SAMLComponent
        onCloseModal={onCloseMetadataModal}
        display={samlModalOpen}
        setDisplay={setSamlModalOpen}
        data={metadata}
      />

      <div className="row">
        <div className="col-md-8">
          {renderGenral()}
          {renderSingleSignon()}
          {renderInactiveUser()}
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title fw-500">{t('PORTAL_IMAGES')}</h5>
            </div>
            <div className="card-body">
              <form
                css={css`
                  .dropify-wrapper {
                    height: 100px;
                  }
                `}
                onSubmit={handleSubmit(handleLogoImageSubmit)}
              >
                <p className="my-2 fs-12">
                  {t('SCHOOL_LOGO')} - {t('TRANSPARENT_PNG_APPROX')}: 560px x
                  110px
                </p>
                <input
                  onInput={() => setLogoImageFormDirty(true)}
                  type="file"
                  name="schoolLogo"
                  accept="image/png"
                  className="dropify"
                  data-provide="dropify"
                  data-default-file={defaultImg.logo}
                  ref={register}
                />
                <button
                  disabled={!logoImageFormDirty}
                  className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
                  type="submit"
                >
                  <span>
                    <i className="ti-check"></i>
                  </span>
                  {t('UPDATE')}
                </button>
              </form>
              <form
                css={css`
                  .dropify-wrapper {
                    height: 100px;
                  }
                `}
                onSubmit={handleSubmit(handleProfileLogoImageSubmit)}
              >
                <p className="my-2 fs-12">
                  {t('SCHOOL_PROFILE_ICON')} - {t('SQUARE_PGN')}: 500px x 500px
                </p>
                <input
                  onInput={() => setProfileImageFormDirty(true)}
                  type="file"
                  name="schoolBanner"
                  accept="image/png"
                  className="dropify"
                  data-provide="dropify"
                  data-default-file={defaultImg.profileLogo}
                  ref={register}
                />

                <button
                  disabled={!profileImageFormDirty}
                  className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
                  type="submit"
                >
                  <span>
                    <i className="ti-check"></i>
                  </span>
                  {t('UPDATE')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralTab;
