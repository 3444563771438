/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SchoolStateContext } from '../../context/SchoolContext';
import ImportsTab from './ImportsTab';
import ExportsTab from './ExportsTab';
import ImportExportCard from './ImportExportCard';
import ServiceCard from './ServiceCard';
import ImportModal from './ImportModal';
import useAxios from 'axios-hooks';

//Request
const ImportExportTab = ({ tabOption }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState('');
  const [periodID, setPeriodID] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedImportData, setSelectedImportData] = useState();
  const [schoolId] = useContext(SchoolStateContext);
  const [count, setCount] = useState(0);
  const [importModal, setImportModal] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [selectedExport, setSelectedExport] = useState();
  const [status, setStatus] = useState('');

  //

  //status data
  const [{ sData, sLoading }, exeStatus] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/imports-files`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }, params: {
        segment_id: selectedPeriod ? periodID[selectedPeriod] : periodID[0],
        processed: '0'
      }
    },
    { manual: true }
  );
  const asyncFetch = async (params) => {
    try {
      let statusData = await exeStatus(params);
      if (statusData) {
        setStatus(statusData.data);
      }

    } catch (e) {
      // Handle cancellation
    }
  }
  const onPageChange = selectedPage => {
    asyncFetch({
      params:
      {
        page: selectedPage,
        segment_id: selectedPeriod ? periodID[selectedPeriod] : periodID[0],
        processed: '0'
      },
    });
  }
  useEffect(() => {
    asyncFetch();
  }, [periodID, importModal]);
  //List of APIs used
  return (
    <div className='container'>

      <div className="row">
        {status !== '' && status && (
          <ImportModal
            setDisplay={setImportModal}
            onPageChange={onPageChange}
            display={importModal}
            modalData={{ status: status, schoolId: schoolId, period: period[selectedPeriod] ? period[selectedPeriod] : period[0] }}
            onClose={importModal}
          />
        )}


        <div className="col-9">
          <div className="card card-shadowed">
            <div className=" card-title" key={count}>
              <div className='row'>
                <div className='col-md-2'><h3>{t(tabOption == 'import' ? 'IMPORTS' : 'EXPORTS')} :</h3></div>
                <div className='col-md-3'>
                  {period && (
                    <form className='form-type-material'>
                      <select
                        name="period"
                        id="period"
                        data-provide="selectpicker"
                        onChange={(e) =>
                          setSelectedPeriod(e.target.options.selectedIndex)
                        }
                        value={
                          period[selectedPeriod] ? period[selectedPeriod] : period[0]
                        }
                      >
                        {period.map((e, key) => (
                          <option key={key} value={e}>
                            {e}
                          </option>
                        ))}
                      </select>
                    </form>

                  )}
                </div>
                <div className='col-md-4'></div>
                <div className='col-md-3'>
                  <>

                  </>
                  {

                    <>

                      <label>File status: </label>
                      {selectedImportData || selectedExport
                        ?
                        < button className={`ml-0 btn btn-bold d-inline-block btn-primary btn-label
                        ${tabOption == "import" && selectedImportData.processed
                            || tabOption == "export" && selectedExport.submitted
                            ? "btn-success" : "btn-warning"}`}>

                          {tabOption == "import" && (
                            selectedImportData.processed ?
                              <><span><i className='fa fa-check'></i></span>{t('PROCESSED')}</>
                              : <><span><i className='fa fa-refresh'></i></span>{t('PENDING')}</>
                          )}

                          {tabOption == "export" && (
                            selectedExport.submitted ?
                              <><span><i className='fa fa-check'></i></span>{t('SENT')}</>
                              : <><span><i className='fa fa-refresh'></i></span>{t('PREVIEW')}</>
                          )}
                        </button>
                        :
                        ""
                      }
                    </>

                  }
                </div>
              </div>

            </div>

            <div className="card-body">
              <div className="" id="imports">
                {tabOption == 'import' && (
                  <ImportsTab
                    importData={{
                      schoolId: schoolId,
                      selectedPeriod: selectedPeriod,
                      selectedImportData: selectedImportData,
                      status: status,
                      periodID: periodID,
                      setDisplay: importModal,
                      period: period[selectedPeriod] ? period[selectedPeriod] : period[0]
                    }
                    }
                    setPeriod={setPeriod}
                    setPeriodID={setPeriodID}
                    setSelectedImportData={setSelectedImportData}
                    onClose={setImportModal}
                  />

                )}
              </div>
              <div className="" id="exports">
                {tabOption == 'export' && (
                  <ExportsTab
                    importData={{
                      schoolId: schoolId,
                      selectedPeriod: selectedPeriod,
                      periodID: periodID,
                      setDisplay: importModal,
                      selectedExport: selectedExport,
                      isPreview: isPreview,
                    }
                    }
                    setPeriod={setPeriod}
                    setSelectedExport={setSelectedExport}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <ImportExportCard schoolId={schoolId} activeTab={tabOption} />
          {tabOption == 'export' && (
            <ServiceCard
              URL={`${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/exports/preview`}
              indexData={{title:"Preview mode", option: tabOption, fileID: selectedExport, isActive: isPreview }}
              setIsActive={setIsPreview}
            />
          )}

        </div>
      </div>
    </div >
  );
};
export default ImportExportTab;
