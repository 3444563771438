import React from "react";
import { css } from "@emotion/core";

const Checkbox = ({ label = null, checked, onCheck, labelStyle ='' }) => {
  return (
    <div className='d-flex'>
      <div
        css={css`
          width: 18px;
          height: 18px;
          background-color: #fcfdfe;
          border: 1px solid #ebebeb;
          cursor: pointer;
        `}
        className="mt-1"
        onClick={onCheck}
      >
        {checked && <div css={css`margin-top: -4px`} className="text-center"> <i className="fa fa-check text-primary"></i> </div>}
      </div>
      {label && <label className={`ml-2 ${labelStyle}`}>{label}</label>}

    </div>
  );
};

export default Checkbox;
