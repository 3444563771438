import React from 'react'
import LandingContent from '../components/LandingContent/LandingContent'
import PageLayout from '../components/PageLayout/PageLayout'

const Landing = () => {
  return (
      <PageLayout>
        <LandingContent />
      </PageLayout>
  )
}

export default Landing
