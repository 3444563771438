import React from 'react'
import { useTranslation } from 'react-i18next'
import Checkbox from '../Checkbox'

const General = ({ formData, provider, handleInputChange, handleActiveChange, handleDefaultChange, handleNoValidationChange }) => {
  const { t } = useTranslation();


  return (
    <form className='form-type-material row mx-auto'>
      <div className='col-12'>
        <div className={`form-group ${formData.name ? 'do-float' : ''}`}>
          <input
            type='text'
            name='name'
            id='name'
            className='form-control'
            value={formData.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
          <label htmlFor='name' className='require'>
            {t('INSURANCE_COMPANY')}
          </label>
        </div>
        <div className={`form-group ${formData.overridePayerId ? 'do-float' : ''}`}>
          <input
            type='text'
            name='overridePayerId'
            id='overridePayerId'
            className='form-control'
            value={formData.overridePayerId}
            onChange={(e) => handleInputChange('overridePayerId', e.target.value)}
          />
          <label htmlFor='overridePayerId' className={`${(!provider && !formData.noValidation) || (provider && provider.payer_id === 'EWAIVE') ? 'require' : ''}`}>
            {t('OVERRIDE_PAYER_ID')}
          </label>
        </div>
        <div className="row">
          <div className='col-6'>
            <div className={`form-group ${formData.overrideNpi ? 'do-float' : ''}`}>
              <input
                type='text'
                name='overrideNpi'
                id='overrideNpi'
                className='form-control'
                value={formData.overrideNpi}
                onChange={(e) => handleInputChange('overrideNpi', e.target.value)}
              />
              <label htmlFor='overrideNpi'>
                {t('OVERRIDE_NPI')}
              </label>
            </div>
          </div>
          <div className='col-6 d-flex align-items-center'>
            <div className='d-flex'>
              <Checkbox checked={formData.defaultPr} onCheck={handleDefaultChange} /> <label className='ml-2 mb-0 fw-300'>{t('DEFAULT_PR')}</label>
            </div>
          </div>
        </div>
        <div className='d-flex mb-3'>
          <Checkbox checked={formData.noValidation} onCheck={handleNoValidationChange} /> <label className='ml-2 mb-0 fw-300'>{t('NO_AUTO_APPROVAL')}</label>
        </div>
        <div className=' d-flex align-items-center'>
          {provider && (
            <div className="form-group p-0">
              <label className='switch'>
                <input
                  type='checkbox'
                  checked={formData.isActive}
                  name='active'
                  id='active'
                  onChange={handleActiveChange}
                />
                <span className='switch-description'>{t('ACTIVE')}</span>
                <span className='switch-indicator'></span>
              </label>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default General
