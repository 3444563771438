import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import ProvidersContent from '../components/ProvidersContent'
import { useTranslation } from 'react-i18next'

const Providers = () => {
  const { t } = useTranslation()
  return (
    <PageLayout withHeader={true} title={t('INSURANCE_PROVIDERS')} crumbs={[]}>
      <ProvidersContent />
    </PageLayout>
  )
}

export default Providers
