import useAxios from 'axios-hooks'

export function useAuthorizedAxios({ url, method, manual, headers = {}, params}) {
  const axiosHook = useAxios({
    url,
    method,
    headers: {
      ...headers,
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    },
    params,
  },
  {
   manual
  })

  return axiosHook
}
