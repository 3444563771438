import React, { useState, useEffect, useCallback, useMemo } from "react";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "react-currency-format";
import { useAuthorizedAxios } from "../../../hooks/use-authorizedaxios";
import { UserService } from "../../../services/UserService";
import Spinner from "../../Spinner";
import CardHeader from "../../CardHeader";
import Preloader from "../../Preloader";
import ValidatorStatusTag from '../../ValidatorStatusTag/index';

const InsuranceProvider = ({ submissionId, waiverFormData, status, validatorCurrentStatus, refetch, updateLocal, setUpdatedData,RolePermissionValue }) => {
  const { t } = useTranslation();
  const [{ loading, data }, executePost] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/waivers`,
    method: "POST",
    manual: true
  });
  const [{ loading: loadingValidate, data: dataValidate, error: errorValidate }, executeValidate] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/waivers/validate`,
    method: "POST",
    manual: true
  });
  const [{ loading: loadingProviders, data: dataProviders }, providers] = useAuthorizedAxios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL}api/v1/providers`
  });

  const [formData, setFormData] = useState({
    insuranceName: waiverFormData.insuranceCompanyName || "",
    memberId: waiverFormData.insuranceMemberNumber || "",
    groupId: waiverFormData.insuranceGroupNumber || "",
    insuranceId: waiverFormData.insuranceId || '',
    memberSupport: waiverFormData.insurancePhoneNumber || "",
    dirty: false,
    overridePayerId: null,
  });

  const [editInsuranceName, setEditInsuranceName] = useState(false);
  const [payerId, setPayerId] = useState(waiverFormData.payerId || "");
  const [count, setCount] = useState(0);
  const [payerIdReadOnly, setPayerIdReadOnly] = useState(false);
  const [displayNotApproved, setDisplayNotApproved] = useState(false);
  const [phoneError, setPhoneError] = useState(false)

  useEffect(() => {
    if (dataProviders && waiverFormData) {
      if (!waiverFormData.insuranceId) {
        setPayerIdReadOnly(true);
        setEditInsuranceName(true);
      } else {
        const provider = dataProviders.find(provider => provider.id === waiverFormData.insuranceId);
        if (provider) {
          setPayerId(provider.payer_id);
          setFormData(d => ({ ...d, overridePayerId: provider.override_payer_id }))
        }
        setPayerIdReadOnly(true);
      }
    }
  }, [waiverFormData, dataProviders])

  useEffect(() => {
    if (dataValidate && !loadingValidate && !errorValidate) {
      refetch();
      if (dataValidate.approved === false) {
        setDisplayNotApproved(true);
        setTimeout(() => {
          setDisplayNotApproved(false);
        }, 5000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValidate, errorValidate, loadingValidate])

  const handleFormChange = e => {
    if (e.target.name === 'memberSupport' && e.target.value && e.target.value[0] === '1') {
      setPhoneError(true)
      setFormData({
        ...formData,
        dirty: false,
        [e.target.name]: e.target.value
      });
    } else {
      setFormData({
        ...formData,
        dirty: true,
        [e.target.name]: e.target.value
      });
      if (e.target.name === 'memberSupport') {
        setPhoneError(false)
      }
    }
  };

  const handleCompanyNameChange = e => {
    setFormData({
      ...formData,
      dirty: true,
      insuranceName: e.target.value,
      insuranceId: ''
    });
  };

  const submitForm = async e => {
    e.preventDefault();

    const params = {
      memberId: formData.memberId,
      groupNumber: formData.groupId,
      memberSupportPhone: formData.memberSupport
    };

    if (!!payerId) {
      params.payerId = payerId;
    }
    if (!!formData.insuranceId) {
      params.insuranceId = formData.insuranceId;
    } else if (!!formData.insuranceName) {
      params.payerName = formData.insuranceName;
    }

    executePost({
      params
    });
  };

  useEffect(() => {
    if (data) {
      if (updateLocal) {
        updateLocal(data.submission)
      } else if (refetch) {
        refetch()
      }
      setFormData(f => ({ ...f, dirty: false }))
      setUpdatedData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (providers) {
      setCount(c => (c + 1))
    }
  }, [providers]);

  const isFormInvalid = () => {
    return loading || !formData.dirty;
  };

  const handleSelectChanged = e => {
    const option = document.querySelector(`#${e.target.value}`);
    const { id, name, payerid, overridePayerId } = option.dataset;
    if (!payerIdReadOnly) {
      setPayerIdReadOnly(true)
    }
    setEditInsuranceName(id === '' && name === '' ? true : false)
    setFormData({
      ...formData,
      dirty: true,
      insuranceId: id,
      insuranceName: name,
      overridePayerId,
    });
    setPayerId(payerid)
  };

  const handleValidate = useCallback((e) => {
    e.preventDefault();
    executeValidate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateIsDisabled = useMemo(() => (
    editInsuranceName || formData.dirty || status === 'AP' || !RolePermissionValue || (payerId === 'EWAIVE' && !formData.overridePayerId)
  ), [editInsuranceName, formData, status, payerId])

  return (
    <div className="card border border-light mb-0">
      {loadingValidate && (
        <Preloader
          customStyle={{ backgroundColor: 'rgba(245, 246, 247,0.9 )' }}
          message={t('INSURANCE_IS_BEING_VERIFIED')}
        />)}
      <div css={css`.card-header{ height: 53px;}`}>
        <CardHeader
          title={t('INSURANCE')}
          rightComponent={<ValidatorStatusTag validatorCurrentStatus={validatorCurrentStatus} customStyle='mb-0' />}
        />
      </div>
      <div className="card-body">

        <form className="form-type-material">
          {loadingProviders
            ? <Spinner />
            : (
              <>
                <div className="row">
                  <div className="col-6">
                    {!editInsuranceName && (
                      <div className="form-group mb-2">
                        <input
                          value={payerId}
                          onChange={e => setPayerId(e.target.value)}
                          id="payerId"
                          className="form-control"
                          type="text"
                          name="payerId"
                          readOnly={!RolePermissionValue || payerIdReadOnly}
                        />
                        <label className="label-floated" htmlFor="payerId">
                          {t("PAYER_ID")}
                        </label>
                      </div>
                    )}
                    <div
                      className="form-group mb-2 do-float"
                      css={css`
                        .dropdown-menu {
                          min-width: 100px !important;
                        }
                      `}
                      key={count}
                    >
                      <select
                        name="insuranceId"
                        id="insuranceId"
                        value={`${formData.insuranceId ? `insurance${formData.insuranceId}` : 'none'}`}
                        onChange={handleSelectChanged}
                        data-provide="selectpicker"
                        data-live-search="true"
                        className="form-control w-100"
                        disabled={!RolePermissionValue}
                      >
                        <option value="">{t("PLEASE_SELECT_INSURANCE")}</option>
                        {dataProviders &&
                          dataProviders.map(provider => {
                            return (
                              <option
                                key={provider.id}
                                value={`insurance${provider.id}`}
                                id={`insurance${provider.id}`}
                                data-id={provider.id}
                                data-name={provider.name}
                                data-payerid={provider.payer_id}
                                data-override-payer-id={provider.override_payer_id}
                              >
                                {provider.name}
                              </option>
                            );
                          })}
                        <option value="none" id="none" data-id="" data-name="">
                          {t("OTHER")}
                        </option>
                      </select>

                      <label className="label-floated" htmlFor="insuranceId">
                        {t("INSURANCE_PROVIDER")}
                      </label>
                    </div>
                    <div
                      className="form-group mb-2"
                    >
                      <input
                        value={formData.insuranceName}
                        onChange={handleCompanyNameChange}
                        placeholder={t("IF_OTHER_SPECIFY")}
                        id="insuranceCustom"
                        className="form-control"
                        type="text"
                        name="insuranceName"
                        readOnly={!RolePermissionValue || !editInsuranceName}
                      />
                      <label className="label-floated" htmlFor="insuranceName">
                        {t("INSURANCE_COMPANY_NAME")}
                      </label>
                    </div>

                  </div>
                  <div className="col-md-6">
                    <button
                      onClick={handleValidate}
                      disabled={validateIsDisabled}
                      className={`btn btn-bold d-block ml-auto btn-info btn-label ${validateIsDisabled ? 'disabled text-white' : ''}`}
                    >
                      <span>
                        <i className="ti-reload"></i>
                      </span>
                      {t("REQUEST_APPROVAL")}
                    </button>
                    {displayNotApproved && <p className="mt-1 text-right w-100 text-danger fw-700">{t('APPLICATION_NOT_APPROVED')}</p>}
                    <div className="form-group mb-2 mt-3">
                      <input
                        value={formData.memberId}
                        onChange={handleFormChange}
                        id="memberId"
                        className="form-control"
                        type="text"
                        name="memberId"
                        readOnly={!RolePermissionValue}
                      />
                      <label className="label-floated" htmlFor="memberId">
                        {t("MEMBER")} #
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div
                          className="form-group mb-2"
                        >
                          <input
                            value={formData.groupId}
                            onChange={handleFormChange}
                            id="groupId"
                            className="form-control"
                            type="text"
                            name="groupId"
                            readOnly={!RolePermissionValue}
                          />
                          <label className="label-floated" htmlFor="groupId">
                            {t("GROUP")} #
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group mb-2">
                          <CurrencyFormat
                            id="phone"
                            name="phone"
                            value={formData.memberSupport}
                            onValueChange={val => handleFormChange({ target: { name: 'memberSupport', value: val.value, } })}
                            className="form-control"
                            format="+1 ###-###-####"
                            readOnly={!RolePermissionValue}
                          />
                          <label className="label-floated" htmlFor="memberSupport">
                            {t("CUSTOMER_SERVICE")} #
                          </label>
                        </div>
                        {phoneError && <label className='text-danger'>
                          {t('PHONE_NO_+1')}
                        </label>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={submitForm}
                  disabled={isFormInvalid() || !RolePermissionValue}
                  className={`btn btn-bold d-block ml-auto btn-primary btn-label ${!RolePermissionValue ? 'disabled text-white' : ''}`}
                >
                  <span>
                    <i className="ti-check"></i>
                  </span>
                  {t("UPDATE")}
                </button>
              </>
            )}
        </form>
      </div>
    </div>
  );
};

export default InsuranceProvider;
