export class UserService {
  static currentUser = null;

  static setUser(user) {
    this.currentUser = user;
  }

  static getUser() {
    return this.currentUser;
  }

  static isAdmin() {
    if (this.currentUser) {
      return this.currentUser.type === 'admin' ||
        this.currentUser.type === 'super_admin'
        ? true
        : false;
    }
    return true;
  }

  static isSimpleAdmin() {
    if (this.currentUser) {
      return this.currentUser.type === 'admin' ? true : false;
    }
    return true;
  }

  static isSuperAdmin() {
    if (this.currentUser) {
      return this.currentUser.type === 'super_admin' ? true : false;
    }
    return true;
  }

  static hasPermission(permission) {
    if (!this.currentUser) {
      return false;
    }

    if (
      this.currentUser.type === 'admin' ||
      this.currentUser.type === 'super_admin'
    ) {
      return true;
    }

    return this.currentUser.security_rights[permission];
  }
}
