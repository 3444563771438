import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import CardHeader from '../../CardHeader/index';
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';

// Services
import { UserService } from '../../../services/UserService';

const ManualBilling = React.memo(({ submissionId, manualBilling, refetch, setUpdatedData,RolePermissionValue}) => {
  const { t } = useTranslation()
  const [enable, setEnable] = useState(false);
  const [{ data }, postManualBilling] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/ship/billing`,
    method: 'POST',
    manual: true
  })

  const handleSubmit = async e => {
    e.preventDefault();
    postManualBilling({
      params: {
        isEnabled: enable ? 1 : 0,
      }
    })
  }

  useEffect(() => {
    setEnable(manualBilling);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setUpdatedData(data);
      refetch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleManualBilling = useCallback(() => {
    setEnable( v => (!v))
  },[])

  return (
    <div className="card border border-light mb-0 h-100">
      <CardHeader title={t('MANAUL_BILLING')} />
      <div className="card-body">
        <div className="d-flex mt-4">
          <label className="switch">
            <input 
              name="email" 
              type="checkbox" 
              disabled={!RolePermissionValue}
              checked={enable}
              onChange={handleManualBilling}
              />
            <span className="switch-indicator"></span>
            <span className="switch-description">{t('ENABLE')}</span>
          </label>
          <button
            onClick={handleSubmit}
            disabled={!RolePermissionValue || enable === manualBilling}
            className={`btn btn-bold d-block ml-auto btn-primary btn-label ${!RolePermissionValue || enable === manualBilling ? 'disabled text-white' : ''}`}
          >
            <span>
              <i className='ti-check'></i>
            </span>
            {t('UPDATE')}
          </button>
        </div>
      </div>
    </div>

  )
});

export default ManualBilling;


