import React from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal/Modal'
import CardHeader from '../CardHeader'

const ValidationConfirmationModal = ({ display, setDisplay, message }) => {
  const { t } = useTranslation();

  return (
    <Modal
      width="w-350px"
      targetId='lock-fields-modal'
      display={display}
      setDisplay={setDisplay} >
      <CardHeader
        title={t('VALIDATION_CONFIRMATION')}
        rightComponent={(
          <button
            className="btn border-0 p-0"
            onClick={() => setDisplay()}
          >
            <i className="fa fa-close"></i>
          </button>)}
      />
      <div className='card-body'>
        <p>{message}</p>
        <div className='d-flex'>
          <button
            className="btn btn-bold d-block text-white ml-auto btn-label bg-info"
            onClick={() => setDisplay()}
          >
          <span> <i className='ti-close'></i></span>
            {t('CLOSE')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ValidationConfirmationModal;
