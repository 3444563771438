import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import NewNote from '../NewNote';
import ChangeStatus from '../ChangeStatus';
import InsuranceProvider from './InsuranceProvider';
import CardHeader from '../../CardHeader';
import StatusCard from '../../StatusCard/index';
import SupplementalCard from './SupplementalCard';
import { SchoolService } from '../../../services/SchoolService';

const EnrollmentTab = ({
  student,
  submission,
  updatedAt,
  refetch,
  supplementPlan,
  supplementPlanEnabled,
  updateLocal,
  setUpdatedData
}) => {
  const { t } = useTranslation();
  const [notesPermission, setNotesPermission] = useState(false);
  const [wSupplementsPermission, setWSupplementsPermission] = useState(false);
  const [wManualAppPermission, setWManualAppPermission] = useState(false);
  const [studentSub, setstudentSub] = useState(false);

  //Use of Multirole permissions
  SchoolService.rolePermission(
    'STUDENTS_SUBMISSIONS:ADD_NOTES',
    student.school_id == undefined
      ? submission.segment.school_id
      : student.school_id,
    setNotesPermission
  );
  SchoolService.rolePermission(
    'STUDENTS_SUBMISSIONS:MANAGE_WAIVER_SUPPLEMENTS',
    student.school_id == undefined
      ? submission.segment.school_id
      : student.school_id,
    setWSupplementsPermission
  );
  SchoolService.rolePermission(
    'STUDENTS_SUBMISSIONS:MANUAL_APPROVAL',
    student.school_id == undefined
      ? submission.segment.school_id
      : student.school_id,
    setWManualAppPermission
  );

  SchoolService.rolePermission(
    'STUDENTS_SUBMISSIONS',
    student.school_id == undefined
      ? submission.segment.school_id
      : student.school_id,
    setstudentSub
  );

  return (
    <>
      <div className="row">
        <div className="col-md-8 pb-4">
          <div className="card border border-light mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <small>{t('FIRST_NAME')}</small>
                  <p className="m-0">{student.first_name}</p>
                </div>
                <div className="col-md-3">
                  <small>{t('LAST_NAME')}</small>
                  <p className="m-0">{student.last_name}</p>
                </div>
                <div className="col-md-3">
                  <small>{t('PHONE')}</small>
                  <p className="m-0">{student.phone}</p>
                </div>
                <div className="col-md-6">
                  <small>{t('EMAIL_ADDRESS')}</small>
                  <p className="m-0">{student.email_address}</p>
                </div>
                <div className="col-md-3">
                  <small>{t('DATE_OF_BIRTH')}</small>
                  <p className="m-0">
                    {moment(student.date_of_birth).format('MM/DD/YYYY')}
                  </p>
                </div>
                <div className="col-md-3">
                  <small>{t('GENDER')}</small>
                  <p className="m-0">{student.gender}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`${supplementPlanEnabled ? 'col-md-6' : 'col-md-12'}`}
            >
              <div className="card border border-light mb-0">
                <CardHeader title={t('CURRENT_STATUS')} />
                <div className="card-body ">
                  <div className="d-flex">
                    <StatusCard
                      status={submission.status}
                      title={submission.status}
                      titleStyle="fs-36 fw-900"
                    />
                    <div className="ml-3">
                      <p className="m-0 fw-700">
                        {t(
                          `WAIVERCODES_STATUS_CODES_${submission.status}_NAME`
                        )}
                      </p>
                      {/* <p className="m-0">{moment(updatedAt).format("MM/DD/YYYY")}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {supplementPlanEnabled && (
              <div className="col-md-6">
                <SupplementalCard
                  submissionId={submission.id}
                  supplementPlan={supplementPlan}
                  refetch={refetch}
                  updateLocal={updateLocal}
                  setUpdatedData={setUpdatedData}
                  RolePermissionValue={wSupplementsPermission}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4 pb-4">
          <div className="card border border-light h-100 mb-0">
            <div className="card-body d-flex">
              <ChangeStatus
                type="waiver"
                submissionId={submission.id}
                currentStatus={submission.status}
                refetch={refetch}
                updateLocal={updateLocal}
                setUpdatedData={setUpdatedData}
                RolePermissionValue={{
                  waiverSupplements: wSupplementsPermission,
                  manualApprove: wManualAppPermission
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 pb-4">
          {/* Add permission */}
          <InsuranceProvider
            waiverFormData={submission.waiver_form_data}
            submissionId={submission.id}
            status={submission.status}
            refetch={refetch}
            validatorCurrentStatus={submission.validator_current_status}
            updateLocal={updateLocal}
            setUpdatedData={setUpdatedData}
            RolePermissionValue={wSupplementsPermission && wManualAppPermission}
          />
        </div>
        <div className="col-md-4 pb-4">
          <div className="card border border-light h-100 mb-0">
            <div className="card-body d-flex">
              <NewNote
                submissionId={submission.id}
                setUpdatedData={setUpdatedData}
                RolePermissionValue={notesPermission}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollmentTab;
