import React, { useState, useContext } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next'
import EnrollmentPeriodSettings from './EnrollmentPeriodSettings'
import EnrollmentPeriodShipAttachments from './EnrollmentPeriodShipAttachments'
import { TabsContainer } from '../../styles/common/tabs';
import { SearchStateContext } from '../../context/SearchContext';

const EnrollmentContent = ({ period }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('#settings');
  const { setWithSearch } = useContext(SearchStateContext);

  setWithSearch(false);

  const handleActiveTab = e => {
    Array.from(e.currentTarget.children).forEach(li => {
      const navLink = li.firstChild
      if (navLink !== e.target && navLink.classList.contains('active')) {
        navLink.classList.remove('active')
      }
    })
  }

  return (
    <TabsContainer>
      <div
        css={css` 
        box-shadow: none;
        z-index: 600;
      `}
        className='topbar header-inverse mt-150'>
        <div className='container'>
          <div className='header-action'>
            <nav
              className='nav'
              onClick={handleActiveTab}>
              <li className='nav-item'>
                <button
                  className='nav-link btn active'
                  data-toggle='tab'
                  href='#settings'
                  data-search='0'
                  onClick={e => setActiveTab(e.target.getAttribute('href'))}
                >
                  {t('SETTINGS')}
                </button>
              </li>
              <li className='nav-item'>
                <button
                  className='nav-link btn'
                  data-toggle='tab'
                  href='#ship-attachments'
                  data-search='1'
                  onClick={e => setActiveTab(e.target.getAttribute('href'))}
                >
                  {t('SHIP_ATTACHMENTS')}
                </button>
              </li>
            </nav>
          </div>
        </div>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade active show' id='settings'>
          {activeTab === '#settings' && <EnrollmentPeriodSettings period={period} />}
        </div>
        <div className='tab-pane fade' id='ship-attachments'>
          {activeTab === '#ship-attachments' && <EnrollmentPeriodShipAttachments period={period} />}
        </div>
      </div>
    </TabsContainer>
  )
}

export default EnrollmentContent
