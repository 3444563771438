import React, { useState, useEffect } from 'react'
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios'
import { useTranslation } from 'react-i18next'
import { UserService } from '../../services/UserService';

const NewNote = ({ submissionId, setUpdatedData,RolePermissionValue }) => {
  const { t } = useTranslation()
  const [note, setNote] = useState('')

  const [{ loading, data }, postNewNote] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/notes`,
    method: 'POST',
    manual: true
  })

  const handleNewNote = () => {
    postNewNote({ params: { note } })
    setNote('')
  }

  useEffect(() => {
    if (data) {
      setUpdatedData(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="d-flex flex-column w-100">
      <div className="form-group form-type-material do-float w-100 flex-grow-1">
        <label htmlFor='note' className='label-floated'>
          {`${t('NOTES')}`}
        </label>
        <textarea
          name='note'
          id='note'
          className='form-control h-100'
          value={note}
          onChange={e => setNote(e.target.value)}
          readOnly={!UserService.hasPermission('students:submissions:addNotes')}
        ></textarea>
      </div>
      <div className="d-flex w-100 align-items-end justify-content-end">
        <button
          onClick={handleNewNote}
          disabled={note.length === 0 || loading || !UserService.hasPermission('students:submissions:addNotes') || !RolePermissionValue}
          className={`btn btn-bold d-block ml-auto btn-primary btn-label ${note.length === 0 || loading || !UserService.hasPermission('students:submissions:addNotes') ? 'disabled text-white' : ''}`}>
          <span>
            <i className='ti-check'></i>
          </span>
          {t('SAVE_NOTE')}
        </button>
      </div>
    </div>
  )
}

export default NewNote
