import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from 'react-i18next';

export const EmptyCard = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <p>{t('DRAG_COMPONENT')}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: auto;
  height: 97px;
  margin: 0;
  border: dashed 1px #c9ced5;
  background-color: #c9ced54f;
  display: flex;
  align-items: center;
  justify-content: center;
`;
