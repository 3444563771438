import React, { useState, useEffect, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';

import Preloader from '../Preloader/index';

const ImportsTab = ({ importData, setPeriod, setPeriodID, setSelectedImportData, onClose }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();
  const [key, setKey] = useState();


  const [highlightedDates, setHighlightedDates] = useState([]);
  const [{ data, loading }, executeGet] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/imports`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );
  const [{ loading: downloadLoading }, download] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/imports`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );


  //use async function to call 2 or more request at same time
  const multipleFetch = async () => {
    try {
      await executeGet();
    } catch (e) {
      // Handle cancellation
    }
  }
  useEffect(() => {
    multipleFetch();
  }, [importData.selectedPeriod, importData.selectedPeriod.period]);

  useEffect(() => {
    if (data) {

      const period = [];
      const periodID = [];
      Object.keys(data.data).forEach((element) => {
        period.push(data.data[element].label);
        periodID.push(data.data[element].segment_id);
      });

      setPeriod(period);
      setPeriodID(periodID);
      const result = [];
      const objectKey = importData.selectedPeriod
        ? Object.keys(data.data)[importData.selectedPeriod]
        : Object.keys(data.data)[0];
      setKey(objectKey);
      if (data.data[objectKey].imports.length > 0) {
        data.data[objectKey].imports.forEach((element) => {
          result.push(new Date(element.created_at));
        });
        setSelectedDate(new Date(data.data[objectKey].imports[0].created_at));
        setSelectedImportData(data.data[objectKey].imports[0]);
        setHighlightedDates(result);
      } else {
        let voidData = importData.selectedPeriod ? data.data[importData.selectedPeriod] : data.data[0];
        voidData = {
          "id": "0",
          "enrollment_period_segment_id": "0",
          "school_id": "0",
          "created_at": "2023-01-01 00:00:00",
          "process_report": {
            "NEW_STUDENTS": 0,
            "ELIGIBILITY_REQUIRED": 0,
            "ELIGIBILITY_NOT_REQUIRED": 0,
            "PROFILE_UPDATED": 0,
            "ELIGIBILITY_LOCKS": 0,
            "UNCHANGED": 0
          },
          "processed": false
        };
        setSelectedImportData(voidData);
      }
    }


  }, [data]);
  useEffect(() => {
    if (data) {
      const result = [];
      const objectKey = Object.keys(data.data)[importData.selectedPeriod];
      setKey(objectKey);
      if (data.data[objectKey].imports.length > 0) {
        data.data[objectKey].imports.forEach((element) => {
          result.push(new Date(element.created_at));
        });
        setSelectedDate(new Date(data.data[objectKey].imports[0].created_at));
        setSelectedImportData(data.data[objectKey].imports[0]);
        setHighlightedDates(result);
      }
    }
  }, [importData.selectedPeriod]);

  const handleSelectDate = useCallback(
    (date) => {
      const searchDate = moment(date).format('YYYY-MM-DD');
      const selectDate = data.data[key].imports.find((element) =>
        element.created_at.includes(searchDate)
      );
      setSelectedDate(date);
      setSelectedImportData(selectDate);
    }
    ,
    [data, key]
  );

  const ColorCard = useCallback(
    ({ className, amout, name }) => (
      <div
        css={css`
          height: 127px;
          width: 140px;
        `}
        className={`text-center pt-4 ${className}`}
      >
        <p className="text-white fw-700 fs-35 mb-10">{amout}</p>
        <p className="text-white fw-700 fs-13">{name}</p>
      </div>
    ),
    []
  );
  return (
    <div
      css={css`
        border: solid 1px rgba(151, 151, 151, 0.25);
      `}
      className="card-body"
    >
      {(downloadLoading || loading) && (
        <Preloader customStyle={{ backgroundColor: 'transparent' }} />
      )}

      <div className="d-flex justify-content-between form-type-material">

        <div
          className="col-4 text-left"
          css={css`
            .react-datepicker-popper {
              z-index: 995;
            }
          `}
        >
          <label for='datepicker-imp' className='m-0 d-block l-datepicker'>{t('DATE')}</label>
          <DatePicker
            selected={selectedDate}
            onChange={handleSelectDate}
            includeDates={highlightedDates}
            className="form-control"
            placeholderText="Pick a Date"
            id="datepicker-imp"
          />
        </div>
        <div className='col-8 text-right'>
          <button
            type="button"
            onClick={() =>
              download({
                url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/imports/${importData.selectedImportData.id}`
              }).then((_data) => window.open(`${_data.data}`))
            }
            className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
          >
            <span><i className="fa fa-eye" /></span>
            {t('VIEW')}
          </button>
          <button
            type="button"
            onClick={() =>
              download({
                url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/imports/${importData.selectedImportData.id}`
              }).then((__data) => window.open(`${__data.data}&download=1`))
            }
            className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
          >
            <span><i className="fa fa-download" /></span>
            {t('DOWNLOAD')}
          </button>
          {importData.status && importData.status.data.length > 0 && (
            <button
              type="button"
              onClick={() => { onClose(true) }}
              className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
            >
              <span><i className="fa fa-file-o" /></span>
              {t('BROWSE')}
            </button>
          )}
        </div>
      </div>
      {importData.selectedImportData && (
        <div
          css={css`
            .bg-dark-purple {
              background-color: #926bde;
            }
            .bg-dark-grey {
              background-color: #939eaf;
            }
          `}
          className="d-flex my-3"
        >
          <ColorCard
            className="mr-1 bg-success"
            name={t('NEW_STUDENTS')}
            amout={importData.selectedImportData.process_report.NEW_STUDENTS}
          />
          <ColorCard
            className="mr-1 bg-dark-purple"
            name={t('ELIGIBILITY_REQUIRED')}
            amout={importData.selectedImportData.process_report.ELIGIBILITY_REQUIRED}
          />
          <ColorCard
            className="mr-1 bg-info"
            name={t('ELIGIBILITY_NOT_REQUIRED')}
            amout={importData.selectedImportData.process_report.ELIGIBILITY_NOT_REQUIRED}
          />
          <ColorCard
            className="mr-1 bg-warning"
            name={t('PROFILE_UPDATED')}
            amout={importData.selectedImportData.process_report.PROFILE_UPDATED}
          />
          <ColorCard
            className="mr-1 bg-pink"
            name={t('ELIGIBILITY_LOCKS')}
            amout={importData.selectedImportData.process_report.ELIGIBILITY_LOCKS}
          />
          <ColorCard
            className="bg-dark-grey"
            name={t('UNCHANGED')}
            amout={importData.selectedImportData.process_report.UNCHANGED}
          />
        </div>
      )}

      <div className="d-flex justify-content-end">

      </div>
    </div>
  );
};

export default ImportsTab;
