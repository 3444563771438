import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks';
import Modal from '../../Modal/Modal'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';
import Spinner from '../../Spinner/index';
import CardHeader from '../../CardHeader';
import EditGroupModal from './EditGroupModal';
import { AlertService } from '../../../services/AlertService';

const ManageAdminRolesModal = ({ display, setDisplay, userId }) => {
  const { t } = useTranslation();
  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const perPage = 10;
  const [{ data, loading }, getGroups] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles/`,
    method: 'GET',
    params: {
      perPage
    }
  })

  const [{ data: roleData, loading: roleLoading }, getRoleData] = useAxios({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    }
  });

  const [{ response: deleteResponse, error: deleteError }, deleteGroup] = useAxios(
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  );

  const renderAdminRoles = (group) => (
    <tr key={group.id}>
      <td className='bg-white'>
        <div className="row justify-content-between px-3">
          <label
            css={css`font-weight: 500;`}
            className="fs-19 m-0"
          >
            {group.name}
          </label>
          <div>
            <button onClick={() => handleEdit(group)} className='btn border-0 text-fadest fs-22 p-0'>
              <i className="fa fa-edit"></i>
            </button>
            <button onClick={() => handleDelete(group)} className='btn border-0 text-fadest fs-22 p-0 ml-3'>
              <i className="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </td>
    </tr>
  )

  const handleOpenEdit = () => {
    setDisplay(false);
    setShowEditGroupModal(true);
  }

  const handleCloseEdit = () => {
    setShowEditGroupModal(false);
    setDisplay(true);
    setSelectedGroup(null);
    getGroups();
  }

  const handleDelete = (group) => {
    deleteGroup({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/roles/${group.id}`,
    });
  }

  const handleEdit = (group) => {
    setSelectedGroup(group);
    handleOpenEdit();
  }

  useEffect(() => {
    if (deleteResponse) {
      AlertService.showSuccess(t('DELETE_SUCCESS'));
      getGroups()
    } else if (deleteError) {
      AlertService.showError(deleteError.response.data.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteResponse, deleteError])

  return (
    <>
      <Modal inputCSS={css`
        .w-650px {
          width: 650px;
        }
        .h-80 {
          height: 80%;
        }
      `} width="w-650px" display={display} setDisplay={setDisplay} height="h-80">
        <CardHeader
          title={t('MANAGE_GROUPS')}
          rightComponent={(
            <button onClick={() => setDisplay(false)} className='btn border-0'>
              <i className='fa fa-close'></i>
            </button>
          )}
        />
        <div className="card-body">
          {loading ? (
            <Spinner />
          ) : (

            <table
              css={css`
              border-right: 1px solid #dee2e6;
              border-left: 1px solid #dee2e6;
              border-bottom: 1px solid #dee2e6;
          `}
              className='table table-striped table-lg table-hover'>
              <tbody>
                {data.data && data.data.length > 0
                  ? (
                    data.data.map(group =>
                      renderAdminRoles(group)
                    )
                  )

                  : (
                    <tr>
                      <td className='bg-white'>
                        <h5 className="mt-5 text-center">{t('NO_ADMIN_ROLES')}</h5>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          )}
        </div>
        <div className='pb-4 pr-4 d-flex mt-4'>
          <button
            css={css`
              grid-column: 2/3;
            `}
            className="btn btn-bold d-block ml-auto btn-warning btn-label"
            onClick={handleOpenEdit}
          >
            <span>
              <i className='fa fa-users'></i>
            </span>
            {t('CREATE_NEW_ROLE')}
          </button>
        </div>
      </Modal >
      {showEditGroupModal && (
        <EditGroupModal
          display={showEditGroupModal}
          onClose={handleCloseEdit}
          roleId={selectedGroup && selectedGroup.id}
          role={selectedGroup}
          reFetch={getGroups}
        />
      )}

    </>
  )
}

export default ManageAdminRolesModal;
