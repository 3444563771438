import React, { useCallback, useState, useEffect } from 'react';
import Spinner from '../Spinner';
import { css } from '@emotion/core';
import { StyledMain } from '../../styles/common/main';
import { useTranslation } from 'react-i18next';
import EmailsPreviewModal from '../EmailsPreviewModal';
import { Link } from 'react-router-dom';
import templateIcon from '../../img/template.svg';
import useAxios from 'axios-hooks';

export const EmailTemplateContent = () => {
  const { t } = useTranslation();

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewtemplateName, setPreviewTemplateName] = useState('');
  const [previewtemplateData, setPreviewTemplateData] = useState('');
  const [lastSelectedTemplate, setLastSelectedTemplate] = useState(null);
  const [changedTemplate, setChangedTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState({
    WELCOME_EMAIL: null,
    SHIP_CONFIRMATION: null,
    WAIVER_CONFIRMATION: null,
    WAIVER_APPROVAL_CONFIRMATION: null,
    WAIVER_PENDING_DENIAL: null,
    WAIVER_FINAL_DENIAL: null,
    SUPPLEMENT_CONFIRMATION: null
  });

  const [{ loading, data: templatesList }, getTemplatesList] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/admin-basic`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [
    { loading: templatesContentLoading, data: templatesContentData },
    getTemplateContent
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/${lastSelectedTemplate?.id}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  const [
    { response: selectedResponse, error: errorSelected },
    changeSelected
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/${changedTemplate?.id}/enable-admin`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    getTemplatesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (changedTemplate && changedTemplate.id) {
      changeSelected({
        data: {
          type: changedTemplate.type
        }
      });
    }
  }, [changedTemplate]);

  useEffect(() => {
    const entries = Object.entries(selectedTemplate);

    entries.map((entry) => {
      //By default selected template is null, this triggers first time only...
      if (!selectedTemplate[entry]) {
        if (entry[0] && templatesList && templatesList[entry[0]]) {
          templatesList[entry[0]].map((template) => {
            if (template.enabled_admin) {
              handleSelectedTemplate(
                template.type,
                template.template_name,
                template.id
              );
            }
          });
        }
      }
    });
  }, [templatesList]);

  useEffect(() => {
    if (lastSelectedTemplate) {
      getTemplateContent({
        data: {
          type: lastSelectedTemplate.type
        }
      });
    }
  }, [lastSelectedTemplate]);

  useEffect(() => {
    if (templatesContentData) {
      const parsedItems = JSON.parse(templatesContentData.items);
      setPreviewTemplateData(parsedItems);
    }
  }, [templatesContentData]);

  const onPageChange = (selectedPage) => {
    console.log(`onPageChange page: ${selectedPage}`);
  };

  const handleSelectedTemplate = useCallback(
    (key, name, value) => {
      setChangedTemplate({ type: key, id: value });
      setSelectedTemplate((f) => ({
        ...f,
        [key]: { name: name, id: value }
      }));
    },
    [setSelectedTemplate]
  );

  const handlePreview = useCallback((key, value, title) => {
    setPreviewTitle(title);
    setPreviewTemplateName(value);
    //setPreviewTemplateData() //la data del template seleccionado
    getSelectedTemplateData(key);
    setOpenPreviewModal(true);
  });

  //This method should only be triggered on handleFunctions...
  const getSelectedTemplateData = (key) => {
    setLastSelectedTemplate({ type: key, id: selectedTemplate[key].id });
  };

  return (
    <StyledMain className="container">
      <EmailsPreviewModal
        title={previewTitle}
        templateName={previewtemplateName}
        items={previewtemplateData}
        display={openPreviewModal}
        setDisplay={setOpenPreviewModal}
        fromEdit={false}
        loading={templatesContentLoading}
      />
      <div className="card card-shadowed">
        {loading && <Spinner />}
        {templatesList && (
          <table className="table table-striped table-lg table-hover">
            <tbody>
              {selectedTemplate &&
                Object.keys(selectedTemplate).map((emailKey) => {
                  let selectedTemplateLabel = 'No selected template';
                  if (
                    selectedTemplate[emailKey] &&
                    selectedTemplate[emailKey].name
                  ) {
                    selectedTemplateLabel = selectedTemplate[emailKey].name;
                  }

                  return (
                    <tr key={emailKey}>
                      <td
                        css={css`
                          vertical-align: middle !important;
                        `}
                        className="py-30"
                      >
                        <span className="fs-20">{t(emailKey)}</span>
                      </td>
                      <td
                        css={css`
                          vertical-align: middle !important;
                        `}
                      >
                        <div className="media">
                          <div className="media-body text-right flexbox">
                            <ul
                              className="m-0 pt-2"
                              css={css`
                                min-width: 250px;
                              `}
                            >
                              <li className="dropdown d-block">
                                <span
                                  tabIndex="0"
                                  className="btn border-0 p-0 fw-500 text-black bg-transparent"
                                  data-toggle="dropdown"
                                >
                                  <img
                                    src={templateIcon}
                                    alt="templateIcon"
                                    className="mr-2"
                                  />{' '}
                                  <span>{selectedTemplateLabel}</span>{' '}
                                  <i className="fa fa-chevron-down"></i>
                                </span>
                                <div
                                  className="dropdown-menu open-top-left dropdown-menu-left"
                                  x-placement="bottom-end"
                                  style={{
                                    position: 'absolute',
                                    top: '65px',
                                    willChange: 'top, right'
                                  }}
                                >
                                  {templatesList &&
                                    templatesList[emailKey] &&
                                    templatesList[emailKey].map(
                                      (emailTemplate) => (
                                        <span
                                          key={`span_${emailTemplate.template_name}`}
                                          tabIndex="0"
                                          className={`dropdown-item btn border-0 ${
                                            selectedTemplateLabel ===
                                            emailTemplate.template_name
                                              ? 'bg-lighter text-body'
                                              : ''
                                          }`}
                                          onClick={() =>
                                            handleSelectedTemplate(
                                              emailTemplate.type,
                                              emailTemplate.template_name,
                                              emailTemplate.id
                                            )
                                          }
                                        >
                                          {emailTemplate.template_name}
                                        </span>
                                      )
                                    )}
                                </div>
                              </li>
                            </ul>
                            <button
                              className="ml-10 btn btn-bold d-block ml-auto btn-info btn-label"
                              type="submit"
                              onClick={() =>
                                handlePreview(
                                  emailKey,
                                  selectedTemplate[emailKey].name,
                                  t(emailKey)
                                )
                              }
                            >
                              <label>
                                <i
                                  css={css`
                                    font-size: 18px;
                                  `}
                                  className="ti-new-window"
                                ></i>
                              </label>
                              {t('PREVIEW')}
                            </button>
                            {selectedTemplate[emailKey] &&
                              selectedTemplate[emailKey].id && (
                                <Link
                                  className="pt-2"
                                  to={`/email-templates/${t(
                                    emailKey
                                  )}/${selectedTemplateLabel}/${
                                    selectedTemplate[emailKey].id
                                  }`}
                                >
                                  <i
                                    css={css`
                                      font-size: 18px;
                                    `}
                                    className="ti-pencil-alt"
                                  ></i>
                                </Link>
                              )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </StyledMain>
  );
};

export default EmailTemplateContent;
