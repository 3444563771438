import React, { useContext, useState, useCallback } from 'react';
import { css } from "@emotion/core";
import SummaryDashboard from './SummaryDashboard';
import AuditingDashboard from './AuditingDashboard';
import { DashboardStateContext } from '../../context/DashboardContext';
import AuditingTable from './AuditingTable';

const LandingContent = () => {
  const [dashboard, setDashboard] = useContext(DashboardStateContext);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedStatus, setSelectedStatus] = useState('PA');

  const handleOnSelectProvider = useCallback((provider) => {
    setSelectedProvider(provider);
    setDashboard('AUDITING_TABLE');
  }, [])

  return (
    <div
      css={css`
      margin-top: -150px;
      @media (min-width: 1200px) {
            max-width: 1250px !important;
      }
    `}
      className="container"
    >
      {dashboard === 'SUMMARY' && <SummaryDashboard />}
      {dashboard === 'AUDITING' && <AuditingDashboard onClick={handleOnSelectProvider} setStatus={setSelectedStatus} status={selectedStatus} />}
      {dashboard === 'AUDITING_TABLE' && <AuditingTable provider={selectedProvider} status={selectedStatus}/>}

    </div>
  );
}

export default LandingContent
