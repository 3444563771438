import React, { useState, useEffect } from 'react'
import useAxios from 'axios-hooks';
import Modal from '../../Modal/Modal'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import Spinner from '../../Spinner/index';
import CardHeader from '../../CardHeader';
import { AlertService } from '../../../services/AlertService';
import Checkbox from '../../Checkbox/index';

const EditGroupModal = ({ display, onClose, schoolId, group = null, reFetch }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [options, setOptions] = useState({
    "students": false,
    "students:profile": false,
    "students:lockFields": false,
    "students:submissions": false,
    "students:submissions:manualApproval": false,
    "students:submissions:manageWaiverSupplements": false,
    "students:submissions:addShipEnrollment": false,
    "students:submissions:manualBilling": false,
    "students:submissions:coverageDates": false,
    "students:submissions:addNotes": false,
    "schools": false,
    "schools:profile": false,
    "schools:periods": false,
    "schools:settings": false,
    "reports": false,
  });
  const [{ data: createData, error: errorData }, createGroup] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/groups`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  );
  const [{ data: updateData, error: updateError }, updateGroup] = useAxios(
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  );
  const [, deleteGroup] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/groups/${group ? group.id : ''}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true,
    }
  );

  const handleChangeOption = option => {
    setOptions(opt => ({
      ...opt,
      [option]: !opt[option]
    }))
  }

  const handleUpdate = e => {
    e.preventDefault();
    updateGroup({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/groups/${group.id}`,
      params: {
        name: name,
        permissions: options
      }
    })
  }

  const handleAdd = e => {
    e.preventDefault();
    createGroup({
      params: {
        name: name,
        permissions: options
      }
    })
  }

  const handleDelete = e => {
    e.preventDefault();
    deleteGroup();
  }

  useEffect(() => {
    if ((createData && !errorData) || (updateData && !updateError)) {
      reFetch();
      onClose();
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (errorData && errorData.response && errorData.response.data && errorData.response.data.message) {
      AlertService.showError(errorData.response.data.message)
    } if (updateError && updateError.response && updateError.response.data && updateError.response.data.message) {
      AlertService.showError(updateError.response.data.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, errorData, updateData, updateError])

  useEffect(() => {
    if (group) {
      setName(group.name);
      setOptions({
        ...group.permissions
      })
    }
  }, [group])


  const renderCheckRow = (title, description, id) => (
    <div className="row mb-1">
      <div className="w-20 px-3">
        <Checkbox
          checked={options[id]}
          onCheck={() => handleChangeOption(id)}
          labelStyle="fw-300"
        />
      </div>
      <div className="col pl-0">
        <span className="font-weight-bold fs-13 m-0">{title}</span><br />
        <span className="fs-12 m-0">{description}</span>
      </div>
    </div>
  );

  return (
    <Modal inputCSS={css`
        .w-540px {
          width: 540px;
        }
        .h-80 {
          height: 80%;
        }
      `} width="w-540px" display={display} setDisplay={onClose} height="h-80">
      <CardHeader
        title={t('PERMISSIONS_GROUP')}
        rightComponent={(
          <button onClick={() => onClose()} className='btn border-0 p-0 fs-18'>
            <i className='fa fa-close'></i>
          </button>
        )}
      />
      <div className="card-body pt-1 overflow-auto">
        {false ? (
          <Spinner />
        ) : (
            <form
              className='form-type-material'
            >
              <div className={`form-group ${name ? 'do-float' : ''}`}>
                <input
                  type='text'
                  name='groupName'
                  id='groupName'
                  className='form-control w-50'
                  onChange={(val) => setName(val.target.value)}
                  value={name}
                />
                <label htmlFor='groupName' className='require'>
                  {t('GROUP_NAME')}
                </label>
              </div>
              <div className="col bg-light py-2">
                {/* STUDENTS */}
                {renderCheckRow(t('MANAGE_STUDENTS'), t('MANAGE_STUDENTS_DESCRIPTION'), 'students')}
                <div className="pl-25">
                  {renderCheckRow(t('MANAGE_PROFILES'), t('MANAGE_STUDENTS_PROFILES_DESCRIPTION'), 'students:profile')}
                  {renderCheckRow(t('MANAGE_LOCK_FIELDS'), t('MANAGE_LOCK_FIELDS_DESCRIPTION'), 'students:lockFields')}
                </div>

                {/*STUDENTSSUBMISSION  */}
                {renderCheckRow(t('MANAGE_STUDENTS_SUBMISSION'), t('MANAGE_STUDENTS_SUBMISSION_DESCRIPTION'), 'students:submissions')}
                <div className="pl-25">
                  {renderCheckRow(t('MANAGE_MANUAL_APPROVAL'), t('MANAGE_MANUAL_APPROVAL_DESCRIPTION'), 'students:submissions:manualApproval')}
                  {renderCheckRow(t('MANAGE_ADD_REMOVE_SUPPLEMTENT'), t('MANAGE_ADD_REMOVE_SUPPLEMTENT_DESCRIPTION'), 'students:submissions:manageWaiverSupplements')}
                  {renderCheckRow(t('MANAGE_ADD_SHIP'), t('MANAGE_ADD_SHIP_DESCRIPTION'), 'students:submissions:addShipEnrollment')}
                  {renderCheckRow(t('MANAGE_COVERAGE_DATES'), t('MANAGE_COVERAGE_DATES_DESCRIPTION'), 'students:submissions:coverageDates')}
                  {renderCheckRow(t('MANAGE_NOTES'), t('MANAGE_NOTES_DESCRIPTION'), 'students:submissions:addNotes')}
                  {renderCheckRow(t('MANAGE_MANUAL_BILLING'), t('MANAGE_MANUAL_BILLING_DESCRIPTION'), 'students:submissions:manualBilling')}
                </div>

                {/* SCHOOLS */}
                {renderCheckRow(t('MANAGE_SCHOOLS'), t('MANAGE_SCHOOLS_DESCRIPTION'), 'schools')}
                <div className="pl-25">
                  {renderCheckRow(t('MANAGE_PROFILE'), t('MANAGE_PROFILE_DESCRIPTION'), 'schools:profile')}
                  {renderCheckRow(t('MANAGE_ENROLLMENTS_PERIODS'), t('MANAGE_ENROLLMENTS_PERIODS_DESCRIPTION'), 'schools:periods')}
                  {renderCheckRow(t('MANAGE_SETTINGS'), t('MANAGE_SETTINGS_DESCRIPTION'), 'schools:settings')}
                </div>
                {/* REPORTS */}
                {renderCheckRow(t('ACCESS_REPORT'), t('ACCESS_REPORT_DESCRIPTION'), 'reports')}
              </div>
              <div className='d-flex mt-4'>
                {group && (
                  <button onClick={handleDelete} className='btn border-0 text-fadest fs-22 p-0'>
                    <i className="fa fa-trash"></i>
                  </button>
                )}
                <button
                  css={css`
                    grid-column: 2/3;
                  `}
                  className="btn btn-bold d-block ml-auto btn-primary btn-label"
                  onClick={group ? handleUpdate : handleAdd}
                >
                  <span>
                    <i className='ti-check'></i>
                  </span>
                  {group ? t('UPDATE') : t('ADD')}
                </button>
              </div>
            </form>
          )}
      </div>
    </Modal>
  )
}

export default EditGroupModal;
