/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../services/AlertService';

import Preloader from '../Preloader/index';

const ExportsTab = ({ setPeriod, importData, setSelectedExport }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();

  const [key, setKey] = useState();

  const [highlightedDates, setHighlightedDates] = useState([]);
  const [{ data, loading }, executeGet] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/exports`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );
  //POST PREVIEW
  const [{ data: updateData, error: updatError, loading: updateLoading }, updatePreview] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/exports/${importData.selectedExport ? importData.selectedExport.id : ""}/preview`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    },
    data: {
      idSchool: importData.schoolId,
      idFile: importData.selectedExport ? importData.selectedExport.id : ""
    }
  },
    { manual: true }
  );
  const [{ loading: downloadLoading }, download] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/exports`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );

  useEffect(() => {
    executeGet();
  }, []);
  const handlePreview = async data => {
    let response = await updatePreview();
    //status code : Okay
    if (response.status == 200) {
      AlertService.showSuccess(response.data.message);
      setSelectedExport(selectedExport=>({...selectedExport,  submitted: true}));
    } else {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }
  useEffect(() => {
    if (data) {
      const period = [];
      Object.keys(data).forEach((element) => {
        period.push([element, data[element][0].enrollment_period_segment_id]);
      });
      setPeriod(period);
      const result = [];
      const objectKey = importData.selectedPeriod
        ? Object.keys(data)[importData.selectedPeriod]
        : Object.keys(data)[0];
      setKey(objectKey);
      data[objectKey].forEach((element) => {
        result.push(new Date(element.created_at));
      });
      setSelectedDate(new Date(data[objectKey][0].created_at));
      setSelectedExport(data[objectKey][0]);
      setHighlightedDates(result);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const result = [];
      const objectKey = Object.keys(data)[importData.selectedPeriod];
      setKey(objectKey);
      data[objectKey].forEach((element) => {
        result.push(new Date(element.created_at));
      });
      setSelectedDate(new Date(data[objectKey][0].created_at));
      setSelectedExport(data[objectKey][0]);
      setHighlightedDates(result);
    }
  }, [importData.selectedPeriod]);

  const handleSelectDate = useCallback(
    (date) => {
      const searchDate = moment(date).format('YYYY-MM-DD');
      const selectDate = data[key].find((element) =>
        element.created_at.includes(searchDate)
      );
      setSelectedDate(date);
      setSelectedExport(selectDate);
    },
    [data, key]
  );

  const ColorCard = useCallback(
    ({ className, amout, name }) => (
      <div
        css={css`
          height: 90px;
          width: 90px;
        `}
        className={`text-center pt-4 ${className}`}
      >
        <p className="text-white fw-700 fs-18 lh-12">{amout}</p>
        <p className="text-white fw-700 fs-11 lh-12">{name}</p>
      </div>
    ),
    []
  );
  return (
    <div
      css={css`
        border: solid 1px rgba(151, 151, 151, 0.25);
      `}
      className="card-body"
    >
      {(downloadLoading || loading) && (
        <Preloader customStyle={{ backgroundColor: 'transparent' }} />
      )}
      {importData.selectedExport && (
        <div className="d-flex justify-content-between form-type-material">
          <div
            className="col-3 text-left"
            css={css`
            .react-datepicker-popper {
              z-index: 995;
            }
          `}
          >
            <label for='datepicker-exp' className='m-0 d-block l-datepicker'>{t('DATE')}</label>
            <DatePicker
              selected={selectedDate}
              onChange={handleSelectDate}
              includeDates={highlightedDates}
              className="form-control"
              placeholderText="Pick a Date"
              id='datepicker-exp'
            />
          </div>
          <div className="col-9 text-right">
            <button
              type="button"
              onClick={() =>
                download({
                  url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/exports/${importData.selectedExport.id}`
                }).then((_data) => window.open(`${_data.data}`))
              }
              className="ml-10 btn btn-bold d-inline-block btn-primary btn-label">
              <span><i className="fa fa-eye" /></span>
              {t('VIEW')}
            </button>
            <button
              type="button"
              onClick={() =>
                download({
                  url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${importData.schoolId}/exports/${importData.selectedExport.id}`
                }).then((__data) => window.open(`${__data.data}&download=1`))
              }
              className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
            ><span><i className="fa fa-download" /></span>
              {t('DOWNLOAD')}
            </button>
            {importData.isPreview && !importData.selectedExport.submitted ?
              <button
                type="button"
                className="ml-10 btn btn-bold d-inline-block btn-primary btn-label"
                onClick={handlePreview}
              ><span><i className="fa fa-paper-plane-o" /></span>
                {t('SEND_FILE')}
              </button>
              :
              ""}

          </div>
        </div>
      )}


      {importData.selectedExport && (
        <div>
          <div className="row my-3">
            <div className="col">
              <label className="fs-13 fw-700">{t('WAIVER')}</label>
              <div
                className="d-flex"
                css={css`
                  .bg-dark-blue {
                    background-color: #2650b2;
                  }
                  .bg-dark-purple {
                    background-color: #926bde;
                  }
                  .bg-pink {
                    background-color: #f96197 !important;
                  }
                `}
              >
                <ColorCard
                  className="mr-1 bg-warning"
                  name={t('SUMMARY_CODE_PR')}
                  amout={importData.selectedExport.process_report.PR}
                />
                <ColorCard
                  className="mr-1 bg-warning"
                  name={t('SUMMARY_CODE_PA')}
                  amout={importData.selectedExport.process_report.PA}
                />
                <ColorCard
                  className="mr-1 bg-warning"
                  name={t('SUMMARY_CODE_PE')}
                  amout={importData.selectedExport.process_report.PE}
                />
                <ColorCard
                  className="mr-1 bg-success"
                  name={t('SUMMARY_CODE_AP')}
                  amout={importData.selectedExport.process_report.AP}
                />
                <ColorCard
                  className="mr-1 bg-danger"
                  name={t('SUMMARY_CODE_CW')}
                  amout={importData.selectedExport.process_report.CW}
                />
                <ColorCard
                  className="mr-1 bg-dark-purple"
                  name={t('SUMMARY_CODE_DE')}
                  amout={importData.selectedExport.process_report.DE}
                />
                <ColorCard
                  className="mr-1 bg-dark-blue"
                  name={t('SUMMARY_CODE_SP')}
                  amout={importData.selectedExport.process_report.SP}
                />
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-8">
              <label className="fs-13 fw-700">{t('SHIP_ENROLLMENT')}</label>
              <div className="d-flex">
                <ColorCard
                  className="mr-1 bg-success"
                  name={t('SUMMARY_CODE_OI')}
                  amout={importData.selectedExport.process_report.OI}
                />
                <ColorCard
                  className="mr-1 bg-info"
                  name={t('SUMMARY_CODE_DF')}
                  amout={importData.selectedExport.process_report.DF}
                />
                <ColorCard
                  className="mr-1 bg-danger"
                  name={t('SUMMARY_CODE_CE')}
                  amout={importData.selectedExport.process_report.CE}
                />
                <ColorCard
                  className="mr-1 bg-pink"
                  name={t('SUMMARY_CODE_MB')}
                  amout={importData.selectedExport.process_report.MB}
                />
              </div>
            </div>
            <div className="col">
              <label className="fs-13 fw-700">{t('GENERAL')}</label>
              <div
                className="d-flex"
                css={css`
                  .bg-dark-grey {
                    background-color: #979797;
                  }
                  .bg-strong-grey {
                    background-color: #626a79;
                  }
                `}
              >
                <ColorCard
                  className="mr-1 bg-strong-grey"
                  name={t('SUMMARY_CODE_DP')}
                  amout={importData.selectedExport.process_report.DP}
                />
                <ColorCard
                  className="mr-1 bg-dark-grey"
                  name={t('SUMMARY_CODE_NR')}
                  amout={importData.selectedExport.process_report.NR}
                />

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExportsTab;
