import { gql } from '@apollo/client';

// Queries
export const studentsManualBilling = gql`
  query studentsManualBilling($periodId: uuid, $offset: Int, $limit: Int) {
    students_submissions(
      where: {
        manual_billing: { _eq: true }
        submission_type: { _eq: "SHIP Enrollment" }
        enrollment_period_segment_id: { _eq: $periodId }
      }
      order_by: { student_id: asc }
      limit: $limit
      offset: $offset
    ) {
      student {
        first_name
        last_name
        student_id
        email_address
      }
    }
    students_submissions_aggregate(
      where: {
        manual_billing: { _eq: true }
        submission_type: { _eq: "SHIP Enrollment" }
        enrollment_period_segment_id: { _eq: $periodId }
      }
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

export const enrollment_periods_segments = gql`
  query enrollment_periods_segments($schoolId: uuid, $startDate: date) {
    enrollment_periods_segments(
      order_by: { coverage_start_date: desc }
      where: {
        _and: {
          school_id: { _eq: $schoolId }
          coverage_start_date: { _lt: $startDate }
        }
      }
    ) {
      id
      enrollment_period_id
      sequence
      segment_label
      enrollment_period {
        academic_year
      }
    }
  }
`;

export const studentsEligibilityLocked = gql`
  query studentsEligibilityLocked($schoolId: uuid, $offset: Int, $limit: Int) {
    students(
      where: {
        _and: {
          school_id: { _eq: $schoolId }
          lock_field_config: { _has_key: "eligibility" }
        }
      }
      order_by: { student_id: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      first_name
      last_name
      student_id
      is_inactive
    }
    students_aggregate(
      where: {
        _and: {
          school_id: { _eq: $schoolId }
          lock_field_config: { _has_key: "eligibility" }
        }
      }
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

export const studentsEligibilityLockedSySegment = gql`
  query studentsEligibilityLockedSySegment(
    $where: students_eligibility_by_enrollment_period_bool_exp
    $offset: Int
    $limit: Int
  ) {
    students_eligibility_by_enrollment_period(
      where: $where
      order_by: { student_id: asc }
      limit: $limit
      offset: $offset
    ) {
      school_id
      enrollment_period_id
      segment_1
      segment_2
      segment_3
      segment_4
      student {
        id
        first_name
        last_name
        student_id
      }
    }
    students_eligibility_by_enrollment_period_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
  }
`;
