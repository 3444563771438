import React, { useState, useContext } from 'react'
import useAxios from 'axios-hooks'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { StyledMain } from '../../styles/common/main'
import Pagination from '../Pagination'
import AddUserCard from './AddUserCard'
import { SearchStateContext } from '../../context/SearchContext'
import Spinner from '../Spinner'
import ManageAdminRolesModal from './Permissions/ManageAdminRolesModal'
import PermissionModal from './Permissions/PermissionModal.js';


const UsersContent = () => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null)
  const { debouncedSearchKeyword } = useContext(SearchStateContext)
  const [manageAdminRolesModal, setManageAdminRolesModal] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const [{ data, loading }, executeGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/users`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
    params: {
      'filter[query]': debouncedSearchKeyword,
    },
  })

  const onPageChange = selectedPage => {
    executeGet({
      params: { page: selectedPage, 'filter[query]': debouncedSearchKeyword },
    })
  }

  const onAdd = () => {
    executeGet()
    setSelectedUser(null)
  }

  const handleClosePermissionModal = () => {
    setShowPermissionModal(false);
    setSelectedUser(null);
  }

  return (
    <StyledMain className='container row'>
      <div className='col-md-8'>
        <div className='card'>
          {loading ? (
            <Spinner />
          ) : (
            <table className='table table-striped table-lg table-hover'>
              <tbody>
                {data &&
                  data.data.map(user => {
                    return (
                      <tr key={user.id} onClick={() => setSelectedUser(user)}>
                        <td>
                          <span className='fs-20'>
                            {user.first_name} {user.last_name}
                          </span>
                          <div>
                            <span className="badge badge-sm badge-secondary mr-1 mb-1" >{user.type == 'super_admin' ? 'Super Admin' : 'Admin'}</span>
                            {user.admin_roles.map(tag => (
                              <span className="badge badge-sm badge-primary mr-1 mb-1" key={tag.id}>{tag.name}</span>)
                            )}
                          </div>
                        </td>
                        <td>
                          <div className='media'>
                            <div className='media-body text-right'>
                              <p>{user.email_address}</p>
                              <small
                                className={`ml-auto ${user.is_active
                                  ? 'text-success'
                                  : 'text-danger'
                                  }`}
                              >
                                {!user.is_active && 'Not'} Verified
                              </small>
                            </div>
                            {user.type != 'super_admin' && (
                              <button
                                css={css`
                                  background-color: transparent;
                                `}
                                onClick={() => setShowPermissionModal(true)} className='text-fadest border-0 p-0'>
                                <i className='fa fa-user-plus'></i>
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}
        </div>
        {data && (
          <Pagination
            pages={data.last_page}
            onPageChange={onPageChange}
            from={data.from}
            to={data.to}
            total={data.total}
          />
        )}
      </div>
      <div className='col-md-4'>
        <AddUserCard onAdd={onAdd} selectedUser={selectedUser} executeGet={executeGet} />
        <div className='d-flex'>
          <button
            css={css`
              grid-column: 2/3;
            `}
            className="btn btn-bold d-block ml-auto btn-warning btn-label"
            onClick={() => setManageAdminRolesModal(true)}
          >
            <span>
              <i className='fa fa-users'></i>
            </span>
            {t('MANAGE_ROLES')}
          </button>
        </div>
      </div>
      {selectedUser && (
        <PermissionModal
          display={showPermissionModal}
          setDisplay={handleClosePermissionModal}
          userId={selectedUser && selectedUser.id}
          user={selectedUser}
          reFetch={executeGet}
        />
      )}

      <ManageAdminRolesModal
        display={manageAdminRolesModal}
        setDisplay={setManageAdminRolesModal}
        userId={selectedUser && selectedUser.id}
      />

    </StyledMain>
  )
}

export default UsersContent
