import React, { memo, useEffect, useState } from 'react';
import Modal from '../Modal/Modal';
import { useForm } from 'react-hook-form';
import CardHeader from '../CardHeader';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import ReactHtmlParser from 'react-html-parser';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from "html-to-draftjs";
import draftToHtmlPuri from "draftjs-to-html";

import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const Text = ({ content, id, updateElement, openModal, setOpenModal }) => {
  const [message, setMessage] = useState(content ? content : '');
  const { t } = useTranslation();

  useEffect(() => {
    updateElement(id, message);
  }, [message]);
  return (
    <Wrapper className=''>
      {message && (
        <div onClick={() => setOpenModal(true)} className="w-100">
          {ReactHtmlParser(message)}
        </div>
      )}
      {message === '' && (
        <Wrapper onClick={() => setOpenModal(true)}>
          {t('CLICK_TO_EDIT')}
        </Wrapper>
      )}
      <TextAreaModal
        message={message}
        display={openModal}
        setMessage={setMessage}
        setDisplay={setOpenModal}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 95px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextAreaModal = memo(({ display, setDisplay, message, setMessage }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: message
  });
  //textAreaValue
  const [convertedContent, setConvertedContent] = useState(message);
  // valueCounter - used to show the number of characters of the textarea value
  // const [valueCounter, setValueCounter] = useState(message);
  //EditorValue
  const [editorState, setEditorState] = useState(() => {
    // Initialize editor state with content.
    let contentState = ContentState.createFromBlockArray(convertFromHTML(`<div>${message}.</div>`));
    return contentState = EditorState.createWithContent(contentState);
  });
  function setPrevEditorState(previousString) {
    let html = htmlToDraft(previousString);
    return setEditorState(EditorState.createWithContent(
      ContentState.createFromBlockArray(html)));
  }
  useEffect((e) => {
    //Get live content of the editor state and copy in the textarea value.
    let html = draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()));
    //Set the previous value in the textarea (no visual change).
    if (html === "<p>.</p>\n" || html ==="<p></p>") {
      setConvertedContent(message);
      if (message !== "" || html ==="<p></p>") {
        setPrevEditorState(message);
      }
    } else {
      setConvertedContent(html);
      // setValueCounter(valCounter.length);
    }
  });
  const onFormSubmit = (data) => {
    if (convertedContent) {
      setMessage(convertedContent);
      setDisplay(false);
      reset(data);
    }

  };

  return (
    <Modal
      inputCSS={css`
        .w-600px {
          width: 600px;
          .note-insert {
            button:nth-of-type(2) {
              display: none !important;
            }
            button:nth-of-type(3) {
              display: none !important;
            }
          }
          .note-view {
            display: none !important;
          }
          .note-table {
            display: none !important;
          }
        }
      `}
      width="w-600px"
      targetId="textEdit"
      display={display}
      setDisplay={setDisplay}
    >
      <CardHeader
        title={'Edit text'}
        rightComponent={
          <button
            onClick={() => setDisplay(false)}
            className="btn border-0 p-0 fs-18"
          >
            <i className="fa fa-close"></i>
          </button>
        }
      />

      <div className="card-body" >
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="form-type-material"
          id="formText"
        >
          <textarea
            disabled={true}
            style={{ display: 'none' }}
            name="textarea"
            id="textarea"
            ref={register}
            value={convertedContent}
            maxLength={9999999}
          />
          {
          convertedContent !== undefined 
          && (
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName={`w_textarea`}
              editorClassName={`e_textarea`}
              toolbarClassName={`t_textarea`}
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
                },

              }} />)}
          <br />
          <button
            className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label`}
            type="submit"
          >
            <label>
              <i className="ti-check"></i>
            </label>
            {t('SAVE')}
          </button>
        </form>

      </div>
    </Modal>
  );
});
