import React, { useState, useContext } from 'react'
import useAxios from 'axios-hooks'
import { StyledMain } from '../../styles/common/main'
import Pagination from '../Pagination'
import AddContactCard from './AddContactCard'
import { SearchStateContext } from '../../context/SearchContext'
import Spinner from '../Spinner'

const ContactsTab = ({ schoolId: id }) => {
  const [selectedContact, setSelectedContact] = useState(null)
  const { debouncedSearchKeyword } = useContext(SearchStateContext)
  const [{ data, loading }, executeGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${id}/contacts`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
    params: {
      'filter[query]': debouncedSearchKeyword,
    },
  })

  const onPageChange = selectedPage => {
    executeGet({
      params: { page: selectedPage, 'filter[query]': debouncedSearchKeyword },
    })
  }

  const onAdd = () => {
    executeGet()
    setSelectedContact(null)
  }

  return (
    <StyledMain className='container row'>
      <div className='col-md-8'>
        <div className='card'>
          {loading ? (
            <Spinner />
          ) : (
            <table className='table table-striped table-lg table-hover'>
              <tbody>
                {data &&
                  data.data.map(contact => {
                    return (
                      <tr
                        key={contact.id}
                        onClick={() => setSelectedContact(contact)}
                      >
                        <td>
                          <span className='fs-20'>
                            {contact.first_name} {contact.last_name}
                          </span>
                        </td>
                        <td>
                          <div className='media'>
                            <div className='media-body text-right'>
                              <p>{contact.email_address}</p>
                              <small>{contact.phone}</small>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          )}
        </div>
        {data && (
          <Pagination
            pages={data.last_page}
            onPageChange={onPageChange}
            from={data.from}
            to={data.to}
            total={data.total}
          />
        )}
      </div>
      <div className='col-md-4'>
        <AddContactCard
          onAdd={onAdd}
          schoolId={id}
          selectedContact={selectedContact}
        />
      </div>
    </StyledMain>
  )
}

export default ContactsTab
