/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';

// Components
import StatusCard from '../StatusCard';
import SummaryDetailModal from '../SummaryDetailModal/index';

const ActivitySummary = ({ summary }) => {
  const { t } = useTranslation();
  const [option, setOption] = useState('ACTIVE');
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleSelect = (item) => {
    setSelected(item);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelected(null);
    setShowModal(false);
  };
  return (
    <>
      {selected && (
        <SummaryDetailModal
          display={showModal}
          setDisplay={handleCloseModal}
          id={summary.ID}
          code={selected}
          active={
            !!(
              option === 'ACTIVE' ||
              option === 'ROLLOVER' ||
              option === 'TOTAL_ACTIVE'
            )
          }
          rollover={
            option === 'TOTAL_ACTIVE'
              ? null
              : !!(option === 'ROLLOVER' || option === 'ROLLOVER_INACTIVE')
          }
          option={option}
        />
      )}
      <div className="card border border-light">
        <div className="card-header py-2">
          <div className="row w-100 justify-content-between">
            <h5 className="card-title fw-500">
              <span>
                <strong className="mr-2">{summary.SCHOOL_NAME}</strong>{' '}
                {summary.ACADEMIC_YEAR} {summary.SEGMENT_TITLE} -{' '}
                {t('ACTIVITY_SUMMARY')}
              </span>
            </h5>
            <ul className="m-0">
              <li className="dropdown d-block">
                <span
                  className="btn border-0 p-0 fw-500 text-black bg-transparent"
                  data-toggle="dropdown"
                >
                  <span>{t(option)}</span> <i className="fa fa-chevron-down" />
                </span>
                <div
                  className="dropdown-menu open-top-right dropdown-menu-right"
                  x-placement="bottom-end"
                  style={{
                    position: 'absolute',
                    top: '65px',
                    left: '-120px',
                    willChange: 'top, left'
                  }}
                >
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('ACTIVE')}
                  >
                    {t('ACTIVE')}
                  </span>
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('INACTIVE')}
                  >
                    {t('INACTIVE')}
                  </span>
                  {summary.ROLLOVER && (
                    <span
                      role="button"
                      tabIndex="0"
                      className="dropdown-item btn border-0"
                      onClick={() => setOption('ROLLOVER')}
                    >
                      {t('ROLLOVER')}
                    </span>
                  )}
                  {summary.ROLLOVER_INACTIVE && (
                    <span
                      role="button"
                      tabIndex="0"
                      className="dropdown-item btn border-0"
                      onClick={() => setOption('ROLLOVER_INACTIVE')}
                    >
                      {t('ROLLOVER_INACTIVE')}
                    </span>
                  )}
                  <span
                    role="button"
                    tabIndex="0"
                    className="dropdown-item btn border-0"
                    onClick={() => setOption('TOTAL_ACTIVE')}
                  >
                    {t('TOTAL_ACTIVE')}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          style={{
            backgroundColor: `${
              option === 'INACTIVE' || option === 'ROLLOVER_INACTIVE'
                ? '#EDEBEB'
                : '#ffffff'
            }`
          }}
          className="card-body"
        >
          <div className="row">
            {/* NO ACTION */}
            <div className="col-md-2">
              <span
                css={css`
                  color: #4d5259;
                  font-weight: 500;
                  font-family: Roboto;
                  font-size: 14px;
                `}
              >
                {t('NO_ACTION')}
              </span>
              <div className="row no-gutters">
                <div className="mr-1 mb-1">
                  <StatusCard
                    status="NO_ACTION"
                    title={summary[option].NO_ACTION || 0}
                    description={t('SUMMARY_CODE_NO_ACTION')}
                    titleStyle="fs-18 mb-0"
                    onClick={() => handleSelect('NR')}
                  />
                </div>
                {summary[option].VOLUNTARY !== false && (
                  <div className="mr-1 mb-1">
                    <StatusCard
                      status="VOLUNTARY"
                      title={summary[option].VOLUNTARY || 0}
                      description={t('SUMMARY_CODE_VOLUNTARY')}
                      titleStyle="fs-18 mb-0"
                    />
                  </div>
                )}
              </div>
            </div>
            {/* WAIVER SUMMARY */}
            <div className="col-md-6 p-md-0">
              <span
                css={css`
                  color: #4d5259;
                  font-weight: 500;
                  font-family: Roboto;
                  font-size: 14px;
                `}
              >
                {t('WAIVER_SUMMARY')}
              </span>
              <div className="row no-gutters">
                {Object.keys(summary[option]['Waiver Application']).map(
                  (key) => {
                    if (summary[option]['Waiver Application'][key] !== false) {
                      let code = key;
                      if (key === 'SUPPLEMENTS') {
                        code = 'SP';
                      }
                      return (
                        <div className="mr-1 mb-1" key={key}>
                          <StatusCard
                            status={key}
                            title={summary[option]['Waiver Application'][key]}
                            description={t(`SUMMARY_CODE_${key}`)}
                            titleStyle="fs-18 mb-0"
                            onClick={
                              code !== 'TOTAL'
                                ? () => handleSelect(code)
                                : () => handleSelect('TW')
                            }
                          />
                        </div>
                      );
                    }
                    return null;
                  }
                )}
              </div>
            </div>
            {/* SHIP SUMMARY */}
            <div className="col-md-4">
              <span
                css={css`
                  color: #4d5259;
                  font-weight: 500;
                  font-family: Roboto;
                  font-size: 14px;
                `}
              >
                {t('SHIP_SUMMARY')}
              </span>
              <div className="row no-gutters">
                {Object.keys(summary[option]['SHIP Enrollment']).map((key) => (
                  <div className="mr-1 mb-1" key={key}>
                    <StatusCard
                      status={key}
                      title={summary[option]['SHIP Enrollment'][key]}
                      description={t(`SUMMARY_CODE_${key}`)}
                      titleStyle="fs-18 mb-0"
                      onClick={
                        key !== 'TOTAL'
                          ? () => handleSelect(key)
                          : () => handleSelect('TE')
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivitySummary;
