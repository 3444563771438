import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { StudentStateContext } from '../../../context/StudentContext';
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';

// Components
import Modal from '../../Modal/Modal';
import CardHeader from '../../CardHeader';
import { AlertService } from '../../../services/AlertService';

const EligibilityModal = ({
  display,
  setDisplay,
  studentPeriod,
  field,
  refetchPeriods
}) => {
  const { t } = useTranslation();
  const [student, setStudent] = React.useContext(StudentStateContext);
  const [elegibilityStatus, setElegibilityStatus] = React.useState(false);

  // Axios
  const [{ data, error, loading }, changeEligibility] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${student.id}/eligibility`,
    method: 'POST',
    manual: true
  });

  const handleChangeEligibility = React.useCallback(
    async (e) => {
      e.preventDefault();
      let status =
        studentPeriod.studentsEligibility[field] === undefined ||
        studentPeriod.studentsEligibility[field] === null
          ? elegibilityStatus
          : !studentPeriod.studentsEligibility[field];

      e.preventDefault();
      let segmentNbr = 0;
      switch (field) {
        case 'segment_1':
          segmentNbr = 1;
          break;
        case 'segment_2':
          segmentNbr = 2;
          break;
        case 'segment_3':
          segmentNbr = 3;
          break;
        case 'segment_3':
          segmentNbr = 3;
          break;
      }

      await changeEligibility({
        params: {
          isEligible: status ? 1 : 0,
          periodId: studentPeriod.studentsEligibility.enrollment_period_id,
          segment: segmentNbr
        }
      }).then((resp) => {
        AlertService.showSuccess(t('SAVE_SUCCESS'));
        setDisplay(false);
        refetchPeriods();
        setStudent((s) => ({ ...s, is_inactive: resp.data.is_inactive }));
      });

      //AlertService.showSuccess(t('SAVE_SUCCESS'));
      //setDisplay(false);
      //refetchPeriods();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [studentPeriod, field, elegibilityStatus]
  );

  return (
    <Modal
      inputCSS={css`
        .w-500px {
          width: 500px;
        }
      `}
      width="w-500px"
      targetId="lock-fields-modal"
      display={display}
      setDisplay={setDisplay}
    >
      <CardHeader
        title={t('ELIGIBILITY_REQUIREMENT')}
        rightComponent={
          <button
            className="btn border-0 p-0"
            onClick={() => setDisplay(false)}
          >
            <i className="fa fa-close"></i>
          </button>
        }
      />
      <div className="card-body">
        {studentPeriod.studentsEligibility[field] !== undefined &&
        studentPeriod.studentsEligibility[field] !== null ? (
          <p>
            {!studentPeriod.studentsEligibility[field]
              ? t('TURN_ON_ELIGIBILITY')
              : t('TURN_OFF_ELIGIBILITY')}
          </p>
        ) : (
          <div className="d-flex">
            <p>{t('TURN_ON_OFF_ELIGIBILITY')}</p>
            <div className="d-flex">
              <label className="switch ml-4">
                <input
                  type="checkbox"
                  checked={elegibilityStatus}
                  name="elegibilityStatus"
                  id="elegibilityStatus"
                  onChange={(e) => {
                    setElegibilityStatus(!elegibilityStatus);
                  }}
                />
                <span className="switch-indicator" />
              </label>
              <span className="ml-2">
                {elegibilityStatus ? t('ON') : t('OFF')}
              </span>
            </div>
          </div>
        )}
        <div className="d-flex">
          <button
            className={`btn btn-bold d-block text-white ml-auto btn-label ${
              !studentPeriod.studentsEligibility[field]
                ? 'bg-success'
                : 'bg-danger'
            }`}
            onClick={(e) => handleChangeEligibility(e)}
          >
            <span>
              {studentPeriod.studentsEligibility[field] === undefined ||
              studentPeriod.studentsEligibility[field] === null ? (
                <i className="ti-check"></i>
              ) : !studentPeriod.studentsEligibility[field] ? (
                <i className="ti-check"></i>
              ) : (
                <i className="fa fa-times"></i>
              )}
            </span>
            {studentPeriod.studentsEligibility[field] === undefined ||
            studentPeriod.studentsEligibility[field] === null
              ? t('CONFIRM')
              : !studentPeriod.studentsEligibility[field]
              ? t('CONFIRM')
              : t('REMOVE')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EligibilityModal;
