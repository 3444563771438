import React, { useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CardHeader from '../../CardHeader/index';
import { SchoolStateContext } from '../../../context/SchoolContext';
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios';
import Preloader from '../../Preloader/index';
import { AlertService } from '../../../services/AlertService';

const ShipFileCard = ({ segment, files, title, handleOpenModal, refetch }) => {
  const { t } = useTranslation();
  const [schoolId] = useContext(SchoolStateContext);
  const [{ response, loading, error }, deleteFile] = useAuthorizedAxios({
    method: 'DELETE',
    manual: true
  });
  const handleDeleteFile = useCallback((file) => {
    deleteFile({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${schoolId}/segments/${segment.id}/files/${file.id}`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response && !error) {
      refetch();
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    }

    if (error) {
      if (Array.isArray(error.response.data.message)) {
        AlertService.showError(error.response.data.message[0]);
      } else if (
        !Array.isArray(error.response.data.message) &&
        error.response.data.message
      ) {
        AlertService.showError(error.response.data.message);
      } else {
        AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, error]);

  return (
    <div className="card w-500px mr-4">
      <CardHeader bold={title} light={t('SEMESTER')} />
      {loading && (
        <Preloader customStyle={{ backgroundColor: 'transparent' }} />
      )}
      <div className="card-body">
        {files && files.length > 0 ? (
          files.map((file, index) => (
            <p key={index}>
              <i
                className={`mr-3 text-muted fs-18 fa fa-file-${
                  file.extension === 'pdf' ? 'pdf' : 'photo'
                }-o`}
              ></i>
              <span>{file.name}</span>
              <a
                href={file.cdn_url}
                target="_blank"
                rel="noreferrer noopener"
                className="btn border-0 bg-transparent text-default px-2"
              >
                <i className="fa fa-download"></i>
              </a>
              <button
                onClick={() => handleDeleteFile(file)}
                className="btn border-0 px-2"
              >
                <i className="fa fa-trash"></i>
              </button>
            </p>
          ))
        ) : (
          <p>{t('NO_FILES_ATTACHED')}</p>
        )}
        <div className="d-flex mt-2">
          <button
            onClick={() => handleOpenModal(segment)}
            className="btn btn-bold d-block ml-auto btn-primary btn-label"
          >
            <span>
              <i className="ti-check"></i>
            </span>
            {t('ADD')}
          </button>
        </div>
      </div>
    </div>
  );
};
export default ShipFileCard;
