import React from 'react';

import AttachmentsFileCard from './AttachmentsFileCard';
import UploadFileCard from './UploadFileCard';

const AttachmentsTab = ({ submission, refetch, setUpdatedData }) => {

  return (
    <div>
      <AttachmentsFileCard files={submission.files} submissionId={submission.id}/>
      <UploadFileCard submission={submission} refetch={refetch} setUpdatedData={setUpdatedData}/>

    </div>
  )
}

export default AttachmentsTab
