import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SearchStateContext } from '../../context/SearchContext';
import { AlertService } from '../../services/AlertService';

// Components
import CardHeader from '../CardHeader';
import Preloader from '../Preloader/index';

// Queries
import { schools_data_exchange } from '../../data/schoolSettings/query';
// Mutations
import {
  update_schools_data_export,
  update_schools_data_import
} from '../../data/schoolSettings/mutations';

const ImportExportCard = ({ schoolId, activeTab }) => {
  const { setWithSearch } = useContext(SearchStateContext);
  const { t } = useTranslation();
  const [isServiceActive, setIsServiceActive] = useState({
    import: true,
    export: true
  });
  const [idSchoolExchange, setIdSchoolExchange] = useState();
  const [
    dataExchageQuery,
    { loading: loadingData, data: dataExchange }
  ] = useLazyQuery(schools_data_exchange, {
    fetchPolicy: 'network-only'
  });

  const [updateImportMutation] = useMutation(update_schools_data_import, {
    onCompleted: () => AlertService.showSuccess(t('SAVE_SUCCESS'))
  });
  const [updateExportMutation] = useMutation(update_schools_data_export, {
    onCompleted: () => AlertService.showSuccess(t('SAVE_SUCCESS'))
  });

  useEffect(() => {
    if (schoolId) {
      dataExchageQuery({
        variables: {
          schoolId
        }
      });
    }
    setWithSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (dataExchange) {
      // eslint-disable-next-line array-callback-return
      dataExchange.schools_data_exchange.map((data) => {
        setIdSchoolExchange(data.id);
        setIsServiceActive({
          ...isServiceActive,
          import: data.is_import_active,
          export: data.is_export_active
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataExchange]);

  const handleDataChange = () => {
    if (activeTab === 'import') {
      setIsServiceActive({
        ...isServiceActive,
        import: !isServiceActive.import
      });
    } else {
      setIsServiceActive({
        ...isServiceActive,
        export: !isServiceActive.export
      });
    }
  };

  const submitDataExchange = (e) => {
    e.preventDefault();
    if (activeTab === 'import') {
      updateImportMutation({
        variables: {
          id: idSchoolExchange,
          value: isServiceActive.import
        }
      });
    } else {
      updateExportMutation({
        variables: {
          id: idSchoolExchange,
          value: isServiceActive.export
        }
      });
    }
  };

  return (
    <div className="card card-shadowed mx-auto" style={{ maxWidth: '100%' }}>
      {activeTab === 'import' ? (
        <CardHeader title={t('IMPORT_SERVICES')} />
      ) : (
        <CardHeader title={t('EXPORT_SERVICES')} />
      )}
      <div
        css={css`
          border: solid 1px rgba(151, 151, 151, 0.25);
        `}
        className="card-body"
      >
        {loadingData && (
          <Preloader customStyle={{ backgroundColor: 'transparent' }} />
        )}
        {dataExchange && (
          <div
            css={css`
              .bg-dark-purple {
                background-color: #926bde;
              }
              .bg-dark-grey {
                background-color: #939eaf;
              }
            `}
            className="d-flex my-3"
          >
            <form
              onSubmit={submitDataExchange}
              className="form-type-material"
              css={css`
                width: 100%;
              `}
            >
              <div className="row">
                <div
                  className="col"
                  css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                  `}
                >
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={
                        activeTab === 'import'
                          ? isServiceActive.import
                          : isServiceActive.export
                      }
                      name="enforceUserSessionTimeout"
                      id="enforceUserSessionTimeout"
                      onChange={handleDataChange}
                    />
                    <span className="switch-indicator" />
                  </label>
                  {activeTab === 'import' && (
                    <span className="ml-10">
                      {isServiceActive.import ? t('ENABLED') : t('DISABLED')}
                    </span>
                  )}
                  {activeTab === 'export' && (
                    <span className="ml-10">
                      {isServiceActive.export ? t('ENABLED') : t('DISABLED')}
                    </span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button
                    disabled={false}
                    className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
                    type="submit"
                  >
                    <span>
                      <i className="ti-check" />
                    </span>
                    {t('UPDATE')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportExportCard;
