import React from 'react';

const RowCard = ({ children, shadowed }) => {
  return (
    <div className={`card ${shadowed ? 'card-shadowed' : ''}`}>
      <div className="card-body row py-2">{children}</div>
    </div>
  );
};

export default RowCard;
