import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks';

import PreAuth from '../components/PreAuth'

const ConfirmAccount = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' })
  const queryParams = new URLSearchParams(history.location.search)
  const [{ loading, error, response }, confirm] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/auth/confirm`,
      method: 'POST',
    },
    {
      manual: true,
    },
  )

  const handleConfirmAccount = async data => {
    const [emailAddress, code] = [
      queryParams.get('emailAddress'),
      queryParams.get('code'),
    ]
    const [password, confirmPassword] = [
      data.createPassword,
      data.confirmPassword,
    ]

    if (emailAddress && code && password === confirmPassword) {
      confirm({
        params: {
          emailAddress,
          code,
          password,
        }
      })
    }
  }

  useEffect(() => {
    if (!loading && !error && response) {
      history.push('/')
    }

  }, [loading, error, response, history])

  return (
    <PreAuth>
      <div
        className='card card-shadowed w-350px mx-auto'
        style={{ maxWidth: '100%' }}
      >
        <div className='card-header'>
          <h5 className='card-title fw-500'>{t('CONFIRM_ACCOUNT')}</h5>
        </div>
        <div className='card-body'>
          <form
            onSubmit={handleSubmit(handleConfirmAccount)}
            className='form-type-material'
          >
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                name='createPassword'
                id='createPassword'
                ref={register({ required: true })}
              />
              <label label={t('CREATE_PASSWORD')} htmlFor='createPassword'>{t('CREATE_PASSWORD')}</label>
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                name='confirmPassword'
                id='confirmPassword'
                ref={register({ required: true })}
              />
              <label label={t('CONFIRM_PASSWORD')} htmlFor='confirmPassword'>{t('CONFIRM_PASSWORD')}</label>
            </div>

            <br />
            <button
              className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label ${
                formState.isValid ? '' : 'disabled'
                }`}
              type='submit'
            >
              <span>
                <i className='ti-check'></i>
              </span>
              {t('SUBMIT')}
            </button>
          </form>
        </div>
      </div>
    </PreAuth>
  )
}

export default ConfirmAccount
