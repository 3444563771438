import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import arrayMove from 'array-move';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { SchoolStateContext } from '../../context/SchoolContext';
//Components
import { Card } from './Card';
import { itemTypes } from '../../utils/emailTemplateTypes';
import { EmptyCard } from './EmptyCard';
import LabeledInput from '../LabeledInput';
import { Footer } from '../StaticEmailLayout/Footer';
import { Header } from '../StaticEmailLayout/Header';
import { Body } from '../StaticEmailLayout/Body';
import Spinner from '../Spinner';
import Modal from '../Modal/Modal';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { conditionalOptions } from '../../utils/conditionalOptions';

export const CardContainer = ({
  onDrop,
  cards,
  setCards,
  lastDroppedColor,
  handleAddItem,
  viewport,
  templateName,
  setTemplateName,
  loading
}) => {
  const [{ isOver, typeDrop, canDrop }, drop] = useDrop({
    accept: Object.values(itemTypes),
    drop(item) {
      onDrop(item.type);
      return undefined;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      typeDrop: monitor.getItemType()
    })
  });
  const [schoolId] = useContext(SchoolStateContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedConditional, setSelectedConditional] = useState({
    value: 0,
    label: 'No condition'
  });
  const opacity = isOver ? 0.7 : 1;
  const { t } = useTranslation();


  const removeElement = useCallback((id) => {
    const filtered = cards.filter((item) => item.id !== id);
    setCards(filtered);
  });

  const updateElement = useCallback((id, content) => {
    const newItems = cards.map((item) =>
      item.id === id ? { ...item, content: content } : item
    );
    setCards(newItems);
  });

  const onSortEnd = useCallback((dragIndex, hoverIndex) => {
    setCards((cards) => arrayMove(cards, dragIndex, hoverIndex));
  });

  const divideElement = useCallback((id) => {
    let copiedCards = [...cards];
    copiedCards.map((item) => {
      if (item.id == id) {
        item.half ? (item.half = !item.half) : (item.half = true);
      }
    });
    setCards(copiedCards);
  });

  const openConditionModal = (id) => {
    setSelectedItem(id);
    setModalOpen(!modalOpen);
  };

  const handleAddCondition = () => {
    let copiedCards = [...cards];
    copiedCards.map((item) => {
      if (item.id == selectedItem) {
        item.conditional = selectedConditional.value;
      }
    });
    setCards(copiedCards);
    setModalOpen(false);
  };

  const renderCard = (card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        removeElement={removeElement}
        id={card.id}
        content={card.content}
        type={card.type}
        moveCard={onSortEnd}
        updateElement={updateElement}
        handleAddItem={handleAddItem}
        handleDivideItem={divideElement}
        openConditionModal={openConditionModal}
        half={card.half}
        conditional={card.conditional}
      />
    );
  };

  const getDefaultConditional = () => {
    let conditionalVal = conditionalOptions[0];
    cards.map((item) => {
      if (item.id == selectedItem && item.conditional) {
        conditionalVal = conditionalOptions.filter(
          (option) => (option.value == item.conditional)
        )[0];
      }
    });
    return conditionalVal;
  };

  return (
    <BlockWhiteBackground className={viewport}>
      <Modal
        targetId="add-waiver-modal"
        display={modalOpen}
        setDisplay={setModalOpen}
        overflow={'overflow-visible'}
      >
        <div style={{ minHeight: '225px', padding: '2rem' }}>
          <p className="text-center" style={{ fontSize: '1.25rem' }}>
            {t(`SELECT_CONDITION`)}
          </p>
          <Select
            className="basic-single"
            isSearchable={true}
            classNamePrefix="select"
            menuPlacement="bottom"
            onChange={(e) => {
              setSelectedConditional(e);
            }}
            defaultValue={getDefaultConditional()}
            options={conditionalOptions}
          />
          <button
            className={`btn btn-bold w-auto ml-auto mr-auto btn-block btn-primary btn-label mt-30`}
            type="submit"
            onClick={() => handleAddCondition()}
          >
            <label>
              <i className="ti-check"></i>
            </label>
            {t('ADD')}
          </button>
        </div>
      </Modal>
      {schoolId && (
        <div className="card-body bg-white form-type-material">
          <div className="col-12">
            <div className="input-group">
              <LabeledInput
                defaultValue={templateName}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
                className="form-control bg-transparent"
                label={'Template name'}
              />
              <i
                css={css`
                  font-size: 18px;
                `}
                className="ti-pencil-alt"
              ></i>
            </div>
          </div>
        </div>
      )}
      <WhiteBackground>
        <CardContainerWrapper className={viewport} ref={drop} opacity={opacity}>
          <Header />
          <Body>
            {loading && <Spinner />}
            {
              //lastDroppedColor &&
              !loading &&
                cards.length > 0 &&
                cards.map((card, i) => renderCard(card, i))
            }
            {canDrop && <AbleToDrop />}
            <EmptyCard />
          </Body>
          <Footer />
        </CardContainerWrapper>
      </WhiteBackground>
    </BlockWhiteBackground>
  );
};

const CardContainerWrapper = styled.div`
  border: 1px solid white;
  background-color: white;
  padding: 0.5rem;
  //text-align: center;
  overflow-y: scroll;
  ${({ opacity }) => `opacity: ${opacity}`};
  transition: all 0.3s ease-in;
`;

const WhiteBackground = styled.div`
  min-height: 730px;
  min-width: 756px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .mobile {
    width: 320px;
    height: 568px;
  }

  .tablet {
    width: 600px;
    max-height: 768px;
  }

  .desktop {
    width: 1200px;
    max-height: 768px;
  }
`;

const AbleToDrop = styled.div`
  background-color: #33cabb33;
  //height: 97px;
  border: dashed 1px #33cabb;
`;

const BlockWhiteBackground = styled.div`
  border: 1px solid white;
  min-height: 730px;
  min-width: 756px;
  background: white;
  display: block;
  justify-content: center;
  align-items: center;
  padding: 0px;

  .mobile {
    width: 320px;
    height: 568px;
  }

  .tablet {
    width: 600px;
    max-height: 768px;
  }

  .desktop {
    width: 1200px;
    max-height: 768px;
  }
`;
