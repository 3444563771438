import { gql } from '@apollo/client';

// Queries
export const suggested_data = gql`
  query suggested_data($where: students_submissions_bool_exp) {
    students_submissions(where: $where, order_by: { student_id: asc }) {
      provider_name
      student {
        student_id
        first_name
        last_name
      }
    }
  }
`;

export const summary_modal_data = gql`
  query summary_modal_data(
    $where: students_submissions_bool_exp
    $whereAggregate: students_bool_exp
    $offset: Int
    $limit: Int
    $order_by: [students_submissions_order_by!]
  ) {
    students_submissions(
      where: $where
      order_by: $order_by
      limit: $limit
      offset: $offset
    ) {
      files: files_summary
      created_at
      updated_at
      provider_name
      status
      pending_denial_reason
      approval_exception
      approval_exception_annual
      coverage_start_date
      coverage_end_date
      waiver_form_data
      students_submissions_addons {
        id
        type
        is_active
      }
      student {
        student_uuid: id
        student_id
        first_name
        last_name
        email_address
        phone
        student_level
        student_type
      }
      school {
        school_abbr
      }
      enrollment_periods_segment {
        coverage_end_date
        coverage_start_date
      }
      insurance_provider_id
      insurance_provider {
        payer_id
        override_payer_id
      }
    }
    students_aggregate(where: $whereAggregate) {
      aggregate {
        totalCount: count
      }
    }
  }
`;
