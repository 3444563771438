/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { useQuery } from '@apollo/client';
import Modal from '../Modal/Modal';

// Components
import plusButton from '../../img/plus-button.svg';
import xButton from '../../img/x-button.svg';
import AddShipModal from './AddShipModal';
import AddWaiverModal from './AddWaiverModal';
import CardHeader from '../CardHeader';
import Spinner from '../Spinner/index';

// Queries
import { enrollmentPeriodsSegmentsBySchoolPeriod } from '../../data/enrollmentPeriods/query';

const AddSubmissionModal = ({ display, setDisplay, refetch, period }) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState('');
  const [displayAdd, setDisplayAdd] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [data, setData] = useState([]);

  const { loading, data: dataPeriods } = useQuery(
    enrollmentPeriodsSegmentsBySchoolPeriod,
    {
      variables: {
        schoolId: period.school_id,
        enrollment_period_id: period.id
      }
    }
  );

  useEffect(() => {
    if (dataPeriods && dataPeriods.enrollment_periods_segments) {
      const segments = [];
      // eslint-disable-next-line array-callback-return
      dataPeriods.enrollment_periods_segments.map((segment) => {
        const segmentData = {
          academic_year: segment.enrollment_period.academic_year,
          end_date: segment.coverage_end_date,
          id: segment.id,
          is_active: segment.is_active,
          label: segment.segment_label,
          start_date: segment.coverage_start_date
        };
        segments.push(segmentData);
      });
      setData(segments);
    }
  }, [dataPeriods]);

  const renderOption = useCallback(
    (type) => (
      <div
        css={css`
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
          .bg-dark-grey {
            background-color: #d7dadd;
          }
        `}
      >
        <div
          className={`card cursor-pointer ${
            type === selectedType ? 'bg-dark-grey' : 'bg-light'
          }`}
          onClick={() => setSelectedType(type)}
        >
          <div className="d-flex">
            <img
              src={type === 'ship' ? plusButton : xButton}
              alt="add icon"
              height={80}
              width={80}
            />
            <p className="pt-30">
              {t(type === 'ship' ? 'CREATE_SHIP' : 'CREATE_WAIVER')}
            </p>
          </div>
        </div>
      </div>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [selectedType]
  );

  useEffect(() => {
    if (!display && data) {
      setSelectedType('');
      setSelectedSegment(null);
      /* const segmentIndex = data.findIndex((item) => item.is_active);
      setSelectedSegment(segmentIndex < 0 ? 0 : segmentIndex); */
    }
  }, [display, data]);

  const handleOpenModals = useCallback(() => {
    setDisplayAdd(true);
  }, []);

  const handleSelectSegment = useCallback((e) => {
    setSelectedSegment(e.target.value);
  }, []);

  const handleOnCreated = useCallback(() => {
    refetch();
    setDisplay(false);
    setDisplayAdd(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const renderAddModal = useCallback(() => {
    if (selectedType === 'ship') {
      return (
        <AddShipModal
          display={displayAdd}
          setDisplay={setDisplayAdd}
          segment={data[selectedSegment]}
          onCreated={handleOnCreated}
        />
      );
    }
    if (selectedType === 'waiver') {
      return (
        <AddWaiverModal
          display={displayAdd}
          setDisplay={setDisplayAdd}
          segment={data[selectedSegment]}
          onCreated={handleOnCreated}
        />
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedType, displayAdd, selectedSegment]);

  return (
    <>
      {displayAdd ? (
        renderAddModal()
      ) : (
        <Modal
          width="w-350px"
          targetId="lock-fields-modal"
          display={display}
          setDisplay={setDisplay}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <CardHeader
                title={t('ADD_SUBMISSION')}
                rightComponent={
                  <button
                    type="button"
                    className="btn border-0 p-0"
                    onClick={() => setDisplay(false)}
                  >
                    <i className="fa fa-close" />
                  </button>
                }
              />
              <div className="card-body">
                <div className="d-flex justify-content-end text-right mb-2">
                  <div className="form-type-material w-75">
                    <select
                      name="period"
                      id="period"
                      className="form-control"
                      data-provide="selectpicker"
                      value={selectedSegment || ''}
                      onChange={handleSelectSegment}
                    >
                      <option key={null} value={null} className="d-none">
                        {null}
                      </option>
                      {data.map((_period, index) => (
                        <option
                          key={_period.id}
                          value={index}
                        >{`${_period.label} ${_period.academic_year}`}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {renderOption('ship')}
                {renderOption('waiver')}

                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    disabled={selectedType === '' || !selectedSegment}
                    className="btn btn-bold d-block btn-primary btn-label"
                    onClick={handleOpenModals}
                  >
                    <span>
                      <i className="ti-check" />
                    </span>
                    {t('CREATE')}
                  </button>
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default AddSubmissionModal;
