import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthorizedAxios } from '../../../hooks/use-authorizedaxios'
import moment from 'moment'
import Pagination from '../../Pagination'
import Spinner from '../../Spinner'
const InsuranceChangesTab = ({ submissionId }) => {
  const perPage = 10;
  const { t } = useTranslation()
  const [{ data, loading }, getLogs] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/logs`,
    method: 'GET',
    params: {
      'filter[log_entry_type]': 'Insurance Information Changed'
    },
    manual: true,
  })

  useEffect(() => {
    getLogs({
      params: {
        perPage,
        page: 1,
        'filter[log_entry_type]': 'Insurance Information Changed'
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePageChange = async selectedPage => {
    await getLogs({
      params: {
        perPage,
        page: selectedPage,
        'filter[log_entry_type]': 'Insurance Information Changed'
      }
    })
  }

  return (
    <div className="card border border-light">
      <div className="card-body">
        {loading ? (
          <Spinner />
        ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>{t('PAYER_ID')}</th>
                  <th>{t('INSURANCE_COMPANY')}</th>
                  <th>{t('MEMBER')} #</th>
                  <th>{t('GROUP')} #</th>
                  <th>{t('CUSTOMER_SERVICE')} #</th>
                  <th>{t('UPDATED')} #</th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.data.map((log) => {
                    if (log.waiver_data) {
                      return (
                        <tr key={log.id}>
                          <td>{log.waiver_data.payerId}</td>
                          <td>{log.waiver_data.insuranceCompanyName}</td>
                          <td>{log.waiver_data.insuranceMemberNumber}</td>
                          <td>{log.waiver_data.insuranceGroupNumber}</td>
                          <td>{log.waiver_data.insurancePhoneNumber}</td>
                          <td>{moment(log.updated_at).format('MM/DD/YYYY hh:mm A')}</td>
                        </tr>
                      )
                    } else return null
                  })
                }
              </tbody>
            </table>
          )}
        {data && (
          <Pagination
            pages={data.last_page}
            onPageChange={handlePageChange}
            {...data}
          />
        )}
      </div>
    </div>
  )
}

export default InsuranceChangesTab;
