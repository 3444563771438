import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import useAxios from 'axios-hooks';

import CardHeader from '../../CardHeader/index';
import Preloader from '../../Preloader/index';

const AttachmentsFileCard = React.memo(({ submissionId, files }) => {
  const { t } = useTranslation();
  const [{ loading }, executeGet] = useAxios(
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
    {
      manual: true,
    }
  );

  const frontFile = useMemo(() => {
    if (files && files.length > 0) {
      return files.find(file => file.document_type === 'CARD_FRONT') || null
    }
    return null
  }, [files]);

  const backFile = useMemo(() => {
    if (files && files.length > 0) {
      return files.find(file => file.document_type === 'CARD_BACK') || null
    }
    return null
  }, [files]);

  const letterFile = useMemo(() => {
    if (files && files.length > 0) {
      return files.find(file => file.document_type === 'LETTER') || null
    }
    return null
  }, [files]);

  const policyFile = useMemo(() => {
    if (files && files.length > 0) {
      return files.find(file => file.document_type === 'POLICY') || null
    }
    return null
  }, [files]);

  const renderFileCard = useCallback((file, footer, download) => (
    <div>
      <div className="d-flex justify-content-center">
        <div
          css={css`
        width: 180px;
        height: 160px;
        border-radius: 4px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px #d7dadd;
        cursor: pointer;
        padding: 40px 0;
        `}
          className="text-center"
          onClick={() => download({ url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/waivers/files/${file.id}` }).then(data => window.open(data.data))}
        >
          {file.file_extension === 'pdf'
            ? <i className="fa fa-file-pdf-o fs-30"></i>
            : <i className="fa fa-file-image-o fs-30"></i>}
        </div>
      </div>
      <p className="text-center mb-0">{footer}
        <button
          onClick={() => download({ url: `${process.env.REACT_APP_BASE_URL}api/v1/submissions/${submissionId}/waivers/files/${file.id}` }).then(data => window.open(`${data.data}&download=1`))}
          className='btn border-0 px-2 bg-transparent'
        >
          <i className='fa fa-download'></i>
        </button>
      </p>
    </div>
  ), [submissionId])


  return (
    <div className="card border border-light h-100">
      {loading && <Preloader customStyle={{ backgroundColor: 'transparent' }} />}
      <CardHeader title={t('FILE_ATTACHMENTS')} />
      <div className="card-body">
        {files && files.length > 0
          ? (
            <div className="row">
              <div className="col-md-3">
                {frontFile && (
                  renderFileCard(frontFile, t('FRONT_OF_CARD'), executeGet)
                )}
              </div>
              <div className="col-md-3">
                {backFile && (
                  renderFileCard(backFile, t('BACK_OF_CARD'), executeGet)
                )}
              </div>
              <div className="col-md-3">
                {letterFile && (
                  renderFileCard(letterFile, t('LETTER_OF_ELIGIBILITY'), executeGet)
                )}
              </div>
              <div className="col-md-3">
                {policyFile && (
                  renderFileCard(policyFile, t('POLICY_DOCUMENTS'), executeGet)
                )}
              </div>
            </div>
          )
          : (
            <p>{t('NO_FILES_ATTACHED')}</p>
          )}

      </div>
    </div>

  )
});

export default AttachmentsFileCard;


