import styled from '@emotion/styled';

export const CardWrapper = styled.div`
  ${({ opacity }) => `opacity: ${opacity};`}
  align-items: center;
  display: ${(props) => (props.half ? 'inline-flex' : 'flex')};
  vertical-align: ${(props) => (props.half ? 'inherit' : '')};
  width: ${(props) => (props.half ? '50%' : '100%')};
  padding-left: ${(props) => (props.half ? '10px' : '')};
  padding-right: ${(props) => (props.half ? '10px' : '')};
  box-sizing: border-box;
  user-select: none;
  color: #333;
  //min-height: 97px;
  height: auto;
  justify-content: center;
  border: solid 1px transparent;

  &:hover {
    border: solid 1px #33cabb;
  }

  ${({ isSelected }) => isSelected && `border: solid 1px #33cabb;`};

  > div {
    position: relative;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const MoveIcon = styled.div`
  position: absolute;
  right: -13px;
  top: 35%;
  cursor: move;
`;

export const ClickToEdit = styled.div`
  left: ${(props) => (props.half ? 'calc(100% - 78px);' : 'calc(100% - 88px);')};
  position: absolute;
  top: 100%;
  z-index: 1;
  p {
    color: white;
    width: 89px;
    height: 23px;
    margin: 0px;
    background-color: #33cabb;
  }
`;

export const ConditionButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: calc(100% - 2px);
  left: ${(props) => (props.half ? 'calc(100% - 84px);' : 'calc(100% - 94px);')};
  z-index: 1;
`;

export const DividerButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: calc(100% - 2px);
  left: ${(props) => (props.half ? 'calc(100% - 59px);' : 'calc(100% - 69px);')};
  z-index: 1;
`;

export const Delete = styled.div`
  position: absolute;
  cursor: pointer;
  top: calc(100% - 2px);
  left: ${(props) => (props.half ? 'calc(100% - 34px);' : 'calc(100% - 44px);')};
  z-index: 1;
`;

export const Duplicate = styled.div`
  position: absolute;
  cursor: pointer;
  top: calc(100% - 2px);
  left: calc(100% - 20px);
  right: ${(props) => (props.half ? '-22px;' : '-2px;')};
  z-index: 1;
`;

export const ConditionalBadge = styled.div`
  position: absolute;
  color: white;
  font-size: 0.65rem;
  z-index: 1;
  background-color: #33cabb;
  left: 0;
  top: 0;
  width: fit-content;
  padding: 0px 5px 0px 5px;
`;
