import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import styled from '@emotion/styled';
import { itemTypes } from '../../utils/emailTemplateTypes';

import buttonImg from '../../img/button.svg';
import imageImg from '../../img/image.svg';
import spacerImg from '../../img/spacer.svg';
import textImg from '../../img/text.svg';
import dividerImg from '../../img/divider.svg';

const DraggableWrapper = styled.div`
  width: 145px;
  height: 45px;
  margin: 0.25rem;
  border-radius: 5px;
  background-color: #d7dadd;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  p {
    margin: 0;
    font-family: Roboto;
    color: #38404e;
  }
`;

const SourceWrapper = styled.div`
  /*column-count: 2;
  div {
    &:first-of-type {
      margin-top: 0px !important;
    }
  }*/
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const elementsList = [
  {
    label: 'Image',
    type: itemTypes.IMAGE,
    icon: imageImg
  },
  {
    label: 'Text',
    type: itemTypes.TEXT,
    icon: textImg
  },
  {
    label: 'Divider',
    type: itemTypes.DIVIDER,
    icon: dividerImg
  },
  {
    label: 'Button',
    type: itemTypes.BUTTON,
    icon: buttonImg
  },
  {
    label: 'Spacer',
    type: itemTypes.SPACER,
    icon: spacerImg
  }
];

export const Source = ({ type, icon, label }) => {
  const [forbidDrag, setForbidDrag] = useState(false);
  const [{ isDragging }, drag] = useDrag({
    item: { type: `${type}` },
    canDrag: !forbidDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <DraggableWrapper ref={drag}>
      <img src={icon} alt={`icon-${label}`} />
      <p className="fw-400 fs-15">{label}</p>
    </DraggableWrapper>
  );
};

export const SourceBox = () => {
  return (
    <div style={{ flex: 2, minWidth: '300px' }} className="flexbox mx-20">
      <SourceWrapper className="mt-3">
        {elementsList.map(({ label, type, icon }) => (
          <Source key={label} label={label} type={type} icon={icon} />
        ))}
      </SourceWrapper>
    </div>
  );
};
