import React from 'react'
import background from '../../img/sis_bg_1.jpg'
import logo from '../../img/logo-dark.png'

const PreAuth = ({ children }) => {
  return (
    <div
      className='row min-h-fullscreen center-vh p-20 m-0'
      style={{ background: `url(${background})` }}
    >
      <div className='col-12'>
        <div className='logo mb-70 text-center d-block'>
          <img src={logo} alt='Logo' width="270" className="mb-10" />
          <p>
            <strong>
              Electronic Waiver Application Insurance Verification & Eligibility
              System
            </strong>
          </p>
        </div>
        {children}
      </div>
    </div>
  )
}

export default PreAuth
