import React from 'react'
import useAxios from 'axios-hooks'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Modal from '../Modal/Modal'

const ProfileModal = ({ display, setDisplay }) => {
  const { t } = useTranslation()
  const me = JSON.parse(localStorage.getItem('me') || '')
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: me.first_name,
      lastName: me.last_name,
      email: me.email_address,
      role: me.type === 'super_admin' ? 'Super Admin' : (me.type === 'admin' ? 'Admin' : 'School User'),
    },
  })
  const [{ loading, error }, executePut] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/users/${me.id}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    { manual: true }
  )

  const handleUpdateUser = async data => {
    const [firstName, lastName, emailAddress] = [
      data.firstName,
      data.lastName,
      data.email,
    ]

    await executePut({
      data: {
        firstName,
        lastName,
        emailAddress,
      },
    })

    if (!error && !loading) {
      setDisplay(false)
    }
  }

  return (
    <Modal targetId='profile-modal' display={display} setDisplay={setDisplay}>
      <div className='card-header'>
        <h5 className='card-title fw-500'>{t('ACCOUNT_SETTINGS')}</h5>
        <i className='fa fa-close' onClick={() => setDisplay(false)}></i>
      </div>
      <div className='card-body'>
        <img
          src={me.avatar_url}
          alt='Avatar'
          className='avatar avatar-xxl ml-auto d-block'
        />

        <form
          onSubmit={handleSubmit(handleUpdateUser)}
          className='form-type-material'
        >
          <div className='form-group do-float'>
            <input
              type='text'
              className='form-control'
              name='firstName'
              id='firstName'
              ref={register}
            />
            <label className='label-floated' htmlFor='firstName'>
              {t('FIRST_NAME')}
            </label>
          </div>
          <div className='form-group do-float'>
            <input
              type='text'
              className='form-control'
              name='lastName'
              id='lastName'
              ref={register}
            />
            <label className='label-floated' htmlFor='lastName'>
              {t('LAST_NAME')}
            </label>
          </div>
          <div className='form-group do-float'>
            <input
              type='email'
              className='form-control'
              name='email'
              id='email'
              ref={register}
            />
            <label className='label-floated' htmlFor='email'>
              example@emailaddress.com
            </label>
          </div>
          <div className='form-group do-float'>
            <input
              type='text'
              className='form-control'
              name='role'
              id='role'
              disabled
              ref={register}
            />
            <label className='label-floated' htmlFor='role'>
              {t('ROLE')}
            </label>
          </div>

          <br />
          <button
            className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label ${formState.dirty ? '' : 'disabled'
              }`}
            type='submit'
          >
            <span>
              <i className='ti-check'></i>
            </span>
            {t('UPDATE')}
          </button>
        </form>
      </div>
    </Modal>
  )
}

export default ProfileModal
