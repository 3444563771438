import { AUTH_ONLY } from "./types";
import Landing from "../views/Landing";
import LockScreen from "../views/LockScreen";
import ResetPassword from "../views/ResetPassword";
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import ConfirmAccount from "../views/ConfirmAccount";
import Students from "../views/Students";
import Schools from "../views/Schools";
import School from "../views/School";
import SchoolSettings from "../views/SchoolSettings";
import Student from "../views/Student";
import Users from "../views/Users";
import Preloader from "../components/Preloader";
import EnrollmentPeriod from '../views/EnrollmentPeriod';
import NewEnrollmentPeriod from '../views/NewEnrollmentPeriod';
import Reports from "../views/Reports";
import Providers from '../views/Providers';
import BillingReport from '../views/BillingReport';
import EligibilityReport from '../views/EligibilityReport';
import EmailTemplate from "../views/EmailTemplate";
import EmailTemplateEditor from "../views/EmailTemplateEditor";
import UsersReport from '../views/UsersReport';
import DentalReport from '../views/DentalReport';
import LockedFieldsReport from '../views/LockedFieldsReport';

import DataDumpReport from '../views/DataDumpReport';
export default () => [
  {
    path: "/",
    exact: true,
    component: Landing,
    loading: Preloader,
    error: "Custom error for home page",
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/locked",
    exact: true,
    component: LockScreen
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPassword
  },
  {
    path: "/login",
    exact: true,
    component: Login
  },
  {
    path: "/confirm-account",
    exact: true,
    component: ConfirmAccount
  },
  {
    path: "/students",
    exact: true,
    component: Students,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/students/:id",
    exact: true,
    component: Student,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/schools",
    exact: true,
    component: Schools,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/schools/:id",
    exact: true,
    component: School,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/schools/:id/new-period",
    exact: true,
    component: NewEnrollmentPeriod,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/schools/:id/periods/:periodId",
    exact: true,
    component: EnrollmentPeriod,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/schools/:id/settings",
    exact: true,
    component: SchoolSettings,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/reports",
    exact: true,
    component: Reports,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/reports/billing",
    exact: true,
    component: BillingReport,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/reports/eligibility",
    exact: true,
    component: EligibilityReport,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/reports/users",
    exact: true,
    component: UsersReport,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/reports/dental",
    exact: true,
    component: DentalReport,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/reports/locked-fields",
    exact: true,
    component: LockedFieldsReport,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/reports/data-dump",
    exact: true,
    component: DataDumpReport,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/users",
    exact: true,
    component: Users,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/email-templates",
    exact: true,
    component: EmailTemplate,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/email-templates/:email/:template/:id",
    exact: true,
    component: EmailTemplateEditor,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "/providers",
    exact: true,
    component: Providers,
    meta: {
      [AUTH_ONLY]: true
    }
  },
  {
    path: "*",
    component: NotFound,
    ignoreGlobal: true
  }
];
