/* eslint-disable no-console */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { css } from '@emotion/core';

const StatusCard = ({
  status,
  title,
  description,
  titleStyle = '',
  onClick = null,
  fontFamily = null
}) => {
  let color = 'white';
  let textColor = 'text-white';
  switch (status) {
    case 'PR':
    case 'PA':
    case 'PE':
    case 'NE':
      color = 'warning';
      break;
    case 'DE':
      color = 'dark-purple';
      break;
    case 'DF':
      color = 'info';
      break;
    case 'CE':
    case 'CW':
      color = 'danger';
      break;
    case 'AP':
    case 'OI':
      color = 'success';
      break;
    case 'VOLUNTARY':
      color = 'white';
      break;
    case 'SUPPLEMENTS':
      color = 'dark-blue';
      break;
    case 'NR':
    case 'NO_ACTION':
      color = 'dark-grey';
      break;
    case 'MB':
      color = 'pink';
      break;
    case 'DP':
      color = 'strong-grey';
      break;
    default:
      break;
  }
  if (status === 'TOTAL' || status === 'VOLUNTARY') {
    textColor = 'text-black';
  }

  return (
    <div
      css={css`
        .bg-dark-blue {
          background-color: #2650b2;
        }
        .bg-dark-purple {
          background-color: #926bde;
        }
        .bg-dark-grey {
          background-color: #979797;
        }
        .bg-strong-grey {
          background-color: #626a79;
        }
      `}
    >
      <div
        role="button"
        css={css`
          height: 67px;
          width: 67px;
          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);
          border: solid 1px rgba(151, 151, 151, 0.25);
          background-color: #979797;
          position: relative;
          font-weight: 400;
          ${fontFamily ? `font-family: ${fontFamily};` : ''}
        `}
        className={`${
          onClick && title > 0 ? 'cursor-pointer' : 'cursor-default'
        } py-1 align-center text-center bg-${color} `}
        onClick={title > 0 ? onClick : () => {}}
      >
        <p className={`m-0 w-100 ${textColor} ${titleStyle}`}>{title}</p>
        {description && (
          <p
            className={`m-0 w-100 fs-11 ${textColor}`}
            css={css`
              line-height: 1.18;
              letter-spacing: normal;
              position: absolute;
              bottom: 6px;
              right: 0;
              ${fontFamily ? `font-family: ${fontFamily};` : ''}
            `}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default StatusCard;
