import React from 'react'
import { css } from "@emotion/core";


const AduditorCard = ({ title, amount, onClick }) => {
  return (
    <div
      css={css`
        border: solid 1px rgba(151, 151, 151, 0.25);
        min-width: 250px;
      `}
      className="card card-shadowed h-150px flex-grow-1 cursor-pointer"
    onClick={() => onClick(title)}
    >
      <div className="card-title text-center">
        <p className="fw-700 fs-13 mb-0">{title}</p>
      </div>
      <div className="card-body bg-light">
        <p className="fw-700 fs-35 text-warning text-center">{amount}</p>
      </div>
    </div>
  )
}

export default AduditorCard
