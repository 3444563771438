import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Select, { components } from "react-select";



const names = [
    { value: "firstName", label: "First name" },
    { value: "lastName", label: "Last name" },
    { value: "classification", label: "Classification" },
    { value: "emailAddress", label: "Email" },
    { value: "phone", label: "Phone" },
    { value: "gender", label: "Gender" },
    { value: "dateOfBirth", label: "Date of birth" },
    { value: "streetAddress1", label: "Street address No.1" },
    { value: "streetAddress2", label: "Street address No.2" },
    { value: "city", label: "City" },
    { value: "state", label: "State" },
    { value: "postalCode", label: "Zip code" },
    { value: "alternateId", label: "Alternate ID" }
];
const DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
) => {
    return (
        <components.DropdownIndicator {...props}>
            <i class="fa fa-caret-down"></i>
        </components.DropdownIndicator>
    );
};
const MultiSelectFilter = ({
    multiOptions,
    setMultiOptions

}) => {
    const { t } = useTranslation();
    const [selectOptions, setSelectOptions] = useState("");
    const handleChange = selectedOption => {
        let stringOptions = "";
        if (selectedOption) {
            selectedOption.map((x, i, arr) => {
                stringOptions += arr.length - 1 === i ?
                    x.value : `${x.value},`;
            });
        }
        setSelectOptions(selectedOption);
        setMultiOptions(stringOptions);
    };
    const colourStyles = {
        menuList: styles => ({
            ...styles,
        }),
        control: (base, state) => ({
            ...base,
            boxShadow: "none",
            background: "transparent",
            borderBottom: state.isFocused ? "1px solid rgba(51,202,187)"
                : state.isMulti ? "blue" :
                    "1px solid rgba(0,0,0,0.125)",
            borderColor: "transparent",
            "&:hover": {
                borderColor: "transparent",
                borderBottom: "1px solid rgba(51,202,187)",
            },
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            background: isFocused
                ? '#f9fafb'
                : isSelected
                    ? 'white'
                    : undefined,
            zIndex: 1,
        }),
        menu: base => ({
            ...base,
            background: 'white',
            zIndex: 100,
        }),
    }

    return (
        <div
            className="form-group do-float"
            css={css`
              .form-type-material .bootstrap-select .dropdown-toggle:focus {
                background-color: transparent !important;
              }
            `}
            >
                <>
                    <Select
                        isSearchable={true}
                        components={{ DropdownIndicator, IndicatorSeparator: () => null, }}
                        classNamePrefix="select"
                        menuPlacement="bottom"
                        isMulti
                        name="schoolId"
                        id="schoolId"
                        placeholder={"Nothing selected"}
                        data-provide="selectpicker"
                        value={selectOptions}
                        onChange={handleChange}
                        options={names}
                        styles={colourStyles}
                    />
                    <label label={t('ENROLLMENT_PERIOD')} htmlFor="schoolId" className="label-floated">
                        {t('LOCKED_FIELDS')}
                    </label>
                </>
            </div>
    );
}
export default MultiSelectFilter;