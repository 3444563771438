import React from 'react'
import { useTranslation } from 'react-i18next'
import PageLayout from '../components/PageLayout/PageLayout'
import ReportsContent from '../components/ReportsContent/index';

const Reports = () => {
  const { t } = useTranslation()
  return (

    <PageLayout
      withHeader={true}
      title={t('REPORTS')}
      crumbs={[]}
    >
      <ReportsContent />
    </PageLayout>

  )
}

export default Reports
