import React from 'react'
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core'

import Tag from '../Tag/index';

const ValidatorStatusTag = ({ validatorCurrentStatus, customStyle='' }) => {
  const { t } = useTranslation();
  if (validatorCurrentStatus) {
    let color = 'bg-danger';
    if (validatorCurrentStatus === 'ACTIVE_COVERAGE') {
      color = 'bg-success'
    } else if (validatorCurrentStatus === 'POLICY_NOT_ACTIVE') {
      color = 'bg-yellow'
    }
    return (
      <div css={css`.reduced-letter-spacing{letter-spacing: -.5px}`}>
      <Tag
        title={t(`TAG_${validatorCurrentStatus}`)}
        color={color}
        customStyle={`reduced-letter-spacing ${customStyle}`}
      />
      </div>
    )
  } else return null;


}

export default ValidatorStatusTag
