import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { draggableTypes, itemTypes } from '../../../utils/emailTemplateTypes';
import useClickOutside from '../../../hooks/useClickOutside';
import dragIcon from '../../../img/dragIcon.svg';
import deleteIcon from '../../../img/delete.svg';
import duplicateIcon from '../../../img/duplicate.svg';
import { conditionalOptions } from '../../../utils/conditionalOptions';

import {
  CardWrapper,
  ClickToEdit,
  Delete,
  Duplicate,
  MoveIcon,
  DividerButton,
  ConditionButton,
  ConditionalBadge
} from './styles';

import {
  Button,
  Divider,
  Image,
  Spacer,
  Text
} from '../../DraggableComponents';
import { useTranslation } from 'react-i18next';

export const Card = ({
  id,
  type,
  index,
  content,
  moveCard,
  removeElement,
  updateElement,
  handleAddItem,
  handleDivideItem,
  openConditionModal,
  half,
  conditional
}) => {
  const [isHover, setIsHover] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { ref, isVisible, setIsVisible } = useClickOutside(false);
  const { t } = useTranslation();

  const [, drop] = useDrop({
    accept: draggableTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards

      // Dragging down
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      // Whis will set both items half to false
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: draggableTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    canDrag(monitor) {
      let modals = document.getElementsByClassName('modal-dialog');
      for (let value of modals) {
        if (value.offsetWidth > 0 && value.offsetHeight > 0) return false;
      }
     return !openModal ? true : false;
    }
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const renderComponentType = () => {
    switch (type) {
      case itemTypes.BUTTON:
        return (
          <Button content={content} id={id} updateElement={updateElement} />
        );
      case itemTypes.DIVIDER:
        return (
          <Divider content={content} id={id} updateElement={updateElement} />
        );
      case itemTypes.TEXT:
        return <Text content={content} id={id} updateElement={updateElement} openModal={openModal} setOpenModal={setOpenModal} />;
      case itemTypes.IMAGE:
        return (
          <Image content={content} id={id} updateElement={updateElement} />
        );
      case itemTypes.SPACER:
        return (
          <Spacer content={content} id={id} updateElement={updateElement} />
        );
      default:
        break;
    }
  };

  const getConditionalBadge = () => {
    if (conditional && conditional != 0) {
      return (
        <ConditionalBadge>
          C:
          {
            conditionalOptions.filter(
              (option) => option.value == conditional
            )[0].label
          }
        </ConditionalBadge>
      );
    }
  };

  return (
    <CardWrapper
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => setIsVisible(!isVisible)}
      ref={ref}
      isSelected={isVisible}
      opacity={opacity}
      half={half}
    >
      <div>
        {getConditionalBadge()}
        {renderComponentType()}
        <>
          {(isVisible || isHover) && (
            <MoveIcon ref={drag}>
              <img src={dragIcon} alt="move-icon" />
            </MoveIcon>
          )}
          {isVisible && (
            <>
              <ConditionButton half={half} onClick={() => openConditionModal(id)}>
                <img src={duplicateIcon} alt="condition-icon" />
              </ConditionButton>
              <DividerButton half={half} onClick={() => handleDivideItem(id)}>
                <img src={duplicateIcon} alt="divide-icon" />
              </DividerButton>
              <Delete half={half} onClick={() => removeElement(id)}>
                <img src={deleteIcon} alt="delete-icon" />
              </Delete>
              <Duplicate half={half} onClick={() => handleAddItem(type, content)}>
                <img src={duplicateIcon} alt="duplicate-icon" />
              </Duplicate>
            </>
          )}
          {isHover && !isVisible && (
            <ClickToEdit half={half}>
              <p>{t('CLICK_TO_EDIT')}</p>
            </ClickToEdit>
          )}
        </>
      </div>
    </CardWrapper>
  );
};
