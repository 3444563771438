import React from 'react'
import { useTranslation } from 'react-i18next';

// Components
import PageLayout from '../components/PageLayout/PageLayout';
import EligibilityReportContent from '../components/ReportsContent/EligibilityReportContent';


const EligibilityReport = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      withHeader={true}
      title={t('ELIGIBILITY_LOCKED')}
      crumbs={[
        { title: t('REPORTS'), to: '/reports' },
        { title: t('ELIGIBILITY_LOCKED'), to: '' },
      ]}
    >
      <EligibilityReportContent />
    </PageLayout>
  )
}

export default EligibilityReport
