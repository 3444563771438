import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import moment from 'moment';

import Pagination from '../../Pagination'
import Spinner from '../../Spinner'
import CardHeader from '../../CardHeader/index';
import Filter from '../../Filter/index';

const ActivityLogsTab = ({ studentId }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    'Authentication': false,
    'Eligibility Change': false,
    'Profile Update': false,
    'Lock Fields Update': false,
  });

  const perPage = 10;
  const [{ data, loading }, executeGet] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${studentId}/logs`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
      },
    },
    {
      manual: true
    })

  const onPageChange = selectedPage => {
    const filter = [];
    for (const key of Object.keys(status)) {
      if (status[key]) {
        filter.push(key);
      }
    }
    executeGet({
      params: {
        perPage,
        page: selectedPage,
        'filter[type]': filter.join('|')
      },
    })
  }

  useEffect(() => {
    const filter = [];
    for (const key of Object.keys(status)) {
      if (status[key]) {
        filter.push(key);
      }
    }
    executeGet({
      params: {
        perPage,
        'filter[type]': filter.join('|')
      },
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);


  const filterOptions = [
    {
      label: t('AUTHENTICATION'),
      key: 'Authentication'
    },
    {
      label: t('ELIGIBILITY_CHANGE'),
      key: 'Eligibility Change'
    },
    {
      label: t('PROFILE_UPDATE'),
      key: 'Profile Update'
    },
    {
      label: t('LOCK_FIELDS_UPDATE'),
      key: 'Lock Fields Update'
    }
  ];

  const handleOnSelect = (key) => {
    setStatus(s => ({
      ...s,
      [key]: !s[key]
    }))
  }

  return (
    <>
      <div className='card card-shadowed'>
        <CardHeader
          title={t('ACTIVITY_LOGS')}
          rightComponent={<Filter options={filterOptions} status={status} onSelect={handleOnSelect} />}
        />
        <div className='card-body'>
          {loading ? (
            <Spinner />
          ) : (
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>{t('LOG_TYPE')}</th>
                    <th>{t('ACTION_LOGGED')}</th>
                    <th>{t('USER')}</th>
                    <th>
                      {t('DATE')} / {t('TIME')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.data &&
                    data.data.map(log => {
                      return (
                        <tr key={log.id}>
                          <td width='18%'>{log.activity_type}</td>
                          <td width='50%'>{log.activity_description}</td>
                          <td>{log.first_name} {log.last_name}</td>
                          <td>{moment(log.created_at).format('MM/DD/YYYY hh:mm A')}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
        </div>
      </div>
      {data && (
        <div className='mb-100'>
          <Pagination
            pages={data.last_page}
            onPageChange={onPageChange}
            {...data}
          />
        </div>
      )}
    </>
  )
}

export default ActivityLogsTab
