import Axios from 'axios';
import permissionList from '../../src/data/permissions/permissionList.json';
import { UserService } from './UserService';
export class SchoolService {

  static canSchool = async (ability = 'SCHOOLS', schoolId) => {
    let result = false;
    // if "me" has declared, not call the API.
    const me = window.localStorage.getItem('me');
    const { data: user } = me == null || me.length == 0 ?
      await Axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/auth/me`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
          }
        }
      ) : { data: JSON.parse(me) };
    
    switch (user.type) {
      case 'super_admin':
        return true;
        break;
      case 'admin':
        user.admin_roles.forEach((adminRole) => {
          if (
            adminRole.all_schools ||
            (adminRole.schools && adminRole.schools.indexOf(schoolId) > -1)
          ) {
            if (adminRole.permissions[ability] == true) {
              result = true;
            }
          }
        });
        return result;
        break;
      default:
        return UserService.hasPermission(permissionList[ability]);
        break;
    }
  };
  static rolePermission = async (permission, schoolId, setState) => {
    const initialPromise = await SchoolService.canSchool(permission, schoolId);
    setState(initialPromise);
  };
}
