import React, { useEffect, useState } from 'react'

const Pagination = ({ pages, onPageChange, from, to, total }) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [prevSelectedPage, setPrevSelectedPage] = useState()

  useEffect(() => {
    if (onPageChange) {
      if (!prevSelectedPage) {
        setPrevSelectedPage(selectedPage)
      } else {
        onPageChange(selectedPage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage])

  useEffect(() => {
    if (from === 1)
      setSelectedPage(1)
  }, [from])

  return (
    <nav>
      <ul className='pagination justify-content-end'>
        <li className='align-self-end mr-10'>
          <span>
            {from}-{to} of {total}
          </span>
        </li>
        <li className={`page-item ${selectedPage === 1 ? 'disabled' : ''}`}>
          <button
            onClick={() => setSelectedPage(selectedPage - 1)}
            className='page-link'
          >
            <span className='ti-arrow-left'></span>
          </button>
        </li>
        <li
          className={`page-item ${selectedPage === pages || !pages ? 'disabled' : ''
            }`}
        >
          <button
            onClick={() => setSelectedPage(selectedPage + 1)}
            className='page-link'
          >
            <span className='ti-arrow-right'></span>
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
