import React from 'react'
import PageLayout from '../components/PageLayout/PageLayout'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import Preloader from '../components/Preloader/index';
import { SchoolStateProvider } from '../context/SchoolContext'
import NewEnrollmentContent from '../components/NewEnrollmentContent';

const NewEnrollmentPeriod = props => {
  const { t } = useTranslation()
  const { match } = props
  const [{ data: schoolData, loading: schoolLoading, error: schoolError }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${match.params.id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  },
    {
      useCache: false,
    },
  );

  if (schoolError) {
    return <h1>No school found.</h1>
  }

  if (schoolLoading) {
    return <Preloader />
  }

  return (
    <SchoolStateProvider value={[match.params.id]}>
      <PageLayout
        match={match}
        withHeader={true}
        title={schoolData && schoolData.school_name}
        crumbs={[
          { title: t('SCHOOLS'), to: '/schools' },
          { title: schoolData.school_abbr, to: `/schools/${match.params.id}` },
          { title: t('NEW_ENROLLMENT_PERIOD'), to: '' },
        ]}
      >
        <NewEnrollmentContent />
      </PageLayout>
    </SchoolStateProvider>
  )
}

export default NewEnrollmentPeriod
