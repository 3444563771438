import React from "react";
import { useTranslation } from "react-i18next";
import useAxios from "axios-hooks";
import moment from "moment";
import { css } from "@emotion/core";

import Spinner from "../../Spinner";
import Pagination from '../../Pagination/index';

const TrackingTable = ({ studentId, emailId }) => {
  const { t } = useTranslation();

  const [{ loading, data }, getTracking] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${studentId}/emails/${emailId}/tracking`,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("jwt")}`
    }
  });

  const onPageChange = selectedPage => {
    getTracking({
      params: {
        page: selectedPage
      }
    });
  };

  return (
    <>
      {loading ? (
        <Spinner minHeight={false} />
      ) : (
          <div
            css={css`
              background-color: #f5f6fa;
              border: solid 1px #d7dadd;
            `}
            className="card-body">
            <table className="table-striped table table-hover">
              <thead>
                <tr>
                  <th className="w-75">{t("ACTIVITY_TYPE")}</th>
                  <th>{t("DATE")} / {t("TIME")}</th>
                </tr>
              </thead>
              <tbody>
                {data && data.data.map(tracking => (
                  <tr key={tracking.id}>
                    <td>{t('VIEWED')}</td>
                    <td>{moment(tracking.created_at).format("MM/DD/YYYY hh:mm A")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      {data && (
        <div className="mb-100">
          <Pagination
            pages={data.last_page}
            onPageChange={onPageChange}
            {...data}
          />
        </div>
      )}
    </>
  );
};

export default TrackingTable;
