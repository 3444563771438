import styled from '@emotion/styled';

export const ReferencesWrapper = styled.div`
  flex: 2;
  min-width: 260px;

  .reference-item {
    &:hover {
      background-color: #c9ced55c;
      display: flex;
    }

    p,
    div {
      display: inline;
    }
    img {
      height: 15px;
    }
  }
`;

export const OptionsBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .componentes-list {
    .active {
      border-bottom: 3px solid #33cabb !important;
    }
  }

  .options-footer {
    position: relative;
    .options-wrapper {
      margin-bottom: 28px;
      .close-button {
        background-color: #a8a9ae;
        color: white;
        label {
          background-color: #82868a;
          color: white;
        }
      }
    }
  }
`;
