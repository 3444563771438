import React from 'react'
import IdleTimer from 'react-idle-timer'
import { useHistory } from 'react-router-dom'
import Topbar from '../Topbar/Topbar'
import Header from '../Header/Header'
import { SearchStateProvider } from '../../context/SearchContext'

const PageLayout = ({
  children,
  withHeader,
  crumbs,
  title,
  settings,
  match,
}) => {
  const lockScreenTime =
    JSON.parse(window.localStorage.getItem('me')).lock_screen_time * 1000
  const history = useHistory()

  const handleLock = () => {
    const shouldLock = JSON.parse(window.localStorage.getItem('me')).enforce_lock_screen;
    if (shouldLock) {
      // If I went idle with a modal open, remove backdrop
      const modalBackdrop = document.querySelector('.modal-backdrop')
      if (modalBackdrop) {
        modalBackdrop.remove()
      }

      window.localStorage.setItem('loggedIn', '0')
      history.push(`/locked?from=${history.location.pathname}`)
    }

  }

  return (
    <SearchStateProvider
      value={{
        withSearch: true,
        setWithSearch: undefined,
        debouncedSearchKeyword: '',
        searchKeyword: '',
        setSearchKeyword: undefined,
      }}
    >
      <IdleTimer timeout={lockScreenTime} onIdle={handleLock} />
      <Topbar title={title} settings={settings} crumbs={crumbs} withHeader={withHeader} />
      {withHeader && (
        <Header title={title} settings={settings} crumbs={crumbs} />
      )}
      <main className='main-container'>
        {children}
      </main>
    </SearchStateProvider>
  )
}

export default PageLayout
