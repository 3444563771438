export const itemTypes = {
  IMAGE: 'image',
  TEXT: 'text',
  BUTTON: 'button',
  SPACER: 'spacer',
  DIVIDER: 'divider'
};

export const draggableTypes = {
  CARD: 'card'
};
