import React, { memo, useState } from 'react';
import { SourceBox } from '../SourceBox';
import copy from '../../../img/copy.svg';
import { useTranslation } from 'react-i18next';
import { ViewportChanger } from '../../EmailsPreviewModal/ViewportChanger';
import { ReferencesWrapper, OptionsBoxWrapper } from './styles';

const referenceList = [
  'Student Id',
  'First Name',
  'Last Name',
  'School Name',
  'School Insurance Name',
  'School Insurance Email',
  'Supplement Name',
  'School Health Services Name',
  'School Health Office Contact',
  'School Health Office Email',
  'School Insurance Contact Name',
  'Link to Portal',
  'Coverage Start Date',
  'Tuition Feed Date',
  'Deadline',
  'Insert Denial Code Here',
  'Semester',
  'Year',
  'Coverage End Date',
  'Premium',
  'Reasons'
];
export const OptionsBox = ({
  setViewport,
  viewport,
  handlePreview,
  handleCancel
}) => {
  const { t } = useTranslation();
  const [isComponentsTab, setIsComponentsTab] = useState(true);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
    } catch (err) {
      console.error(err);
    }
  };

  const RenderReferences = memo(() => (
    <ReferencesWrapper className="bg-pale-secondary b-1 m-4 p-2">
      {referenceList.map((ref) => (
        <div className="reference-item" key={ref}>
          <p className="m-0 px-3">{`{${ref}}`}</p>
          <div>
            <img
              className="cursor-pointer"
              onClick={() => copyToClipBoard(`{${ref}}`)}
              src={copy}
              alt="copy-icon"
            />
          </div>
        </div>
      ))}
    </ReferencesWrapper>
  ));

  return (
    <OptionsBoxWrapper className="bg-white w-350px">
      <div className="mx-10">
        <ul className="componentes-list nav-justified bb-1 nav nav-tabs mb-0 border-0">
          <li className="nav-item">
            <span
              onClick={() => setIsComponentsTab(true)}
              className="nav-link cursor-pointer active fw-600 pt-20 pb-1"
              data-toggle="tab"
              role="button"
              href="#active"
            >
              {t('COMPONENTS')}
            </span>
          </li>
          <li className="nav-item">
            <span
              onClick={() => setIsComponentsTab(false)}
              className="nav-link cursor-pointer fw-600 pt-20 pb-1"
              data-toggle="tab"
              href="#no-active"
              role="button"
            >
              {t('REFERENCE_TAGS')}
            </span>
          </li>
        </ul>
      </div>

      {isComponentsTab ? <SourceBox /> : <RenderReferences />}

      <div className="options-footer">
        <div className="options-wrapper mx-20">
          <div className="center-h mb-3">
            <ViewportChanger viewport={viewport} setViewport={setViewport} />
          </div>
          <div className="flexbox">
            <button
              style={{ backgroundColor: '#a8a9ae', color: "#fff" }}
              className="btn btn-bold btn-label"
              onClick={handleCancel}
            >
              <label className="center-v">
                <i className="ti-close" />
              </label>
              {t('CANCEL')}
            </button>
            <button
              className="btn btn-bold btn-info btn-label"
              onClick={handlePreview}
            >
              <label className="center-v fs-15">
                <i className="ti-new-window"></i>
              </label>
              {t('PREVIEW')}
            </button>
          </div>
        </div>
      </div>
    </OptionsBoxWrapper>
  );
};
