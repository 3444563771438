import React, { useState, useEffect, useContext, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { SearchStateContext } from '../../context/SearchContext';
import { CSVLink } from "react-csv";

// Components
import Pagination from '../Pagination/index';
import Preloader from '../Preloader/index';
import SchoolFilter from './SchoolFilter';
import EnrollmentPeriodFilter from './EnrollmentPeriodFilter';
// Queries

// Services
import { UserService } from '../../services/UserService';


const DentalReportContent = () => {
  const { setWithSearch, debouncedSearchKeyword } = useContext(SearchStateContext);
  const [schoolName, setSchoolName] = useState();
  const [dataList, setDataList] = useState();
  const [selectEnrollmentId, setSelectEnrollmentId] = useState('');
  const [selectedSchoolId, setSelectedSchoolId] = useState(UserService.getUser().school_id ? UserService.getUser().school_id : '');
  const [alternateId, setAlternateId] = React.useState("");
  const [shouldDownload, setShouldDownload] = useState(false);
  const [csvName, setCsvName] = useState('');
  const [parsedUsersData, setParsedUsersData] = useState([])
  const refCsvLink = React.useRef(null);

  const { t } = useTranslation();

  // Queries

  const [{ data, loading }, executeGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/reports/students-dental-plan`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
    params: {
      'filter[query]': debouncedSearchKeyword,
    },
  });

  const [{ data: exportData, loading: exportLoading }, executeExportPost] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/reports/students-dental-plan/exports`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
    params: {
      'filter[query]': debouncedSearchKeyword,
    }
  },
    {
      manual: true,
    })
  const handleExport = async data => {
    setShouldDownload(true);
    await executeExportPost({
      params: {
        schoolId: selectedSchoolId,
        segmentId: selectEnrollmentId,
        studentId: alternateId
      },
    });
  }
  const [{ data: schoolData }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  });
  const onPageChange = selectedPage => {
    if (selectEnrollmentId !== "" && selectedSchoolId !== "" && alternateId == "" || alternateId.length >= 4) {
      executeGet({
        params: {
          page: selectedPage,
          schoolId: selectedSchoolId,
          segmentId: selectEnrollmentId,
          studentId: alternateId
        },
      })
    }
  }

  useEffect(() => {
    switch (true) {
      case selectEnrollmentId !== "" && selectedSchoolId !== "" && alternateId == "" || alternateId.length >= 4:
        executeGet({
          params: {
            schoolId: selectedSchoolId,
            segmentId: selectEnrollmentId,
            studentId: alternateId
          },
        });
        break;
      case selectedSchoolId !== "" && alternateId == "" || alternateId.length >= 4:
        executeGet({
          params: {
            schoolId: selectedSchoolId,
            studentId: alternateId
          },
        });
        break;
      default:
        break;
    }
    setDataList(data);
  }, [selectEnrollmentId, selectedSchoolId, alternateId]);

  useEffect(() => {
    if (schoolData) {
      schoolData.forEach(school => {
        if (school.id === selectedSchoolId)
          setSchoolName(school.school_name)
      });
    }
  }, [selectedSchoolId, schoolData]);

  useEffect(() => {
    if (shouldDownload) {

      if (exportData && exportData.length > 0 && refCsvLink.current && refCsvLink.current.link) {
        setParsedUsersData(exportData);
      }
    }
  }, [exportData]);

  useEffect(() => {
    if (shouldDownload && parsedUsersData.length > 0 && schoolName) {
      let dt = new Date();
      let formatDate = dt.getFullYear().toString().padStart(4, '0') + (dt.getMonth() + 1).toString().padStart(2, '0') + dt.getDate().toString().padStart(2, '0') + dt.getHours().toString().padStart(2, '0') + dt.getMinutes().toString().padStart(2, '0') + dt.getSeconds().toString().padStart(2, '0')
      setCsvName(`Dental Report ${schoolName} -${formatDate}.csv`)
    }
  }, [parsedUsersData]);

  useEffect(() => {
    if (shouldDownload) {
      if (parsedUsersData && parsedUsersData.length > 0 && refCsvLink.current && refCsvLink.current.link) {
        refCsvLink.current.link.click();
        setShouldDownload(false);
        setParsedUsersData([])
      }
      setShouldDownload(false);
    }
  }, [csvName]);
  return (
    <div className="container">
      <div className="row form-type-material">
        {
          (loading)
          && <Preloader customStyle={{ backgroundColor: 'transparent' }} />
        }
        {!UserService.getUser().school_id && (
          <div className="col-3">
            <SchoolFilter
              selectedSchoolId={selectedSchoolId}
              setSelectedSchoolId={setSelectedSchoolId}
            />
          </div>
        )}
        {selectedSchoolId && (
          <div className="col-3">
            <EnrollmentPeriodFilter
              selectEnrollmentId={selectEnrollmentId}
              selectedSchoolId={selectedSchoolId}
              setSelectEnrollmentId={setSelectEnrollmentId}
            />
          </div>
        )}
        <div className="col-3">
          <div className="form-group do-float">
            <label htmlFor="altId" className="label-floated">
              {t('STUDENT_ID')}
            </label>
            <input
              type="number"
              name="altId"
              id="altId"
              className="form-control bg-transparent"
              placeholder={t('STUDENT_ID')}
              value={alternateId}
              onChange={(e) => setAlternateId(e.target.value)}
            />

          </div>
        </div>
        <div className="col mb-3">
          <button
             disabled={data && data.data.length == 0 || selectedSchoolId==""}
            onClick={handleExport}
            className="mt-10 btn btn-bold d-block ml-auto btn-primary btn-label"
            type='submit'>
            <span>
              <i className='ti-download'></i>
            </span>
            {t('EXPORT_CSV')}
          </button>
          {data && (
            <CSVLink
              data={parsedUsersData}
              filename={csvName}
              className="hidden"
              target="_blank"
              ref={refCsvLink}
            >
            </CSVLink>
          )}
        </div>
      </div>
      <div className='card card-shadowed'>
        <div className='card-body'>
          <table className='table table-striped table-lg'>
            <thead>
              <tr>
                <th>{t('FIRST_NAME')}</th>
                <th>{t('LAST_NAME')}</th>
                <th>{t('EMAIL')}</th>
                <th>{t('STUDENT_ID')}</th>
              </tr>
            </thead>
            <tbody
              css={css`
                .left-border{
                    border-left: 2px solid #f96868;
                }
                `}
            >
              {data &&
                data.data.map(user => {

                  if (schoolName !== null && schoolName !== undefined) {
                    return (
                      <tr>
                        <td>{user.first_name}</td>
                        <td>{user.last_name}</td>
                        <td>{user.email_address}</td>
                        <td>{user.student_id}</td>
                      </tr>
                    )
                  } else {
                    return '';
                  }

                })}
            </tbody>
          </table>
        </div>
      </div>
      {
        data && (
          <div className='mb-100'>
            <Pagination
              pages={data.last_page}
              onPageChange={onPageChange}
              from={data.from}
              to={data.to}
              total={data.total}
            />
          </div>
        )
      }
    </div>
  )
}

export default DentalReportContent
