import React, { useState, useCallback, useEffect } from 'react'
import useAxios from 'axios-hooks'
import { css } from '@emotion/core'
import { StyledMain } from '../../styles/common/main'
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'
import Spinner from '../Spinner'
import Checkbox from '../Checkbox/index';
import EditProviderModal from '../EditProviderModal/index';
import LabeledInput from '../LabeledInput'


const ProvidersContent = () => {
  const [search, setSearch] = useState('');
  const [filterActive, setFilterActive] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState();
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [displayEditProvider, setDisplayEditProvider] = useState(false);
  const [highlighted, setHighlighted] = useState(false);

  const { t } = useTranslation();
  const [{ data, loading }, executeGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/admin/providers`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
    params: {
      page: 1,
      'filter[is_active]': filterActive ? 1 : 0,
      'filter[query]': search,
    },
  },
    { manual: true });

  useEffect(() => {
    if (data && !loading) {
      setDataToDisplay([...data.data])
    }
  }, [data, loading])

  useEffect(() => {
    executeGet({
      params: {
        page: 1,
        'filter[is_active]': 1,
        'filter[query]': '',
      }
    })
  }, [])

  useEffect(() => {
    executeGet({
      params: {
        page: 1,
        'filter[is_active]': filterActive ? 1 : 0,
        'filter[query]': search,
      }
    })
  }, [filterActive]);

  useEffect(() => {
    if (highlighted) {
      setTimeout(() => {
        setHighlighted(false)
      }, 2000);
    }
  }, [highlighted]);

  const onPageChange = selectedPage => {
    executeGet({
      params: { page: selectedPage, 'filter[query]': search, 'filter[is_active]': filterActive ? 1 : 0, },
    })
  }

  const handleOpenEdit = useCallback((provider) => {
    setSelectedProvider(provider);
    setDisplayEditProvider(true);
  }, []);

  const handleAddProvider = useCallback(() => {
    setSelectedProvider(undefined);
    setDisplayEditProvider(true);
  }, []);

  const handleSearch = useCallback(() => {
    executeGet({
      params: { page: 1, 'filter[query]': search, 'filter[is_active]': filterActive ? 1 : 0, },
    })
  }, [search, filterActive]);

  const handleOnCloseModal = useCallback((updateProvider) => {
    setDisplayEditProvider(false);
    if (selectedProvider) {
      const index = dataToDisplay.findIndex(item => item.id === updateProvider.id);
      const updatedList = [...dataToDisplay];
      // if change the active state remove from list
      if (dataToDisplay[index].is_active !== updateProvider.isActive) {
        const deleted = updatedList.splice(index, 1)
        setDataToDisplay(updatedList);
      } else {
        updatedList[index] = {
          ...updatedList[index],
          name: updateProvider.name,
          override_payer_id: updateProvider.overridePayerId,
          override_npi: updateProvider.overrideNpi,
          default_pr: updateProvider.defaultPr,
          do_not_allow_validation: updateProvider.noValidation,
          pattern_change_rule: updateProvider.patternChangeRule,
        }
        setDataToDisplay(updatedList);
      }
      setHighlighted(true);
    } else {
      executeGet({
        params: { page: 1, 'filter[query]': search, 'filter[is_active]': filterActive ? 1 : 0, },
      })
    }

  }, [search, filterActive, dataToDisplay, selectedProvider]);

  const ProvidersTable = useCallback(() => {
    return (

      <div className='card-body'>
        <table className='table table-striped table-lg'>
          <thead>
            <tr>
              <th>{t('INSURANCE_COMPANY')}</th>
              <th>{t('PAYER_ID')}</th>
              <th>{t('OVERRIDE_PAYER_ID')}</th>
              <th>{t('OVERRIDE_NPI')}</th>
              <th>{t('DEFAULT_PR')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              dataToDisplay.map(provider => {
                return (
                  <tr key={provider.id} className={`${(highlighted && provider.id === selectedProvider.id) ? 'bg-pale-cyan' : ''}`}>
                    <td>{provider.name}</td>
                    <td>{provider.payer_id}</td>
                    <td>{provider.override_payer_id}</td>
                    <td>{provider.override_npi}</td>
                    <td>
                      <Checkbox
                        checked={provider.default_pr}
                      />
                    </td>
                    <td>
                      <button
                        onClick={() => handleOpenEdit(provider)}
                        className='btn btn-bold btn-warning btn-label'
                      >
                        <span>
                          <i className='ti-exchange-vertical'></i>
                        </span>
                        {t('EDIT')}
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )

  }, [data, dataToDisplay, highlighted, selectedProvider])

  return (
    <StyledMain className='container'>
      <button
        css={css`
            margin-top: -40px;
          `}
        className='btn btn-primary btn-float'
        onClick={handleAddProvider}
      >
        <i className='ti-plus'></i>
      </button>
      {displayEditProvider && (
        <EditProviderModal
          display={displayEditProvider}
          setDisplay={setDisplayEditProvider}
          provider={selectedProvider}
          onCloseModal={handleOnCloseModal}
        />
      )}

      <div className='d-flex justify-content-end form-type-material mb-2'>
        <div className='col-md-4 offset-md-8 p-0'>
          <div className="input-group">
            <LabeledInput
              value={search}
              onChange={e => { setSearch(e.target.value) }}
              className="form-control bg-transparent"
              label={t('SEARCH')}
            />
            <span className="input-group-append">
              <button className="btn btn-light" type="button" onClick={handleSearch}>Go!</button>
            </span>
          </div>
        </div>
      </div>
      <div className='card card-shadowed'>
        <div>
          <ul className="nav nav-tabs mb-0 border-0">
            <li className="nav-item">
              <span onClick={() => setFilterActive(true)} className="nav-link cursor-pointer active" data-toggle="tab" tole="button" href="#active">{t('ACTIVE')}</span>
            </li>
            <li className="nav-item">
              <span onClick={() => setFilterActive(false)} className="nav-link cursor-pointer" data-toggle="tab" href="#no-active" role="button">{t('INACTIVE')}</span>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          {loading ?
            <Spinner />
            : (
              <>
                <div className="tab-pane fade active show" id="active">
                  <ProvidersTable />
                </div>
                <div className="tab-pane fade" id="no-active">
                  <ProvidersTable />
                </div>
              </>
            )}
        </div>
      </div>
      {data && (
        <Pagination
          pages={data.last_page}
          onPageChange={onPageChange}
          from={data.from}
          to={data.to}
          total={data.total}
        />
      )}
    </StyledMain>
  )
}

export default ProvidersContent
