import React, { useState, useEffect, useCallback } from 'react';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertService } from '../../services/AlertService';
// Components
import CardHeader from '../CardHeader';
import Preloader from '../Preloader/index';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';


const ServiceCard = ({ URL, indexData,setIsActive }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState();
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [{ data: data,loading: loading }, executeGet] = useAxios(
    {
      url: `${URL}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );
  const [{ data: updateData, error: error,loading: updateLoading }, changePreview] = useAxios({
    url: `${URL}`,
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    },
    data: { preview_mode: indexData.isActive, }
  },
    { manual: true }
  );
  const asyncFetch = async () => {
    try {
      let preview = await executeGet();
      if (preview) {
        setIsActive(preview.data.preview_mode);
      }
    } catch (e) {
      // Handle cancellation
    }
  }
  useEffect(() => {
    asyncFetch();
  }, []);
  useEffect(() => {
    if (updateData && !error && !updateLoading) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (updateData && !updateLoading) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
  }, [updateData, error, updateLoading]);
  return (
    <div className="card card-shadowed mx-auto" style={{ maxWidth: '100%' }}>
      <CardHeader title={indexData.title !== "" ? indexData.title : t('SERVICE')} />
      <div
        css={css`
              border: solid 1px rgba(151, 151, 151, 0.25);
            `}
        className="card-body"
      >
        {loading && (
          <Preloader customStyle={{ backgroundColor: 'transparent' }} />
        )}
        <div
          css={css`
                  .bg-dark-purple {
                    background-color: #926bde;
                  }
                  .bg-dark-grey {
                    background-color: #939eaf;
                  }
                `}
          className="d-block my-3"
        >
          <div className="row">
            <div
              className="col"
              css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                      `}
            >
              <label className="switch">
                <input
                  type="checkbox"
                  checked={indexData.isActive}
                  name="enforceUserSessionTimeout"
                  id="enforceUserSessionTimeout"
                  onChange={() => setIsActive(!indexData.isActive)}
                />
                <span className="switch-indicator" />
              </label>
              <span className="ml-10">
                {indexData.isActive ? t('ENABLED') : t('DISABLED')}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                onClick={changePreview}
                className="mt-20 btn btn-bold d-block ml-auto btn-primary btn-label"
              >
                <span>
                  <i className="ti-check" />
                </span>
                {t('UPDATE')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
