/* eslint-disable no-console */
import React, { useCallback, useState, useEffect, useContext } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import Select, { components } from "react-select";

const SelectFilter = ({
    arrayData,
    Label,
    setValue,
    value,
}) => {
    const { t } = useTranslation();



    useEffect(() => {

    }, []);
    return (
        <div
            className="form-group do-float"
            css={css`
              .form-type-material .bootstrap-select .dropdown-toggle:focus {
                background-color: transparent !important;
              }
            `}
        >
            <select
                type="text"
                name="schoolId"
                id="schoolId"
                className="form-control bg-transparent"
                data-provide="selectpicker"
                onChange={(e) => setValue(e.target.value)}
                placeholder={"Nothing selected"}
                value={value}
            >
                <option value="" disabled>Nothing selected</option>
                {arrayData.map((x) => (<option>{x}</option>))}
            </select>

            <label label={Label} htmlFor="schoolId" className="label-floated">
                {Label}
            </label>
        </div>
    );
};

export default SelectFilter;
