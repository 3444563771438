import React, { useContext, useState, useEffect, useCallback } from 'react';
import { css } from '@emotion/core';
import { StyledMain } from '../../styles/common/main';
import useAxios from 'axios-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { SearchStateContext } from '../../context/SearchContext';
import { UserService } from '../../services/UserService';
//Components
import Pagination from '../Pagination';
import Spinner from '../Spinner';
import AddStudentModal from '../AddStudentModal';
import LabeledInput from '../LabeledInput';
import { SchoolService } from '../../services/SchoolService';

const StudentsContent = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [active, setActive] = useState(true);
  const [inactive, setInactive] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [searchStudentId, setSearchStudentId] = useState('');
  const [search, setSearch] = useState('');
  const [selectedSchoolId, setSelectedSchoolId] = useState('');
  const [count, setCount] = useState(0);
  const { setWithSearch } = useContext(SearchStateContext);
  const [execReset, setExecReset] = useState(false);
  const [studentSettings, setStudentSettings] = useState(false);

  setWithSearch(false);

  const [{ data, loading }, executeGet] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );

  const [{ data: schoolData, loading: schoolLoading }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    }
  });

  useEffect(() => {
    defaultGet(1, 10);
  }, []);

  useEffect(() => {
    if (schoolData) {
      let schoolOptions = [];
      schoolData.forEach((school) =>
        schoolOptions.push({ value: school.id, label: school.school_name })
      );
      SchoolService.canSchool('STUDENTS:PROFILE', schoolOptions.value).then((response) => {
        setStudentSettings(response)
      })
      setSelectOptions(schoolOptions);
      setCount((c) => c + 1);
    }
  }, [schoolData]);

  const handleOpenStudent = (e) => {
    history.push(`/students/${e.currentTarget.dataset.open}`);
  };

  const defaultGet = useCallback(
    (page, perPage) => {
      let filter = 0;
      if (active && inactive) {
        filter = '';
      } else if (active) {
        filter = 0;
      } else if (inactive) {
        filter = 1;
      }
      let query = '';
      if (filter !== '') {
        query = `is_inactive:${filter}`;
      }
      if (searchStudentId) {
        query = query + `|student_id:${searchStudentId}`;
      }

      if (search) {
        query = query + `|demographics:${search}`;
      }
      if (selectedSchoolId) {
        query = query + `|school_id:${selectedSchoolId}`;
      }

      executeGet({
        params: {
          page,
          perPage,
          'filter[query]': query
        }
      });
    },
    [active, inactive, searchStudentId, search, selectedSchoolId]
  );

  const onPageChange = (selectedPage) => {
    defaultGet(selectedPage, 10);
  };

  const handleSearch = useCallback(() => {
    defaultGet(1, 10);
  }, [defaultGet]);

  const handleEnterPress = useCallback(
    (e) => {
      var keyCode = e.keyCode || e.which;
      if (keyCode == '13') {
        defaultGet(1, 10);
      }
    },
    [defaultGet]
  );

  const markAsDirty = useCallback(() => {
    if (!dirty) {
      setDirty(true);
    }
  }, [dirty]);

  const handleReset = useCallback(() => {
    if (selectedSchoolId) {
      setSelectedSchoolId('');
      setCount((c) => c + 1);
    }
    if (search) {
      setSearch('');
    }
    if (searchStudentId) {
      setSearchStudentId('');
    }

    setActive(true);
    setInactive(false);
    setDirty(false);
    setExecReset(true);
  }, [selectedSchoolId, search, searchStudentId]);

  useEffect(() => {
    if (
      (!selectedSchoolId || selectedSchoolId === '') &&
      (!searchStudentId || searchStudentId === '') &&
      (!search || search === '') &&
      active &&
      !inactive &&
      !dirty &&
      execReset
    ) {
      defaultGet(1, 10);
      setExecReset(false);
    }
  }, [
    selectedSchoolId,
    search,
    active,
    inactive,
    dirty,
    execReset,
    searchStudentId
  ]);
  return (
    <>
      <AddStudentModal
        display={studentModalOpen}
        setDisplay={setStudentModalOpen}
      />
      <StyledMain className="container">
        {loading || studentSettings && (
          <button
            css={css`
              margin-top: -40px;
            `}
            className="btn btn-primary btn-float"
            onClick={() => setStudentModalOpen(true)}
          >
            <i className="ti-plus"></i>
          </button>
        )}
        <div className="row form-type-material">
          <div
            className={`col-2 ${UserService.getUser().school_id ? 'offset-md-5' : 'offset-md-2'
              }`}
          >
            <div className="input-group">
              <LabeledInput
                value={searchStudentId}
                onChange={(e) => {
                  setSearchStudentId(e.target.value);
                  markAsDirty();
                }}
                onKeyPress={handleEnterPress}
                className="form-control bg-transparent"
                label={t('STUDENT_ID')}
              />
            </div>
          </div>
          {!UserService.getUser().school_id && (
            <div className="col-3">
              <div
                className="form-group do-float"
                key={count}
                css={css`
                  .form-type-material .bootstrap-select .dropdown-toggle:focus {
                    background-color: transparent !important;
                  }
                `}
              >
                <select
                  type="text"
                  name="schoolId"
                  id="schoolId"
                  className="form-control bg-transparent"
                  data-provide="selectpicker"
                  onChange={(e) => {
                    setSelectedSchoolId(e.target.value);
                    markAsDirty();
                  }}
                  value={selectedSchoolId}
                >
                  <option value="">{t('ALL')}</option>
                  {selectOptions.map((o) => (
                    <option key={o.value} value={o.value}>
                      {t(o.label)}
                    </option>
                  ))}
                </select>
                <label htmlFor="schoolId" className="label-floated">
                  {t('SCHOOL_NAME')}
                </label>
              </div>
            </div>
          )}
          <div className="col-5">
            <div className="input-group">
              <LabeledInput
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  markAsDirty();
                }}
                onKeyPress={handleEnterPress}
                className="form-control bg-transparent"
                label={t('SEARCH_FOR')}
              />
              <span
                className="input-group-append"
                css={css`
                  .bg-disabled {
                    background-color: #d7dadd;
                  }
                `}
              >
                <button
                  css={css`
                    text-transform: uppercase;
                  `}
                  className={`btn btn-light ${dirty ? 'bg-warning text-white' : 'bg-disabled'
                    }`}
                  type="button"
                  onClick={handleReset}
                  disabled={!dirty}
                >
                  {t('RESET')}
                </button>
              </span>
              <span className="input-group-append">
                <button
                  css={css`
                    text-transform: uppercase;
                  `}
                  className="btn btn-primary"
                  type="button"
                  onClick={handleSearch}
                >
                  Go!
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="card card-shadowed">
          <div className="card-body">
            {loading || schoolLoading ? (
              <Spinner />
            ) : (
              <table className="table table-striped table-lg">
                <thead>
                  <tr>
                    <th>{t('FIRST_NAME')}</th>
                    <th>{t('LAST_NAME')}</th>
                    <th>{t('STUDENT_ID')}</th>
                    <th>{t('EMAIL_ADDRESS')}</th>
                    <th>DOB</th>
                    <th>{t('SCHOOL')}</th>
                    <th>{t('ACTIVE')}</th>
                    <th>
                      <ul className="m-0 p-0">
                        <li className="dropdown d-none d-md-block">
                          <span
                            css={css`
                              color: #212529;
                            `}
                            tabIndex="0"
                            className="btn border-0 p-0 fw-500 bg-transparent"
                            data-toggle="dropdown"
                          >
                            <i className="fa ion-ios-settings-strong"></i>
                            <span className="mx-2">{t('FILTERS')}</span>{' '}
                            <i className="fa fa-chevron-down"></i>
                          </span>
                          <div
                            className="dropdown-menu open-top-right dropdown-menu-right p-2"
                            x-placement="bottom-end"
                            style={{
                              position: 'absolute',
                              top: '65px',
                              left: '-120px',
                              willChange: 'top, left'
                            }}
                          >
                            <span
                              tabIndex="0"
                              className="dropdown-item btn border-0"
                              onClick={() => {
                                setActive((a) => !a);
                                markAsDirty();
                              }}
                            >
                              {active ? (
                                <i className="fa fa-check-square-o"></i>
                              ) : (
                                <i className="fa fa-square-o"></i>
                              )}
                              {t('ELIGIBLE')}
                            </span>
                            <span
                              tabIndex="0"
                              className="dropdown-item btn border-0"
                              onClick={() => {
                                setInactive((i) => !i);
                                markAsDirty();
                              }}
                            >
                              {inactive ? (
                                <i className="fa fa-check-square-o"></i>
                              ) : (
                                <i className="fa fa-square-o"></i>
                              )}
                              {t('NOT_ELIGIBLE')}
                            </span>
                          </div>
                        </li>
                      </ul>
                    </th>
                  </tr>
                </thead>
                <tbody
                  css={css`
                    .left-border {
                      border-left: 2px solid #f96868;
                    }
                  `}
                >
                  {data &&
                    data.data.map((student) => {
                      return (
                        <tr
                          key={student.id}
                          className={`${student.is_inactive ? 'left-border' : ''
                            }`}
                        >
                          <td>{student.first_name}</td>
                          <td>{student.last_name}</td>
                          <td>{student.student_id}</td>
                          <td>{student.email_address}</td>
                          <td>
                            {moment(student.date_of_birth).format('MM/DD/YYYY')}
                          </td>
                          <td>{student.school_abbr}</td>
                          <td>{student.is_inactive ? t('NO') : t('YES')}</td>
                          <td>
                            <button
                              data-open={student.id}
                              onClick={handleOpenStudent}
                              className="btn btn-bold btn-primary btn-label"
                            >
                              <span>
                                <i className="ti-check"></i>
                              </span>
                              {t('VIEW')}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {data && (
          <div className="mb-100">
            <Pagination
              pages={data.last_page}
              onPageChange={onPageChange}
              {...data}
            />
          </div>
        )}
      </StyledMain>
    </>
  );
};

export default StudentsContent;
