import React, { useState, useRef, memo, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import Modal from '../Modal/Modal';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import CardHeader from '../CardHeader';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import { AlertService } from '../../services/AlertService';
import Spinner from '../Spinner';

export const Image = ({ content, id, updateElement }) => {
  const srcFromContent = /<img.*?src="(.*?)"/.exec(content)?.pop();
  const [display, setDisplay] = useState(!srcFromContent);
  const [file, setFile] = useState({});
  const imgRef = useRef(null);

  useEffect(() => {
    if (file?.name) {
      // imgRef.current.src = '';
      const img = imgRef.current.outerHTML;
      updateElement(id, img);
    }
  }, [file]);

  return (
    <Wrapper>
      <ImageModal
        display={display}
        setDisplay={setDisplay}
        droppedFile={file}
        setDroppedFile={setFile}
      />
      {/* We should change this with the url */}
      <img
        ref={imgRef}
        style={{
          height: '80%',
          width: '80%',
          objectFit: 'contain',
          maxHeight: '140px'
        }}
        src={file?.preview || srcFromContent}
        className="cursor-pointer"
        onClick={() => setDisplay(true)}
      />
    </Wrapper>
  );
};

const ImageModal = memo(
  ({ display, setDisplay, droppedFile, setDroppedFile }) => {
    const { t } = useTranslation();

    const [
      { loading: imgLoading, response: imageResponse, error: imageError },
      executeImagesPost
    ] = useAuthorizedAxios({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/f5e0c620-66ba-42f7-8441-c8159c129dad/images`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      manual: true
    });

    const handleImageSubmit = (e) => {
      e.preventDefault();
      const imageComponent = new FormData();
      imageComponent.append('file', droppedFile);
      imageComponent.append('type', 'PROFILE_LOGO');
      //executeImagesPost({ data: imageComponent });
      setDisplay();
    };

    const handleFileDrop = useCallback(async (item, monitor) => {
      if (monitor) {
        const file = monitor.getItem().files[0];
        if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
          Object.assign(file, {
            preview: await toBase64(file)
          });
          setDroppedFile(file);
        }
      }
    }, []);

    const [{ canDrop, isOver }, drop] = useDrop({
      accept: [NativeTypes.FILE],
      drop(item, monitor) {
        handleFileDrop(item, monitor);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    });

    const isActive = canDrop && isOver;

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    useEffect(() => {
      if (imageResponse) {
        setDisplay();
      }

      if (imageError) {
        AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
      }
    }, [imageResponse, imageError]);

    return (
      <Modal
        targetId="imageEdit"
        display={display}
        setDisplay={setDisplay}
        inputCSS={css`
          .card-body {
            padding: 36px 19px;
          }
          .modal-content {
            overflow: unset !important;
          }
        `}
      >
        <CardHeader
          title={`${t('ADD')} ${t('IMAGE')}`}
          rightComponent={
            <button
              onClick={() => setDisplay()}
              className="btn border-0 p-0 fs-18"
            >
              <i className="fa fa-close"></i>
            </button>
          }
        />

        <div className="card-body">
          <form onSubmit={handleImageSubmit} className="form-type-material">
            <ImageUploaderContainer
              ref={drop}
              isActive={isActive}
              className="center-v"
            >
              {droppedFile?.preview ? (
                <>
                  <img src={droppedFile.preview} alt="preview-image" />
                </>
              ) : (
                <label className="m-0 cursor-pointer" htmlFor="file-uploader">
                  <i className="ion-ios-cloud-upload-outline fs-40" />
                  <p className="fs-13 fw-300 lh-16">
                    Drag and drop a file here or click
                  </p>
                </label>
              )}
              <input
                type="file"
                name="file-uploader"
                accept="image/*"
                id="file-uploader"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  Object.assign(file, {
                    preview: await toBase64(file)
                  });
                  setDroppedFile(file);
                }}
              />
            </ImageUploaderContainer>
            <div
              css={css`
                position: relative;
                i {
                  color: #8b95a5;
                  position: absolute;
                  right: 8px;
                  bottom: 5px;
                }
              `}
            >
              {droppedFile?.preview && (
                <i
                  className="fs-19 fa fa-trash cursor-pointer"
                  onClick={() => setDroppedFile({})}
                />
              )}
            </div>
            <br />
            <button
              className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label`}
              type="submit"
            >
              {!imgLoading && (
                <label>
                  <i className="ti-check"></i>
                </label>
              )}
              {imgLoading && (
                <label>
                  <Spinner minHeight={false} />
                </label>
              )}
              {t('ADD')}
            </button>
          </form>
        </div>
      </Modal>
    );
  }
);

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  img {
    max-width: 100% !important;
    max-height: 140px !important;
    min-height: 97px;
    object-fit: contain;
  }
`;

const ImageUploaderContainer = styled.div`
  height: 120px;
  border: solid 1px #d7dadd;
  #file-uploader {
    opacity: 0;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid #32cabb;
    `}
  input {
    width: 0;
    height: 0;
  }
  label {
    i {
      color: #cccccc;
      font-size: 60px !important;
    }
    p {
      font-family: Roboto;
      letter-spacing: 0.23px;
      color: #5b5b5b;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;
