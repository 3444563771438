import React from 'react'
import { useTranslation } from 'react-i18next';

// Components
import PageLayout from '../components/PageLayout/PageLayout';
import DentalReportContent from '../components/ReportsContent/DentalReportContent';


const EligibilityReport = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      withHeader={true}
      title={t('DENTAL_REPORT')}
      crumbs={[
        { title: t('REPORTS'), to: '/reports' },
        { title: t('DENTAL_REPORT'), to: '' },
      ]}
    >
      <DentalReportContent />
    </PageLayout>
  )
}

export default EligibilityReport
