import React, { useState, useEffect } from "react";
import useAxios from "axios-hooks";
import { Route, Switch } from "react-router-dom";
import PageLayout from "../components/PageLayout/PageLayout";
import SchoolContent from "../components/SchoolContent/SchoolContent";
import Preloader from "../components/Preloader";
import { SchoolStateProvider } from "../context/SchoolContext";
import { SchoolService } from "../services/SchoolService";

const School = props => {
  const { match } = props;
  const [{ data, loading, error }, refetch] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${match.params.id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("jwt")}`
    },
  },
    {
      useCache: false,
    },
  );

  const [manageSettings, setManageSettings] = useState(false);

  useEffect(() => {
    SchoolService.canSchool('SCHOOLS:SETTINGS', match.params.id).then((response) => {
      setManageSettings(response);
    })
  }, [])


  if (error) {
    return <h1>No school found.</h1>;
  }

  if (loading) {
    return <Preloader />;
  }

  return (
    <SchoolStateProvider value={[match.params.id]}>
      <PageLayout
        match={match}
        withHeader={true}
        settings={manageSettings ? `${match.params.id}/settings` : ''}
        title={data && data.school_name}
        crumbs={[]}
      >
        <Switch>
          <Route
            exact
            path="/schools/:id/"
            render={() => <SchoolContent school={data} refetch={refetch} />}
          />
        </Switch>
      </PageLayout>
    </SchoolStateProvider>
  );
};

export default School;
