import React from 'react'
import { useTranslation } from 'react-i18next';

// Components
import PageLayout from '../components/PageLayout/PageLayout';
import UsersReportContent from '../components/ReportsContent/UsersReportContent';


const UsersReport = () => {
  const { t } = useTranslation();

  return (
    <PageLayout
      withHeader={true}
      title={t('USERS')}
      crumbs={[
        { title: t('REPORTS'), to: '/reports' },
        { title: t('USERS'), to: '' },
      ]}
    >
      <UsersReportContent />
    </PageLayout>
  )
}

export default UsersReport
