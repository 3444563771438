import React from 'react'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import PageLayout from '../components/PageLayout/PageLayout'
import Preloader from '../components/Preloader'
import SchoolSettingsContent from '../components/SchoolSettingsContent'
import { SchoolStateProvider } from '../context/SchoolContext'

const SchoolSettings = props => {
  const { t } = useTranslation()
  const { match } = props
  const [{ data, loading, error }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${match.params.id}`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  })

  if (error) {
    return <h1>No school found.</h1>
  }

  if (loading) {
    return <Preloader />
  }

  return (
    <SchoolStateProvider value={[match.params.id, undefined]}>
      <PageLayout
        match={match}
        withHeader={true}
        title={data && data.school_name}
        crumbs={[
          { title: t('SCHOOLS'), to: '/schools' },
          { title: data.school_name, to: `/schools/${match.params.id}` },
          { title: t('SETTINGS'), to: '' },
        ]}
      >
        <SchoolSettingsContent />
      </PageLayout>
    </SchoolStateProvider>
  )
}

export default SchoolSettings
