import React, { useState, useCallback, useContext, useEffect } from 'react';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { StudentStateContext } from '../../context/StudentContext';
import { useAuthorizedAxios } from '../../hooks/use-authorizedaxios';
import { AlertService } from '../../services/AlertService';

// Components
import RowCard from '../RowCard';
import Tag from '../Tag/index';

// Modals
import EligibilityModal from './EligibilityModal/index';
import AddSubmissionModal from '../AddSubmissionModal/index';
import { SchoolService } from '../../services/SchoolService';


const AccordionCard = ({
  title,
  children,
  studentPeriod,
  refetchPeriods,
  refetchSubmissions,
  startOpen,
  FT,
  FL,
  ST,
  SL
}) => {
  const { t } = useTranslation();
  const [student] = useContext(StudentStateContext);
  // States
  const [open, setOpen] = useState(startOpen);
  const [field, setField] = useState('');
  const [displayEligibilityModal, setDisplayEligibilityModal] = useState(false);
  const [displayAddSubmissionModal, setDisplayAddSubmissionModal] = useState(
    false
  );
  const [lFPermission, setLFPermission] = useState('');
  const [profilePermission, setProfilePermission] = useState(false);
  const [asePermission, setASEPermission] = useState(false);
  const [fallLevel, setFallLevel] = useState(FL || '');
  const [fallType, setFallType] = useState(FT || '');

  const [springLevel, setSpringLevel] = useState(SL || '');
  const [springType, setSpringType] = useState(ST || '');

  // Axios
  const [{ data, error, loading }, changeLockStatus] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${student.id}/eligibility/locks`,
    method: 'POST',
    manual: true
  });
  // Services
  // Use multiroles to enable or disable checks
  SchoolService.rolePermission("STUDENTS:PROFILE", student.school_id, setProfilePermission);
  SchoolService.rolePermission("STUDENTS:LOCK_FIELDS", student.school_id, setLFPermission);
  SchoolService.rolePermission("STUDENTS_SUBMISSIONS:ADD_SHIP_ENROLLMENT", student.school_id == undefined ? student.school.id : student.school_id, setASEPermission);
  const [
    {
      data: typeData,
      response: responseType,
      error: errorType,
      loading: loadingType
    },
    changeTypeStatus
  ] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v2/students/${student.id}/eligibility/${studentPeriod.studentsEligibility.id}/type`,
    method: 'PATCH',
    manual: true
  });

  const [
    {
      data: levelData,
      response: responseLevel,
      error: errorLevel,
      loading: loadingLevel
    },
    changeLevelStatus
  ] = useAuthorizedAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v2/students/${student.id}/eligibility/${studentPeriod.studentsEligibility.id}/level`,
    method: 'PATCH',
    manual: true
  });

  const handleLockChange = useCallback(
    async (e) => {
      e.stopPropagation();
      let segmentNbr = 0;
      switch (e.target.name) {
        case 'segment_1_locked':
          segmentNbr = 1;
          break;
        case 'segment_2_locked':
          segmentNbr = 2;
          break;
        case 'segment_3_locked':
          segmentNbr = 3;
          break;
        case 'segment_3_locked':
          segmentNbr = 3;
          break;
      }

      await changeLockStatus({
        params: {
          isLocked: studentPeriod.studentsEligibility[e.target.name] ? 0 : 1,
          periodId: studentPeriod.studentsEligibility.enrollment_period_id,
          segment: segmentNbr
        }
      });
      refetchPeriods();
    },
    [studentPeriod]
  );

  const handleChangeEligibility = useCallback(
    (e, fieldName) => {
      e.stopPropagation();
      setField(fieldName);
      setDisplayEligibilityModal(true);
    },
    [studentPeriod]
  );

  const handleOpenAddSubmissionModal = useCallback((e) => {
    e.stopPropagation();
    setDisplayAddSubmissionModal(true);
  }, []);

  const handleTypeChange = (e, i) => {
    i == 1 ? setFallType(e) : setSpringType(e);

    changeTypeStatus({
      data: {
        segment: i,
        type: e
      }
    });
  };

  const handleLevelChange = (e, i) => {
    i == 1 ? setFallLevel(e) : setSpringLevel(e);

    changeLevelStatus({
      data: {
        segment: i,
        level: e
      }
    });
  };

  useEffect(() => {
    if (responseLevel && !errorLevel) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (errorLevel) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseLevel, errorLevel]);

  useEffect(() => {
    if (responseType && !errorType) {
      AlertService.showSuccess(t('SAVE_SUCCESS'));
    } else if (errorType) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseType, errorType]);

  return (
    <>
      <EligibilityModal
        display={displayEligibilityModal}
        setDisplay={setDisplayEligibilityModal}
        studentPeriod={studentPeriod}
        field={field}
        refetchPeriods={refetchPeriods}
      />

      <AddSubmissionModal
        display={displayAddSubmissionModal}
        setDisplay={setDisplayAddSubmissionModal}
        refetch={refetchSubmissions}
        period={studentPeriod.period}
      />
      <div
        css={css`
          display: block;
          width: 100%;
        `}
      //onClick={() => setOpen((o) => !o)}
      >
        <div
          className="card"
          style={{
            marginBottom: '0px',
            padding: '0x 15px 14px 30px',
            borderBottom: '1px solid rgb(215, 218, 221)'
          }}
        >
          <div className="d-flex justify-content-end">
            <button
              disabled={!asePermission}
              css={css`
                margin-top: -20px;
                margin-right: 70px;
                height: 40px;
                width: 40px;
                z-index: 3;
              `}
              className="btn btn-primary btn-float"
              onClick={(e) => {
                handleOpenAddSubmissionModal(e);
              }}
            >
              <i className="ti-plus" />
            </button>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <h5 className="mb-0" style={{ marginLeft: '35px' }}>{`${title} ${t(
              'ENROLLMENT_PERIODS'
            )}`}</h5>
            <div
              className="col-md-2 text-right d-flex justify-content-end mr-2"
              onClick={() => setOpen((o) => !o)}
            >
              <span className="btn border-0 p-0 fw-500 text-black bg-transparent">
                <i
                  className={
                    open ? 'fa fa-angle-down fa-2x' : 'fa fa-angle-right fa-2x'
                  }
                />
              </span>
            </div>
          </div>
        </div>
        <RowCard shadowed={false}>
          <div className="d-flex justify-content-between flex-wrap">
            {/* Anual */}
            {studentPeriod &&
              studentPeriod.period.validation_period === 'Anual' && (
                <div className="d-flex">
                  <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                    {t('ANUAL')}
                  </span>
                  <Tag
                    title={
                      studentPeriod.studentsEligibility.segment_1 ===
                        undefined ||
                        studentPeriod.studentsEligibility.segment_1 === null
                        || !lFPermission
                        ? t('N_A')
                        : studentPeriod.studentsEligibility.segment_1
                          ? t('YES')
                          : t('NO')
                    }
                    color={
                      studentPeriod.studentsEligibility.segment_1 ===
                        undefined ||
                        studentPeriod.studentsEligibility.segment_1 === null
                        || !lFPermission
                        ? 'bg-secondary'
                        : studentPeriod.studentsEligibility.segment_1
                          ? 'bg-success'
                          : 'bg-danger'
                    }
                    small
                    customStyle="h-22px mb-0"
                    className="my-auto"
                    onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_1') : ""}
                  />
                  <div className="d-flex flex-column justify-content-center">
                    <label
                      className="switch my-auto"
                      css={css`
                        height: 10px;
                        margin-right: 0.5rem;
                      `}
                    >
                      <input
                        type="checkbox"
                        checked={studentPeriod.studentsEligibility.segment_1_locked ||
                          false
                        }
                        name="segment_1_locked"
                        id="segment_1_locked"
                        onChange={(e) => handleLockChange(e)}
                        disabled={
                          studentPeriod.studentsEligibility.segment_1 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_1 === null
                          || !lFPermission
                        }
                      />
                      <span className="switch-indicator" />
                    </label>
                    <span
                      className="mx-auto"
                      css={css`
                        margin-top: 0.75rem;
                      `}
                    >
                      {t('LOCK')}
                    </span>
                  </div>
                </div>
              )}
            {/* Semester */}
            {studentPeriod &&
              studentPeriod.period.validation_period === 'Semesters' && (
                <>
                  <div
                    className="d-flex"
                    style={{ alignItems: 'center', paddingLeft: '30px' }}
                  >
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {t('FALL')}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_1 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_1 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_1
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_1 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_1 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_1
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="h-22px mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_1') : ""}
                    />
                    <div className="d-flex ml-2">
                      <div style={{ width: '6.5rem' }}>
                        <select
                          name="studentLevel"
                          id="studentLevel"
                          data-provide="selectpicker"
                          className="form-control w-100"
                          value={!lFPermission ? '' : fallLevel}
                          onChange={(e) => {
                            handleLevelChange(e.target.value, 1);
                          }}
                          disabled={
                            !profilePermission ||
                            studentPeriod.studentsEligibility.segment_1 === null
                            || !lFPermission
                          }
                        >
                          <option disabled value="">
                            {t('SELECT')}
                          </option>
                          <option value="Undergrad">{t('UNDERGRAD')}</option>
                          <option value="Grad">{t('GRAD')}</option>
                          <option value="Law">{t('LAW')}</option>
                        </select>
                      </div>
                      <div
                        style={{
                          width: '6.5rem',
                          marginLeft: '1rem',
                          marginRight: '1rem'
                        }}
                      >
                        <select
                          name="studentType"
                          id="studentType"
                          data-provide="selectpicker"
                          className="form-control w-100"
                          value={!lFPermission ? '' : fallType}
                          onChange={(e) => {
                            handleTypeChange(e.target.value, 1);
                          }}
                          disabled={
                            !profilePermission ||
                            studentPeriod.studentsEligibility.segment_1 === null ||
                            !lFPermission
                          }
                        >

                          <option disabled value="">
                            {t('SELECT')}
                          </option>
                          <option value="Domestic">{t('DOMESTIC')}</option>
                          <option value="International">
                            {t('INTERNATIONAL')}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch my-auto"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            !lFPermission ? false :
                              studentPeriod.studentsEligibility
                                .segment_1_locked || false
                          }
                          name="segment_1_locked"
                          id="segment_1_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_1 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_1 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      borderLeft: '1px solid #D7DADD',
                      marginRight: '2rem',
                      marginLeft: '2rem',
                      marginTop: '-8px'
                    }}
                  />
                  <div className="d-flex" style={{ alignItems: 'center' }}>
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {t('SPRING')}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_2 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_2
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_2 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_2
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_2') : ""}
                    />
                    <div className="ml-2" style={{ width: '6.5rem' }}>
                      <select
                        name="studentLevel"
                        id="studentLevel"
                        data-provide="selectpicker"
                        className="form-control w-100"
                        value={!lFPermission ? '' : springLevel}
                        onChange={(e) => {
                          handleLevelChange(e.target.value, 2);
                        }}
                        disabled={
                          !profilePermission ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                        }
                      >
                        <option disabled value="">
                          {t('SELECT')}
                        </option>
                        <option value="Undergrad">{t('UNDERGRAD')}</option>
                        <option value="Grad">{t('GRAD')}</option>
                        <option value="Law">{t('LAW')}</option>
                      </select>
                    </div>
                    <div
                      style={{
                        width: '6.5rem',
                        marginLeft: '1rem',
                        marginRight: '1rem'
                      }}
                    >
                      <select
                        name="studentType"
                        id="studentType"
                        data-provide="selectpicker"
                        className="form-control w-100"
                        value={springType}
                        onChange={(e) => {
                          handleTypeChange(e.target.value, 2);
                        }}
                        disabled={
                          !profilePermission ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                        }
                      >
                        <option disabled value="">
                          {t('SELECT')}
                        </option>
                        <option value="Domestic">{t('DOMESTIC')}</option>
                        <option value="International">
                          {t('INTERNATIONAL')}
                        </option>
                      </select>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_2_locked || false
                          }
                          name="segment_2_locked"
                          id="segment_2_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_2 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_2 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                </>
              )}
            {/* Trimester */}
            {studentPeriod &&
              studentPeriod.period.validation_period === 'Trimester' && (
                <>
                  <div className="d-flex">
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {`1º ${t('TRIMESTER')}`}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_1 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_1 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_1
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_1 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_1 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_1
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="h-22px mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_1') : ""}
                    />
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch my-auto"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_1_locked || false
                          }
                          name="segment_1_locked"
                          id="segment_1_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_1 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_1 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {`2º ${t('TRIMESTER')}`}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_2 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_2
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_2 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_2
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_2') : ""}

                    />
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_2_locked || false
                          }
                          name="segment_2_locked"
                          id="segment_2_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_2 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_2 === null
                            || !lFPermission
                          }/>
                          <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {`3º ${t('TRIMESTER')}`}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_3 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_3 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_3
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_3 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_3 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_3
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_3') : ""}
                    />
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_3_locked || false
                          }
                          name="segment_3_locked"
                          id="segment_3_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_3 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_3 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                </>
              )}
            {/* QUARTER */}
            {studentPeriod &&
              studentPeriod.period.validation_period === 'Quarter' && (
                <>
                  <div className="d-flex">
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {`1º ${t('QUARTER')}`}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_1 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_1 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_1
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_1 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_1 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_1
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="h-22px mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_1') : ""}
                    />
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch my-auto"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_1_locked || false
                          }
                          name="segment_1_locked"
                          id="segment_1_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_1 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_1 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {`2º ${t('QUARTER')}`}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_2 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_2
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_2 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_2 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_2
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_2') : ""}
                    />
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_2_locked || false
                          }
                          name="segment_2_locked"
                          id="segment_2_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_2 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_2 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {`3º ${t('QUARTER')}`}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_3 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_3 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_3
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_3 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_3 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_3
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_3') : ""}
                    />
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_3_locked || false
                          }
                          name="segment_3_locked"
                          id="segment_3_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_3 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_3 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <span className="font-roboto border-0 mr-2 p-0 fw-500 text-black bg-transparent my-auto">
                      {`4º ${t('QUARTER')}`}
                    </span>
                    <Tag
                      title={
                        studentPeriod.studentsEligibility.segment_4 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_4 === null
                          || !lFPermission
                          ? t('N_A')
                          : studentPeriod.studentsEligibility.segment_4
                            ? t('YES')
                            : t('NO')
                      }
                      color={
                        studentPeriod.studentsEligibility.segment_4 ===
                          undefined ||
                          studentPeriod.studentsEligibility.segment_4 === null
                          || !lFPermission
                          ? 'bg-secondary'
                          : studentPeriod.studentsEligibility.segment_4
                            ? 'bg-success'
                            : 'bg-danger'
                      }
                      small
                      customStyle="mb-0"
                      className="my-auto"
                      onClick={lFPermission ? (e) => handleChangeEligibility(e, 'segment_4') : ""}
                    />
                    <div className="d-flex flex-column justify-content-center">
                      <label
                        className="switch"
                        css={css`
                          height: 10px;
                          margin-right: 0.5rem;
                        `}
                      >
                        <input
                          type="checkbox"
                          checked={
                            studentPeriod.studentsEligibility
                              .segment_4_locked || false
                          }
                          name="segment_4_locked"
                          id="segment_4_locked"
                          onChange={(e) => handleLockChange(e)}
                          disabled={
                            studentPeriod.studentsEligibility.segment_4 ===
                            undefined ||
                            studentPeriod.studentsEligibility.segment_4 === null
                            || !lFPermission
                          }
                        />
                        <span className="switch-indicator" />
                      </label>
                      <span
                        className="mx-auto"
                        css={css`
                          margin-top: 0.75rem;
                        `}
                      >
                        {t('LOCK')}
                      </span>
                    </div>
                  </div>
                </>
              )}
          </div>
        </RowCard>
        {open && children}
      </div>
    </>
  );
};

export default AccordionCard;
