import React, { useState, useEffect, useCallback } from 'react'
import Modal from '../../Modal/Modal'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import EnrollmentTab from './EnrollmentTab'
import NotesTab from './NotesTab'
import ChangeLogTab from './ChangeLogTab'
import moment from 'moment'
import Tag from '../../Tag/index';
import NotificationAlert from '../../NotificationAlert/index';

const SHIPModal = ({ display, setDisplay, submission, refetch }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState('#ship-enrollment')
  const [updatedData, setUpdatedData] = useState();
  const student = submission ? submission.student : {}
  const segment = submission ? submission.segment : {}
  const period = submission ? submission.period : {}

  useEffect(() => {
    if (!display) {
      setActiveTab('#ship-enrollment');
    }
  }, [display])

  const renderTags = useCallback(() => {
    const tags = []
    if (submission) {
      if (submission.special_enrollment) {
        tags.push(<Tag title={t('SPECIAL_ENROLLMENT')} color="bg-primary" customStyle="mb-0 ml-1" />)
      }
      if (submission.manual_billing) {
        tags.push(<Tag title={t('MANUAL_BILLING')} color="bg-pink" customStyle="mb-0 ml-1" />)
      }
    }
    return tags

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission])

  return (
    <Modal inputCSS={css`
        .w-1100px {
          width: 1100px;
        }
      `} width="w-1100px" height="h-90" display={display} setDisplay={setDisplay}>
      {display && (
        <>
          <div className='card-header'>
            <div className="row w-100">
              <div className="col-6">
                <h5 className='card-title fw-500'>
                  {student.first_name} {student.last_name} - ID: {student.student_id} - {t('SHIP_ENROLLMENT')}
                </h5>
              </div>
              <div className="col-6">
                <div className="d-flex justify-content-end">
                  {renderTags()}
                  <button onClick={() => setDisplay(false)} className='btn border-0 p-0 ml-3'>
                    <i className='fa fa-close'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body flex-grow-0">
            <div>
              <ul className="nav nav-tabs mb-0 border-0">
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="btn nav-link active" data-toggle="tab" role="button" href="#ship-enrollment">{t('SHIP_ENROLLMENT')}</span>
                </li>
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="btn nav-link" data-toggle="tab" href="#notes" role="button">{t('NOTES')}</span>
                </li>
                <li className="nav-item">
                  <span onClick={e => setActiveTab(e.target.getAttribute('href'))} className="btn nav-link" data-toggle="tab" href="#change-log" role="button">{t('CHANGE_LOG')}</span>
                </li>
                <li className="ml-auto">
                  <h5 className="m-0">
                    {period.academic_year} {segment.segment_label}
                  </h5>
                  <p className="m-0 text-right">
                    {t('SUBMITTED')}: {submission && moment(submission.created_at).format('MM/DD/YYYY')}
                  </p>
                </li>
              </ul>
              <div className='mt-2'>
                <NotificationAlert data={updatedData} message={t('NOTIFICATION_STUDENT_UPDATE_SUCCESS')} />
              </div>
            </div>
          </div>

          <div css={css`overflow-y: auto; `} >
            <div className="card-body pt-0">
              <div className="tab-content" css={css`
                &.tab-content {
                  ${'' /* min-height: 600px; */}
                  max-height: 100vh;
                }
              `}>
                <div className="tab-pane fade active show" id="ship-enrollment">
                  {
                    activeTab === '#ship-enrollment' && (
                      <EnrollmentTab
                        submission={submission}
                        updatedAt={submission && moment(submission.updated_at).format('MM/DD/YYYY')}
                        student={student}
                        coveragePeriod={submission && { start: submission.coverage_start_date, end: submission.coverage_end_date }}
                        refetch={refetch} 
                        setUpdatedData={setUpdatedData}
                        />
                    )
                  }
                </div>
                <div className="tab-pane fade" id="notes">
                  {
                    activeTab === '#notes' && (
                      <NotesTab submissionId={submission && submission.id} active={activeTab === '#notes'} />
                    )
                  }
                </div>
                <div className="tab-pane fade" id="change-log">
                  {
                    activeTab === '#change-log' && (
                      <ChangeLogTab submissionId={submission && submission.id} active={activeTab === '#change-log'}/>
                    )
                  }
                </div>
              </div>

            </div>
          </div>
        </>
      )
      }
    </Modal>
  )
}

export default SHIPModal
