import React from "react";
import { css } from '@emotion/core';

const DragInputFile = ({ fileMessage, download, type, onChangeFile }) => {

  return (
    <div
      css={css`
        .dropify-wrapper{
          width: 180px;
          height: 160px !important;
        }
        .touch-fallback {
            width: 180px;
            height: 160px !important;
            .dropify-message{
            top: 0;
            padding: 20px 0;
            }
          }
          }
        .dropify-message{
            padding: 20px 0;
          }
      `}>
      <div className="d-flex justify-content-center">
        <input
          type='file'
          accept='image/png,image/gif,image/jpeg,application/pdf'
          className='dropify'
          data-provide='dropify'
          onInput={e => onChangeFile(type, e.target.files[0])}
        />
      </div>
      <p className="text-center mb-0 mt-2">{fileMessage}
        {download && (
          <button onClick={download} className='btn border-0 px-2'>
            <i className='fa fa-download'></i>
          </button>
        )}
      </p>
    </div>

  );
};

export default DragInputFile;
