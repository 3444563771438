import React from 'react'
const SortArrows = ({ column, sortedBy, asc, customStyle = '' }) => {
  return (
    <div className={`d-flex align-items-center ${customStyle}`}>
      <i className={`fa fa-long-arrow-down ${column === sortedBy && !asc ? 'text-default' : 'text-fade'}`}></i>
      <i className={`fa fa-long-arrow-up ${column === sortedBy && asc ? 'text-default' : 'text-fade'}`}></i>
    </div>
  )
}

export default SortArrows
