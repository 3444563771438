import styled from '@emotion/styled'

export const StyledMain = styled.div`
  width: 100%;
  margin: 30px auto 0;
  position: relative;

  button.btn-float {
    height: 51px;
    width: 51px;
    position: fixed;
    top: 200px;
    left: 80%;
    z-index: 600;
    transition: unset;

    &.stick {
      position: fixed;
      top: 130px;
    }
  }
`
