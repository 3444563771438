import React from 'react';
import logo from '../../img/AHP-wh.png';

export const Footer = () => {
  return (
    <div
      className="footer"
      style={{
        clear: 'both',
        textAlign: 'center',
        width: '100%',
        backgroundColor: '#2fb4a8',
        boxShadow: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.49)',
        position: 'relative'
      }}
    >
      <table
        border="0"
        cellPadding="0"
        cellSpacing="0"
        style={{
          borderCollapse: 'separate',
          msoTableLspace: '0pt',
          msoTableRspace: '0pt',
          width: '100%'
        }}
      >
        <tbody>
          <tr>
            <td
              className="content-block"
              style={{
                fontFamily: ' Lato',
                paddingBottom: ' 10px',
                paddingTop: ' 10px',
                fontSize: ' 12px',
                color: '#999999',
                textAlign: ' center'
              }}
            >
              <img style={{marginBottom: '10px'}} width="124" height="77" src={logo} alt="logo" />
              <p
                style={{
                  margin: '0px',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: 1.82,
                  color: '#f5f6fa',
                  fontFamily: 'Roboto'
                }}
              >
                16201 West 95th Street, Ste. 210
              </p>
              <p
                style={{
                  margin: '0px',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: 1.82,
                  color: '#f5f6fa',
                  fontFamily: 'Roboto'
                }}
              >
                Lenexa, KS 66219
              </p>
              <p
                style={{
                  margin: '0px',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: 1.82,
                  color: '#f5f6fa',
                  fontFamily: 'Roboto'
                }}
              >
                <a
                  href="tel:877-974-7462"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    margin: '0px',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: 1.82,
                    color: '#f5f6fa',
                    fontFamily: 'Roboto',
                    textDecoration: 'none'
                  }}
                >
                  877-974-7462 ext. 315
                </a>
              </p>
              <a
                href="mailto:student-insurance@ahpcare.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  margin: '0px',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '1.82',
                  color: '#f5f6fa',
                  fontFamily: 'Roboto'
                }}
              >
                student-insurance@ahpcare.com
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
