import React, { useState, useCallback } from "react";
import { css } from '@emotion/core'
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from '@apollo/client';
import Spinner from "../../Spinner";
import Pagination from "../../Pagination";
import EmailDetailModal from "./EmailDetailModal";
import { SearchStateContext } from '../../../context/SearchContext'

// Queries
import { student_mail_by_period, student_emails_periods } from '../../../data/students/query';

const DEFAULT_PAGINATION = {
  limit: 10,
  offset: 0,
}

const EmailsTab = ({ studentId, isActive }) => {
  const { t } = useTranslation();
  const [displayEmailModal, setDisplayEmailModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState();
  const [enrollmentOptions, setEnrollmentOptions] = useState([]);
  const [selectEnrollmentId, setSelectEnrollmentId] = useState('');
  const [count, setCount] = useState(0);
  const { setWithSearch } = React.useContext(SearchStateContext)
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
  });

  // Queries
  const [studentMailQuery, { loading: loadingData, data: studentMailData }] = useLazyQuery(student_mail_by_period);

  const [studentMailPeriodsQuery, { data: dataEmailPeriods }] = useLazyQuery(student_emails_periods, {
    variables: {
      studentId
    }
  })

  React.useEffect(() => {
    if (isActive) {
      studentMailPeriodsQuery();
    }
  }, [isActive])

  React.useEffect(() => {
    if (dataEmailPeriods && dataEmailPeriods.enrollment_periods_segments && dataEmailPeriods.enrollment_periods_segments.length > 0) {
      let enrollmentsOptions = []
      dataEmailPeriods.enrollment_periods_segments.forEach(period =>
        enrollmentsOptions.push({
          value: period.id,
          label: `${period.enrollment_period.academic_year} - ${period.segment_label}`
        })
      )
      setSelectEnrollmentId(enrollmentsOptions[0].value)
      setEnrollmentOptions(enrollmentsOptions)
      studentMailQuery({
        variables: {
          studentId: studentId,
          enrollmentPeriodSegmentId: enrollmentsOptions[0].value,
          offset: DEFAULT_PAGINATION.offset,
          limit: DEFAULT_PAGINATION.limit
        }
      })
      setCount(c => (c + 1));
    }
  }, [dataEmailPeriods])

  React.useEffect(() => {
    setWithSearch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenEmailModal = useCallback((email) => {
    setSelectedEmail(email);
    setDisplayEmailModal(true);
  }, [])

  const handleCloseEmailModal = useCallback(() => {
    setSelectedEmail();
    setDisplayEmailModal(false);
  }, [])

  const onPageChange = useCallback((newPage) => {
    studentMailQuery({
      variables: {
        studentId: studentId,
        enrollmentPeriodSegmentId: selectEnrollmentId,
        offset: pagination.limit * (newPage - 1),
        limit: pagination.limit,
      }
    })
    setPagination(p => ({
      ...p,
      offset: (newPage - 1) * p.limit
    }))
  }, [pagination, selectEnrollmentId])

  const handleChangePeriod = useCallback((periodId) => {
    setSelectEnrollmentId(periodId)
    studentMailQuery({
      variables: {
        studentId: studentId,
        enrollmentPeriodSegmentId: periodId,
        offset: DEFAULT_PAGINATION.offset,
        limit: DEFAULT_PAGINATION.limit
      }
    })
    setPagination({
      limit: DEFAULT_PAGINATION.limit,
      offset: DEFAULT_PAGINATION.offset
    })
  }, [])

  return (
    <>
      {selectedEmail && (
        <EmailDetailModal
          display={displayEmailModal}
          setDisplay={handleCloseEmailModal}
          openEmail={selectedEmail}
          studentId={studentId}
        />
      )}
      <div className="container">
        <div className="row form-type-material">
          <div className="col-4">
            <div
              className='form-group do-float'
              key={count}
              css={css`
                  .form-type-material .bootstrap-select .dropdown-toggle:focus {
                    background-color: transparent !important;
                  }
                `}>
              <select
                type='text'
                name='schoolId'
                id='schoolId'
                className='form-control bg-transparent'
                css={css`
                  .dropdown-menu {
                    max-height: 300px !important;
                  }
                `}
                data-provide='selectpicker'
                onChange={e => handleChangePeriod(e.target.value)}
                value={selectEnrollmentId}
              >
                {enrollmentOptions.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
              </select>
              <label htmlFor='schoolId' className='label-floated'>
                {t('ENROLLMENT_PERIOD')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-shadowed">
        <div className="card-header">
          <h5 className="card-title fw-500">{t("EMAIL_LOG")}</h5>
        </div>
        <div className="card-body">
          {loadingData ? (
            <Spinner />
          ) : (
            <table className="table-striped table table-hover">
              <thead>
                <tr>
                  <th>{t("VIEWED")}</th>
                  <th>{t("EMAIL_SUBJECT")}</th>
                  <th>{t("TYPE")}</th>
                  <th>{t("SENT")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {studentMailData &&
                  studentMailData.students_email_logs &&
                  studentMailData.students_email_logs.map(email => (
                    <tr key={email.id}>
                      <td>
                        {email.viewed
                          ? moment(email.viewed).format("MM/DD/YYYY")
                          : "No"}
                      </td>
                      <td>{email.subject}</td>
                      <td>{email.email_type}</td>
                      <td>{moment(email.created_at).format("MM/DD/YYYY")}</td>
                      <td>
                        <button
                          className="btn btn-bold btn-label btn-primary"
                          onClick={() => handleOpenEmailModal(email)}
                        >
                          <span>
                            <i className="ti-check"></i>
                          </span>
                          {t("VIEW")}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {studentMailData && (
        <div className="mb-100">
          <Pagination
            pages={Math.ceil(studentMailData.students_email_logs_aggregate.aggregate.totalCount / pagination.limit)}
            onPageChange={onPageChange}
            from={studentMailData.students_email_logs_aggregate.aggregate.totalCount > 0
              ? pagination.offset + 1
              : null
            }
            to={studentMailData.students_email_logs_aggregate.aggregate.totalCount === 0
              ? null
              : studentMailData.students_email_logs_aggregate.aggregate.totalCount < pagination.offset + pagination.limit
                ? pagination.offset + (studentMailData.students_email_logs_aggregate.aggregate.totalCount % pagination.limit)
                : pagination.offset + pagination.limit}
            total={studentMailData.students_email_logs_aggregate.aggregate.totalCount}
          />
        </div>
      )}
      {!studentMailData && (
        <div className="mb-100">
          <Pagination
            pages={0}
            onPageChange={onPageChange}
            from={null}
            to={null}
            total={0}
          />
        </div>
      )}
    </>
  );
};

export default EmailsTab;
