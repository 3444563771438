import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';

import { SearchStateContext } from '../../context/SearchContext';

const Header = ({ title, crumbs, settings }) => {
  const { setSearchKeyword, withSearch } = useContext(SearchStateContext);
  const search = useRef();

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    setSearchKeyword(e.target.search.value);
  };

  return (
    <header
      css={css`
        margin-top: 106px;
        z-index: 550;
      `}
      className="topbar topbar-expand-lg topbar-inverse p-0"
    >
      <header className="header header-inverse w-100">
        <div className="container">
          <div className="header-info mt-20 mb-40">
            <div className="left">
              <h2 className="header-title">
                <strong>{title}</strong>
                {settings && (
                  <Link
                    to={settings}
                    className="btn border-0"
                    style={{
                      background: 'none',
                      color: '#ffffff',
                      position: 'absolute'
                    }}
                  >
                    <i className="ti-settings"></i>
                  </Link>
                )}
              </h2>
              <ol className="breadcrumb">
                {crumbs &&
                  crumbs.map((crumb, index) => {
                    return index === crumbs.length - 1 ? (
                      <li
                        key={`crumb-${index}`}
                        className="breadcrumb-item active"
                      >
                        {crumb.title}
                      </li>
                    ) : (
                      <li key={`crumb-${index}`} className="breadcrumb-item">
                        <Link to={crumb.to}>{crumb.title}</Link>
                      </li>
                    );
                  })}
              </ol>
            </div>
            {withSearch && (
              <div className="right flex-middle d-none d-md-block">
                <form
                  onSubmit={handleSearchSubmit}
                  ref={search}
                  className="lookup lookup-circle lookup-lg lookup-right"
                  css={css`
                    &.stick {
                      position: fixed;
                      top: 90px;
                    }
                  `}
                >
                  <input type="text" name="search" />
                </form>
              </div>
            )}
          </div>
        </div>
      </header>
    </header>
  );
};

export default Header;
