import React from 'react';

export const Body = ({ children }) => {
  return (
    <table
      border={0}
      cellPadding={0}
      cellSpacing={0}
      className="body"
      style={{
        borderCollapse: 'separate',
        msoTableLspace: '0pt',
        msoTableRspace: '0pt',
        width: '100%',
        backgroundColor: '#f6f6f6',
      }}
    >
      <tbody>
        <tr>
          <td
            className="container"
            style={{
              display: 'block',
              margin: '0 auto',
              maxWidth: '580px',
              padding: '10px',
              marginBottom: "20px"
            }}
          >
            <div
              className="content"
              style={{
                boxSizing: 'border-box',
                display: 'block',
                margin: '0 auto',
                maxWidth: '580px',
                padding: '10px',
              }}
            >
              {/* START CENTERED WHITE CONTAINER */}
              <table
                className="main"
                style={{
                  borderCollapse: 'separate',
                  msoTableLspace: '0pt',
                  msoTableRspace: '0pt',
                  width: '100%',
                  background: '#ffffff',
                  borderRadius: '4px',
                  marginTop: '-55px',
                  boxShadow: '0 2px 4px 0 rgba(53, 56, 63, 0.5)',
                  position: 'relative',
                }}
              >
                <tbody>
                  <tr>
                    <td
                      className="wrapper"
                      style={{
                        boxSizing: 'border-box',
                        padding: '20px',
                        minHeight: '300px',
                        display: 'flex',
                      }}
                    >
                      <table
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        style={{
                          borderCollapse: 'separate',
                          msoTableLspace: '0pt',
                          msoTableRspace: '0pt',
                          width: '100%',
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>{children}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* END MAIN CONTENT AREA */}
              {/* END CENTERED WHITE CONTAINER */}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
