import React, { useContext, useState } from 'react'
import moment from 'moment'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next';
import { TabsContainer } from '../../styles/common/tabs';
import { SearchStateContext } from '../../context/SearchContext';

// Views
import EmailsTab from './EmailsTab';
import ProfileTab from './ProfileTab';
import ActivityLogsTab from './ActivityLogsTab';

// Components
import RowCard from '../RowCard/index';

const StudentContent = ({ student, reloadUser }) => {
  const { t } = useTranslation()
  const { setSearchKeyword, setWithSearch } = useContext(SearchStateContext);
  const [tabSelected, setTabSelected] = useState('#profile');

  setWithSearch(false)

  const handleActiveTab = e => {
    setSearchKeyword('')
    Array.from(e.currentTarget.children).forEach(li => {
      const navLink = li.firstChild
      if (navLink !== e.target && navLink.classList.contains('active')) {
        navLink.classList.remove('active')
      }
    })
  }

  return (
    <TabsContainer>
      <div
        css={css`
          box-shadow: none;
          z-index: 600;
        `}
        className='topbar header-inverse mt-150'>
        <div className='container'>
          <div className='header-action'>
            <nav className='nav' onClick={handleActiveTab}>
              <li className='nav-item'>
                <button
                  className='nav-link active'
                  data-toggle='tab'
                  href='#profile'
                  onClick={e => setTabSelected(e.target.getAttribute('href'))}
                >
                  {t('PROFILE')}
                </button>
              </li>
              <li className='nav-item'>
                <button
                  className='nav-link'
                  data-toggle='tab'
                  href='#emails'
                  onClick={e => setTabSelected(e.target.getAttribute('href'))}
                >
                  {t('EMAILS')}
                </button>
              </li>
              <li className='nav-item'>
                <button
                  className='nav-link'
                  data-toggle='tab'
                  href='#activity-logs'
                  onClick={e => setTabSelected(e.target.getAttribute('href'))}
                >
                  {t('ACTIVITY_LOGS')}
                </button>
              </li>
            </nav>
          </div>
        </div>
      </div>

      <div className='container'>
        <RowCard>
          <div className='col-md-6'>
            <h5 className="mb-0">{student.school.school_name}</h5>
          </div>
          <div className='col-md-6'>
            <p className='text-right fs-11 mb-0'>
              {`${t('PROFILE_ADDED')}: ${moment(student.created_at).format(
                'MM/DD/YYYY'
              )}`}
            </p>
          </div>
        </RowCard>

          <div className='tab-content'>
            <div className='tab-pane fade active show' id='profile'>
              {tabSelected === '#profile' && <ProfileTab school={student.school} reloadUser={reloadUser}/>}
            </div>
            <div className='tab-pane fade' id='emails'>
              {tabSelected === '#emails' && <EmailsTab studentId={student.id} isActive={tabSelected === '#emails'} />}
            </div>
            <div className='tab-pane fade' id='activity-logs'>
              {tabSelected === '#activity-logs' && <ActivityLogsTab studentId={student.id} />}
            </div>
          </div>
        </div>
    </TabsContainer>
  )
}

export default StudentContent
