import React, { useContext, useState } from 'react';
import { css } from '@emotion/core';
import useAxios from 'axios-hooks';
import Pagination from '../Pagination';
import { StyledMain } from '../../styles/common/main';
import { SearchStateContext } from '../../context/SearchContext';
import { useHistory } from 'react-router-dom';
import Spinner from '../Spinner';
import AddSchoolModal from '../AddSchoolModal/AddSchoolModal';

const SchoolsContent = () => {
  const { debouncedSearchKeyword } = useContext(SearchStateContext);
  const [openSchoolModal, setOpenSchoolModal] = useState(false);
  const history = useHistory();

  const [{ data, loading }, executeGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
    },
    params: {
      'filter[query]': debouncedSearchKeyword
    }
  });

  const onPageChange = (selectedPage) => {
    executeGet({
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools?page=${selectedPage}`
    });
  };

  const handleOpenSchool = (e) => {
    history.push(`/schools/${e.currentTarget.dataset.open}`);
  };

  const handleMobileOpenSchool = (e) => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      history.push(`/schools/${e.currentTarget.dataset.open}`);
    }
  };

  return (
    <>
      <AddSchoolModal
        display={openSchoolModal}
        setDisplay={setOpenSchoolModal}
        onCloseModal={executeGet}
      />
      <StyledMain className="container">
        <button
          css={css`
            margin-top: -40px;
          `}
          className="btn btn-primary btn-float"
          onClick={() => setOpenSchoolModal(true)}
        >
          <i className="ti-plus"></i>
        </button>
        <div className="card card-shadowed">
          {loading ? (
            <Spinner />
          ) : (
            <table className="table table-striped table-lg table-hover">
              <tbody>
                {data &&
                  data.data.map((school) => {
                    return (
                      <tr
                        css={css`
                          cursor: pointer;
                        `}
                        onClick={handleMobileOpenSchool}
                        onDoubleClick={handleOpenSchool}
                        data-open={school.id}
                        key={school.id}
                      >
                        <td width="80%">
                          <div className="media">
                            <img
                              src={school.school_avatar_url}
                              alt="school avatar"
                              className="avatar"
                            />
                            <div className="media-body flex align-center">
                              <p>
                                <strong className="fs-19">
                                  {school.school_name}
                                </strong>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media">
                            <div className="media-body">
                              <p className="lh-1">
                                <strong>
                                  {school.contacts[0] &&
                                    school.contacts[0].first_name}{' '}
                                  {school.contacts[0] &&
                                    school.contacts[0].last_name}
                                </strong>
                              </p>
                              <small>
                                {school.contacts[0] && school.contacts[0].phone}
                              </small>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </div>
        {data && (
          <div className="px-2">
            <Pagination
              pages={data.last_page}
              onPageChange={onPageChange}
              {...data}
            />
          </div>
        )}
      </StyledMain>
    </>
  );
};

export default SchoolsContent;
