import React, { useState, useCallback } from 'react';
import { CardContainer } from '../CardContainer';

export const randomId = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

export const StatefulTargetBox = (props) => {
  const { list, setList, loading } = props;
  const [lastDroppedColor, setLastDroppedColor] = useState(null);


  const handleAddItem = useCallback((item, initialContent = null) => {
    const newElement = { id: randomId(), type: item, content: initialContent };
    setLastDroppedColor(item);
    setList((prevState) => setList([...prevState, newElement]));
  }, [setList]);

  const handleDrop = useCallback((item) => handleAddItem(item), [handleAddItem]);

  return (
    <CardContainer
      {...props}
      cards={list}
      onDrop={handleDrop}
      setCards={setList}
      handleAddItem={handleAddItem}
      lastDroppedColor={lastDroppedColor}
      loading={loading}
    />
  );
};
