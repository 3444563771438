import React from 'react'
import { css } from '@emotion/core'

import CardHeader from '../CardHeader/index';

const ReportCard = ({ title, icon, onClick }) => {

  return (
    <div
      css={css`
        height: 241px;
        width: 241px;
      `}
      className="card mr-3 mb-3 cursor-pointer"
      onClick={onClick}>
      <CardHeader
        title={title}
      />
      <div className="px-3 py-2 text-center">
        <img src={icon} alt="report-icon" />
      </div>

    </div>
  )
}

export default ReportCard
