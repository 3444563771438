import { gql } from '@apollo/client';

// Queries
export const schools_data_exchange = gql`
  query schools_data_exchange($schoolId: uuid) {
    schools_data_exchange(where: { school_id: { _eq: $schoolId } }) {
      id
      is_export_active
      is_import_active
    }
  }
`;

export const schools_tpa = gql`
  query schools_tpa($schoolId: uuid) {
    enrollment_periods(
      where: { school_id: { _eq: $schoolId } }
      order_by: { academic_year: desc }
    ) {
      id
      academic_year
      schools_tpa_exports_files(order_by: { created_at: desc }) {
        id
        created_at
        school_id
        process_report
      }
    }
  }
`;
