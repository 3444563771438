import styled from '@emotion/styled'

export const TabsContainer = styled.div`
  .header {
    position: sticky;
    top: 125px;
    z-index: 996;
  }

  .nav {
    .nav-item {
      button {
        display: inline-block;
        font-family: Roboto, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: none;
        padding: 0.75rem 1rem 1rem;
        border: 0;
        border-bottom: 3px solid transparent;
        background-color: transparent !important;
        line-height: 33px;
        white-space: nowrap;

        &.active,
        &:hover {
          color: #fff;
          border-bottom-color: #33cabb;
        }
      }
    }
  }
`
