import React, { useEffect, useRef } from 'react';

export const Divider = ({ id, updateElement }) => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current.outerHTML;
    updateElement(id, element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <hr
        style={{
          borderTopColor: 'rgba(77,82,89,0.07)',
          margin: '2rem auto'
        }}
      />
    </div>
  );
};
