import { gql } from '@apollo/client';

// Queries
export const enrollmentPeriodsSegmentsBySchoolPeriod = gql`
query enrollment_periods_segments($schoolId: uuid, $enrollment_period_id: uuid ) {
  enrollment_periods_segments(where: {school_id: {_eq: $schoolId}, enrollment_period_id: {_eq: $enrollment_period_id}}) {
    coverage_end_date
    coverage_start_date
    enrollment_period_id
    id
    is_active
    segment_label
    enrollment_period {
      academic_year
    }
  }
}
`;

export const enrollmentPeriodsBySchool = gql`
query enrollment_periods($schoolId: uuid) {
  enrollment_periods(where: {school_id: {_eq: $schoolId}}, order_by: {academic_year: desc}) {
    academic_year
    created_at
    created_by
    id
    is_archived
    plan_end_date
    plan_start_date
    school_id
    updated_at
    validation_period
  }
}
`;