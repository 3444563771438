import React, { useState, useEffect, useContext } from 'react'
import useAxios from 'axios-hooks'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next';
import { SearchStateContext } from '../../context/SearchContext';
import { CSVLink } from "react-csv";

// Components
import Pagination from '../Pagination/index';
import Preloader from '../Preloader/index';
import SchoolFilter from './SchoolFilter'
// Queries

// Services
import { UserService } from '../../services/UserService';

const UsersReportContent = () => {
  const { setWithSearch, debouncedSearchKeyword } = useContext(SearchStateContext)
  const [schoolName, setSchoolName] = useState();
  const [selectedSchoolId, setSelectedSchoolId] = useState(UserService.getUser().school_id ? UserService.getUser().school_id : '');
  const [shouldDownload, setShouldDownload] = useState(false);
  const [csvName, setCsvName] = useState('');
  const [parsedUsersData, setParsedUsersData] = useState([])
  const refCsvLink = React.useRef(null);

  const { t } = useTranslation();

  // Queries
  const [{ data, loading }, executeGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${selectedSchoolId}/users`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
    params: {
      'filter[query]': debouncedSearchKeyword,
    },
  })

  const [{ data: exportData, loading: exportLoading }, executeExportGet] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/${selectedSchoolId}/users`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
    params: {
      'filter[query]': debouncedSearchKeyword,
    }
  },
    {
      manual: true,
    })

  const [{ data: schoolData }] = useAxios({
    url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/basic`,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('jwt')}`,
    },
  });

  const onPageChange = selectedPage => {
    executeGet({
      params: {
        page: selectedPage,
        'filter[query]': debouncedSearchKeyword,
      },
    })
  }

  useEffect(() => {
    setWithSearch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedSchoolId) {
      executeGet({
        params: {
          page: 1,
          'filter[query]': debouncedSearchKeyword,
        },
      })
    }
  }, [selectedSchoolId])

  useEffect(() => {
    if (schoolData) {
      schoolData.forEach(school => {
        if (school.id === selectedSchoolId)
          setSchoolName(school.school_name)
      });
    }
  }, [selectedSchoolId, schoolData])

  const handleExport = () => {
    setShouldDownload(true);
    if (selectedSchoolId) {
      executeExportGet({
        params: {
          page: 1,
          perPage: 100000,
          'filter[query]': debouncedSearchKeyword,
        },
      })
    }
  }

  useEffect(() => {
    if (shouldDownload) {
      if (exportData && exportData.data && exportData.data.length > 0 && refCsvLink.current && refCsvLink.current.link) {
        setParsedUsersData(parseUsersData(exportData.data))
      }
    }
  }, [exportData])

  useEffect(() => {
    if (shouldDownload && parseUsersData.length > 0 && schoolName) {
      let dt = new Date();
      let formatDate = dt.getFullYear().toString().padStart(4, '0') + (dt.getMonth() + 1).toString().padStart(2, '0') + dt.getDate().toString().padStart(2, '0') + dt.getHours().toString().padStart(2, '0') + dt.getMinutes().toString().padStart(2, '0') + dt.getSeconds().toString().padStart(2, '0')
      setCsvName(`Users Report ${schoolName} -${formatDate}.csv`)
    }
  }, [parsedUsersData])

  useEffect(() => {
    if (shouldDownload) {
      if (parsedUsersData && parsedUsersData.length > 0 && refCsvLink.current && refCsvLink.current.link) {
        refCsvLink.current.link.click();
        setShouldDownload(false);
        setParsedUsersData([])
      }
      setShouldDownload(false);
    }
  }, [csvName])

  const parseUsersData = (data) => {
    return data.map(
      ({ first_name, last_name, email_address, group_tags }) => ({
        "First Name": first_name,
        "Last Name": last_name,
        "Email": email_address,
        "Role": group_tags.join(','),
        "School": schoolName
      })
    );
  }

  return (
    <div className="container">
      <div className="row form-type-material">
        {
          (loading)
          && <Preloader customStyle={{ backgroundColor: 'transparent' }} />
        }
        {!UserService.getUser().school_id && (
          <div className="col-4">
          <SchoolFilter
            selectedSchoolId={selectedSchoolId}
            setSelectedSchoolId={setSelectedSchoolId}
          />
          </div>
        )}
        <div className="col mb-3">
          <button
            onClick={handleExport}
            className="mt-10 btn btn-bold d-block ml-auto btn-primary btn-label"
            type='submit'>
            <span>
              <i className='ti-download'></i>
            </span>
            {t('EXPORT_CSV')}
          </button>
          {data && (
            <CSVLink
              data={parsedUsersData}
              filename={csvName}
              className="hidden"
              target="_blank"
              ref={refCsvLink}
            >
            </CSVLink>
          )}
        </div>
      </div>
      <div className='card card-shadowed'>
        <div className='card-body'>
          <table className='table table-striped table-lg'>
            <thead>
              <tr>
                <th>{t('FIRST_NAME')}</th>
                <th>{t('LAST_NAME')}</th>
                <th>{t('EMAIL')}</th>
                <th>{t('ROLE')}</th>
                <th>{t('SCHOOL')}</th>
              </tr>
            </thead>
            <tbody
              css={css`
                .left-border{
                    border-left: 2px solid #f96868;
                }
                `}
            >
              {data &&
                data.data.map(user => {
                  return (
                    <tr key={user.id}>
                      <td>{user.first_name}</td>
                      <td>{user.last_name}</td>
                      <td>{user.email_address}</td>
                      <td>{user.group_tags.join(',')}</td>
                      <td>{schoolName || ''}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </div>
      {
        data && (
          <div className='mb-100'>
            <Pagination
              pages={data.last_page}
              onPageChange={onPageChange}
              from={data.from}
              to={data.to}
              total={data.total}
            />
          </div>
        )
      }
    </div>
  )
}

export default UsersReportContent
