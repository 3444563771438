import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import PreviewEmail from './PreviewEmail';
import { css } from '@emotion/core';
import './styles.css';
import { ViewportChanger } from './ViewportChanger';
import { SchoolStateContext } from '../../context/SchoolContext';
import useAxios from 'axios-hooks';
import { AlertService } from '../../services/AlertService';
import Select from 'react-select';

const EmailsPreviewModal = ({
  title,
  templateName,
  items,
  display,
  setDisplay,
  fromEdit,
  onCloseModal,
  selectedCreateType,
  loading,
  willEdit,
  executeCreate,
  executeEdit
}) => {
  const { t } = useTranslation();
  const [device, setDevice] = useState('desktop');
  const [schoolId] = useContext(SchoolStateContext);
  const [studentPreviewId, setStudentPreviewId] = useState('');
  const [segmentPreviewId, setSegmentPreviewId] = useState('');

  const handleSaveTemplate = useCallback(() => {
    //Aca se guarda el template
    //onCloseModal()
    if (selectedCreateType && items && templateName && willEdit == false) {
      executeCreate({
        data: {
          template_name: templateName,
          school_id: schoolId,
          type: selectedCreateType,
          items: JSON.stringify(items)
        }
      });
    } else if (willEdit == true) {
      executeEdit({
        data: {
          template_name: templateName,
          school_id: schoolId,
          type: selectedCreateType,
          items: JSON.stringify(items)
        }
      });
    }

    handleClose();
  });

  const handleClose = useCallback(() => {
    setDevice('desktop');
    setDisplay(false);
  });

  const [
    { response: previewResponse, error: errorPreview },
    executePreview
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/schools/mails-templates/preview/${studentPreviewId}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    {
      manual: true
    }
  );

  useEffect(() => {
    if (previewResponse && !errorPreview) {
      AlertService.showSuccess(t('PREVIEW_EMAIL_SUCCESS'));
    } else if (errorPreview) {
      AlertService.showError(t('ERROR_SOMETHING_WENT_WRONG'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewResponse, errorPreview]);

  const handleSendPreview = useCallback(() => {
    if (segmentPreviewId && studentPreviewId) {
      executePreview({
        data: {
          items: JSON.stringify(items),
          segment_id: segmentPreviewId
        }
      });
    }
    handleClose();
  });

  const [{ data: studentData, loading: studentLoading }, executeGet] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students${
        schoolId ? `?filter[query]=school_id:${schoolId}` : ''
      }`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );

  const [
    { data: segmentData, loading: segmentLoading },
    executeGetSegment
  ] = useAxios(
    {
      url: `${process.env.REACT_APP_BASE_URL}api/v1/students/${studentPreviewId}/segments-periods`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('jwt')}`
      }
    },
    { manual: true }
  );

  useEffect(() => {
    const page = 1;
    const perPage = 50;

    executeGet({
      params: {
        page,
        perPage
      }
    });
  }, []);

  useEffect(() => {
    if (studentData && studentData.data) {
      setStudentPreviewId(studentData.data[0].id);
    }
  }, [studentData]);

  useEffect(() => {
    if (studentPreviewId) {
      executeGetSegment();
    }
  }, [studentPreviewId]);

  useEffect(() => {
    if (segmentData) {
      setSegmentPreviewId(segmentData[0].id);
    }
  }, [segmentData]);

  /** */
  return (
    <Modal
      targetId="add-school-modal"
      width="w-75"
      height="h-100"
      display={display}
      setDisplay={setDisplay}
    >
      <div className="card-header">
        <h5 className="card-title fw-500">
          <span>
            <strong>{title}</strong> {templateName} - {t('PREVIEW')}
          </span>
        </h5>
        <div>
          <div className="justify-content-right">
            <ViewportChanger viewport={device} setViewport={setDevice} />
            <button onClick={() => handleClose()} className="btn border-0 p-0">
              <i
                css={css`
                  font-size: 25px;
                `}
                className="fa fa-close pb-1 "
              ></i>
            </button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="h-757px w-100">
          <div className="h-100 row w-100 m-0 p-0 justify-content-center">
            <PreviewEmail size={device} items={items} loading={loading} />
          </div>
        </div>
        <div className="row w-100 justify-content-center">
          {fromEdit && (
            <>
              <div className="mr-auto ml-30">
                <div style={{ display: 'inline-flex' }}>
                  <button
                    className={`btn btn-bold w-auto mt-20 btn-block btn-warning btn-label mr-1`}
                    type="submit"
                    onClick={() => handleClose()}
                  >
                    <label>
                      <i
                        css={css`
                          font-size: 18px;
                        `}
                        className="ti-pencil-alt"
                      ></i>
                    </label>
                    {t('BACK_TO_EDIT')}
                  </button>
                  {studentData && studentData.data && (
                    <div className="mr-1 mt-auto" style={{ width: '10rem' }}>
                      <Select
                        className="basic-single"
                        isSearchable={true}
                        classNamePrefix="select"
                        menuPlacement="top"
                        onChange={(e) => {
                          setStudentPreviewId(e.value);
                        }}
                        defaultValue={{
                          value: studentData.data[0].id,
                          label: `${studentData.data[0].first_name} ${studentData.data[0].last_name}`
                        }}
                        options={studentData.data.map((student, index) => {
                          return {
                            value: student.id,
                            label: `${student.first_name} ${student.last_name}`
                          };
                        })}
                      />
                    </div>
                  )}
                  {segmentData && (
                    <div className="mr-1 mt-auto" style={{ width: '10rem' }}>
                      <Select
                        className="basic-single"
                        isSearchable={true}
                        classNamePrefix="select"
                        menuPlacement="top"
                        onChange={(e) => {
                          setSegmentPreviewId(e.value);
                        }}
                        defaultValue={{
                          value: segmentData[0].id,
                          label: `${segmentData[0].segment_label} ${segmentData[0].period.academic_year}`
                        }}
                        options={segmentData.map((segment, index) => {
                          return {
                            value: segment.id,
                            label: `${segment.segment_label} ${segment.period.academic_year}`
                          };
                        })}
                      />
                    </div>
                  )}
                  <button
                    className={`btn btn-bold w-auto mt-20 btn-info btn-label`}
                    type="submit"
                    disabled={loading}
                    onClick={() => handleSendPreview()}
                  >
                    <label>
                      <i
                        css={css`
                          font-size: 18px;
                        `}
                        className="ti-check"
                      ></i>
                    </label>
                    {t('SEND_PREVIEW')}
                  </button>
                </div>
              </div>
              <div className="ml-auto">
                <button
                  className="btn btn-bold mt-20 btn-primary btn-label"
                  type="submit"
                  disabled={loading}
                  onClick={() => handleSaveTemplate()}
                >
                  <label>
                    <i
                      css={css`
                        font-size: 18px;
                      `}
                      className="ti-check"
                    ></i>
                  </label>
                  {t('SAVE_TEMPLATE')}
                </button>
              </div>
            </>
          )}
          {!fromEdit && (
            <div className="d-flex">
              {studentData && studentData.data && (
                <div className="mr-1 mt-auto" style={{ width: '10rem' }}>
                  <Select
                    className="basic-single"
                    isSearchable={true}
                    classNamePrefix="select"
                    menuPlacement="top"
                    onChange={(e) => {
                      setStudentPreviewId(e.value);
                    }}
                    defaultValue={{
                      value: studentData.data[0].id,
                      label: `${studentData.data[0].first_name} ${studentData.data[0].last_name}`
                    }}
                    options={studentData.data.map((student, index) => {
                      return {
                        value: student.id,
                        label: `${student.first_name} ${student.last_name}`
                      };
                    })}
                  />
                </div>
              )}
              <div className="ml-1 mt-auto" style={{ width: '10rem' }}>
                {segmentData && (
                  <Select
                    className="basic-single"
                    isSearchable={true}
                    classNamePrefix="select"
                    menuPlacement="top"
                    onChange={(e) => {
                      setSegmentPreviewId(e.value);
                    }}
                    defaultValue={{
                      value: segmentData[0].id,
                      label: `${segmentData[0].segment_label} ${segmentData[0].period.academic_year}`
                    }}
                    options={segmentData.map((segment, index) => {
                      return {
                        value: segment.id,
                        label: `${segment.segment_label} ${segment.period.academic_year}`
                      };
                    })}
                  />
                )}
              </div>
              <button
                className={`btn btn-bold w-auto ml-3 mt-20 btn-info btn-label`}
                type="submit"
                disabled={loading}
                onClick={() => handleSendPreview()}
              >
                <label>
                  <i
                    css={css`
                      font-size: 18px;
                    `}
                    className="ti-check"
                  ></i>
                </label>
                {t('SEND_PREVIEW')}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EmailsPreviewModal;
