import React, { memo, useEffect, useRef, useState } from 'react';
import Modal from '../Modal/Modal';
import { useForm } from 'react-hook-form';
import CardHeader from '../CardHeader';
import { useTranslation } from 'react-i18next';
import {
  getContentBetweenTags,
  getHrefFromAnchor,
  parseStylesToJsx
} from '../../utils/htmlToJsx';
import { css } from '@emotion/core';
import rgbHex from 'rgb-hex';

export const Button = ({ content, id, updateElement }) => {
  const parseInitialContent = () => {
    const contentUrl = getHrefFromAnchor(content) || '';
    const contentText = getContentBetweenTags(content, 'a');
    const backgroundColor = parseStylesToJsx(content)['backgroundColor'];
    const textColor = parseStylesToJsx(content)['color'];

    return {
      label: contentText || 'Button Text',
      url: contentUrl,
      textColor: textColor ? `#${rgbHex(textColor)}` : '#ffffff',
      backgroundColor: backgroundColor ? `#${rgbHex(backgroundColor)}` : '#33cabb'
    };
  };

  const [display, setDisplay] = useState(false);
  const [buttonSettings, setButtonSettings] = useState(parseInitialContent());

  const { label, url, textColor, backgroundColor } = buttonSettings;
  const buttonRef = useRef(null);

  useEffect(() => {
    const a = buttonRef.current.outerHTML.replace('<a', `<a href="${url}"`);
    updateElement(id, a);
  }, [buttonSettings]);

  return (
    <div
      className="center-v"
      css={css`
        min-height: 93px;
      `}
    >
      <a
        className="cursor-pointer"
        ref={buttonRef}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => setDisplay(true)}
        style={{
          color: textColor,
          backgroundColor,
          borderRadius: '5px',
          padding: '15px 33px',
          textDecoration: 'none',
          boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          border: 'none',
          fontSize: '16px',
          lineHeight: 1.31,
          display: 'inline-flex'
        }}
      >
        {label}
      </a>
      <ButtonModal
        buttonSettings={buttonSettings}
        display={display}
        setButtonSettings={setButtonSettings}
        setDisplay={setDisplay}
      />
    </div>
  );
};

const ButtonModal = memo(
  ({ display, setDisplay, buttonSettings, setButtonSettings }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, reset } = useForm({
      mode: 'onChange',
      defaultValues: buttonSettings
    });

    const onFormSubmit = (data) => {
      setButtonSettings(data);
      setDisplay(false);
      reset(data);
    };

    return (
      <Modal
        targetId="buttonEdit"
        display={display}
        setDisplay={setDisplay}
        inputCSS={css`
          .card-body {
            padding: 36px 30px;
          }
          .modal-content {
            overflow: unset !important;
          }
        `}
      >
        <CardHeader
          title={`${t('ADD')} ${'BUTTON'}`}
          rightComponent={
            <button
              onClick={() => setDisplay()}
              className="btn border-0 p-0 fs-18"
            >
              <i className="fa fa-close"></i>
            </button>
          }
        />

        <div className="card-body">
          <form
            onSubmit={handleSubmit(onFormSubmit)}
            className="form-type-material"
            css={css`
              .minicolors-swatch {
                border: 1px solid #707070;
              }
            `}
          >
            <div className="form-group do-float">
              <input
                type="text"
                className="form-control"
                name="label"
                id="label"
                ref={register}
              />
              <label className="label-floated" htmlFor="label">
                {t('LABEL')}
              </label>
            </div>
            <div className="form-group do-float">
              <input
                type="text"
                className="form-control"
                name="url"
                id="url"
                ref={register}
              />
              <label className="label-floated" htmlFor="url">
                Url
              </label>
            </div>
            <div className="flexbox">
              <div>
                <label className="label-floated" htmlFor="textColor">
                  {t('TEXT_COLOR')}
                </label>
                <input
                  type="text"
                  ref={register}
                  style={{ outline: 'none' }}
                  className="minicolors-input no-border"
                  data-provide="colorpicker"
                  name="textColor"
                  id="textColor"
                  data-position="top left"
                />
              </div>
              <div>
                <label className="label-floated" htmlFor="backgroundColor">
                  {t('BACKGROUND_COLOR')}
                </label>
                <input
                  ref={register}
                  type="text"
                  style={{ outline: 'none' }}
                  className="minicolors-input no-border"
                  data-provide="colorpicker"
                  name="backgroundColor"
                  id="backgroundColor"
                  data-position="top left"
                />
              </div>
            </div>

            <br />
            <button
              className={`btn btn-bold w-auto ml-auto btn-block btn-primary btn-label`}
              type="submit"
            >
              <label>
                <i className="ti-check"></i>
              </label>
              {t('ADD')}
            </button>
          </form>
        </div>
      </Modal>
    );
  }
);
